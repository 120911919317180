import { IFormInstance } from '../ui-core/V2/cfForm/cfForm';

export const scrollAndFocusFormItemError = (formErrors: any, timeoutInMs = 500) => {
  const errorNode = document.getElementById(formErrors?.name?.join('_'));
  errorNode?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  setTimeout(() => {
    errorNode?.focus();
  }, timeoutInMs);
};

export const scrollAndFocusFormItemErrorV2 = async (form?: IFormInstance) => {
  // The below code is added to find the first error field, and then if error exists calls
  // scrollAndFocusFormItemError(error) function which navigates to 1st formItem error field
  const errorFields = await form?.getFieldsError();

  const err = errorFields?.find((error: any) => error.errors?.length > 0);
  if (err) {
    scrollAndFocusFormItemError(err);
  }
};
