import { ApplicationStatus } from '@coverforce-platform/cf-common-types';

export const CONFIRMATION_PAGE_TITLE: { [key: string]: string } = {
  [ApplicationStatus.BOUND]: 'Bind Confirmation',
  [ApplicationStatus.REFERRED]: 'Referral Confirmation',
};

export const CONFIRMATION_STORE_ACTIONS = {
  NAME: 'Confirmation Store',
  ANONYMOUS_ACTION: 'Confirmation',
  POLICY_DOCUMENTS_LOADING_START: 'POLICY_DOCUMENTS_LOADING_START',
  POLICY_DOCUMENTS_LOADING_STOP: 'POLICY_DOCUMENTS_LOADING_STOP',
  POLICY_DOCUMENTS_FETCHED: 'POLICY_DOCUMENTS_FETCHED',
  POLICY_DOCUMENTS_ERROR: 'POLICY_DOCUMENTS_ERROR',
  POLICY_DETAILS_LOADING_START: 'POLICY_DETAILS_LOADING_START',
  POLICY_DETAILS_LOADING_STOP: 'POLICY_DETAILS_LOADING_STOP',
  POLICY_DETAILS_FETCHED: 'POLICY_DETAILS_FETCHED',
  POLICY_DETAILS_ERROR: 'POLICY_DETAILS_ERROR',
  CLEAR_CONFIRMATION: 'CLEAR_CONFIRMATION',
};

export const FILE_NAME = 'Policy Document(s)';

export const GA_CATEGORY = {
  CONFIRMATION_TAB_DOCUMENT: 'confirmationTab_document',
  CONFIRMATION_TAB_POLICY_DOCUMENT: 'confirmationTab_policyDocument',
};

export const GA_ACTION = {
  VIEW_DOCUMENT_BTN_CLICK: 'viewUploadDocumentBtn_click',
};

export const GA_LABEL = {
  VIEW_DOCUMENT_BTN_CLICK: 'View/Upload policy document button click.',
};
