import { useCallback, useState } from 'react';

import { normalizeFeinNumber } from '../../../helpers/Utils';
import Input from '../cfInput/cfInput';
import { IFein } from './interface';

const FeinNumber = ({ data, onChange, initialValue, id, style, value, disabled = false, ...rest }: IFein) => {
  const [fein, setFein] = useState(initialValue);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const val = normalizeFeinNumber(event?.target?.value) || '';
      setFein(val);
      onChange && onChange(val, data);
    },
    [data, onChange],
  );

  return (
    <Input
      placeholder='XX-XXXXXXX'
      id={id}
      disabled={disabled}
      {...rest}
      style={style}
      value={(fein || value || '').toString()}
      onChange={handleChange}
    />
  );
};

export default FeinNumber;
