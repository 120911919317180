import { Button } from 'antd';
import { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { BUTTON_TYPE } from '../../../globalConstants';
import { ICFButton } from './interface';
import { CFBaseButton } from './style';

const CFButton = (props: ICFButton) => {
  const { buttonType, onClick, data, stopPropagation = false, preventDefault = false } = props;
  const theme = useTheme();

  // custom handle click
  const handleOnClick = useCallback(
    (event) => {
      if (stopPropagation) {
        event.stopPropagation();
      }
      if (preventDefault) {
        event.preventDefault();
      }
      onClick && onClick(event, data);
    },
    [data, onClick, stopPropagation, preventDefault],
  );

  const ComputedCFButton = useMemo(() => {
    let computedProps;
    switch (buttonType) {
      case BUTTON_TYPE.ACTION:
        computedProps = {
          color: theme?.colorScheme?.primary,
          backgroundColor: theme?.colors.grayscale.white,
          border: 'none',
          transition: '0.2s all',
          boxShadow: theme?.custom.boxShadow.actionButton,
          hoverBackgroundColor: theme?.colorScheme?.primary,
          hoverColor: theme?.colors.grayscale.white,
          ...props,
        };
        return <CFBaseButton {...computedProps} onClick={handleOnClick} />;

      case BUTTON_TYPE.PRIMARY: {
        const modifiedHoverProps = theme?.colorScheme?.isPrimaryHoverLight
          ? {
              hoverColor: theme?.colorScheme?.primaryHover,
              hoverBackgroundColor: theme?.colorScheme?.primaryHoverLight,
            }
          : {
              hoverColor: theme?.colors.grayscale.white,
              hoverBackgroundColor: theme?.colorScheme?.primaryHover,
            };

        computedProps = {
          backgroundColor: theme?.colorScheme?.primary,
          borderColor: theme?.colorScheme?.primary,
          color: theme?.colors.grayscale.white,
          hoverBorderColor: theme?.colorScheme?.primaryHover,
          ...modifiedHoverProps,
          ...props,
        };
        return <CFBaseButton {...computedProps} onClick={handleOnClick} />;
      }

      case BUTTON_TYPE.SECONDARY:
        computedProps = {
          backgroundColor: theme?.colors.grayscale.white,
          hoverBackgroundColor: theme?.colors.grayscale.white,
          borderColor: theme?.colors.grayscale.grayBorder,
          color: theme?.colors.grayscale.black,
          hoverColor: theme?.colorScheme?.primary,
          hoverBorderColor: theme?.colorScheme?.primary,
          ...props,
        };
        return <CFBaseButton {...computedProps} onClick={handleOnClick} />;

      case BUTTON_TYPE.DELETE:
        computedProps = {
          danger: true,
          ...props,
        };
        return <Button {...computedProps} onClick={handleOnClick} />;

      case BUTTON_TYPE.FILE:
        computedProps = {
          hoverBackgroundColor: theme?.custom.fileContainer.bg,
          borderColor: theme?.custom.fileContainer.buttonBorder,
          hoverColor: theme?.colors.grayscale.black,
          hoverBorderColor: theme?.custom.fileContainer.hoverBorder,
          ...props,
        };
        return <CFBaseButton {...computedProps} onClick={handleOnClick} />;

      // Base button, Custom styling can be passed from parent without buttonType
      default:
        return <CFBaseButton {...props} onClick={handleOnClick} />;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonType, props]);

  // Render Button
  return ComputedCFButton;
};

export default CFButton;
