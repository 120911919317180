import dayjs, { extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { IEffectiveDateModalStore } from './interface';

extend(utc);

export const EFFECTIVE_DATE_MODAL_STORE = {
  NAME: 'Effective Date Modal Store',
  ANONYMOUS_ACTION: 'effectiveDateModal',
  SET_EFFECTIVE_DATE_MODAL_FORM: 'SET_EFFECTIVE_DATE_MODAL_FORM',
  UPDATE_EFFECTIVE_DATE_MODAL_BY_KEY: 'UPDATE_EFFECTIVE_DATE_MODAL_BY_KEY',
  CLEAR_EFFECTIVE_DATE_MODAL: 'CLEAR_EFFECTIVE_DATE_MODAL',
};

export const EFFECTIVE_DATE_ERROR = 'An error occurred, please try again later';

export const EFFECTIVE_DATE_MODAL_QUESTIONS_KEYS = {
  insuranceEffectiveDate: 'insuranceEffectiveDate',
  insuranceExpiryDate: 'insuranceExpiryDate',
};

export const EFFECTIVE_DATE_QUESTIONS = [
  {
    type: 'date',
    name: 'Policy Effective Date',
    dataIndex: EFFECTIVE_DATE_MODAL_QUESTIONS_KEYS.insuranceEffectiveDate,
    rules: [
      {
        required: true,
        validator(_: any, value: any) {
          if (!value) {
            return Promise.reject('Please enter Policy Effective Date.');
          }
          // if date is current or future then do nothing
          if (dayjs().diff(value, 'days') <= 0) {
            return Promise.resolve();
          }
          return Promise.reject("Policy Effective Date can't be past.");
        },
      },
    ],
    tooltipInfo: {
      description: 'The effective date for insurance coverage is the date that the coverage is officially active',
    },
    format: 'MM-DD-YYYY',
    disabledDate: (date: dayjs.Dayjs) => date && date <= dayjs().local().subtract(1, 'day'),
  },
  {
    type: 'date',
    name: 'Policy Expiration Date',
    dataIndex: EFFECTIVE_DATE_MODAL_QUESTIONS_KEYS.insuranceExpiryDate,
    rules: [
      {
        required: true,
        validator(_: any, value: any) {
          if (!value) {
            return Promise.reject('Please enter Policy Expiry Date.');
          }
          // if date is current or future then do nothing
          if (dayjs().diff(value, 'days') <= 0) {
            return Promise.resolve();
          }
          return Promise.reject("Policy Expiry Date can't be past.");
        },
      },
    ],
    format: 'MM-DD-YYYY',
    disabledDate: (date: dayjs.Dayjs) => date && date <= dayjs().local().subtract(0, 'day'),
  },
];

export const EFFECTIVE_DATE_MODAL_STORE_KEYS = {
  EFFECTIVE_DATE_MODAL_CALLBACK_DATA: 'effectiveDateModalCallbackData' as keyof IEffectiveDateModalStore,
};

export const EFFECTIVE_DATE_MODAL_CALLBACK_ACTION = { LIABILITY_LIMIT_CHANGE: 'LIABILITY_LIMIT_CHANGE' };
