import { useMemo } from 'react';

import { DropdownMode, ICFSelect } from './interface';
import { StyledCFSelect } from './style';

const CFSelect = (props: ICFSelect) => {
  const {
    data,
    onChange,
    onMultiModeChange,
    disabled,
    showSearch = true,
    getPopupContainer: customGetPopupContainer,
    value: selectedValue,
    options = [],
    mode,
    filterOption,
  } = props;

  const handleChange = (value: string | string[]) => {
    onChange && onChange(value as string, data);
    onMultiModeChange && onMultiModeChange(value as string[], data);
  };

  const getPopupContainer: Function = useMemo(
    () =>
      customGetPopupContainer
        ? customGetPopupContainer
        : (triggerNode: HTMLElement) => (triggerNode?.parentNode as HTMLElement) || document.body,
    [customGetPopupContainer],
  );

  const getValidSelectedValueByMode = () => {
    if (mode) {
      return mode === DropdownMode.MULTIPLE
        ? (selectedValue || []).filter((item: string) => options?.some((option) => option.value === item))
        : selectedValue || [];
    } else {
      return options?.find((item) => item?.value === selectedValue);
    }
  };

  const handleSingleModeSelect = (validSelectedValue?: string) => {
    if (selectedValue && !validSelectedValue) {
      onChange && handleChange('');
    }
    return validSelectedValue ? selectedValue : undefined;
  };

  const handleMultiModeSelect = (validSelectedValue: string[]) => {
    // If selectedValue.length > 0, means there is an existing value in the form of string[]
    // If validSelectedValue.length !== selectedValue?.length, means either 1 or all the selectedValue are now not allowed
    if (selectedValue?.length > 0 && selectedValue?.length !== validSelectedValue?.length) {
      onMultiModeChange && handleChange(validSelectedValue);
    }
    return validSelectedValue;
  };

  const getValidSelectedValue = () => {
    const validSelectedValue = getValidSelectedValueByMode();

    if (mode) {
      return handleMultiModeSelect(validSelectedValue);
    } else {
      return handleSingleModeSelect(validSelectedValue);
    }
  };

  return (
    <StyledCFSelect
      {...props}
      disabled={disabled}
      showSearch={showSearch}
      mode={mode}
      getPopupContainer={getPopupContainer}
      onChange={handleChange}
      value={getValidSelectedValue()}
      optionFilterProp={typeof filterOption === 'function' ? filterOption : 'label'}
    />
  );
};

export default CFSelect;
