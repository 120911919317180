import {
  IDeleteApplicationFileInput,
  IDownloadApplicationFileInput,
  IDownloadApplicationFileOutput,
  ISearchApplicationFilesInput,
  ISearchApplicationFilesOutput,
  IUploadApplicationFileInput,
} from '@coverforce-platform/cf-common-api-model';
import { AxiosResponse } from 'axios';

import axiosApplicationService from '../../axios/applicationService/instance';
import { createQueryUrl } from '../../utils/createQueryUrl';
import { APPLICATION_SERVICE_ENDPOINTS } from '../helpers/ApiRoutes';

const uploadFile = async (params: IUploadApplicationFileInput) => {
  const apiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.UPLOAD_FILE });
  try {
    const response: AxiosResponse = await axiosApplicationService.post(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const searchFile = async (params: ISearchApplicationFilesInput): Promise<ISearchApplicationFilesOutput> => {
  const apiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.SEARCH_FILE });
  try {
    const response: AxiosResponse = await axiosApplicationService.post(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const downloadFile = async (params: IDownloadApplicationFileInput): Promise<IDownloadApplicationFileOutput> => {
  const apiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.DOWNLOAD_FILE(params) });
  try {
    const response: AxiosResponse = await axiosApplicationService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const deleteFile = async (params: IDeleteApplicationFileInput) => {
  const apiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.DELETE_FILE(params) });
  try {
    const response: AxiosResponse = await axiosApplicationService.delete(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export { uploadFile, searchFile, downloadFile, deleteFile };
