import { IApplicationInfo, ILocationInfo } from '@coverforce-platform/cf-common-api-model';
import { ApplicationStatus, ApplicationTag, PolicyType } from '@coverforce-platform/cf-common-types';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';

import { POLICY_LIABILITY_LIMITS } from '../../constants/applicationV2Constants';
import { PAGE_ROUTES } from '../../globalConstants';
import { IPolicyLiabilityLimits } from '../../globalInterface';
import { useApplicationStore } from '../../pages/v2/application/store';
import { getPolicyFromApplication } from '../../pages/v2/application/utils';

export const getLiabilityLimitsByPolicy = (policyType?: PolicyType): IPolicyLiabilityLimits => {
  const { policyType: applicationPolicyType } = useApplicationStore.getState();
  const selectedPolicyType = policyType || applicationPolicyType;

  if (selectedPolicyType) {
    const policyLiabilityLimits = POLICY_LIABILITY_LIMITS[selectedPolicyType];
    const {
      liabilityLimitKeys = [],
      liabilityLimitTexts = [],
      liabilityLimitTooltips = [],
    } = policyLiabilityLimits || {};
    return { liabilityLimitKeys, liabilityLimitTexts, liabilityLimitTooltips };
  } else {
    return { liabilityLimitKeys: [], liabilityLimitTexts: [], liabilityLimitTooltips: [] };
  }
};

export const isPastInsuranceEffectiveDate = (applicationData?: IApplicationInfo): boolean => {
  const { applicationData: storeApplicationData } = useApplicationStore.getState();
  const selectedApplication = applicationData || storeApplicationData;
  const { applicationDetails } = selectedApplication || {};
  const { insuranceEffectiveDate } = applicationDetails || {};
  return insuranceEffectiveDate ? dayjs().diff(insuranceEffectiveDate, 'days') > 0 : true;
};

export const checkApplicationEligibilityForBound = (statusCode: ApplicationStatus) => {
  if (
    [
      ApplicationStatus.ACORD_IN_PROGRESS,
      ApplicationStatus.BOUND,
      ApplicationStatus.DELETED,
      ApplicationStatus.QUOTING,
      ApplicationStatus.BIND_IN_PROGRESS,
    ].includes(statusCode)
  ) {
    return false;
  }
  return true;
};

export const isMailingAddressSameAsPrimaryAddress = (applicationData?: IApplicationInfo) => {
  const { applicationData: storeApplicationData } = useApplicationStore.getState();
  const policyType = getPolicyFromApplication(storeApplicationData);
  const selectedApplicationData = applicationData || storeApplicationData;
  const primaryAddress = selectedApplicationData?.locationDetails?.find((location) => location.isPrimary)?.address;
  const mailingAddress = selectedApplicationData?.basicBusinessDetails?.mailingAddress;

  let mailingAddressSameAsPrimaryAddress = false;
  if (mailingAddress && primaryAddress) {
    mailingAddressSameAsPrimaryAddress = isEqual(mailingAddress, primaryAddress);
  }
  if (
    ((!mailingAddress && !primaryAddress) || (!mailingAddress && primaryAddress)) &&
    isApplicationGeneratedFromAcord(applicationData?.applicationTags) &&
    policyType !== PolicyType.BR
  ) {
    mailingAddressSameAsPrimaryAddress = true;
  }

  return mailingAddressSameAsPrimaryAddress;
};

export const getViewApplicationLink = (applicationData: IApplicationInfo) => {
  if (applicationData?.applicationStatus?.statusCode !== ApplicationStatus.ACORD_IN_PROGRESS) {
    return `${PAGE_ROUTES.APPLICATION}${applicationData?.applicationId}`;
  }
};

export const getAddressAsText = (location: ILocationInfo) => {
  let computedString = '';
  if (location?.address?.addressLine1) {
    computedString = `${location?.address?.addressLine1}`;
  }
  if (location?.address?.addressLine2) {
    computedString = `${computedString}, ${location?.address?.addressLine2}`;
  }
  if (location?.address?.city) {
    computedString = `${computedString}, ${location?.address?.city}`;
  }
  if (location?.address?.state) {
    computedString = `${computedString}, ${location?.address?.state}`;
  }
  if (location?.address?.zip) {
    computedString = `${computedString}, ${location?.address?.zip}`;
  }
  return computedString;
};

export const isApplicationGeneratedFromAcord = (applicationTags?: ApplicationTag[]) => {
  return applicationTags?.includes(ApplicationTag.CREATED_FROM_ACORD);
};
