import { Carrier } from '@coverforce-platform/cf-common-types';
import { useState } from 'react';

import { APPLICATION_STORE_KEYS, UPDATE_APPLICATION_STEPS } from '../../../constants/applicationV2Constants';
import { useApplicationStore } from '../../../pages/v2/application/store';
import { isOwnerOfficersEnabled } from '../../../pages/v2/application/utils';
import { errorV2, success } from '../../../ui-core/Notification';
import CFModal from '../../../ui-core/V2/cfModal/cfModal';
import { useCFModalStore } from '../../../ui-core/V2/cfModal/store';
import { pushEvent } from '../../../utils/googleAnalytics';
import { useIndustrySelectionStore } from '../../industrySelection/store';
import { OWNER_OFFICER_DETAILS_STORE_KEYS } from '../../ownerOfficerDetails/constants';
import { useOwnerOfficerDetailsStore } from '../../ownerOfficerDetails/store';
import { GA_ACTION, GA_LABEL } from './constants';
import { useDeleteCarrierStore } from './store';
import { getGACategory } from './utils';

const DeleteCarrierModal = () => {
  const {
    selectedCarriersInfo,
    applicationData,
    currentStep,
    updateApplicationByKey,
    updateApplication,
    fetchApplicationFieldsConfig,
  } = useApplicationStore();
  const { updateSelectedCarriers } = useDeleteCarrierStore();
  const { clearCfModal } = useCFModalStore();
  const { updateOwnerOfficerDetailsStoreByKey } = useOwnerOfficerDetailsStore();
  const { deletingCarrier, setDeletingCarrier } = useDeleteCarrierStore();
  const { carrierIndustrySelections, updateCarrierIndustrySelections } = useIndustrySelectionStore();

  const [isDeletingCarrier, setIsDeletingCarrier] = useState(false);

  const handleDeleteCarrier = async () => {
    if (deletingCarrier) {
      pushEvent({
        category: getGACategory(),
        action: GA_ACTION.DELETE_CARRIER_BTN_CLICK,
        label: GA_LABEL.DELETE_CARRIER_BTN_CLICK,
      });
      setIsDeletingCarrier(true);
      const selectedCarriers = [...selectedCarriersInfo];
      const industrySelections = [...carrierIndustrySelections];
      try {
        const updatedCarriers = selectedCarriersInfo?.filter(
          (carrier) => carrier?.carrierId !== deletingCarrier?.carrierId,
        );
        // If the deleted carrier is TRAVELERS then resetting ownerOfficer details list.
        if (isOwnerOfficersEnabled() && deletingCarrier.carrierId === Carrier.TRAVELERS) {
          await updateOwnerOfficerDetailsStoreByKey(OWNER_OFFICER_DETAILS_STORE_KEYS.OWNER_OFFICER_DETAILS_LIST, []);
          await updateApplication({ updateApplicationStep: UPDATE_APPLICATION_STEPS.OWNEROFFICER });
        }
        await updateSelectedCarriers(updatedCarriers);
        success(`Carrier ${deletingCarrier.carrierShortName} deleted successfully!`);

        await fetchApplicationFieldsConfig({ applicationId: applicationData?.applicationId! });
      } catch (err: any) {
        errorV2(err?.[0]?.errorMessage);
        updateApplicationByKey(APPLICATION_STORE_KEYS.SELECTED_CARRIERS_INFO, selectedCarriers);
        if (currentStep === UPDATE_APPLICATION_STEPS.INDUSTRY_SELECTION) {
          updateCarrierIndustrySelections(industrySelections);
        }
      } finally {
        updateApplicationByKey(APPLICATION_STORE_KEYS.IS_APPLICATION_LOADING, false);
        setDeletingCarrier(undefined);
        setIsDeletingCarrier(false);
        clearCfModal();
      }
    }
  };

  const handleDeleteCarrierCancel = () => {
    pushEvent({
      category: getGACategory(),
      action: GA_ACTION.CLOSE_MODAL_BTN_CLICK,
      label: GA_LABEL.CLOSE_MODAL_BTN_CLICK,
    });
    setDeletingCarrier(undefined);
  };

  return (
    <CFModal
      title='Delete Carrier'
      onOk={handleDeleteCarrier}
      onCancel={handleDeleteCarrierCancel}
      cancelButtonProps={{ id: 'mod_btn_delete_carrier_cancel', disabled: isDeletingCarrier }}
      okButtonProps={{ id: 'mod_btn_delete_carrier_ok', loading: isDeletingCarrier }}
      okText='Yes'
      cancelText='No'
      confirmLoading={isDeletingCarrier}
      disableCloseIcon={isDeletingCarrier}
      maskClosable={!isDeletingCarrier}
    >
      Are you sure you want to remove this carrier? The carrier cannot be added back to the application once deleted.
    </CFModal>
  );
};

export default DeleteCarrierModal;
