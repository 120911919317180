import { ValidateStatus } from 'antd/lib/form/FormItem';

import { getStateRegex, getZipRegex } from '../../../helpers/Utils';
import { get, isEmpty, omit } from '../../../utils/lodash';
import { Address, IAddressComponents, ILocationValidationProps } from './interface';

const getValidationProps = (
  address: Address,
  dataIndex: string,
  showError: boolean,
  message?: string,
): ILocationValidationProps => {
  const value = get(address, dataIndex);
  let validateStatus: ValidateStatus;

  // AddressLine2 is not required so it is skipped for validation
  if (dataIndex !== 'addressLine2' && showError) {
    validateStatus = value?.trim() ? 'success' : 'error';
  } else {
    validateStatus = 'success';
  }

  if (dataIndex === 'city') {
    if (!value?.trim() && showError) {
      validateStatus = 'error';
      message = 'Please enter city.';
    }
    if (value?.trim()) {
      validateStatus = 'success';
    }
  }

  if (dataIndex === 'state') {
    if (!value?.trim() && showError) {
      validateStatus = 'error';
      message = 'Please enter state name.';
    }
    if (value?.trim() && getStateRegex().test(value?.trim())) {
      validateStatus = 'success';
    }
    if (value?.trim() && !getStateRegex().test(value?.trim()) && showError) {
      validateStatus = 'error';
      message = 'Please enter valid state name.';
    }
  }

  if (dataIndex === 'zip') {
    if (!value?.toString()?.trim() && showError) {
      validateStatus = 'error';
      message = 'Please enter zipcode.';
    }
    if (value?.toString()?.trim() && getZipRegex().test(value?.toString()?.trim())) {
      validateStatus = 'success';
    }
    if (value?.toString()?.trim() && !getZipRegex().test(value?.toString()?.trim()) && showError) {
      validateStatus = 'error';
      message = 'Please enter valid zipcode.';
    }
  }

  return {
    validateStatus,
    help: validateStatus === 'error' ? message || '' : '',
  };
};

const instanceOfAddressComponents = (array: any): array is IAddressComponents => {
  return get(array, `[0].types`) !== undefined;
};

const finderOfComponentType = (type: string) => (addressComponents: IAddressComponents) => {
  const component = addressComponents.find((comp) => comp.types.includes(type));

  return component ? component.short_name : '';
};

const getAddressComponent = (comps: IAddressComponents, type: string) => {
  return finderOfComponentType(type)(comps);
};

const getAddressLine1 = (comps: IAddressComponents) => {
  return `${getAddressComponent(comps, 'street_number')} ${getAddressComponent(comps, 'route')}`.trim();
};

const getCity = (comps: IAddressComponents) => {
  return getAddressComponent(comps, 'locality') || getAddressComponent(comps, 'sublocality');
};

const isAddressFilled = (address?: Address) => {
  if (address === undefined) {
    return false;
  }
  return Object.values(address).some((item) => !isEmpty(item?.trim()));
};

const validateAddress = (address: Address) => {
  if (address === undefined) {
    return false;
  }

  // AddressLine2 is optional field so removing it before validation
  const requiredAddressFields = omit(address, 'addressLine2');

  if (
    requiredAddressFields?.hasOwnProperty('addressLine1') &&
    requiredAddressFields?.hasOwnProperty('city') &&
    requiredAddressFields?.hasOwnProperty('state') &&
    requiredAddressFields?.hasOwnProperty('zip')
  ) {
    const state = address?.state?.trim();
    const zipCode = address?.zip?.trim();
    return (
      Object.values(requiredAddressFields).every((item) => !isEmpty(item?.trim())) &&
      getStateRegex().test(state) &&
      getZipRegex().test(zipCode)
    );
  }
  return false;
};

const isAddressEmpty = (address: Address) => {
  if (!address) {
    return true;
  }

  return Object.values(address).every((item) => isEmpty(item?.trim()));
};

export {
  getValidationProps,
  instanceOfAddressComponents,
  finderOfComponentType,
  getAddressLine1,
  getCity,
  getAddressComponent,
  validateAddress,
  isAddressFilled,
  isAddressEmpty,
};
