import dayjs from 'dayjs';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { useApplicationStore } from '../../pages/v2/application/store';
import { IFormInstance } from '../../ui-core/V2/cfForm/cfForm';
import { EFFECTIVE_DATE_MODAL_QUESTIONS_KEYS, EFFECTIVE_DATE_MODAL_STORE } from './constants';
import { IEffectiveDateModalStore } from './interface';
import { getEffectiveDateModalPayloadUtil } from './utils';

export const useEffectiveDateModalStore = create<IEffectiveDateModalStore>()(
  devtools(
    (set, get) => ({
      effectiveDataModalForm: undefined,
      effectiveDateModalCallbackData: undefined,

      setEffectiveDateModalForm: (formObj: IFormInstance) => {
        set({ effectiveDataModalForm: formObj }, false, EFFECTIVE_DATE_MODAL_STORE.SET_EFFECTIVE_DATE_MODAL_FORM);
      },

      autoFillEffectiveDateModalForm: () => {
        const { effectiveDataModalForm } = get();
        const { applicationData } = useApplicationStore.getState();
        const { applicationDetails } = applicationData || {};
        const { insuranceEffectiveDate, insuranceExpiryDate } = applicationDetails || {};

        effectiveDataModalForm?.setFieldsValue({
          [EFFECTIVE_DATE_MODAL_QUESTIONS_KEYS.insuranceEffectiveDate]: insuranceEffectiveDate
            ? dayjs(insuranceEffectiveDate)
            : undefined,
          [EFFECTIVE_DATE_MODAL_QUESTIONS_KEYS.insuranceExpiryDate]: insuranceExpiryDate
            ? dayjs(insuranceExpiryDate)
            : undefined,
        });
      },

      validateEffectiveDateModalForm: async () => {
        const { effectiveDataModalForm } = get();

        try {
          await effectiveDataModalForm?.validateFields();
          return true;
        } catch (err) {
          return false;
        }
      },

      getEffectiveDateModalApiPayload: () => {
        return getEffectiveDateModalPayloadUtil();
      },

      updateEffectiveDateModalByKey: async (storeKey: keyof IEffectiveDateModalStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, EFFECTIVE_DATE_MODAL_STORE.UPDATE_EFFECTIVE_DATE_MODAL_BY_KEY),

      clearEffectiveDateModal: () => {
        const { effectiveDataModalForm } = get();
        effectiveDataModalForm?.resetFields();
        set(
          {
            effectiveDataModalForm: undefined,
            effectiveDateModalCallbackData: undefined,
          },
          false,
          EFFECTIVE_DATE_MODAL_STORE.CLEAR_EFFECTIVE_DATE_MODAL,
        );
      },
    }),
    { anonymousActionType: EFFECTIVE_DATE_MODAL_STORE.ANONYMOUS_ACTION, name: EFFECTIVE_DATE_MODAL_STORE.NAME },
  ),
);
