import { IBrokerCodeAgentInfo, IGetAvailableCarriersForAgentOutput } from '@coverforce-platform/cf-common-api-model';
import { Carrier, IBrokerConfigurationId } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  addBrokerCode,
  deleteBrokerCode,
  getAgentDefaultBrokerCodes,
  getBrokerCodesByAgencyId,
  setDefaultBrokerCode,
  updateBrokerCode,
} from '../../../externalServices/V2/brokerConfig';
import { getAvailableCarriers } from '../../../externalServices/V2/carriersV2';
import { deNormalizePhoneNumber } from '../../../helpers/Utils';
import { IUserData } from '../../../types';
import { useProfileV2Store } from '../profile/store';
import { IBrokerConfigStore } from './interface';

const initialCarriersData = {
  carriers: [],
  isLoadingCarrier: true,
  carrierError: null,
};

const initialBrokerConfigData = { brokerIdConfigs: {}, brokerConfigDataLoading: false, brokerConfigError: null };

export const useBrokerConfigStore = create<IBrokerConfigStore>()(
  devtools(
    (set, get) => ({
      carriersStore: initialCarriersData,
      brokerConfigDataStore: initialBrokerConfigData,

      fetchBrokerConfigs: async ({ agentId, agencyId, loading = true }) => {
        try {
          set(
            {
              brokerConfigDataStore: {
                ...get().brokerConfigDataStore,
                brokerConfigDataLoading: loading,
              },
            },
            false,
            'fetchBrokerConfigsDataSet',
          );
          const agencyBrokerCodes = await getBrokerCodesByAgencyId({
            agencyId,
          });
          const defaultBrokerCodes = await getAgentDefaultBrokerCodes({
            agentId,
          });
          const brokerIdConfigs: { [key: string]: IBrokerCodeAgentInfo[] } = {};
          agencyBrokerCodes.brokerCodes.forEach((item) => {
            const defaultBrokerCode = defaultBrokerCodes.defaultBrokerCodes.find(
              (code) => code.brokerConfigurationId === item.brokerConfigurationId,
            );
            if (item.isEnabled) {
              if (brokerIdConfigs[item.carrierId]) {
                brokerIdConfigs[item.carrierId].push({
                  ...item,
                  isDefaultCode: !!defaultBrokerCode,
                });
              } else {
                brokerIdConfigs[item.carrierId] = [
                  {
                    ...item,
                    isDefaultCode: !!defaultBrokerCode,
                  },
                ];
              }
            }
          });
          set(
            {
              brokerConfigDataStore: {
                brokerIdConfigs,
                brokerConfigDataLoading: false,
                brokerConfigError: null,
              },
            },
            false,
            'fetchBrokerConfigsDataSet',
          );
        } catch (err) {
          set(
            {
              brokerConfigDataStore: {
                brokerIdConfigs: {},
                brokerConfigDataLoading: false,
                brokerConfigError: err,
              },
            },
            false,
            'fetchBrokerConfigsDataSet',
          );
        }
      },

      addNewBrokerId: async ({ carrierId, newBrokerIdConfig, userData }) => {
        const { selectedUserProfile } = useProfileV2Store.getState();
        carrierId &&
          (await addBrokerCode({
            ...newBrokerIdConfig,
            carrierId,
            agencyOrNetworkId: selectedUserProfile?.agencyId || '',
            contactDetails: {
              ...newBrokerIdConfig?.contactDetails,
              phone:
                newBrokerIdConfig?.contactDetails?.phone &&
                deNormalizePhoneNumber(newBrokerIdConfig?.contactDetails?.phone),
            },
          }));
        get().fetchBrokerConfigs({
          agentId: userData?.agentId || '',
          agencyId: selectedUserProfile?.agencyId || '',
        });
      },

      updateBrokerId: async ({ carrierId, updatedBrokerConfig }) => {
        const { isDefaultCode, ...rest } = updatedBrokerConfig;
        const { userData, selectedUserProfile } = useProfileV2Store.getState();
        carrierId &&
          (await updateBrokerCode({
            ...rest,
            carrierId: Carrier[carrierId as keyof typeof Carrier],
            agencyOrNetworkId: selectedUserProfile?.agencyId || '',
            brokerConfigurationId: updatedBrokerConfig.brokerConfigurationId,
            contactDetails: {
              ...rest.contactDetails,
              phone: rest?.contactDetails?.phone && deNormalizePhoneNumber(rest.contactDetails.phone),
            },
          }));
        get().fetchBrokerConfigs({
          agentId: userData?.agentId || '',
          agencyId: selectedUserProfile?.agencyId || '',
        });
      },

      deleteBrokerId: async ({
        carrierId,
        brokerConfigurationId,
        userData,
      }: {
        carrierId: Carrier;
        brokerConfigurationId: IBrokerConfigurationId;
        userData?: IUserData;
      }) => {
        const { selectedUserProfile } = useProfileV2Store.getState();
        carrierId &&
          (await deleteBrokerCode({
            carrierId,
            brokerConfigurationId,
            agencyOrNetworkId: selectedUserProfile?.agencyId || '',
          }));
        get().fetchBrokerConfigs({
          agentId: userData?.agentId || '',
          agencyId: selectedUserProfile?.agencyId || '',
        });
      },

      setDefaultBrokerConfig: async ({ carrierId, brokerConfigurationId, userData }) => {
        set(
          {
            brokerConfigDataStore: {
              ...get().brokerConfigDataStore,
              brokerConfigDataLoading: true,
            },
          },
          false,
          'setDefaultBrokerConfigLoadingStart',
        );

        const { selectedUserProfile } = useProfileV2Store.getState();

        await setDefaultBrokerCode({
          agentId: userData?.agentId || '',
          carrierId,
          brokerConfigurationId,
        });
        get().fetchBrokerConfigs({
          agentId: userData?.agentId || '',
          agencyId: selectedUserProfile?.agencyId || '',
        });
      },

      fetchAllCarriers: async ({ agentId }) => {
        try {
          const data: IGetAvailableCarriersForAgentOutput = await getAvailableCarriers({
            agentId,
          });
          set(
            { carriersStore: { carriers: data.availableCarriers, isLoadingCarrier: false, carrierError: null } },
            false,
            'fetchAllCarriersDataSet',
          );
          return data.availableCarriers;
        } catch (err: any) {
          set(
            { carriersStore: { carriers: [], isLoadingCarrier: false, carrierError: err } },
            false,
            'fetchAllCarriersDataSet',
          );
          return err;
        }
      },

      clearBrokerConfigs: () =>
        set(
          { carriersStore: initialCarriersData, brokerConfigDataStore: initialBrokerConfigData },
          false,
          'clearBrokerConfigs',
        ),
    }),

    { anonymousActionType: 'brokerConfig' },
  ),
);
