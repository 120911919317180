import { Empty, Table } from 'antd';
import styled from 'styled-components';

import CFButton from '../cfButton/cfButton';
import { CFTableProps } from './interface';

export const CFTableStyled = styled(Table)<CFTableProps>`
  table {
    border: 1px solid ${(p) => p.theme.colors.primary.border};
    border-top: ${(p) => p.hasHeader && 'none'};
    box-shadow: ${(p) => p.theme.custom.boxShadow.table};
  }

  .ant-spin-dot-item {
    background-color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-pagination-item-active {
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-pagination-item-active a {
    color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-pagination-item:hover {
    a {
      color: ${(p) => p?.theme?.colorScheme?.primary};
    }
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-pagination-item-link:hover {
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
    span {
      color: ${(p) => p?.theme?.colorScheme?.primary};
    }
  }

  .ant-table-thead > tr > th.ant-table-cell {
    background-color: ${(p) => p.theme.colors.primary.bgMuted};
    font-weight: ${(p) => p.theme.typography.fontWeight.bold};
    font-size: 12px;
    color: ${(p) => p?.theme?.colors?.grayscale?.black};
    text-transform: uppercase;
    padding-left: 19px !important;
    padding-right: 19px !important;
  }

  .ant-table-container table > thead > tr:first-child th:first-child,
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-left-radius: ${(p) => p.hasHeader && '0px'};
  }

  .ant-table-expanded-row-fixed {
    width: inherit !important;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: ${(p) => p.theme.colors.primary.blackOpacity};
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-table-row {
    cursor: ${(p) => p.fullRowClickable && 'pointer'};

    :active {
      background-color: ${(p) => p.theme.colors.primary.bgMuted};
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${(p) => p.theme.colors.navBar.dropDownItemSelectedBgColor};
  }

  td {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${(p) => p.theme.colors.primary.border};
  }

  .ant-checkbox-checked > .ant-checkbox-inner {
    background-color: ${(p) => p?.theme?.colorScheme?.primary};
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-checkbox-checked::after {
    border-color: ${(p) => p?.theme?.colorScheme?.primary} !important;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
    ::after {
      background-color: ${(p) => p?.theme?.colorScheme?.primary};
    }
  }

  .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-radio-input:hover + .ant-radio-inner {
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-table-filter-dropdown .ant-table-filter-dropdown-btns .ant-btn-link {
    color: ${(p) => p?.theme?.colorScheme?.primary};

    :hover {
      color: ${(p) => p?.theme?.colorScheme?.primaryHover};
    }
  }

  .ant-table-filter-dropdown .ant-table-filter-dropdown-btns .ant-btn-link[disabled],
  .ant-link[disabled]:hover {
    color: ${(p) => p.theme.colors.grayscale.blackOpacity};
  }
  .ant-table-filter-dropdown .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background-color: initial;
  }
  .ant-table-filter-dropdown .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.5);
  }

  .ant-btn-primary {
    background-color: ${(p) => p?.theme?.colorScheme?.primary};
    color: ${(p) => p?.theme?.colors.grayscale.white};
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
    :hover {
      background-color: ${(p) =>
        p?.theme?.colorScheme?.isPrimaryHoverLight
          ? p?.theme?.colorScheme?.primaryHoverLight
          : p.theme?.colorScheme?.primaryHover};
      color: ${(p) =>
        p?.theme?.colorScheme?.isPrimaryHoverLight
          ? p?.theme?.colorScheme?.primaryHover
          : p?.theme?.colors.grayscale.white};
      border-color: ${(p) => p?.theme?.colorScheme?.primaryHover};
    }
  }

  .ant-dropdown-menu-item-selected {
    color: ${(p) => p?.theme?.colorScheme?.primary};
    background-color: ${(p) => p.theme.colors.navBar.dropDownItemSelectedBgColor};
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${(p) => p.theme?.colorScheme?.primaryHover};
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: ${(p) => p.theme?.colorScheme?.primaryHover};
    box-shadow: none;
  }
  .ant-table-filter-trigger.active {
    color: ${(p) => p?.theme?.colorScheme?.primary};
  }
`;

export const ActionsButtonStyled = styled(CFButton)`
  .anticon {
    font-size: ${(p) => p.theme.typography?.fontSize?.mediumLarge};
  }
`;

export const TableEmptyStyled = styled(Empty)`
  margin: 40px auto;
`;

export const CFTableHeaderStyled = styled.div`
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${(p) => p.theme?.colorScheme?.primaryHover};
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: ${(p) => p.theme?.colorScheme?.primaryHover};
    box-shadow: none;
  }
  background-color: ${(p) => p.theme.colors.grayscale.white};
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid ${(p) => p.theme.colors.primary.border};
  box-shadow: ${(p) => p.theme.custom.boxShadow.table};
  border-bottom: none;
  margin-top: 15px;
`;
