import { Button, ButtonProps } from 'antd';
import styled from 'styled-components';

// import { ICFButton } from './interface';

// TO-DO:- Fix for "props: any"
export const CFBaseButton: React.FunctionComponent<ButtonProps | any> = styled(Button)
  // .attrs((props: ICFButton) => props)
  .attrs((props: any) => props)
  .withConfig({
    shouldForwardProp: (props: any) =>
      ![
        'backgroundColor',
        'borderColor',
        'hoverColor',
        'hoverBackgroundColor',
        'hoverBackground',
        'hoverBorderColor',
        'boxShadow',
        'hoverBorder',
        'focusBackgroundColor',
        'focusColor',
        'focusBorderColor',
        'buttonType',
        'fontWeight',
      ].includes(props),
  })`
  border: ${(p) => p.border && p.border};
  border-color: ${(p) => p.borderColor ?? 'inherit'};
  color: ${(p) => p.color ?? 'inherit'};
  background: ${(p) => p.background && p.background};
  background-color: ${(p) => p.backgroundColor ?? 'inherit'};
  transition: ${(p) => p.transition && p.transition};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};

  :hover {
    border-color: ${(p) => (!p.disabled ? p.hoverBorderColor ?? 'inherit' : 'default')} !important;
    color: ${(p) => (!p.disabled ? p.hoverColor ?? 'inherit' : 'default')} !important;
    background-color: ${(p) => (!p.disabled ? p.hoverBackgroundColor ?? 'inherit' : 'default')} !important;
  }

  :focus {
    border-color: ${(p) => p.borderColor ?? 'inherit'};
    color: ${(p) => p.color ?? 'inherit'};
    background-color: ${(p) => p.backgroundColor ?? 'inherit'};
  }
`;
