import {
  IApplicationInfo,
  IBindQuoteInput,
  IBindQuoteOutput,
  IGetPaymentPlansInput,
  IGetPaymentPlansOutput,
  ISelectPaymentPlanInput,
  ISelectPaymentPlanOutput,
} from '@coverforce-platform/cf-common-api-model';
import { ApplicationStatus } from '@coverforce-platform/cf-common-types';
import { AxiosResponse } from 'axios';

import axiosApplicationService from '../../axios/applicationService/instance';
import axiosApplicationServiceV2 from '../../axios/applicationServiceV2/instance';
import { IPoll } from '../../globalInterface';
import { createQueryUrl } from '../../utils/createQueryUrl';
import sleep from '../../utils/sleep';
import { APPLICATION_SERVICE_ENDPOINTS } from '../helpers/ApiRoutes';
import { getApplication } from './applicationV2';

const getPaymentPlans = async (params: IGetPaymentPlansInput): Promise<IGetPaymentPlansOutput> => {
  const getPaymentPlansApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.GET_PAYMENT_PLANS(params) });

  try {
    const response: AxiosResponse = await axiosApplicationService.get(getPaymentPlansApiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const selectPaymentPlan = async (params: ISelectPaymentPlanInput): Promise<ISelectPaymentPlanOutput> => {
  const selectPaymentPlanApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.SELECT_PAYMENT_PLAN });

  try {
    const response: AxiosResponse = await axiosApplicationService.post(selectPaymentPlanApiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const bindApplication = async (params: IBindQuoteInput): Promise<IBindQuoteOutput> => {
  const bindApplicationApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.BIND_APPLICATION });

  try {
    const response: AxiosResponse = await axiosApplicationService.post(bindApplicationApiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const bindApplicationV2 = async (params: IBindQuoteInput): Promise<IBindQuoteOutput> => {
  const bindApplicationApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.BIND_APPLICATION });

  try {
    const response: AxiosResponse = await axiosApplicationServiceV2.post(bindApplicationApiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const pollForApplication = async (applicationId: string, pollingData?: IPoll): Promise<IApplicationInfo> => {
  const { frequencyInMs = 3000, maxRetries = 300 } = pollingData || {};
  let retries = 0;

  let applicationData;

  do {
    await sleep(frequencyInMs);
    applicationData = await getApplication({ applicationId });
    retries++;
  } while (
    applicationData?.applicationStatus?.statusCode === ApplicationStatus.BIND_IN_PROGRESS &&
    retries <= maxRetries &&
    window.location.pathname.includes(applicationId)
  );

  return applicationData;
};

export { getPaymentPlans, selectPaymentPlan, bindApplication, bindApplicationV2, pollForApplication };
