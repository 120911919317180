import { useCallback, useState } from 'react';

import { normalizeExperience } from '../../../helpers/Utils';
import CFInput from '../cfInput/cfInput';
import { IYearsOfExp } from './interface';

const YearsOfExperience = ({ data, onChange, id, style, value, disabled = false, ...rest }: IYearsOfExp) => {
  const [yearsOfExperience, setYearsOfExperience] = useState(value);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const val = normalizeExperience(event?.target?.value) || '';
      setYearsOfExperience(val);
      onChange && onChange(val, data);
    },
    [data, onChange],
  );
  return (
    <CFInput
      placeholder='12'
      id={id}
      disabled={disabled}
      {...rest}
      style={style}
      onChange={handleChange}
      value={yearsOfExperience || value || ''}
    />
  );
};

export default YearsOfExperience;
