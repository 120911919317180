import { INaicsCodeInfo, ISicCodeInfo } from '@coverforce-platform/cf-common-api-model';

export const parseIndustryCodesToLabelValue = ({
  naicsCodeInfos,
  sicCodeInfos,
  isValueNaicsExtendedId = false,
}: {
  naicsCodeInfos?: INaicsCodeInfo[];
  sicCodeInfos?: ISicCodeInfo[];
  isValueNaicsExtendedId?: boolean;
}) => {
  if (!Array.isArray(naicsCodeInfos) && !Array.isArray(sicCodeInfos)) {
    return [];
  }
  if (naicsCodeInfos && naicsCodeInfos?.length > 0) {
    const naicsCodes = naicsCodeInfos?.map((naicsCodeInfo: INaicsCodeInfo) => ({
      label: `${naicsCodeInfo?.naicsCode} - ${naicsCodeInfo?.descriptionText}`,
      value: isValueNaicsExtendedId
        ? `${naicsCodeInfo?.naicsCode} - ${naicsCodeInfo?.naicsExtendedId}`
        : `${naicsCodeInfo?.naicsCode} - ${naicsCodeInfo?.descriptionText}`,
      code: naicsCodeInfo?.naicsCode,
      description: naicsCodeInfo?.descriptionText,
      tags: naicsCodeInfo?.tags,
    }));
    return naicsCodes?.flat();
  } else if (sicCodeInfos && sicCodeInfos?.length > 0) {
    const sicCodes: any = [];
    sicCodeInfos?.forEach((sicCode: ISicCodeInfo) => {
      sicCode?.descriptions?.map((description) => {
        sicCodes.push({
          label: `${sicCode?.sicCode} - ${description}`,
          value: `${sicCode?.sicCode} - ${description}`,
          code: sicCode?.sicCode,
          description,
          tags: sicCode?.tags,
        });
      });
    });
    return sicCodes?.flat();
  }
};
