// eslint-disable-next-line no-shadow
export enum OwnerOfficerMessageType {
  QUOTE = 'QUOTE',
  BIND = 'BIND',
}

export const OWNERS_OFFICERS_MESSAGE_REPLACE_PATTERNS = {
  BRIDGE:
    '(To exclude Owners/Officers coverage you can bridge over to the {carrierName} Agent Portal)|(To modify the inclusion/exclusion you can bridge over to the {carrierName} Agent Portal)|(To include Owners/Officers coverage you can bridge over to the {carrierName} Agent Portal)',
  STATE: '{state}',
  CARRIER_PORTAL: '{carrierName} Agent Portal',
};
