import { cloneDeep } from 'lodash';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { LOCATIONV2_ACTIONS } from './constants';
import { Address, ILocationInputStore } from './interface';

export const useLocationInputStore = create<ILocationInputStore>()(
  devtools(
    (set, get) => ({
      addressData: {},
      setShowLocationError: (showLocationError: boolean, id: string) => {
        const cloneAddressData = cloneDeep(get().addressData);
        cloneAddressData[id] = { ...cloneAddressData[id], showLocationError };
        set({ addressData: cloneAddressData }, false, LOCATIONV2_ACTIONS.SET_SHOW_LOCATION_ERROR);
      },

      setAddressData: (addressDataItem: Address, id: string) => {
        const cloneAddressData = cloneDeep(get().addressData);
        cloneAddressData[id] = { ...cloneAddressData[id], address: addressDataItem };
        set({ addressData: cloneAddressData }, false, LOCATIONV2_ACTIONS.SET_ADDRESS_DATA);
      },

      clearLocationInput: (id: string) => {
        const cloneAddressData = cloneDeep(get().addressData);
        if (cloneAddressData[id]) {
          delete cloneAddressData[id];
        }
        set(
          {
            addressData: cloneAddressData,
          },
          false,
          LOCATIONV2_ACTIONS.CLEAR_LOCATION,
        );
      },
    }),
    { name: LOCATIONV2_ACTIONS.STORE_NAME, anonymousActionType: LOCATIONV2_ACTIONS.ANONYMOUS_ACTION_TYPE },
  ),
);
