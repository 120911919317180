import styled from 'styled-components';

export const EmptyNotificationsWrapperStyled = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  padding: 0px 20px 0px 20px;
`;

export const EmptyNotificationsHeadingStyled = styled.h3``;

export const EmptyNotificationsMessageStyled = styled.p`
  text-align: center;
`;
