import { useCallback } from 'react';

import LocationInput from '../../../components/question/locationInputV2/locationInput';
import { ICFLocationV2 } from './interface';

/** This component acts as wrapper to all the locationInputV2 and for any use case. Cuz eventually locationInputV2 requires an onSave from parent. */
// When you want to form answer in multilevel object format then you can pass id in "_" separated, else you can directly pass in string format(It will store answer in key value format).
const LocationV2 = ({ form, id: dataIndex, disabled = false }: ICFLocationV2) => {
  const onAddressSave = useCallback(
    (currentAddress: any) => {
      form?.setFieldValue(dataIndex?.split('_'), currentAddress);
    },
    [dataIndex, form],
  );

  return (
    <LocationInput
      disabled={disabled}
      id={dataIndex}
      value={form?.getFieldValue(dataIndex?.split('_'))}
      onSave={onAddressSave}
    />
  );
};

export default LocationV2;
