import {
  AimOutlined,
  CalculatorOutlined,
  CarryOutOutlined,
  DollarOutlined,
  FileSearchOutlined,
  FundViewOutlined,
  GiftOutlined,
  ProjectOutlined,
  ShopOutlined,
  SolutionOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { AgentPortalUserType, PolicyType } from '@coverforce-platform/cf-common-types';

export const APPLICATION_STEPS_STORE_META_DATA = {
  STORE_NAME: 'Application Steps Store',
  ANONYMOUS_ACTION_NAME: 'APPLICATION_STEPS_ACTIONS',
  SET_SELECTED_STEP: 'SET_SELECTED_STEP',
  SET_SELECTED_MENU_OPEN_KEYS: 'SET_SELECTED_MENU_OPEN_KEYS',
  CLEAR_APPLICATION_STEPS_STORE: 'CLEAR_APPLICATION_STEPS_STORE',
};

export const APPLICATION_STEPS_ACCOUNT_TOOLTIP = {
  [AgentPortalUserType.AGENCY]: 'Go back to Account',
  [AgentPortalUserType.AGENCY_NETWORK]: 'Go back to Applications',
};

export const APPLICATION_STEPS_CONFIG: Array<{ key: string; title: string; icon?: any }> = [
  {
    key: 'generalQuestions',
    title: 'General Questions',
    icon: SolutionOutlined,
  },
  {
    key: 'industrySelection',
    title: 'Industry Selection',
    icon: ShopOutlined,
  },
  {
    key: 'locations',
    title: 'Locations',
    icon: AimOutlined,
  },
  {
    key: 'ownerOfficer',
    title: 'Owner/Officer Details',
    icon: UserSwitchOutlined,
  },
  {
    key: 'expMods',
    title: 'Experience Modifiers',
    icon: CalculatorOutlined,
  },
  {
    key: 'pastPolicyLoss',
    title: 'Past Policy Loss',
    icon: FundViewOutlined,
  },
  {
    key: 'additionalInterest',
    title: 'Additional Interests',
    icon: UserAddOutlined,
  },
  {
    key: 'underwriting',
    title: 'Underwriting',
    icon: FileSearchOutlined,
  },
  {
    key: 'brokerCodes',
    title: 'Broker Codes',
    icon: ProjectOutlined,
  },
  {
    key: 'quotes',
    title: 'Quotes',
    icon: CarryOutOutlined,
  },
  {
    key: 'payment',
    title: 'Payment',
    icon: DollarOutlined,
  },
  {
    key: 'confirmation',
    title: 'Confirmation',
    icon: GiftOutlined,
  },
];

export const REMOVE_ADD_LOCATION_FOR_POLICIES = [PolicyType.CYBER, PolicyType.BR];

export const GA_CATEGORY = {
  APPLICATION_SIDEBAR_NAVIGATION: 'application_sideBarNavigation',
  APPLICATION_SIDEBAR_DOWNLOAD_ACORD_MODAL: 'application_sideBarDownloadAcordModal',
  APPLICATION_SIDEBAR_DOCUMENT_CENTER_MODAL: 'application_sideBarDocumentCenterModal',
};

export const GA_ACTION = {
  SIDEBAR_MENU_ITEM_BTN_CLICK: 'sideBarMenuItemBtn_click',
  DOWNLOAD_ACCORD_BTN_CLICK: 'downloadAccordBtn_click',
  DOCUMENT_CENTER_BTN_CLICK: 'documentCenterBtn_click',
  CLOSE_MODAL_BTN_CLICK: 'closeModalBtn_click',
  BACK_BTN_CLICK: 'backBtn_click',
  ADD_LOCATION_BTN_CLICK: 'addLocationBtn_click',
  DELETE_LOCATION_BTN_CLICK: 'deleteLocationBtn_click',
  DELETE_CARRIER_BTN_CLICK: 'deleteCarrierBtn_click',
};

export const GA_LABEL = {
  SIDEBAR_MENU_ITEM: 'Sidebar menu item button clicked.',
  DOWNLOAD_ACCORD: 'Download accord button clicked.',
  DOCUMENT_CENTER: 'Document center button clicked.',
  CLOSE_MODAL: 'Close modal button clicked.',
  BACK_BTN_CLICK: 'Back button clicked from sidebar.',
  ADD_LOCATION_BTN_CLICK: 'Location add button click from sidebar.',
  DELETE_LOCATION_BTN_CLICK: 'Location delete button click from sidebar.',
  DELETE_CARRIER_BTN_CLICK: 'Carrier delete button click from sidebar',
};
