export const DELETE_CARRIER_METADATA = {
  CLEAR_DELETE_CARRIER_STORE: 'clearDeleteCarrierStore',
  SET_DELETING_CARRIER: 'setDeletingCarrier',
  ANONYMOUS_ACTION: 'DELETE_CARRIER_STORE_ACTION',
  STORE_NAME: 'Delete Carrier Store',
};

export const GA_ACTION = {
  CLOSE_MODAL_BTN_CLICK: 'closeModalBtn_click',
  DELETE_CARRIER_BTN_CLICK: 'deleteCarrierBtn_click',
};

export const GA_LABEL = {
  CLOSE_MODAL_BTN_CLICK: 'Close delete carrier modal.',
  DELETE_CARRIER_BTN_CLICK: 'Delete carrier button clicked.',
};
