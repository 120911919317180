export const normalizeCardNumber = (value: string) => {
  const currentValue = value.replace(/[^\d]/g, '');

  const cardNum = currentValue.length <= 16 ? currentValue : currentValue.slice(0, 16);

  const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
  const onlyNumbers = cardNum.replace(/[^\d]/g, '');

  return onlyNumbers.replace(regex, (_regex, $1, $2, $3, $4) => [$1, $2, $3, $4].filter((group) => !!group).join(' '));
};

export const denormalizeCardNumber = (value: string) => {
  const currentValue = value.replace(/[^\d]/g, '');

  const cardNum = currentValue.length <= 16 ? currentValue : currentValue.slice(0, 16);
  return cardNum;
};

// Basic validation: as we already sanitize the input via normalizeCardNumber
export const isValidCardNumber = (input: string) => {
  if (!input || input?.length > 16) {
    return false;
  }
  return true;
};
