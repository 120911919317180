import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createReportSchedule, updateReportSchedule } from '../../externalServices/V2/reports';
import { SCHEDULE_REPORT_STORE_META_DATA } from './constants';
import { IScheduleReportStore } from './interface';
import { autoFillScheduleReportFormUtil, getScheduleReportApiPayloadUtil } from './utils';

export const useScheduleReportStore = create<IScheduleReportStore>()(
  devtools<IScheduleReportStore>(
    (set, get) => ({
      reportSchedule: undefined,
      scheduleReportElements: undefined,
      scheduleReportForm: undefined,
      scheduleReportLoading: false,
      isEditReportSchedule: false,
      disableScheduleReportButton: false,

      updateScheduleReportStoreByKey: async (storeKey: keyof IScheduleReportStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, SCHEDULE_REPORT_STORE_META_DATA.UPDATE_SCHEDULE_REPORT_STORE_BY_KEY),

      autoFillScheduleReportForm: () => {
        autoFillScheduleReportFormUtil();
      },

      validateScheduleReportForm: async () => {
        const { scheduleReportForm } = get();
        try {
          await scheduleReportForm?.validateFields();
          return true;
        } catch (error) {
          return false;
        }
      },

      getScheduleReportApiPayload: () => {
        return getScheduleReportApiPayloadUtil();
      },

      createReportSchedule: async () => {
        const { getScheduleReportApiPayload } = get();
        const response = await createReportSchedule(getScheduleReportApiPayload());
        return response;
      },

      updateReportSchedule: async () => {
        const { getScheduleReportApiPayload } = get();
        const response = await updateReportSchedule(getScheduleReportApiPayload());
        return response;
      },

      clearScheduleReportStore: () => {
        set(
          {
            reportSchedule: undefined,
            scheduleReportElements: undefined,
            scheduleReportForm: undefined,
            scheduleReportLoading: false,
            isEditReportSchedule: false,
            disableScheduleReportButton: false,
          },
          false,
          SCHEDULE_REPORT_STORE_META_DATA.CLEAR_SCHEDULE_REPORT_STORE,
        );
      },
    }),
    {
      // NOTE: Please don't include these from constant in future. There is an existing issue in zustand where it tries to initialise store(name, actiontype) even before constants.
      name: 'Schedule Report Store',
      anonymousActionType: 'SCHEDULE_REPORT_ANONYMOUS_ACTION',
    },
  ),
);
