import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { ICFModalStore } from './interface';

export const useCFModalStore = create<ICFModalStore>()(
  devtools(
    (set) => ({
      isVisible: false,
      popupType: '',
      setCfModalData: ({ isVisible, popupType }) =>
        set({ isVisible, popupType: popupType ? popupType : '' }, false, 'setCfModalData'),
      clearCfModal: () => set({ isVisible: false, popupType: '' }, false, 'clearCfModal'),
    }),
    { name: 'CF Modal Store', anonymousActionType: 'cfModal' },
  ),
);
