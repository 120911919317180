interface ICreateQueryUrl {
  url?: string;
  params?: object;
}

export const createQueryUrl = ({ url = '', params = {} }: ICreateQueryUrl): string => {
  let query = '';

  query =
    params &&
    Object.keys(params)
      .map((key) => `${key}=${params[key as keyof typeof params]}`)
      .join('&');
  return query ? `${url?.trim()}?${query}` : `${url?.trim()}`;
};
