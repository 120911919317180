import dayjs from 'dayjs';

export const getValidDate = (date?: string | null) => {
  if (date) {
    const dayJsDate = dayjs(date);
    if (dayJsDate.isValid()) {
      return dayJsDate;
    }
  }
  return undefined;
};
