import { IMarkApplicationBoundInput } from '@coverforce-platform/cf-common-api-model';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  getApplication,
  markApplicationAsBound as markApplicationAsBoundRequest,
} from '../../externalServices/V2/applicationV2';
import { getQuotes } from '../../externalServices/V2/quotes';
import {
  getSelectedCarriersFromApplication,
  getSelectedCarriersInfoFromApplication,
} from '../../pages/v2/application/utils';
import { useBrokerConfigStore } from '../../pages/v2/brokerConfig/store';
import { useProfileV2Store } from '../../pages/v2/profile/store';
import { IFormInstance } from '../../ui-core/V2/cfForm/cfForm';
import { MARK_AS_BOUND_STORE_META_DATA } from './constants';
import { IMarkAsBoundData, IMarkAsBoundStore } from './interface';
import { getQuoteIdentifierPayload } from './utils';

export const useMarkAsBoundModalStore = create<IMarkAsBoundStore>()(
  devtools(
    (set, get) => ({
      applicationId: undefined,
      accountName: undefined,
      markAsBoundForm: undefined,
      postMarkAsBoundCallback: undefined,
      isMarkAsBoundModalLoading: false,
      isApplicationBounding: false,
      markAsBoundApplicationData: undefined,
      markAsBoundQuotesData: undefined,
      markAsBoundSelectedCarriersInfo: undefined,

      setMarkAsBoundData: ({
        applicationId,
        accountName,
        presetCarrier,
        postMarkAsBoundCallback,
      }: IMarkAsBoundData) => {
        set(
          {
            applicationId,
            accountName,
            presetCarrier,
            postMarkAsBoundCallback,
          },
          false,
          MARK_AS_BOUND_STORE_META_DATA.SET_MARK_AS_BOUND_DATA,
        );
      },

      setMarkAsBoundForm: (markAsBoundForm: IFormInstance) => {
        set({ markAsBoundForm }, false, MARK_AS_BOUND_STORE_META_DATA.SET_MARK_AS_BOUND_FORM);
      },

      fetchMarkAsBoundModalData: async (isMarkAsBoundModalLoading = true) => {
        const { applicationId } = get();
        const { userData } = useProfileV2Store.getState();
        const { fetchAllCarriers: fetchAllAvailableCarriers } = useBrokerConfigStore.getState();

        set({ isMarkAsBoundModalLoading }, false, MARK_AS_BOUND_STORE_META_DATA.SET_MARK_AS_BOUND_LOADING);

        // Get application call
        const applicationData = await getApplication({ applicationId: applicationId || '' });

        const applicationSelectedCarriers = getSelectedCarriersFromApplication(applicationData);

        // available carriers call
        if (applicationSelectedCarriers?.length > 0) {
          const allAvailableCarriers = await fetchAllAvailableCarriers({ agentId: userData?.agentId || '' });
          if (allAvailableCarriers?.length > 0) {
            const applicationSelectedCarriersInfo = getSelectedCarriersInfoFromApplication(
              applicationData,
              allAvailableCarriers,
            );
            set(
              { markAsBoundSelectedCarriersInfo: applicationSelectedCarriersInfo },
              false,
              MARK_AS_BOUND_STORE_META_DATA.SET_MARK_AS_BOUND_CARRIERS_DATA,
            );
          }
        }

        // get quotes call
        try {
          const quotesData = await getQuotes({ applicationId: applicationId! });
          set({ markAsBoundQuotesData: quotesData?.quotes });
        } catch (error) {
          set({ markAsBoundQuotesData: undefined });
        }

        set(
          { markAsBoundApplicationData: applicationData, isMarkAsBoundModalLoading: false },
          false,
          MARK_AS_BOUND_STORE_META_DATA.SET_MARK_AS_BOUND_DATA,
        );

        return applicationData;
      },

      markApplicationAsBound: async () => {
        const { markAsBoundApplicationData, markAsBoundForm } = get();
        const modalFormAnswers = markAsBoundForm?.getFieldsValue();

        const payload: IMarkApplicationBoundInput = {
          applicationId: markAsBoundApplicationData?.applicationId!,
          policyNumber: modalFormAnswers?.policyNumber,
          premiumAmount: Number(modalFormAnswers?.totalPremium) || undefined,
          quoteIdentifier: getQuoteIdentifierPayload(),
        };

        await markApplicationAsBoundRequest(payload);
      },

      setIsMarkAsBoundModalLoading: (isMarkAsBoundModalLoading: boolean) => {
        set({ isMarkAsBoundModalLoading }, false, MARK_AS_BOUND_STORE_META_DATA.SET_MARK_AS_BOUND_LOADING);
      },

      setIsApplicationBound: (isApplicationBounding) => {
        set({ isApplicationBounding }, false, MARK_AS_BOUND_STORE_META_DATA.SET_IS_APPLICATION_BOUNDING);
      },

      clearMarkAsBoundModalStore: () => {
        set(
          {
            applicationId: undefined,
            accountName: undefined,
            isMarkAsBoundModalLoading: false,
            markAsBoundApplicationData: undefined,
            markAsBoundSelectedCarriersInfo: undefined,
          },
          false,
          MARK_AS_BOUND_STORE_META_DATA.CLEAR_MARK_AS_BOUND_STORE,
        );
      },
    }),
    {
      name: MARK_AS_BOUND_STORE_META_DATA.STORE_NAME,
      anonymousActionType: MARK_AS_BOUND_STORE_META_DATA.ANONYMOUS_ACTION_NAME,
    },
  ),
);
