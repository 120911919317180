export const MARK_AS_BOUND_MESSAGE = {
  BOUND_FAILED: 'Failed to bound the application!',
  BOUND_SUCCESS: 'Application is bound successfully!',
};

export const MARK_AS_BOUND_STORE_META_DATA = {
  STORE_NAME: 'Mark As Bound Modal Store',
  ANONYMOUS_ACTION_NAME: 'MARK_AS_BOUND_MODAL_ACTION',
  SET_MARK_AS_BOUND_DATA: 'SET_MARK_AS_BOUND_DATA',
  SET_MARK_AS_BOUND_FORM: 'SET_MARK_AS_BOUND_FORM',
  SET_MARK_AS_BOUND_LOADING: 'SET_MARK_AS_BOUND_LOADING',
  SET_MARK_AS_BOUND_CARRIERS_DATA: 'SET_MARK_AS_BOUND_CARRIERS_DATA',
  SET_IS_APPLICATION_BOUNDING: 'SET_IS_APPLICATION_BOUNDING',
  CLEAR_MARK_AS_BOUND_STORE: 'CLEAR_MARK_AS_BOUND_STORE',
};

export const MARK_AS_BOUND_FORM_ITEM = {
  CARRIER: 'carrier',
  POLICY_NUMBER: 'policyNumber',
  TOTAL_PREMIUM: 'totalPremium',
};

export const MARK_AS_BOUND_FORM_CONFIG = {
  carrierField: {
    id: 'form_item_sel_carrier',
    name: MARK_AS_BOUND_FORM_ITEM.CARRIER,
    label: 'Carrier',
    rules: [{ required: true, message: 'Please select a carrier.' }],
  },
  policyIdField: {
    id: 'form_item_inp_policy_id',
    name: MARK_AS_BOUND_FORM_ITEM.POLICY_NUMBER,
    label: 'Policy Number',
  },
  totalPremiumField: {
    id: 'form_item_inp_total_premium',
    name: MARK_AS_BOUND_FORM_ITEM.TOTAL_PREMIUM,
    label: 'Total Premium',
    tooltip: 'Enter the bound premium if it is different from the quoted premium',
    rules: [
      () => ({
        validator(_: any, value: any) {
          if (value && Number(value?.trim()) === 0) {
            return Promise.reject('Total Premium must be greater than zero');
          }
          return Promise.resolve();
        },
      }),
    ],
  },
};

export const MARK_AS_BOUND_MODAL_CONFIG = {
  OK_TEXT: 'Mark as Bound',
  TITLE: 'Mark as Bound',
};

export const GA_ACTION = {
  MARK_AS_BOUND_BTN_CLICK: 'markAsBoundBtn_click',
  CLOSE_MARK_AS_BOUND_MODAL: 'closeMarkAsBoundAppModalBtn_click',
};

export const GA_LABEL = {
  MARK_AS_BOUND_MODAL_SUCCESS: 'Mark as bound application successfully threw modal',
  MARK_AS_BOUND_MODAL_CLOSE: 'Mark as bound application modal closed',
};
