import {
  IDownloadAcordForApplicationRequestInput,
  IDownloadAcordForApplicationRequestOutput,
  IGetAcordFormForTransactionInput,
  IGetAcordFormForTransactionOutput,
} from '@coverforce-platform/cf-common-api-model';
import { AcordDownloadTransactionStatus } from '@coverforce-platform/cf-common-types';
import { AxiosResponse } from 'axios';

import axiosApplicationService from '../../axios/applicationService/instance';
import { IPoll } from '../../globalInterface';
import { createQueryUrl } from '../../utils/createQueryUrl';
import sleep from '../../utils/sleep';
import { APPLICATION_SERVICE_ENDPOINTS } from '../helpers/ApiRoutes';

const requestAcord = async (
  params: IDownloadAcordForApplicationRequestInput,
): Promise<IDownloadAcordForApplicationRequestOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.REQUEST_ACORD,
  });
  try {
    const response: AxiosResponse = await axiosApplicationService.post(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const downloadAcord = async (
  params: IGetAcordFormForTransactionInput,
  pollingData?: IPoll,
): Promise<IGetAcordFormForTransactionOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.DOWNLOAD_ACORD(params),
  });

  const { frequencyInMs = 3000, maxRetries = 300 } = pollingData || {};

  let retries = 0,
    data: IGetAcordFormForTransactionOutput,
    status: AcordDownloadTransactionStatus;

  do {
    await sleep(frequencyInMs);
    try {
      const response: AxiosResponse = await axiosApplicationService.get(apiUrl);
      data = response?.data;
      status = data?.acordDownloadTransactionStatus;
      retries++;
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.errors);
    }
  } while (
    status !== AcordDownloadTransactionStatus.SUCCESS &&
    status !== AcordDownloadTransactionStatus.FAILED &&
    retries <= maxRetries &&
    window.location.pathname.includes(params.applicationId)
  );

  return data;
};

export { requestAcord, downloadAcord };
