export const formatCvv = (input: string) => {
  const currentValue = input.replace(/[^\d]/g, '');
  return currentValue.slice(0, 4);
};

// Basic validation: as we already sanitize the input via formatCvv
export const isValidCardCvv = (input: string) => {
  if (!input || input?.length > 4) {
    return false;
  }
  return true;
};
