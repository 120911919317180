import { IModifiersStore } from './interface';

export const MODIFIERS_STORE_METADATA = {
  STORE_NAME: 'MODIFIERS_STORE',
  ANONYMOUS_ACTION: 'MODIFIERS_DEFAULT_ACTION',
  FETCH_MODIFIERS: 'FETCH_MODIFIERS',
  UPDATE_MODIFIERS_STORE_BY_KEY: 'UPDATE_MODIFIERS_STORE_BY_KEY',
};

export const MODIFIERS_STORE_KEYS: { [key: string]: keyof IModifiersStore } = {
  MODIFIERS_FORM_CONFIG: 'modifiersFormConfig',
  MODIFIERS_FORM: 'modifiersForm',
  MODIFIERS_LOADING: 'modifiersLoading',
  UPDATE_MODIFIERS_LOADING: 'updateModifiersLoading',
};

export const INCOMPATIBLE_MODIFIERS = {
  EXPERIENCE_MODIFIER: 'EXPERIENCE_MODIFIER',
};

export const MODIFIER_MESSAGES = {
  UPDATE_ERROR: 'Something went wrong while submitting modifiers',
  MODIFIER_FETCH_ERROR: 'Something went wrong while fetching modifiers details',
};
