import {
  IBrokerCodeStatusToggleInput,
  ICreateBrokerCodeInput,
  ICreateBrokerCodeOutput,
  IDeleteBrokerCodeInput,
  IGetAgencyBrokerCodesInput,
  IGetAgencyBrokerCodesOutput,
  IGetAgencyNetworkBrokerCodesInput,
  IGetAgencyNetworkBrokerCodesOutput,
  IGetAgentDefaultBrokerCodesInput,
  IGetAgentDefaultBrokerCodesOutput,
  ISetDefaultBrokerCodeInput,
  IUpdateBrokerCodeInput,
  IUpdateBrokerCodeOutput,
} from '@coverforce-platform/cf-common-api-model';
import { AxiosResponse } from 'axios';

import axiosAccountService from '../../axios/accountService/instance';
import { createQueryUrl } from '../../utils/createQueryUrl';
import { ACCOUNT_SERVICE_ENDPOINTS, APPLICATION_SERVICE_ENDPOINTS } from '../helpers/ApiRoutes';

const getBrokerCodesByAgencyId = async (params: IGetAgencyBrokerCodesInput): Promise<IGetAgencyBrokerCodesOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.GET_AGENCY_BROKER_CODES,
    params,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getBrokerCodesByAgencyNetworkId = async (
  params: IGetAgencyNetworkBrokerCodesInput,
): Promise<IGetAgencyNetworkBrokerCodesOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.GET_AGENCY_NETWORK_BROKER_CODES,
    params,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const addBrokerCode = async (params: ICreateBrokerCodeInput): Promise<ICreateBrokerCodeOutput> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.ADD_BROKER_CODE,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.post(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const updateBrokerCode = async (params: IUpdateBrokerCodeInput): Promise<IUpdateBrokerCodeOutput> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.UPDATE_BROKER_CODE,
  });

  try {
    delete (params as any)?.isEnabled;
    const response: AxiosResponse = await axiosAccountService.put(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const deleteBrokerCode = async (params: IDeleteBrokerCodeInput) => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.DELETE_BROKER_CODE(params),
  });

  try {
    const response: AxiosResponse = await axiosAccountService.delete(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const setDefaultBrokerCode = async (params: ISetDefaultBrokerCodeInput) => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.SET_DEFAULT_BROKER_CODE,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.put(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const toggleBrokerCodeStatus = async (params: IBrokerCodeStatusToggleInput) => {
  const toggleBrokerCodeStatusUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.TOGGLE_BROKER_CODE_STATUS,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.post(toggleBrokerCodeStatusUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getAgentDefaultBrokerCodes = async (
  params: IGetAgentDefaultBrokerCodesInput,
): Promise<IGetAgentDefaultBrokerCodesOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.GET_AGENT_DEFAULT_BROKER_CODES(params.agentId),
  });

  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export {
  addBrokerCode,
  updateBrokerCode,
  deleteBrokerCode,
  setDefaultBrokerCode,
  getBrokerCodesByAgencyId,
  getBrokerCodesByAgencyNetworkId,
  toggleBrokerCodeStatus,
  getAgentDefaultBrokerCodes,
};
