import { useMemo } from 'react';

import { DEFAULT_POPOVER_ID } from './constants';
import { ICFPopoverProps } from './interface';
import { CFPopoverStyled, CfPopoverWrapperStyled } from './styles';

const CFPopover = (cfPopoverProps: ICFPopoverProps) => {
  const {
    id = DEFAULT_POPOVER_ID,
    getPopupContainer: customGetPopupContainer,
    children,
    ...restCFPopoverProps
  } = cfPopoverProps;

  const getPopupContainer = useMemo(() => {
    return customGetPopupContainer ? customGetPopupContainer : () => document.getElementById(id) || document.body;
  }, [customGetPopupContainer, id]);

  return (
    <CfPopoverWrapperStyled id={id}>
      <CFPopoverStyled getPopupContainer={getPopupContainer} {...restCFPopoverProps}>
        {children}
      </CFPopoverStyled>
    </CfPopoverWrapperStyled>
  );
};

export default CFPopover;
