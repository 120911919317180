import { AgentPortalUserType } from '@coverforce-platform/cf-common-types';

import { ITheme } from '../../types';
import { getAllCarrierIds } from '../../utils/getAllCarrierIds';
import { BaseTheme } from './BaseTheme';

const CFTheme: ITheme = {
  ...BaseTheme,

  colorScheme: {
    primary: '#624de3',
    primaryHover: '#8c78f0',
  },

  component: {
    navBar: {
      backgroundColor: '#3639a4',
      textColor: '#ffffff',
    },
  },

  config: {
    ...BaseTheme?.config,
    disabledRequestToBindCarriers: {
      [AgentPortalUserType.AGENCY]: [],
      [AgentPortalUserType.AGENCY_NETWORK]: getAllCarrierIds(),
    },
  },
};

export { CFTheme };
