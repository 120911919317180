import { IAgentProfile } from '@coverforce-platform/cf-common-api-model';
import { GrowthBook } from '@growthbook/growthbook-react';

import { GROWTHBOOK_API_KEY } from '../helpers/EnvironmentVariables';
import { IUserData } from '../types';
import { isDevEnvironment } from './isDevEnvironment';

interface IGrowthBook {
  callback?: Function;
}

const GROWTHBOOK = {
  ENDPOINT: `https://cdn.growthbook.io/api/features/${GROWTHBOOK_API_KEY}`,
};

const growthbook = new GrowthBook({
  enableDevMode: isDevEnvironment(),
});

export const initialiseGrowthBook = async (growthBookParams: IGrowthBook = {}) => {
  await fetch(GROWTHBOOK.ENDPOINT)
    .then(async (res) => await res.json())
    .then((json) => {
      growthbook.setFeatures(json.features);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch growthbook features', err);
    })
    .finally(async () => {
      const { callback } = growthBookParams || {};
      if (callback) {
        await callback();
      }
    });
};

export const setGrowthbookAttributesUser = (currentAgentProfile: IAgentProfile, userData: IUserData) => {
  growthbook.setAttributes({
    agentId: userData?.agentId,
    agencyId: currentAgentProfile?.agencyId,
    agencyNetworkId: currentAgentProfile?.agencyNetworkId,
  });
};

export const isGrowthbookFeatureEnabledByKey = (featureFlagKey: string): boolean => {
  return growthbook.feature(featureFlagKey).on;
};

export const getGrowthbookFeatureValueByKey = (featureFlagKey: string): any => {
  return growthbook.feature(featureFlagKey).value;
};
