import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getModifiers } from '../../../externalServices/V2/applicationV2';
import { useApplicationStore } from '../../../pages/v2/application/store';
import { scrollAndFocusFormItemErrorV2 } from '../../../utils/scrollAndFocusFormItemError';
import { MODIFIERS_STORE_KEYS, MODIFIERS_STORE_METADATA } from './constants';
import { IModifiersStore } from './interface';
import { autoFillModifiersUtil, getModifiersUpdateApplicationPayloadUtil } from './utils';

export const useModifiersStore = create<IModifiersStore>()(
  devtools(
    (set, get) => ({
      modifiersConfig: undefined,
      modifiersFormConfig: undefined,
      modifiersForm: undefined,
      modifiersLoading: false,
      updateModifiersLoading: false,

      updateModifiersStoreByKey: async (storeKey: keyof IModifiersStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, MODIFIERS_STORE_METADATA.UPDATE_MODIFIERS_STORE_BY_KEY),

      fetchModifiersConfig: async (carrier) => {
        const { updateModifiersStoreByKey } = get();
        const { applicationData } = useApplicationStore.getState();
        const { applicationId = '' } = applicationData || {};
        updateModifiersStoreByKey(MODIFIERS_STORE_KEYS.MODIFIERS_LOADING, true);
        const response = (await getModifiers({ applicationId }, carrier && { carrier }))
          ?.carrierSpecificModifierDetails;
        set({ modifiersConfig: response }, false, MODIFIERS_STORE_METADATA.FETCH_MODIFIERS);
        updateModifiersStoreByKey(MODIFIERS_STORE_KEYS.MODIFIERS_LOADING, false);

        return response;
      },

      autoFillModifiers: () => {
        autoFillModifiersUtil();
      },

      validateModifiersForm: async () => {
        const { modifiersForm } = get();
        let isValid = true;
        try {
          await modifiersForm?.validateFields();
        } catch (error) {
          isValid = false;
          scrollAndFocusFormItemErrorV2(modifiersForm);
        }
        return isValid;
      },

      getModifiersUpdateApplicationPayload: () => {
        return getModifiersUpdateApplicationPayloadUtil();
      },

      clearModifiersStore: () => {
        set(
          {
            modifiersConfig: undefined,
            modifiersFormConfig: undefined,
            modifiersForm: undefined,
            modifiersLoading: false,
            updateModifiersLoading: false,
          },
          false,
          MODIFIERS_STORE_KEYS.CLEAR_MODIFIERS_STORE,
        );
      },
    }),
    { anonymousActionType: MODIFIERS_STORE_METADATA.ANONYMOUS_ACTION, name: MODIFIERS_STORE_METADATA.STORE_NAME },
  ),
);
