export const APPLICATION_STORE = {
  NAME: 'Application Store',
  ANONYMOUS_ACTION_TYPE: 'APPLICATION_STORE',
  APPLICATION_LOADING: 'APPLICATION_LOADING',
  FETCH_APPLICATION: 'FETCH_APPLICATION',
  CLEAR_APPLICATION: 'CLEAR_APPLICATION',
  APPLICATION_SUCCESS: 'APPLICATION_SUCCESS',
  APPLICATION_ERROR: 'APPLICATION_ERROR',
  FETCH_ACCOUNT: 'FETCH_ACCOUNT',
  ACCOUNT_LOADING: 'ACCOUNT_LOADING',
  ACCOUNT_SUCCESS: 'ACCOUNT_SUCCESS',
  ACCOUNT_ERROR: 'ACCOUNT_ERROR',
  UPDATE_APPLICATION: 'UPDATE_APPLICATION',
  UPDATE_APPLICATION_STORE_BY_KEY: 'UPDATE_APPLICATION_STORE_BY_KEY',
  APPLICATION_SELECTED_CARRIERS_INFO: 'APPLICATION_SELECTED_CARRIERS_INFO',
  SET_APPLICATION_FIELDS: 'SET_APPLICATION_FIELDS',
};
