import { IAntdOptions } from '../globalInterface';

export const getValidDropdownValue = (value?: string, options?: IAntdOptions[]) => {
  if (value && options && options?.length > 0) {
    const option = options.find((item) => item.value === value);
    if (option) {
      return value;
    }
  }
  return undefined;
};
