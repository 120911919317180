import styled from 'styled-components';

export const WrapperStyled = styled.div`
  background-color: white;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ImageWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 30px;
`;

export const TextWrapperStyled = styled.div`
  max-width: 350px;
  text-align: center;
`;
