import { Switch } from 'antd';
import styled from 'styled-components';

export const StyledCFSwitch = styled(Switch)`
  background-color: ${(p) => (p.checked ? p?.theme?.colorScheme?.primary : p.theme.colors.grayscale.grayBorder)};

  .ant-switch-handle::before {
    background-color: ${(p) => (p.checked ? p.theme.colors.grayscale.white : p.theme.colors.grayscale.grayBg)};
  }
`;
