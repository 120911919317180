import { IOwnerDetails } from '@coverforce-platform/cf-common-api-model';
import { Carrier, USState } from '@coverforce-platform/cf-common-types';

import { DATE_FORMAT } from '../../globalConstants';
import { useApplicationStore } from '../../pages/v2/application/store';
import { getValidDate } from '../../utils/getValidDate';
import { OWNER_OFFICER_QUESTION_KEYS } from '../ownerOfficerDetailsModal/constants';
import { useOwnerOfficerDetailsModalStore } from '../ownerOfficerDetailsModal/store';
import { OPTIONAL_FIELDS, OWNER_OFFICER_KEYS_MAPPING } from './constants';
import { IOwnerDetailsError } from './interface';

export const getStateByLocationId = (cfLocationId: string | undefined | null): USState => {
  const { applicationData } = useApplicationStore.getState();
  const selectedLocation = applicationData?.locationDetails?.find((location) => location?.locationId === cfLocationId);
  return (selectedLocation?.address?.state ?? '') as USState;
};

export const createOwnerOfficerDetailsApiPayload = (ownerOfficerDetailsList: IOwnerDetails[]) => {
  const ownerOfficerDetailsPayload = ownerOfficerDetailsList?.map((currentDetails) => {
    return {
      firstName: currentDetails?.firstName,
      lastName: currentDetails?.lastName,
      annualPayroll: Number(currentDetails?.annualPayroll) || 0,
      ncciStateClassCode: currentDetails?.ncciStateClassCode?.trim(),
      jobCodeExtendedId: currentDetails?.jobCodeExtendedId?.trim(),
      cfLocationId: currentDetails?.cfLocationId,
      dateOfBirth: getValidDate(currentDetails?.dateOfBirth)?.format(DATE_FORMAT.YYYYMMDD),
      ownershipPercentage: currentDetails?.ownershipPercentage
        ? Number(currentDetails?.ownershipPercentage)
        : undefined,
      officialTitle: currentDetails?.officialTitle,
    };
  });
  return ownerOfficerDetailsPayload ?? [];
};

const getOwnerOfficerErrorObj = ({
  validatedDetails,
  index,
  key,
}: {
  validatedDetails: { errors: IOwnerDetailsError[]; isValid: boolean };
  index: number;
  key: string;
}) => {
  validatedDetails.errors[index] = { isError: true, errorMessage: `Please enter ${OWNER_OFFICER_KEYS_MAPPING[key]}.` };
  const obj = {
    ...validatedDetails,
    isValid: false,
  };
  return obj;
};

export const validateOwnerOfficerDetails = (ownerOfficerDetailsList: IOwnerDetails[]) => {
  const { questionElements } = useOwnerOfficerDetailsModalStore.getState();
  const { selectedCarriersInfo } = useApplicationStore.getState();

  let validatedDetails: { errors: IOwnerDetailsError[]; isValid: boolean } = {
    isValid: true,
    errors: [],
  };

  ownerOfficerDetailsList?.forEach((ownerOfficerDetails, index) => {
    for (const key of Object.values(OWNER_OFFICER_QUESTION_KEYS)) {
      const isFieldHidden = questionElements.find((ques) => ques.dataIndex === key)?.hidden;
      const isKeyOptionalToValidate = OPTIONAL_FIELDS.includes(key);
      if (!(key in ownerOfficerDetails) && !isFieldHidden && !isKeyOptionalToValidate) {
        validatedDetails = getOwnerOfficerErrorObj({ validatedDetails, key, index });
        return false;
      }

      const value = ownerOfficerDetails[key as keyof IOwnerDetails];

      switch (key) {
        // if its class code then we need to check additional property location, if its present then need to verify class code
        case OWNER_OFFICER_QUESTION_KEYS.CLASS_CODE: {
          const locationId = ownerOfficerDetails?.cfLocationId;
          if (locationId && !value) {
            validatedDetails = getOwnerOfficerErrorObj({ validatedDetails, key, index });
            return false;
          }
          break;
        }

        // if its dob then we need to check only if the state is NH and carriers list has GUARD
        case OWNER_OFFICER_QUESTION_KEYS.DOB: {
          const state = getStateByLocationId(ownerOfficerDetails?.cfLocationId);
          const selectedCarriers = selectedCarriersInfo?.map((carrierInfo) => carrierInfo?.carrierId);
          if (state === USState.NH && selectedCarriers?.includes(Carrier.GUARD) && !value) {
            validatedDetails = getOwnerOfficerErrorObj({ validatedDetails, key, index });
            return false;
          }
          break;
        }

        // for remaining cases we only need to check if data is present or not
        default:
          if (!value && !isFieldHidden && !isKeyOptionalToValidate) {
            validatedDetails = getOwnerOfficerErrorObj({ validatedDetails, key, index });
            return false;
          }
      }
    }

    if (!validatedDetails.isValid) {
      return false;
    }
  });
  return validatedDetails;
};
