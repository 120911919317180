import {
  IAddLocationInput,
  IAddLocationOutput,
  IApplicationInfo,
  IUpdateLocationInput,
  IUpdateLocationOutput,
} from '@coverforce-platform/cf-common-api-model';
import { PolicyType } from '@coverforce-platform/cf-common-types';

import {
  addLocation as requestAddLocation,
  updateLocation as requestUpdateLocation,
} from '../../externalServices/V2/location';
import { checkIfTwoObjectsAreSame } from '../../utils/checkIfTwoObjAreSame';
import { useBuildingInformationStore } from '../buildingInformation/store';
import { useBuildingRiskInformationStore } from '../buildingRiskInformation/store';
import { useEmployeeInformationStore } from '../employeeInformation/store';
import { Address } from '../question/locationInputV2/interface';

export const getBaseLocationApiPayload = ({
  applicationData,
  selectedLocationIndex,
  selectedLocationDetail,
}: {
  applicationData?: IApplicationInfo;
  selectedLocationIndex: number;
  selectedLocationDetail: Address;
}) => {
  const existingLocationDetails = applicationData?.locationDetails?.[selectedLocationIndex] || {};
  return {
    applicationId: applicationData?.applicationId,
    locationDetails: {
      ...existingLocationDetails,
      address: {
        addressLine1: selectedLocationDetail?.addressLine1,
        addressLine2: selectedLocationDetail?.addressLine2,
        city: selectedLocationDetail?.city,
        state: selectedLocationDetail?.state,
        zip: selectedLocationDetail?.zip,
      },
      isPrimary: selectedLocationIndex === 0,
    },
  };
};

export const addOrUpdateLocationUtil = async ({
  locationPayload,
  applicationData,
  resetLocationDependentData,
  editCallback,
}: {
  locationPayload: any;
  applicationData: IApplicationInfo;
  resetLocationDependentData?: boolean;
  editCallback?: Function;
}): Promise<IUpdateLocationOutput | IAddLocationOutput | undefined> => {
  if (locationPayload?.locationDetails?.locationId) {
    if (
      !checkIfTwoObjectsAreSame(
        locationPayload?.locationDetails,
        applicationData?.locationDetails?.find(
          (item) => item?.locationId === locationPayload?.locationDetails?.locationId,
        ),
      )
    ) {
      // If we are updating any of the existing location and we want to reset its dependent data
      // then we just need to pass "resetLocationDependentData" and it will reset employeeInformation, buildingInformation, locationUWInformation
      // from that specific location details.
      if (resetLocationDependentData) {
        locationPayload.locationDetails.employeeInformation = [];
        locationPayload.locationDetails.buildingInformation = null;
      }

      // A callback utility to have any custom logic execute just before the update location.
      if (editCallback) {
        await editCallback(locationPayload);
      }
      return await requestUpdateLocation(locationPayload);
    }
  } else {
    return await requestAddLocation(locationPayload);
  }
};

export const getPolicyTypeSpecificLocationDetailsPayload = (commonRequestPayload: any, policyType: PolicyType) => {
  let requestPayload: any = {};
  switch (policyType) {
    case PolicyType.WC: {
      const { getEmployeeInformationApiPayload } = useEmployeeInformationStore.getState();
      requestPayload = {
        ...commonRequestPayload,
        locationDetails: {
          ...commonRequestPayload?.locationDetails,
          ...getEmployeeInformationApiPayload(),
        },
      };
      break;
    }
    case PolicyType.BOP: {
      const { getBuildingInformationApiPayload } = useBuildingInformationStore.getState();
      requestPayload = {
        ...commonRequestPayload,
        locationDetails: {
          ...commonRequestPayload?.locationDetails,
          ...getBuildingInformationApiPayload(),
        },
      };
      break;
    }
    case PolicyType.CGL: {
      requestPayload = {
        ...commonRequestPayload,
      };
      break;
    }

    case PolicyType.CYBER: {
      requestPayload = {
        ...commonRequestPayload,
      };
      break;
    }

    case PolicyType.BR: {
      const { getBuildingRiskInformationApiPayload } = useBuildingRiskInformationStore.getState();
      requestPayload = {
        ...commonRequestPayload,
        locationDetails: {
          ...commonRequestPayload?.locationDetails,
          ...getBuildingRiskInformationApiPayload(),
        },
      };
      break;
    }
  }

  return requestPayload;
};

export const getSpecificAddOrUpdateLocationDetailsPayload = (locationDetails: any, baseLocationPayload: any) => {
  // Update Location Payload
  if (locationDetails?.locationId) {
    const locationDetailUpdatePayload = {
      ...baseLocationPayload,
      locationDetails: {
        ...baseLocationPayload?.locationDetails,
        locationId: locationDetails?.locationId,
      },
    };

    return locationDetailUpdatePayload as IUpdateLocationInput;
  }

  // Add Location Payload
  // eslint-disable-next-line no-case-declarations
  const locationStepAddPayload = {
    ...baseLocationPayload,
  };
  return locationStepAddPayload as IAddLocationInput;
};
