import { ComponentType } from 'react';

import Checkbox from '../../ui-core/V2/cfCheckbox/cfCheckbox';
import DatePicker from '../../ui-core/V2/cfDatePicker/cFDatePicker';
import FeinNumber from '../../ui-core/V2/cfFein/cfFein';
import Input from '../../ui-core/V2/cfInput/cfInput';
import LocationV2 from '../../ui-core/V2/cfLocationV2/cfLocationV2';
import MoneyInput from '../../ui-core/V2/cfMoneyInput/cfMoneyInput';
import NumberInput from '../../ui-core/V2/cfNumber/cfNumber';
import PhoneInput from '../../ui-core/V2/cfPhoneInput/cfPhoneInput';
import RadioChoice from '../../ui-core/V2/cfRadioChoice/cfRadioChoice';
import RangePicker from '../../ui-core/V2/cfRangePicker/cfRangePicker';
import Select from '../../ui-core/V2/cfSelect/cfSelect';
import TextArea from '../../ui-core/V2/cfTextArea/cfTextArea';
import TimePicker from '../../ui-core/V2/cfTimePicker/cfTimePicker';
import YearsOfExperience from '../../ui-core/V2/cfYearsOfExp/cfYearsOfExp';

export const ANTD_QUESTION_TYPE = {
  STRING: 'string',
  ADDRESS: 'address',
  RADIO: 'radio',
  DROPDOWN: 'dropdown',
  DATE: 'date',
  TEXTAREA: 'textarea',
  MONEY: 'money',
  YEAR_OF_EXP: 'yearsofexperience',
  FEIN: 'fein',
  NUMBER: 'inputnumber',
  PHONE: 'phone',
  YEAR: 'year',
  MONTH: 'month',
  CHECKBOX: 'checkbox',
  DATE_RANGE: 'daterange',
  TIME_PICKER: 'timepicker',
};

export const QUESTION_ELEMENTS: { [key: string]: ComponentType<any> } = {
  [ANTD_QUESTION_TYPE.STRING]: Input,
  [ANTD_QUESTION_TYPE.ADDRESS]: LocationV2,
  [ANTD_QUESTION_TYPE.RADIO]: RadioChoice,
  [ANTD_QUESTION_TYPE.DROPDOWN]: Select,
  [ANTD_QUESTION_TYPE.DATE]: DatePicker,
  [ANTD_QUESTION_TYPE.TEXTAREA]: TextArea,
  [ANTD_QUESTION_TYPE.MONEY]: MoneyInput,
  [ANTD_QUESTION_TYPE.YEAR_OF_EXP]: YearsOfExperience,
  [ANTD_QUESTION_TYPE.FEIN]: FeinNumber,
  [ANTD_QUESTION_TYPE.NUMBER]: NumberInput,
  [ANTD_QUESTION_TYPE.PHONE]: PhoneInput,
  [ANTD_QUESTION_TYPE.CHECKBOX]: Checkbox,
  [ANTD_QUESTION_TYPE.DATE_RANGE]: RangePicker,
  [ANTD_QUESTION_TYPE.TIME_PICKER]: TimePicker,
};

export const CONDITION_TYPE = {
  CONDITIONAL: 'CONDITIONAL',
};

export const APPLICATION_QUESTION_INTERFACE_KEYS = {
  FIELD_MAPPING: 'fieldMapping',
};
