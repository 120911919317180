import { BUTTON_TYPE } from '../../globalConstants';

export const BUTTON_IDS = {
  HOME: 'btn_home',
  SUBMIT_REQUEST: 'btn_submit_request',
  TRY_AGAIN: 'btn_try_again',
};

export const errorActionButtons = [
  {
    id: BUTTON_IDS.HOME,
    buttonType: BUTTON_TYPE.PRIMARY,
    title: 'Home',
  },
  {
    id: BUTTON_IDS.SUBMIT_REQUEST,
    buttonType: BUTTON_TYPE.PRIMARY,
    title: 'Submit a Request',
  },
  {
    id: BUTTON_IDS.TRY_AGAIN,
    buttonType: BUTTON_TYPE.SECONDARY,
    title: 'Try Again',
  },
];
