export const UnderWritingDeclarationQuestion = [
  {
    type: 'radio',
    name: 'Do you attest that the Insured complies with the following statements?',
    dataIndex: 'underWritingDeclarationQuestion',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
    rules: [
      {
        required: true,
        validator: (_: any, value: any) => {
          if (!value) {
            return Promise.reject('Please select Yes or No.');
          }
          if (value === 'false') {
            return Promise.reject('Please accept to continue.');
          }
          return Promise.resolve();
        },
      },
    ],
  },
];
