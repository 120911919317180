import { InboxOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import styled from 'styled-components';

import CFForm from '../../../ui-core/V2/cfForm/cfForm';
import CFFormItem from '../../../ui-core/V2/cfFormItem/cfFormItem';
import { CFRadioGroup } from '../../../ui-core/V2/cfRadio/cfRadio';

export const CFFormStyled = styled(CFForm)`
  .ant-upload-list-item .ant-upload-list-item-card-actions-btn {
    opacity: 1;
  }
`;

export const AppetiteInfoWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 0 20px;
`;
export const AppetiteWrapperStyled = styled.div`
  padding-top: 10px;
  box-shadow: ${(p) => p.theme.custom.boxShadow.dashBoard};
  padding-bottom: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
`;
export const AppetiteErrorTitleStyled = styled.div`
  display: flex;
  justify-content: center;
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  padding-bottom: 8px;
  border-bottom: 1px solid ${(p) => p.theme.colors.primary.borderLight};
`;
export const AppetiteErrorStyled = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: center;
  color: red;
  margin-bottom: 20px;
`;

export const CFFormItemStyled = styled(CFFormItem)`
  .ant-form-item-label {
    white-space: pre-line;

    > label {
      display: flex;
      align-items: flex-end;
      height: auto;
    }
  }

  .ant-form-item-control {
    display: flex;
    justify-content: flex-end;
  }

  .ant-form-item-control-input {
    min-height: auto;
  }

  .ant-form-item-tooltip {
    padding-bottom: 3px;
  }
`;

export const AcordTextStyled = styled.p<{
  disabled: boolean;
}>`
  color: ${(p) => (p.disabled ? p.theme.colors.grayscale.blackOpacity : p.theme.custom.application.blackOpacity)};
`;

export const InboxOutlinedStyled = styled(InboxOutlined)<{
  disabled: boolean;
}>`
  opacity: ${(p) => (p.disabled ? 0.25 : 1)};
  > svg {
    color: ${(p) => p?.theme?.colorScheme?.primary};
  }
`;

export const LoaderWrapperStyled = styled.div`
  z-index: 9;
  position: absolute;
  top: 40%;
  width: 90%;
`;

export const AcordStatusTextStyled = styled.div`
  margin-top: 20px;
  font-size: ${(p) => p.theme.typography.fontSize.mediumLarge};
  font-weight: ${(p) => p.theme.typography.fontWeight.medium};
`;

export const AcordUploadStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CFRadioGroupStyled = styled(CFRadioGroup)``;

export const SubInfoWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SubInfoStyled = styled.div`
  margin-top: -20px;
  margin-bottom: 8px;
  cursor: pointer;
  text-decoration: underline;
  color: ${(p) => p?.theme?.colorScheme?.primary};
  :hover {
    color: ${(p) => p.theme?.colorScheme?.primaryHover};
  }
`;

export const AccordInfoWrapper = styled.div`
  margin-top: 10px;
`;

export const AccordInfoTagStyled = styled(Tag)`
  white-space: normal;
  font-size: ${(p) => p.theme.typography.fontSize.medium};
  // This is common for all the theme
  color: #957c01;
  width: 100%;
  padding: 8px 10px;
`;
