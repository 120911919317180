import createInstance from '../common/createInstance';
import { defaultRequestInterceptor, defaultResponseInterceptor } from '../common/interceptor';
import methods from '../common/methods';
import { APPLICATION_SERVICE_PARAMS_V2, APPLICATION_SERVICE_V2 } from './constants';

const axiosApplicationServiceV2 = createInstance(APPLICATION_SERVICE_PARAMS_V2);

defaultRequestInterceptor({ axiosInstance: axiosApplicationServiceV2, serviceName: APPLICATION_SERVICE_V2 });
defaultResponseInterceptor({ axiosInstance: axiosApplicationServiceV2, serviceName: APPLICATION_SERVICE_V2 });

export default methods(axiosApplicationServiceV2);
