import { Carrier, USState } from '@coverforce-platform/cf-common-types';

import { IAntdOptions } from '../../globalInterface';

export interface ICarrierInternalClassCode {
  [key: string]: IAntdOptions[];
}

interface IUpdateLocationEmployee {
  locationIndex: number;
  employeeTypeIndex: number;
  fieldName: string;
  fieldValue: string | number;
}

export interface IEmployeeInformationProps {
  disabled?: boolean;
}

export type CarrierClassCodeConfig = {
  [K in keyof typeof Carrier]?: {
    carrier: Carrier;
    formItem: {
      label: string;
      rules?: any[];
      selectItem?: { id: string; placeholder?: string };
    };
  };
};

export interface IActiveKeys {
  nextFieldKey: number;
  keys: {
    [k: number]: string[];
  };
}

export interface IEmployeeInformation {
  classCodes: IAntdOptions[];
  isEmployeeInfoLoading: boolean;
  carriersInternalClassCodesLoading: boolean;
  selectedEmployeeTypeIndex: number;
  activeKeys: IActiveKeys;
  handleActiveKeys: (keys: IActiveKeys) => void;
  carriersInternalClassCodes: ICarrierInternalClassCode[];
  carriersValidForInternalClassCodes: Carrier[];

  setCarriersInternalClassCodesLoading: (carriersInternalClassCodesLoading: boolean) => void;
  setIsEmployeeInfoLoading: (isEmployeeInfoLoading: boolean) => void;
  autoFillEmployeeInfo: () => void;
  setCarriersValidForInternalClassCodes: () => void;
  setSelectedEmployeeTypeIndex: (selectedEmployeeTypeIndex: number) => void;
  setClassCodes: (classCodes: IAntdOptions[]) => void;
  fetchClassCodes: (state: USState, isEmployeeInfoLoading?: boolean) => Promise<IAntdOptions[]>;
  getEmployeeInformationApiPayload: () => { employeeInformation: IEmployeeInformation[] };
  setCarrierInternalClassCodes: (
    selectedEmployeeTypeIndex: number,
    classCodes: IAntdOptions[],
    carrier?: Carrier,
  ) => void;
  deleteLocationEmployeeByIndex: (locationIndex: number, employeeIndex: number) => void;
  updateLocationEmployee: (param: IUpdateLocationEmployee) => void;
  deleteEmployeeInformationByIndex: (selectedEmployeeTypeIndex: number) => void;
  clearEmployeeInformation: () => void;
}

export enum ACTION {
  ADD = 'ADD',
  DELETE = 'DELETE',
}
