import {
  ICreateReportScheduleInput,
  ICreateReportScheduleOutput,
  IDeleteReportScheduleInput,
  IGetReportScheduleInput,
  IGetReportScheduleOutput,
  IRequestReportInput,
  IRequestReportOutput,
  IUpdateReportScheduleInput,
  IUpdateReportScheduleOutput,
} from '@coverforce-platform/cf-common-api-model';
import { AxiosResponse } from 'axios';

import axiosApplicationLifeCycleService from '../../axios/applicationLifeCycleService/instance';
import { createQueryUrl } from '../../utils/createQueryUrl';
import { APPLICATION_LIFECYCLE_SERVICE_ENDPOINTS } from '../helpers/ApiRoutes';

const getAllReportSchedules = async (params: IGetReportScheduleInput): Promise<IGetReportScheduleOutput> => {
  const apiUrl = createQueryUrl({ url: APPLICATION_LIFECYCLE_SERVICE_ENDPOINTS.GET_ALL_REPORTS_SCHEDULES });
  try {
    const response: AxiosResponse = await axiosApplicationLifeCycleService.post(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const generateReport = async (params: IRequestReportInput): Promise<IRequestReportOutput> => {
  const apiUrl = createQueryUrl({ url: APPLICATION_LIFECYCLE_SERVICE_ENDPOINTS.GENERATE_REPORT });
  try {
    const response: AxiosResponse = await axiosApplicationLifeCycleService.post(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const createReportSchedule = async (params: ICreateReportScheduleInput): Promise<ICreateReportScheduleOutput> => {
  const apiUrl = createQueryUrl({ url: APPLICATION_LIFECYCLE_SERVICE_ENDPOINTS.CREATE_REPORT_SCHEDULE });
  try {
    const response: AxiosResponse = await axiosApplicationLifeCycleService.post(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const updateReportSchedule = async (params: IUpdateReportScheduleInput): Promise<IUpdateReportScheduleOutput> => {
  const apiUrl = createQueryUrl({ url: APPLICATION_LIFECYCLE_SERVICE_ENDPOINTS.UPDATE_REPORT_SCHEDULE });
  try {
    const response: AxiosResponse = await axiosApplicationLifeCycleService.put(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const deleteReportSchedule = async (params: IDeleteReportScheduleInput) => {
  const apiUrl = createQueryUrl({ url: APPLICATION_LIFECYCLE_SERVICE_ENDPOINTS.DELETE_REPORT_SCHEDULE(params) });
  try {
    await axiosApplicationLifeCycleService.delete(apiUrl);
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export { getAllReportSchedules, generateReport, createReportSchedule, updateReportSchedule, deleteReportSchedule };
