import { CFModifierID } from '@coverforce-platform/cf-common-types';
import { cloneDeep } from 'lodash';
import { useEffect } from 'react';

import { UPDATE_APPLICATION_STEPS } from '../../../constants/applicationV2Constants';
import { QUOTES_STORE_KEYS } from '../../../constants/quotesConstants';
import { BUTTON_TYPE } from '../../../globalConstants';
import { useApplicationStore } from '../../../pages/v2/application/store';
import { errorV2 } from '../../../ui-core/Notification';
import CFButton from '../../../ui-core/V2/cfButton/cfButton';
import CFForm, { useForm } from '../../../ui-core/V2/cfForm/cfForm';
import CFLoader from '../../../ui-core/V2/cfLoader/cfLoader';
import { useCFModalStore } from '../../../ui-core/V2/cfModal/store';
import ApplicationQuestion from '../../applicationQuestion/applicationQuestion';
import { IApplicationQuestion } from '../../applicationQuestion/interface';
import { useQuotesStore } from '../../quotes/store';
import { MODIFIER_MESSAGES, MODIFIERS_STORE_KEYS } from './constants';
import { useModifiersStore } from './store';
import {
  CFModalStyled,
  DividerStyled,
  ModifiersQuestionsWrapperStyled,
  ModifierStateGroupStyled,
  ModifierStateGroupTitleStyled,
  ModifierStateGroupTitleWrapper,
  ModifiersWrapperStyled,
} from './styles';
import {
  generateFormConfigFromModifiersData,
  getGroupedModifierFormData,
  getStateAndModifierIdFromDataIndex,
} from './utils';

const Modifiers = () => {
  const {
    modifiersConfig,
    modifiersForm,
    modifiersFormConfig,
    modifiersLoading,
    updateModifiersLoading,
    updateModifiersStoreByKey,
    fetchModifiersConfig,
    validateModifiersForm,
    autoFillModifiers,
    clearModifiersStore,
  } = useModifiersStore();
  const { selectedQuote, updateQuotesByKey, fetchQuotes } = useQuotesStore();
  const { isReadOnlyApplication, updateApplication } = useApplicationStore();
  const { clearCfModal } = useCFModalStore();

  const [form] = useForm();

  const stateGroupKeys = Object.keys(getGroupedModifierFormData(modifiersConfig ?? []));

  useEffect(() => {
    (async () => {
      try {
        const responseModifiersConfig = await fetchModifiersConfig(selectedQuote?.carrierId);

        if (responseModifiersConfig) {
          const formConfig = generateFormConfigFromModifiersData(responseModifiersConfig);
          updateModifiersStoreByKey(MODIFIERS_STORE_KEYS.MODIFIERS_FORM, form);
          updateModifiersStoreByKey(MODIFIERS_STORE_KEYS.MODIFIERS_FORM_CONFIG, formConfig);
          autoFillModifiers();
        }
      } catch (error) {
        errorV2(MODIFIER_MESSAGES.MODIFIER_FETCH_ERROR);
      }
    })();

    return () => {
      updateQuotesByKey(QUOTES_STORE_KEYS.SELECTED_QUOTE, undefined);
      clearModifiersStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModifiersSubmit = async () => {
    const isValidModifiers = await validateModifiersForm();
    if (isValidModifiers) {
      try {
        updateModifiersStoreByKey(MODIFIERS_STORE_KEYS.UPDATE_MODIFIERS_LOADING, true);
        updateQuotesByKey(QUOTES_STORE_KEYS.REGENERATE_QUOTE_CARRIER_ID, selectedQuote?.carrierId);
        await updateApplication({
          updateApplicationStep: UPDATE_APPLICATION_STEPS.UPDATE_MODIFIERS,
          isApplicationLoading: false,
        });
        clearCfModal();
        await fetchQuotes();
      } catch (error) {
        errorV2(MODIFIER_MESSAGES.UPDATE_ERROR);
      } finally {
        updateModifiersStoreByKey(MODIFIERS_STORE_KEYS.UPDATE_MODIFIERS_LOADING, false);
        updateQuotesByKey(QUOTES_STORE_KEYS.REGENERATE_QUOTE_CARRIER_ID, undefined);
      }
    }
  };

  const handleModifierFieldChange = async (value: any, data: any) => {
    const { state: fieldState, modifierId: fieldModifierId } = getStateAndModifierIdFromDataIndex(data?.dataIndex);

    const selectedModifierIncompatibleConfig =
      modifiersConfig?.find((item) => item?.state === fieldState && item?.modifierId === fieldModifierId)
        ?.incompatibleModifiers ?? [];

    const modifiersFormConfigClone = cloneDeep(modifiersFormConfig);

    selectedModifierIncompatibleConfig.forEach((modifierId: string) => {
      const toDisableModifierFieldIndex =
        modifiersFormConfigClone?.findIndex((item) => {
          return item?.dataIndex === `${fieldState}#${modifierId}`;
        }) ?? -1;

      if (toDisableModifierFieldIndex !== -1 && modifiersFormConfigClone) {
        if (value) {
          modifiersFormConfigClone[toDisableModifierFieldIndex] = {
            ...modifiersFormConfigClone[toDisableModifierFieldIndex],
            disabled: true,
          };
        } else {
          modifiersFormConfigClone[toDisableModifierFieldIndex] = {
            ...modifiersFormConfigClone[toDisableModifierFieldIndex],
            disabled: false,
          };
        }
      }
    });
    updateModifiersStoreByKey(MODIFIERS_STORE_KEYS.MODIFIERS_FORM_CONFIG, modifiersFormConfigClone);
  };

  const handleModifiersStateGroupReset = (event: React.ChangeEvent<HTMLInputElement>, data?: any) => {
    const modifiersFormConfigClone = cloneDeep(modifiersFormConfig);
    const modifierState = data?.state ?? '';

    if (modifierState) {
      modifiersFormConfigClone?.forEach((entry) => {
        const modifierConfigState = getStateAndModifierIdFromDataIndex(entry?.dataIndex)?.state;
        const modifierConfigModifierId = getStateAndModifierIdFromDataIndex(entry?.dataIndex)?.modifierId;

        if (modifierConfigState === modifierState) {
          modifiersForm?.resetFields([entry?.dataIndex]);

          const targetModifierToSkipEnable = modifiersConfig
            ?.find((config) => config?.state === modifierState && config?.modifierId === modifierConfigModifierId)
            ?.incompatibleModifiers?.includes(CFModifierID.EXPERIENCE_MODIFIER);

          if (entry?.disabled && !targetModifierToSkipEnable) {
            entry.disabled = false;
          }
        }
      });
    }
    updateModifiersStoreByKey(MODIFIERS_STORE_KEYS.MODIFIERS_FORM_CONFIG, modifiersFormConfigClone);
  };

  return (
    <CFModalStyled
      title='Add Modifiers'
      onOk={handleModifiersSubmit}
      cancelButtonProps={{
        id: 'mod_btn_add_modifiers_cancel',
        disabled: updateModifiersLoading,
      }}
      okButtonProps={{
        id: 'mod_btn_add_modifiers_ok',
        loading: updateModifiersLoading,
        disabled:
          updateModifiersLoading ||
          modifiersLoading ||
          isReadOnlyApplication ||
          (modifiersConfig && stateGroupKeys?.length === 0),
      }}
      okText='Submit'
      cancelText='Close'
      width={700}
      maskClosable={false}
      disableCloseIcon={updateModifiersLoading}
    >
      {(!modifiersConfig || modifiersLoading) && <CFLoader marginTop='50px' marginBottom='50px'></CFLoader>}
      {modifiersConfig && !modifiersLoading && (
        <ModifiersWrapperStyled>
          <ModifiersQuestionsWrapperStyled>
            <CFForm id='form_modifiers_tab' labelCol={{ span: 15 }} wrapperCol={{ span: 24 }} form={form}>
              {modifiersFormConfig &&
                stateGroupKeys?.length > 0 &&
                stateGroupKeys?.map((state: string, index) => {
                  const stateGroupedData = getGroupedModifierFormData(modifiersConfig)?.[state];
                  return (
                    <ModifierStateGroupStyled key={state}>
                      <ModifierStateGroupTitleWrapper>
                        <ModifierStateGroupTitleStyled>{`${state} - Modifiers`}</ModifierStateGroupTitleStyled>
                        {stateGroupedData?.length > 0 && (
                          <CFButton
                            buttonType={BUTTON_TYPE.ACTION}
                            onClick={handleModifiersStateGroupReset}
                            data={{ state }}
                            disabled={updateModifiersLoading || isReadOnlyApplication}
                          >
                            Reset
                          </CFButton>
                        )}
                      </ModifierStateGroupTitleWrapper>
                      {stateGroupedData?.length > 0 &&
                        stateGroupedData?.map((question: IApplicationQuestion) => {
                          return (
                            <ApplicationQuestion
                              question={question}
                              key={`question-${question?.dataIndex}}`}
                              form={modifiersForm}
                              allQuestions={modifiersFormConfig}
                              onChange={handleModifierFieldChange}
                              disabled={question?.disabled || isReadOnlyApplication}
                            />
                          );
                        })}
                      {stateGroupedData?.length === 0 && <div>No Modifiers available for state {state}</div>}
                      {index !== stateGroupKeys?.length - 1 && <DividerStyled />}
                    </ModifierStateGroupStyled>
                  );
                })}
              {modifiersFormConfig && stateGroupKeys?.length === 0 && (
                <div>No Modifiers available for this application</div>
              )}
            </CFForm>
          </ModifiersQuestionsWrapperStyled>
        </ModifiersWrapperStyled>
      )}
    </CFModalStyled>
  );
};

export default Modifiers;
