import {
  IAgentPasswordChangeInput,
  ICreateAgentInput,
  ICreateAgentOutput,
  IDeleteAgentInput,
  IGetAgentInput,
  IGetAgentOutput,
  IGetAgentsInput,
  IGetAgentsOutput,
  IUpdateAgentInput,
  IUpdateAgentOutput,
} from '@coverforce-platform/cf-common-api-model';
import { AxiosResponse } from 'axios';
import { jwtDecode } from 'jwt-decode';

import axiosAccountService from '../../axios/accountService/instance';
import { JWT_TOKEN } from '../../globalConstants';
import { createQueryUrl } from '../../utils/createQueryUrl';
import { getFromLocalStorage } from '../../utils/getFromLocalStorage';
import { ACCOUNT_SERVICE_ENDPOINTS } from '../helpers/ApiRoutes';

const getAgentByUsername = async (): Promise<IGetAgentsOutput> => {
  const storageAccessToken = getFromLocalStorage(JWT_TOKEN.ACCESS_TOKEN);
  const accessToken: any = (storageAccessToken && jwtDecode(storageAccessToken)) || {};
  const { sub: userName = '' } = accessToken || {};

  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.GET_AGENT_BY_USERNAME_OR_AGENCY_ID_OR_AGENCY_NETWORK_ID,
    params: { userName },
  });
  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getAgentByAgentId = async (params: IGetAgentInput): Promise<IGetAgentOutput> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.GET_AGENT_BY_AGENT_ID(params.agentId),
  });
  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getAgents = async (params: IGetAgentsInput): Promise<IGetAgentsOutput> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.GET_AGENT_BY_USERNAME_OR_AGENCY_ID_OR_AGENCY_NETWORK_ID,
    params,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const addAgent = async (agent: ICreateAgentInput): Promise<ICreateAgentOutput> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.ADD_AGENT,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.post(apiUrl, agent);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const updateAgent = async (agent: IUpdateAgentInput): Promise<IUpdateAgentOutput> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.UPDATE_AGENT,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.put(apiUrl, agent);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

// This API is being replaced by "deleteAgentProfile"
const deleteAgent = async (params: IDeleteAgentInput): Promise<any> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.DELETE_AGENT(params.agentId),
  });

  try {
    const response: AxiosResponse = await axiosAccountService.delete(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const resetAgentPassword = async (params: IAgentPasswordChangeInput): Promise<any> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.RESET_PASSWORD(params),
  });
  try {
    const response: AxiosResponse = await axiosAccountService.post(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export { getAgentByUsername, getAgentByAgentId, getAgents, addAgent, updateAgent, deleteAgent, resetAgentPassword };
