import { IGetCFOwnerOfficerTitleInput, IGetCFOwnerOfficerTitleOutput } from '@coverforce-platform/cf-common-api-model';
import { AxiosResponse } from 'axios';

import axiosApplicationHelperService from '../../axios/applicationHelperService/instance';
import { HELPER_SERVICE_ENDPOINTS } from '../../externalServices/helpers/ApiRoutes';
import { createQueryUrl } from '../../utils/createQueryUrl';

export const getOwnerOfficerTitle = async (
  params: IGetCFOwnerOfficerTitleInput,
): Promise<IGetCFOwnerOfficerTitleOutput> => {
  const apiUrl = createQueryUrl({
    url: HELPER_SERVICE_ENDPOINTS.GET_ALL_CF_OWNERS_OFFICERS_TITLE,
    params,
  });

  try {
    const response: AxiosResponse = await axiosApplicationHelperService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};
