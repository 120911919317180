import { Tooltip } from 'antd';
import dayjs from 'dayjs';

import { getEmailRegex } from '../../helpers/Utils';
import { ReportDurationInfo, ReportTypeInfo } from '../../pages/v2/reportCenter/constants';
import { tagRender } from '../../pages/v2/requestToBindSettings/componentUtils';
import { DROPDOWN_MODE } from '../../ui-core/V2/cfSelect/constants';
import { ANTD_QUESTION_TYPE } from '../applicationQuestion/constants';
import { GENERATE_REPORT_QUESTION_KEYS, REPORT_DURATION_CUSTOM_OPTION } from './constants';
import { IGenerateReportQuestion } from './interface';
import { useGenerateReportStore } from './store';

export const GENERATE_REPORT_FORM_CONFIG: IGenerateReportQuestion[] = [
  {
    type: ANTD_QUESTION_TYPE.DROPDOWN,
    name: 'Report Type',
    dataIndex: GENERATE_REPORT_QUESTION_KEYS.REPORT_TYPE,
    placeholder: 'Select a Report Type',
    options:
      ReportTypeInfo?.map((reportType) => ({
        label: (
          <Tooltip title={reportType?.toolTipText} placement='right'>
            {reportType.displayValue}
          </Tooltip>
        ) as unknown as string,
        value: reportType.value,
      })) ?? [],
    filterOption: (input: string, option: any) => {
      return (option?.label?.props?.children as unknown as string)?.toLowerCase().includes(input?.toLowerCase());
    },
    rules: [{ required: true, message: 'Please select Report Type.' }],
  },
  {
    type: ANTD_QUESTION_TYPE.DROPDOWN,
    name: 'Recipient Email(s)',
    dataIndex: GENERATE_REPORT_QUESTION_KEYS.TO_EMAIL_LIST,
    placeholder: 'Please enter the Recipient Email(s)',
    options: [],
    tagRender: ({ label, value }) => {
      const { generateReportLoading } = useGenerateReportStore?.getState() ?? {};
      return tagRender({
        label,
        value,
        closable: !generateReportLoading,
        onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>, removedEmail: string) => {
          const { generateReportForm } = useGenerateReportStore?.getState() ?? {};

          const currentEmailList = generateReportForm?.getFieldValue(GENERATE_REPORT_QUESTION_KEYS.TO_EMAIL_LIST) ?? [];
          const filterEmailList = currentEmailList?.filter((email: string) => email !== removedEmail) ?? [];

          generateReportForm?.setFieldValue(GENERATE_REPORT_QUESTION_KEYS.TO_EMAIL_LIST, filterEmailList);
          generateReportForm?.validateFields([GENERATE_REPORT_QUESTION_KEYS.TO_EMAIL_LIST]);
        },
      });
    },
    rules: [
      { required: true, message: `Please enter valid Email(s).` },
      () => ({
        validator(_: any, value: any) {
          let isError = false;

          value?.forEach((email: string) => {
            if (!getEmailRegex().test(email?.trim())) {
              isError = true;
            }
          });

          if (isError) {
            return Promise.reject(`Please enter valid Email(s).`);
          }
          return Promise.resolve();
        },
      }),
    ],
    mode: DROPDOWN_MODE.TAGS,
  },
  {
    type: ANTD_QUESTION_TYPE.DROPDOWN,
    name: 'Time Frame',
    dataIndex: GENERATE_REPORT_QUESTION_KEYS.REPORT_DURATION,
    placeholder: 'Select a Time Frame',
    options:
      [...ReportDurationInfo, REPORT_DURATION_CUSTOM_OPTION].map((reportDuration) => ({
        label: (
          <Tooltip title={reportDuration?.toolTipText} placement='right'>
            {reportDuration.displayValue}
          </Tooltip>
        ) as unknown as string,
        value: reportDuration.value,
      })) ?? [],
    filterOption: (input: string, option: any) => {
      return (option?.label?.props?.children as unknown as string)?.toLowerCase().includes(input?.toLowerCase());
    },
    rules: [{ required: true, message: 'Please select Report Time Frame.' }],
    getPopupContainer: () => document.getElementById('root') ?? document.body,
  },
  {
    type: ANTD_QUESTION_TYPE.DATE_RANGE,
    name: 'Start and End Date',
    dataIndex: GENERATE_REPORT_QUESTION_KEYS.TIME_FRAME_DATE_RANGE,
    format: 'MM-DD-YYYY',
    disabledDate: (date: dayjs.Dayjs) => date && date >= dayjs().local().subtract(0, 'day'),
    defaultPickerValue: [dayjs().local().subtract(30, 'day'), dayjs().local().subtract(0, 'day')],
    containerId: 'root',
    hidden: true,
  },

  {
    type: ANTD_QUESTION_TYPE.CHECKBOX,
    name: 'Download Report Copy',
    dataIndex: GENERATE_REPORT_QUESTION_KEYS.IS_REPORT_DOWNLOAD,
  },
];
