import { normalizePhoneNumber } from '../../../helpers/Utils';
import { isEmpty, omitBy } from '../../../utils/lodash';
import { IAccount } from '../account/interface';

export const getEditingAccount = (account?: IAccount) => {
  return account
    ? {
        ...account,
        primaryContactDetails: {
          ...account.primaryContactDetails,
          phone: normalizePhoneNumber(account.primaryContactDetails?.phone),
        },
      }
    : account;
};

export const getPhoneFromAccount = ({ primaryContactDetails }: IAccount) => {
  const formattedPhone = normalizePhoneNumber(`${primaryContactDetails?.phone}`).trim();
  if (formattedPhone.length) {
    return formattedPhone;
  }
  return '--';
};

export const formattedName = ({ primaryContactDetails }: IAccount) => {
  const name = `${primaryContactDetails?.firstName || ''} ${primaryContactDetails?.lastName || ''}`.trim();
  if (name.length) {
    return name;
  }
  return '--';
};

export const formattedLocation = ({ primaryAddress }: IAccount) => {
  const location = `${primaryAddress?.city || ''}, ${primaryAddress?.state || ''}`.trim();
  if (location.length <= 1) {
    return '--';
  }
  return location;
};

// With latest changes, agencyNetworkId is received in IAccountInfo response
// This function can be improved in future in terms if expecting params type and return types
export const getFormattedAccountParams = (params: any) => {
  const payload = {
    ...params,
    primaryContactDetails: omitBy(params.primaryContactDetails, isEmpty),
    primaryAddress: params.primaryAddress && {
      ...params.primaryAddress,
      zip: params.primaryAddress.zip?.toString(),
    },
  };
  delete payload?.agencyNetworkId;
  return payload;
};
