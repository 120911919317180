import { IRequestToBindStore } from './interface';

export const REQUEST_TO_BIND_MODAL = {
  TITLE: 'Request to Bind',
};

export const REQUEST_TO_BIND_MODAL_STORE = {
  NAME: 'Request To Bind Modal Store',
  ANONYMOUS_ACTION: 'REQUEST_TO_BIND_MODAL',
  REQUEST_TO_BIND_LOADING: 'REQUEST_TO_BIND_LOADING',
  UPDATE_REQUEST_TO_BIND_MODAL_STORE_BY_KEY: 'UPDATE_REQUEST_TO_BIND_MODAL_STORE_BY_KEY',
  CLEAR_REQUEST_TO_BIND: 'CLEAR_REQUEST_TO_BIND',
};

export const REQUEST_TO_BIND_MODAL_STORE_KEYS = {
  IS_REQUEST_TO_BIND_LOADING: 'isRequestToBindLoading' as keyof IRequestToBindStore,
  REQUEST_TO_BIND_FORM: 'requestToBindForm' as keyof IRequestToBindStore,
};

export const REQUEST_TO_BIND_QUOTE_MESSAGES = {
  SUCCESS: 'The bind request was created successfully!',
  ERROR: 'Error while marking quote as request to bind!',
};

export const REQUEST_TO_BIND_FORM_ITEM = {
  NOTE: 'note',
};

export const REQUEST_TO_BIND_FORM_CONFIG = {
  noteField: {
    id: 'form_item_inp_note',
    name: REQUEST_TO_BIND_FORM_ITEM.NOTE,
    label: 'Notes to central team:',
  },
};
