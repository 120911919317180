import { QuestionCircleOutlined } from '@ant-design/icons';
import { IBrokerCodeInfo, IBrokerCodeRestrictionsInfo } from '@coverforce-platform/cf-common-api-model';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { normalizePhoneNumber } from '../../../helpers/Utils';
import { GA_CATEGORY } from '../../../pages/v2/brokerConfig/constants';
import { getCarrierSpecificCategory } from '../../../pages/v2/brokerConfig/utils';
import { error, success } from '../../../ui-core/Notification';
import CFModal from '../../../ui-core/V2/cfModal/cfModal';
import { useCFModalStore } from '../../../ui-core/V2/cfModal/store';
import CFTable, { CFTableColumn } from '../../../ui-core/V2/cfTable/cfTable';
import { TableEmptyStyled } from '../../../ui-core/V2/cfTable/styles';
import { pushEvent } from '../../../utils/googleAnalytics';
import {
  BIND_ACTIONS_MESSAGES,
  BIND_ACTIONS_ROW_LEVEL,
  bindActionsTableColumns,
  BROKER_CODE_RESTRICTIONS_KEYS,
  EDIT_BIND_ACTIONS_STORE_KEYS,
  GA_ACTION,
  GA_LABEL,
} from './constants';
import { useEditBindActionsStore } from './store';
import { TextStyled, TooltipStyled } from './styles';
import { getAgencyLevelBrokerActionKey } from './utils';

const EditBindActionsModal = () => {
  const {
    brokerConfig,
    presetBrokerConfig,
    agencyRowDisabled,
    selectedCarrierDetails,
    updateBrokerConfig,
    setBindActionsStoreByKey,
    clearEditBindActionsStore,
  } = useEditBindActionsStore();
  const { clearCfModal } = useCFModalStore();

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (presetBrokerConfig) {
      const configArray = [{ level: BIND_ACTIONS_ROW_LEVEL.AGENCY, ...presetBrokerConfig }];
      setBindActionsStoreByKey(EDIT_BIND_ACTIONS_STORE_KEYS.BROKER_CONFIG, configArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clearEditBindActionsStore(), []);

  const handleSubmit = async () => {
    pushEvent({
      category: getCarrierSpecificCategory(
        GA_CATEGORY.BROKER_CONFIG_QUOTE_ACTION_MODAL,
        selectedCarrierDetails?.carrierId || '',
      ),
      action: GA_ACTION.SAVE_QUOTE_ACTIONS,
      label: GA_LABEL.SAVE_QUOTE_ACTIONS,
    });
    if (brokerConfig?.length > 0) {
      setIsSubmitting(true);
      // add handling for 2 api calls once the network row is integrated
      try {
        const { level, ...brokerConfigDetails } = brokerConfig[0];
        await updateBrokerConfig({
          carrierId: brokerConfigDetails?.carrierId,
          updatedBrokerConfig: {
            ...brokerConfigDetails,
            contactDetails: {
              ...brokerConfigDetails?.contactDetails,
              phone:
                brokerConfigDetails?.contactDetails?.phone &&
                normalizePhoneNumber(brokerConfigDetails?.contactDetails?.phone),
            },
          },
        });
        success(BIND_ACTIONS_MESSAGES.UPDATE_SUCCESS);
        clearCfModal();
      } catch (err: any) {
        error(err?.[0]?.errorMessage);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const onSwitchClick = useCallback(
    (checked, e, data) => {
      const updatedbrokerConfig = cloneDeep(brokerConfig);
      const index = brokerConfig?.findIndex((el) => el.level === data?.level);
      let key;
      if (data?.level === BIND_ACTIONS_ROW_LEVEL.AGENCY) {
        key = getAgencyLevelBrokerActionKey(data?.key);
      }
      if (index !== -1 && key) {
        const brokerCodeRestrictions = updatedbrokerConfig?.[index]?.[
          BROKER_CODE_RESTRICTIONS_KEYS.BROKER_CODE_RESTRICTIONS as keyof IBrokerCodeInfo
        ] as IBrokerCodeRestrictionsInfo;
        if (brokerCodeRestrictions) {
          brokerCodeRestrictions[key as keyof IBrokerCodeRestrictionsInfo] = !checked;
          setBindActionsStoreByKey(EDIT_BIND_ACTIONS_STORE_KEYS.BROKER_CONFIG, updatedbrokerConfig);
        }
      }
    },
    [brokerConfig, setBindActionsStoreByKey],
  );
  const handleCancel = useCallback(() => {
    pushEvent({
      category: getCarrierSpecificCategory(
        GA_CATEGORY.BROKER_CONFIG_QUOTE_ACTION_MODAL,
        selectedCarrierDetails?.carrierId || '',
      ),
      action: GA_ACTION.CLOSE_MODAL,
      label: GA_LABEL.CLOSE_MODAL,
    });
  }, [selectedCarrierDetails?.carrierId]);

  return (
    <CFModal
      title={
        <>
          Configure Broker Code Quote Actions
          <TooltipStyled title='Change what quote actions are enabled for the Broker Code'>
            <QuestionCircleOutlined />
          </TooltipStyled>
        </>
      }
      okText='Save'
      onOk={handleSubmit}
      onCancel={handleCancel}
      confirmLoading={isSubmitting}
      okButtonProps={{ id: 'btn_edit_bind_actions_ok', loading: isSubmitting, disabled: agencyRowDisabled }}
      cancelButtonProps={{ id: 'btn_edit_bind_actions_cancel', disabled: isSubmitting }}
      disableCloseIcon={isSubmitting}
      maskClosable={!isSubmitting}
      width={800}
    >
      <div>
        These settings are applicable to the following {selectedCarrierDetails?.carrierShortName ?? ''} Broker Code:
      </div>
      <TextStyled>{presetBrokerConfig?.configurationName ?? ''}</TextStyled>
      <br />

      <CFTable
        id='tbl_bind_actions'
        dataSource={brokerConfig}
        size='middle'
        pagination={false}
        scroll={{ x: true }}
        locale={{
          emptyText: <TableEmptyStyled />,
        }}
      >
        {bindActionsTableColumns({ onSwitchClick, disabled: agencyRowDisabled || isSubmitting }).map((col) => {
          return <CFTableColumn key={col?.key} {...col} />;
        })}
      </CFTable>
    </CFModal>
  );
};

export default EditBindActionsModal;
