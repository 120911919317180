import { useEffect } from 'react';

import { PAYMENT_STORE_KEYS } from '../payment/constants';
import { usePaymentStore } from '../payment/store';
import { DECLINED_REASONS_MODAL } from './constants';
import {
  PolicyDeclinedReasonItemStyled,
  PolicyDeclinedReasonsModalStyled,
  PolicyDeclinedReasonsStyled,
} from './styles';

const PolicyDeclinedReasonsModal = () => {
  const { policyDetails, updatePaymentsByKey } = usePaymentStore();
  const { failures = [] } = policyDetails || {};

  useEffect(() => {
    return () => {
      updatePaymentsByKey(PAYMENT_STORE_KEYS.POLICY_DETAILS as any, undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PolicyDeclinedReasonsModalStyled title={DECLINED_REASONS_MODAL.TITLE} footer={null}>
      <PolicyDeclinedReasonsStyled>
        {failures.map((item, index) => (
          <PolicyDeclinedReasonItemStyled key={`${item.code}-${index}`}>{item.reason}</PolicyDeclinedReasonItemStyled>
        ))}
      </PolicyDeclinedReasonsStyled>
    </PolicyDeclinedReasonsModalStyled>
  );
};

export default PolicyDeclinedReasonsModal;
