import {
  IGetAllCarriersOutput,
  IGetAvailableCarriersForAgencyInput,
  IGetAvailableCarriersForAgencyNetworkInput,
  IGetAvailableCarriersForAgencyNetworkOutput,
  IGetAvailableCarriersForAgencyOutput,
  IGetAvailableCarriersForAgentInput,
  IUpdateAvailableCarriersForAgencyInput,
  IUpdateAvailableCarriersForAgencyOutput,
  IUpdateCarrierRankingsInput,
} from '@coverforce-platform/cf-common-api-model';
import { AxiosResponse } from 'axios';

import axiosAccountService from '../../axios/accountService/instance';
import axiosApplicationHelperService from '../../axios/applicationHelperService/instance';
import { ACCOUNT_SERVICE_ENDPOINTS, CARRIER_SERVICE_ENDPOINTS } from '../../externalServices/helpers/ApiRoutes';
import { createQueryUrl } from '../../utils/createQueryUrl';
import { set, sortBy } from '../../utils/lodash';

const getAvailableCarriers = async (params: IGetAvailableCarriersForAgentInput) => {
  const apiUrl = createQueryUrl({
    url: CARRIER_SERVICE_ENDPOINTS.GET_AVAILABLE_CARRIERS({ agentId: params?.agentId }),
  });

  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl);

    response?.data?.availableCarriers?.length > 0 &&
      set(response, ['data', 'availableCarriers'], sortBy(response.data.availableCarriers, 'carrierDisplayName'));

    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getAllCarriersInfo = async (): Promise<IGetAllCarriersOutput> => {
  const apiUrl = createQueryUrl({
    url: CARRIER_SERVICE_ENDPOINTS.GET_ALL_CARRIERS,
  });

  try {
    const response: AxiosResponse = await axiosApplicationHelperService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const updateCarrierRanking = async (params: IUpdateCarrierRankingsInput) => {
  const apiUrl = createQueryUrl({
    url: CARRIER_SERVICE_ENDPOINTS.UPDATE_CARRIER_RANKING,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.put(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getAvailableCarriersByAgencyId = async (
  params: IGetAvailableCarriersForAgencyInput,
): Promise<IGetAvailableCarriersForAgencyOutput> => {
  const { agencyId } = params;
  const getAvailableCarriersByAgencyIdUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.GET_AVAILABLE_CARRIERS_BY_AGENCY_ID(agencyId),
  });

  try {
    const response: AxiosResponse = await axiosAccountService.get(getAvailableCarriersByAgencyIdUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getAvailableCarriersByAgencyNetworkId = async (
  params: IGetAvailableCarriersForAgencyNetworkInput,
): Promise<IGetAvailableCarriersForAgencyNetworkOutput> => {
  const { agencyNetworkId } = params;
  const getAvailableCarriersByAgencyNetworkIdUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.GET_AVAILABLE_CARRIERS_BY_AGENCY_NETWORK_ID(agencyNetworkId),
  });

  try {
    const response: AxiosResponse = await axiosAccountService.get(getAvailableCarriersByAgencyNetworkIdUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const updateAvailableCarriersByAgencyId = async (
  params: IUpdateAvailableCarriersForAgencyInput,
): Promise<IUpdateAvailableCarriersForAgencyOutput> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.UPDATE_AVAILABLE_CARRIERS_BY_AGENCY_ID,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.put(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

// TODO: to update with correct type once BE is done
const updateAvailableCarriersByAgencyNetworkId = async (params: any): Promise<any> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.UPDATE_AVAILABLE_CARRIERS_BY_AGENCY_NETWORK_ID,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.put(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export {
  getAvailableCarriers,
  getAllCarriersInfo,
  updateCarrierRanking,
  getAvailableCarriersByAgencyId,
  getAvailableCarriersByAgencyNetworkId,
  updateAvailableCarriersByAgencyNetworkId,
  updateAvailableCarriersByAgencyId,
};
