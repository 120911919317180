import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import Anchor from '../../../ui-core/V2/anchor/anchor';
import { CFMenuItem } from '../../../ui-core/V2/cfMenu/cfMenu';
import { CFNavMenuStyled } from '../../navBarV2/styles';
import { accountsMenuElements } from './constants';

const AccountsMenu = () => {
  const theme: any = useTheme();
  const location = useLocation();

  return (
    <CFNavMenuStyled
      id='nav_menu_accounts'
      selectedKeys={[location?.pathname]}
      backgroundColor={theme.colors.grayscale.white}
    >
      {accountsMenuElements.map((el) => (
        <CFMenuItem icon={<el.icon />} key={el.to}>
          <Anchor id={el.label} href={el.to}>
            {el.label}
          </Anchor>
        </CFMenuItem>
      ))}
    </CFNavMenuStyled>
  );
};

export default AccountsMenu;
