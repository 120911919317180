import {
  IApplicationInfo,
  ICarrierInfo,
  ILiabilityLimitsInfo,
  ILocationInfo,
  IPaymentPlan,
  IQuoteInfo,
} from '@coverforce-platform/cf-common-api-model';
import { ApplicationStatus, Carrier, PaymentPlanType } from '@coverforce-platform/cf-common-types';

import { APPLICATION_STORE_KEYS, APPLICATION_TABS } from '../../constants/applicationV2Constants';
import { FEATURE_FLAG, POLICY_NAME_MAPPING, POPUP_TYPE } from '../../globalConstants';
import { IAntdOptions } from '../../globalInterface';
import { OwnerOfficerMessageType } from '../../helpers/Constants';
import { formatDate, formatPrice, normalizeMoneyInput, removeDecimal } from '../../helpers/Utils';
import { IAccount } from '../../pages/v2/account/interface';
import { useApplicationStore } from '../../pages/v2/application/store';
import { errorV2 } from '../../ui-core/Notification';
import { IFormInstance } from '../../ui-core/V2/cfForm/cfForm';
import { useCFModalStore } from '../../ui-core/V2/cfModal/store';
import { getLiabilityLimitsByPolicy } from '../../utils/application/application';
import { generateAPIErrorMessage } from '../../utils/generateAPIErrorMessage';
import { getGrowthbookFeatureValueByKey } from '../../utils/growthbook';
import { isValidCardExpiryDate, normalizeCardExpiryDate } from '../../utils/paymentCardExpiryDateUtil';
import { denormalizeCardNumber, isValidCardNumber } from '../../utils/paymentCardNumberUtil';
import { formatCvv, isValidCardCvv } from '../../utils/paymentCvvUtil';
import { DISABLED_PAYMENT_PLAN_CARRIERS, PAYMENT_WIDGET_FORM_CONFIG } from '../paymentWidget/constants';
import { getOwnersOfficerMessages } from '../quoteOwnersOfficersDetails/utils';
import { useQuotesStore } from '../quotes/store';
import { getSumTotalConstructionProjectValue } from '../quotes/utils';
import { PAYMENT_ERROR_MESSAGE, POLICY_FALLBACK_FAILURE_REASON } from './constants';
import { usePaymentStore } from './store';

export const getLiabilityLimitValueByPolicyType = (quote?: IQuoteInfo) => {
  const { liabilityLimitKeys } = getLiabilityLimitsByPolicy();
  let liabilityLimitContent = '';

  liabilityLimitKeys.map((item: any, index: number) => {
    if (quote?.liabilityLimits) {
      liabilityLimitContent = `${liabilityLimitContent} $ ${removeDecimal(
        formatPrice(quote?.liabilityLimits?.[item as keyof ILiabilityLimitsInfo]),
      )} ${index < liabilityLimitKeys.length - 1 ? '/' : ''}`;
    }
  });

  return liabilityLimitContent;
};

export const generateOwnerOfficersMessages = (applicationData: IApplicationInfo) =>
  getOwnersOfficerMessages({
    states: applicationData?.locationDetails?.map((location: ILocationInfo) => location?.address?.state) || [],
    legalEntity: applicationData?.companyStructure?.legalEntityType || '',
    messageType: OwnerOfficerMessageType.BIND,
  });

export const getEnabledPaymentPlans = (paymentPlans: IPaymentPlan[], carrierId: Carrier): IPaymentPlan[] => {
  let filteredPaymentPlans: IPaymentPlan[] = [];
  const paymentCarrierGrowthBookConfig = getGrowthbookFeatureValueByKey(FEATURE_FLAG.PAYMENT_CARRIER_CONFIG);

  if (paymentCarrierGrowthBookConfig) {
    paymentPlans?.forEach((plan) => {
      if (paymentCarrierGrowthBookConfig?.NON_DEFERRED?.includes(carrierId)) {
        if (plan?.paymentPlanType === PaymentPlanType.NON_DEFERRED) {
          filteredPaymentPlans.push(plan);
        }
      }

      if (paymentCarrierGrowthBookConfig?.DEFERRED?.includes(carrierId)) {
        if (plan?.paymentPlanType === PaymentPlanType.DEFERRED) {
          filteredPaymentPlans.push(plan);
        }
      }
    });
  } else {
    filteredPaymentPlans = paymentPlans;
  }
  return filteredPaymentPlans;
};

export const isPaymentDetailsValidUtil = ({
  storePaymentOption,
  form,
}: {
  storePaymentOption?: PaymentPlanType;
  form: IFormInstance;
}) => {
  if (storePaymentOption === PaymentPlanType.NON_DEFERRED) {
    if (
      form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.paymentPlanNonDeferred.name) &&
      isValidCardNumber(
        denormalizeCardNumber(form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.cardNumberNonDeferred.name) || ''),
      ) &&
      isValidCardExpiryDate(
        normalizeCardExpiryDate(form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.cardExpiryNonDeferred.name) || ''),
      ) &&
      isValidCardCvv(formatCvv(form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.cardCvvNonDeferred.name) || '')) &&
      form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.cardNameNonDeferred.name)?.length >= 2
    ) {
      return true;
    }
    return false;
  } else if (storePaymentOption === PaymentPlanType.DEFERRED) {
    if (form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.paymentPlanDeferred.name)) {
      return true;
    }
    return false;
  }

  return false;
};

export const getSelectedCarrierDetails = ({
  selectedCarriersInfo,
  selectedQuote,
}: {
  selectedCarriersInfo?: ICarrierInfo[];
  selectedQuote?: IQuoteInfo;
}) => selectedCarriersInfo?.find((carrier) => carrier.carrierId === selectedQuote?.carrierId);

export const getEffectiveAmount = ({
  storePaymentPlans,
  selectedQuote,
  form,
}: {
  storePaymentPlans: IPaymentPlan[];
  selectedQuote?: IQuoteInfo;
  form?: IFormInstance;
}) => {
  if (storePaymentPlans?.length > 0 && form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.paymentPlanNonDeferred.name)) {
    return formatPrice(
      storePaymentPlans
        ?.find((plan) => {
          return plan?.cfPaymentPlanId === form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.paymentPlanNonDeferred.name);
        })
        ?.downPaymentDetails?.downPaymentAmount?.toString(),
    );
  }
  return formatPrice(selectedQuote?.totalAmount?.toString());
};

export const getPaymentSummaryInfo = ({
  selectedQuote,
  accountData,
}: {
  selectedQuote?: IQuoteInfo;
  accountData?: IAccount;
}): IAntdOptions[] => {
  return [
    {
      label: 'Policy Type',
      value: (selectedQuote?.policyType && POLICY_NAME_MAPPING[selectedQuote?.policyType]) || '',
    },
    { label: 'Account Name', value: accountData?.accountName || '' },
    {
      label: 'Total Amount',
      value: (selectedQuote?.totalAmount && `$${formatPrice(selectedQuote?.totalAmount.toString()) || ''}`) || '',
    },
    { label: 'Quote ID', value: `#${selectedQuote?.carrierQuoteId ?? ''}` },
    { label: 'Effective Date', value: formatDate(selectedQuote?.insuranceEffectiveDate, 'MM-DD-YYYY', false) || '' },
    { label: 'Expiration Date', value: formatDate(selectedQuote?.insuranceExpiryDate, 'MM-DD-YYYY', false) || '' },
    { label: 'Liability Limits', value: getLiabilityLimitValueByPolicyType(selectedQuote) || '' },
    {
      label: 'Project Value',
      value: getSumTotalConstructionProjectValue()
        ? normalizeMoneyInput(getSumTotalConstructionProjectValue()?.toString())
        : '',
    },
  ];
};

const setPaymentPlanUtil = async (selectedPaymentPlan: any): Promise<boolean> => {
  const { applicationData } = useApplicationStore.getState();
  const { setPaymentPlan, setIsBounding } = usePaymentStore.getState();
  const { selectedQuote } = useQuotesStore.getState();
  try {
    await setPaymentPlan({
      applicationId: applicationData?.applicationId!,
      cfPaymentPlanId: selectedPaymentPlan?.cfPaymentPlanId,
      cfQuoteId: selectedQuote?.cfQuoteId!,
    });
    return true;
  } catch (error) {
    errorV2(PAYMENT_ERROR_MESSAGE.SET_PAYMENT_PLAN);
    setIsBounding(false);
    return false;
  }
};

export const bindAsyncPolicyUtil = async (): Promise<boolean> => {
  const { setIsBounding, bindPolicyV2, fetchPolicy, pollApplication } = usePaymentStore.getState();
  const { selectedQuote } = useQuotesStore.getState();
  const { applicationData, updateApplicationByKey } = useApplicationStore.getState();
  const { setCfModalData } = useCFModalStore.getState();

  setIsBounding(true);
  try {
    // If it is happy flow and we have selected quote
    if (selectedQuote) {
      let cfPolicyId = selectedQuote?.cfPolicyId;
      // both payment option DEFERRED, NON_DEFERRED bind logic resides here

      // either status is QUOTED/REFERRED then for binding start we need to call bindPolicy function
      if (
        [ApplicationStatus.QUOTED, ApplicationStatus.REFERRED].includes(applicationData?.applicationStatus?.statusCode!)
      ) {
        // Make bind quote api call and get cfPolicyId for polling
        const data = await bindPolicyV2({
          applicationId: applicationData?.applicationId!,
          cfQuoteId: selectedQuote?.cfQuoteId!,
        });

        cfPolicyId = data.cfPolicyId;
      }

      // if we dont get policy id then we dont want to start polling. (This will be never happen but its just safe check)
      if (!cfPolicyId) {
        return false;
      }

      // Now we have policy id so we can start poling
      const response = await pollApplication(applicationData?.applicationId!);
      if (!response) {
        return false;
      }

      // After  poll complete
      switch (response.applicationStatus?.statusCode) {
        // Status QUOTED/REFERRED or payment that means policy bound failed, so we need to display the failing reasons.
        case ApplicationStatus.QUOTED:
        case ApplicationStatus.REFERRED:
          // It means Policy binding fail, in this case we need to display failing reasons so we call fetch policy
          await fetchPolicy({
            cfPolicyId,
            cfQuoteId: selectedQuote?.cfQuoteId || '',
            applicationId: selectedQuote?.applicationId || '',
          });
          setCfModalData({ isVisible: true, popupType: POPUP_TYPE.POLICY_DECLINED_REASONS });
          return false;
        case ApplicationStatus.BOUND:
          // In case of success
          return true;
        default:
          return false;
      }
    } else {
      // if page refresh and we dont have selected quote
      if (applicationData?.applicationStatus?.statusCode === ApplicationStatus.BIND_IN_PROGRESS) {
        // Now we have policy id so we can start poling
        const response = await pollApplication(applicationData?.applicationId!);
        if (!response) {
          return false;
        }
        switch (response.applicationStatus?.statusCode) {
          case ApplicationStatus.QUOTED:
          case ApplicationStatus.REFERRED:
            errorV2(POLICY_FALLBACK_FAILURE_REASON, 3);
            updateApplicationByKey(APPLICATION_STORE_KEYS.CURRENT_STEP, APPLICATION_TABS.QUOTES);
            return false;
          case ApplicationStatus.BOUND:
            updateApplicationByKey(APPLICATION_STORE_KEYS.CURRENT_STEP, APPLICATION_TABS.CONFIRMATION);
            return true;

          default:
            return false;
        }
      }
      return false;
    }
  } catch (error: any) {
    errorV2(generateAPIErrorMessage(error, PAYMENT_ERROR_MESSAGE.BIND_POLICY));
    return false;
  } finally {
    setIsBounding(false);
  }
};

export const handleBindPolicy = async (form?: IFormInstance) => {
  const { selectedQuote } = useQuotesStore.getState();
  const { updateApplicationByKey } = useApplicationStore.getState();
  const {
    paymentOption: storePaymentOption,
    paymentPlans: storePaymentPlans,
    setIsBounding,
  } = usePaymentStore.getState();

  let isValidToMoveAhead = true;
  if (selectedQuote?.cfQuoteId) {
    let selectedPaymentPlan;
    if (storePaymentOption === PaymentPlanType.NON_DEFERRED) {
      selectedPaymentPlan = storePaymentPlans?.find(
        (plan) => plan?.cfPaymentPlanId === form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.paymentPlanNonDeferred.name),
      );
    } else if (storePaymentOption === PaymentPlanType.DEFERRED) {
      selectedPaymentPlan = storePaymentPlans?.find(
        (plan) => plan?.cfPaymentPlanId === form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.paymentPlanDeferred.name),
      );
    }

    if (selectedPaymentPlan || DISABLED_PAYMENT_PLAN_CARRIERS.includes(selectedQuote?.carrierId || '')) {
      setIsBounding(true);

      // if its fresh flow, set payment plan from from data by api request setPaymentPlan
      if (selectedPaymentPlan) {
        isValidToMoveAhead = await setPaymentPlanUtil(selectedPaymentPlan);
      }

      if (!isValidToMoveAhead) {
        return;
      }

      // both payment option DEFERRED, NON_DEFERRED bind logic resides here
      isValidToMoveAhead = await bindAsyncPolicyUtil();

      if (!isValidToMoveAhead) {
        return;
      }

      updateApplicationByKey(APPLICATION_STORE_KEYS.CURRENT_STEP, APPLICATION_TABS.CONFIRMATION);
    }
  }
};
