import { SelectProps } from 'antd';

export interface ICFSelect extends SelectProps {
  onChange?: (value: string, data: any) => void;
  onMultiModeChange?: (values: string[], data: any) => void;
  data?: any;
}

// eslint-disable-next-line no-shadow
export enum DropdownMode {
  MULTIPLE = 'multiple',
  TAGS = 'tags',
}
