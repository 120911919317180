import { APPLICATION_TABS } from '../../constants/applicationV2Constants';
import { PAGE_NAME } from '../../globalConstants';
import { useApplicationStore } from '../../pages/v2/application/store';
import { getCurrentPageName } from '../../utils/getCurrentPageName';
import { GA_CATEGORY as QUOTES_GA_CATEGORY } from '../quotes/constants';
import { getGaCategoryName } from '../quotes/utils';

export const getGACategory = (carrierId?: string) => {
  const currentPage = getCurrentPageName();
  const { currentStep } = useApplicationStore.getState();

  switch (currentPage) {
    case PAGE_NAME.APPLICATION:
      switch (currentStep) {
        case APPLICATION_TABS.QUOTES:
          return getGaCategoryName(QUOTES_GA_CATEGORY.QUOTES_TAB_REQUEST_TO_BIND_MODAL, carrierId);

        default:
          return currentPage;
      }

    default:
      return currentPage;
  }
};
