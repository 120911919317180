import { Carrier, USState } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getJobCodesByState } from '../../externalServices/V2/location';
import { IAntdOptions } from '../../globalInterface';
import { useApplicationStore } from '../../pages/v2/application/store';
import { getSelectedCarriersFromApplication } from '../../pages/v2/application/utils';
import { errorV2 } from '../../ui-core/Notification';
import { formatJobCodesToAntdOptions } from '../../utils/formatToAntdOptions';
import { cloneDeep, get as _get, set as _set } from '../../utils/lodash';
import { useLocationDetailsStore } from '../locationDetails/store';
import {
  EMPLOYEE_INFO_ERROR_MESSAGES,
  EMPLOYEE_INFO_STORE_META_DATA,
  ENABLED_CARRIERS_FOR_CLASS_CODE,
  FIELD_KEYS,
} from './constants';
import { IActiveKeys, IEmployeeInformation } from './interface';
import {
  getEmployeeInformationApiPayloadUtil,
  handleAutoFillCarrierNCCIClassCode,
  handleAutoFillNCCIClassAndEmployeeTypeDetails,
} from './utils';

export const useEmployeeInformationStore = create<IEmployeeInformation>()(
  devtools(
    (set, get) => ({
      classCodes: [],
      isEmployeeInfoLoading: false,
      carriersInternalClassCodesLoading: false,
      carriersInternalClassCodes: [],
      carriersValidForInternalClassCodes: [],
      selectedEmployeeTypeIndex: 0,
      activeKeys: { nextFieldKey: 0, keys: {} },

      handleActiveKeys: (keys: IActiveKeys) => {
        set({ activeKeys: keys }, false, EMPLOYEE_INFO_STORE_META_DATA.SET_ACCORDION_ACTIVE_KEYS);
      },

      setIsEmployeeInfoLoading: (isEmployeeInfoLoading: boolean) => {
        set({ isEmployeeInfoLoading });
      },

      setCarriersInternalClassCodesLoading: (carriersInternalClassCodesLoading: boolean) => {
        set({ carriersInternalClassCodesLoading });
      },

      // This is basically the intersection for the enabled carriers and the selected carriers from application
      setCarriersValidForInternalClassCodes: () => {
        const { applicationData } = useApplicationStore.getState();
        const applicationCarriersSelected = getSelectedCarriersFromApplication(applicationData);
        const carrierSelected: Carrier[] = [];
        ENABLED_CARRIERS_FOR_CLASS_CODE.forEach((carrier) => {
          if (applicationCarriersSelected?.indexOf(carrier) !== -1) {
            carrierSelected.push(carrier);
          }
        });
        set(
          { carriersValidForInternalClassCodes: carrierSelected },
          false,
          EMPLOYEE_INFO_STORE_META_DATA.SET_CARRIERS_VALID_FOR_INTERNAL_CLASS_CODES,
        );
      },

      setSelectedEmployeeTypeIndex: (selectedEmployeeTypeIndex: number) => {
        set({ selectedEmployeeTypeIndex }, false, EMPLOYEE_INFO_STORE_META_DATA.SET_SELECTED_EMPLOYEE_INDEX);
      },

      setClassCodes: (classCodes: IAntdOptions[]) => {
        set({ classCodes }, false, EMPLOYEE_INFO_STORE_META_DATA.SET_CLASS_CODES);
      },

      fetchClassCodes: async (state: USState, isEmployeeInfoLoading = true) => {
        set({ isEmployeeInfoLoading });
        try {
          const jobCodesByStateResponse = await getJobCodesByState({ state });
          const { jobCodesByState } = jobCodesByStateResponse || {};
          const classCodes = formatJobCodesToAntdOptions(jobCodesByState);
          set({ classCodes }, false, EMPLOYEE_INFO_STORE_META_DATA.SET_CLASS_CODES);
          return classCodes;
        } catch (error: any) {
          errorV2(error?.[0]?.errorMessage || EMPLOYEE_INFO_ERROR_MESSAGES.FAILED_TO_FETCH_STATE_CLASS_CODES);
          return [];
        } finally {
          set({ isEmployeeInfoLoading: false });
        }
      },

      autoFillEmployeeInfo: async () => {
        const { locations } = useLocationDetailsStore.getState();
        const { currentSubStepIndex: selectedLocationIndex } = useApplicationStore.getState();
        const selectedLocationState = locations?.[selectedLocationIndex]?.address?.state;

        if (selectedLocationState && Object.keys(USState).includes(selectedLocationState)) {
          set({ isEmployeeInfoLoading: true });
          await handleAutoFillNCCIClassAndEmployeeTypeDetails(locations);
          await handleAutoFillCarrierNCCIClassCode();
          set({ isEmployeeInfoLoading: false });
        }
      },

      setCarrierInternalClassCodes: (selectedEmployeeTypeIndex: number, classCodes: IAntdOptions[], carrier?: any) => {
        const { carriersInternalClassCodes } = get();
        const storeCarriersInternalClassCodes = cloneDeep(carriersInternalClassCodes);
        if (carrier) {
          storeCarriersInternalClassCodes[selectedEmployeeTypeIndex] = {
            ...storeCarriersInternalClassCodes[selectedEmployeeTypeIndex],
            [carrier]: classCodes,
          };
          storeCarriersInternalClassCodes[selectedEmployeeTypeIndex][carrier] = classCodes;
        } else {
          storeCarriersInternalClassCodes[selectedEmployeeTypeIndex] = {};
        }

        set(
          { carriersInternalClassCodes: storeCarriersInternalClassCodes },
          false,
          EMPLOYEE_INFO_STORE_META_DATA.SET_CARRIER_SINTERNAL_CLASS_CODES,
        );
      },

      deleteEmployeeInformationByIndex: (selectedEmployeeTypeIndex: number) => {
        const { carriersInternalClassCodes } = get();
        const storeCarriersInternalClassCodes = cloneDeep(carriersInternalClassCodes);
        storeCarriersInternalClassCodes?.splice(selectedEmployeeTypeIndex, 1);
        set({ carriersInternalClassCodes: storeCarriersInternalClassCodes });
      },

      updateLocationEmployee: ({ locationIndex, employeeTypeIndex, fieldName, fieldValue }) => {
        const { locations: storedLocations, updateLocationDetails } = useLocationDetailsStore.getState();
        const locations = cloneDeep(storedLocations);
        const updatedLocation = _set(
          locations,
          [locationIndex, FIELD_KEYS.employeeInformation, employeeTypeIndex, fieldName],
          fieldValue,
        );
        updateLocationDetails(locationIndex, updatedLocation[locationIndex]);
      },

      deleteLocationEmployeeByIndex: (locationIndex: number, employeeIndex: number) => {
        const { locations: storeLocations, updateLocationDetails } = useLocationDetailsStore.getState();

        const locations = cloneDeep(storeLocations);
        const employeeInformationList = _get(locations, [locationIndex, FIELD_KEYS.employeeInformation], []);
        employeeInformationList?.splice(employeeIndex, 1);

        const updatedLocation = _set(
          locations,
          [locationIndex, FIELD_KEYS.employeeInformation],
          employeeInformationList,
        );

        updateLocationDetails(locationIndex, updatedLocation[locationIndex]);
      },

      getEmployeeInformationApiPayload: () => {
        const { currentSubStepIndex: selectedLocationIndex } = useApplicationStore.getState();
        const { locationDetailsForm: form } = useLocationDetailsStore.getState();

        const employeeInfoPayload = {
          employeeInformation: form?.getFieldValue([selectedLocationIndex, FIELD_KEYS.employeeInformation]),
        };

        return getEmployeeInformationApiPayloadUtil(employeeInfoPayload);
      },

      clearEmployeeInformation: () => {
        set(
          {
            classCodes: [],
            isEmployeeInfoLoading: false,
            carriersInternalClassCodesLoading: false,
            carriersInternalClassCodes: [],
            carriersValidForInternalClassCodes: [],
            selectedEmployeeTypeIndex: 0,
            activeKeys: { nextFieldKey: 0, keys: {} },
          },
          false,
          EMPLOYEE_INFO_STORE_META_DATA.CLEAR_EMPLOYEE_INFORMATION,
        );
      },
    }),
    {
      name: EMPLOYEE_INFO_STORE_META_DATA.STORE_NAME,
      anonymousActionType: EMPLOYEE_INFO_STORE_META_DATA.ANONYMOUS_ACTIONS_NAME,
    },
  ),
);
