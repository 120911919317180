import { IJobCode } from '@coverforce-platform/cf-common-api-model';

import { IAntdOptions } from '../globalInterface';

export const formatToAntdOptions = ({
  list,
  listLabelKey,
  listValueKey,
  labelCallback,
  valueCallback,
}: {
  list: any[];
  listLabelKey?: string;
  listValueKey?: string;
  valueCallback?: (value: string) => string;
  labelCallback?: (label: string) => string;
}): IAntdOptions[] => {
  return list.map((item) => {
    if (typeof item === 'object' && listLabelKey && listValueKey) {
      return {
        label: labelCallback ? labelCallback(item[listLabelKey]) : item[listLabelKey],
        value: valueCallback ? valueCallback(item[listValueKey]) : item[listValueKey],
      };
    } else {
      return { label: labelCallback ? labelCallback(item) : item, value: valueCallback ? valueCallback(item) : item };
    }
  });
};

export const formatJobCodesToAntdOptions = (jobCodes?: IJobCode[]): IAntdOptions[] => {
  return (jobCodes || []).map((item) => ({
    label: `${item.jobCode} - ${item.description}`,
    value: `${item.jobCode} - ${item.jobCodeExtendedId}`,
  }));
};
