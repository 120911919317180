import { FeedItem } from '@knocklabs/client';

import NotificationItem from '../notificationItem/notificationItem';
import { useNotificationsStore } from '../notifications/store';
import { EmptyNotifications } from './componentUtils';

const NotificationItems = () => {
  const { notificationsData } = useNotificationsStore();

  return (
    <>
      {notificationsData?.length > 0 &&
        notificationsData.map((item: FeedItem) => <NotificationItem key={item.id} notificationItem={item} />)}
      {notificationsData?.length === 0 && <EmptyNotifications />}
    </>
  );
};

export default NotificationItems;
