import { convertBase64ToBlob } from './convertBase64ToBlob';

export const downloadFileFromBase64 = (
  base64data: string,
  fileName: string,
  fileFormat?: string,
  fileType?: string,
) => {
  const blob = convertBase64ToBlob(base64data, fileType);
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${fileName}.${fileFormat || 'pdf'}`;
  link.click();
};
