import { ILocationInfo, IPastPolicyLossInfo } from '@coverforce-platform/cf-common-api-model';
import { ClaimStatus, PolicyType } from '@coverforce-platform/cf-common-types';

import { DATE_FORMAT } from '../../globalConstants';
import { deNormalizeMoneyInput } from '../../helpers/Utils';
import { useApplicationStore } from '../../pages/v2/application/store';
import { getLocationFromApplication } from '../../pages/v2/application/utils';
import { isLossDescriptionFieldDisplay, isLossTypeFieldDisplay, isStateFieldDisplay } from '../policyLossModal/utils';
import { POLICY_KEYS_MAPPING, QUESTION_KEYS } from './constants';
import { IPastPolicyLossDetails, IPolicyLossError } from './interface';

const getPolicyLossErrorObj = ({
  validatedDetails,
  index,
  key,
}: {
  validatedDetails: { errors: IPolicyLossError[]; isValid: boolean };
  index: number;
  key: string;
}) => {
  validatedDetails.errors[index] = { isError: true, errorMessage: `Please enter ${POLICY_KEYS_MAPPING[key]}.` };
  const obj = {
    ...validatedDetails,
    isValid: false,
  };
  return obj;
};

export const validatePolicyLossDetails = (policyLossList: IPastPolicyLossDetails[]) => {
  const { policyType } = useApplicationStore.getState();
  let validatedDetails: { errors: IPolicyLossError[]; isValid: boolean } = {
    isValid: true,
    errors: [],
  };

  // iterate all the policy loss details means individual card
  policyLossList?.forEach((policyLoss, index) => {
    // iterate whole policy loss object and check if there is invalid details or not.
    Object.keys(policyLoss || {}).every((key) => {
      const value = policyLoss[key as keyof IPastPolicyLossDetails];
      switch (key) {
        // if its reversed amount then we need to check additional property which is claim status, if its open then and then need to verify to reserved amount
        case QUESTION_KEYS.TOTAL_RESERVED_AMOUNT: {
          const valueOfClaimStatus = policyLoss?.claimStatus;
          if (valueOfClaimStatus === 'true' && (!value || Number(value) <= 0)) {
            validatedDetails = getPolicyLossErrorObj({ validatedDetails, key, index });
            return false;
          }
          return true;
        }
        case QUESTION_KEYS.LOSS_DESCRIPTION: {
          if (isLossDescriptionFieldDisplay() && !value && policyType !== PolicyType.CYBER) {
            validatedDetails = getPolicyLossErrorObj({ validatedDetails, key, index });
            return false;
          }
          return true;
        }
        case QUESTION_KEYS.STATE: {
          if (isStateFieldDisplay() && !value) {
            validatedDetails = getPolicyLossErrorObj({ validatedDetails, key, index });
            return false;
          }
          return true;
        }
        case QUESTION_KEYS.LOSS_TYPE: {
          if (isLossTypeFieldDisplay() && !value) {
            validatedDetails = getPolicyLossErrorObj({ validatedDetails, key, index });
            return false;
          }
          return true;
        }
        case QUESTION_KEYS.TOTAL_PAID_AMOUNT: {
          if (!value || Number(value) <= 0) {
            validatedDetails = getPolicyLossErrorObj({ validatedDetails, key, index });
            return false;
          }
          return true;
        }
        // for remaining cases we only need to check if data is present or not
        default:
          if (!value) {
            validatedDetails = getPolicyLossErrorObj({ validatedDetails, key, index });
            return false;
          }
          return true;
      }
    });
  });
  return validatedDetails;
};

export const createPastPolicyLossApiPayload = (policyLossList: IPastPolicyLossDetails[]) => {
  const { applicationData, policyType } = useApplicationStore.getState();
  const policyLossPayload: IPastPolicyLossInfo[] = policyLossList.map((policyLoss: IPastPolicyLossDetails) => {
    const totalReservedAmount =
      policyLoss.claimStatus === 'true' ? Number(deNormalizeMoneyInput(policyLoss?.totalReservedAmount)) : undefined;
    const totalPaidAmount = policyLoss?.totalPaidAmount
      ? Number(deNormalizeMoneyInput(policyLoss?.totalPaidAmount))
      : undefined;
    return {
      policyType,
      lossDate: policyLoss?.lossDate?.format(DATE_FORMAT.YYYYMMDD),
      lossDescription: policyLoss?.lossDescription?.trim(),
      claimDate: policyLoss?.claimDate?.format(DATE_FORMAT.YYYYMMDD),
      claimStatus: policyLoss?.claimStatus === 'true' ? ClaimStatus.OPEN : ClaimStatus.CLOSED,
      state: isStateFieldDisplay()
        ? policyLoss?.state
        : (getLocationFromApplication(applicationData) as ILocationInfo)?.address?.state,
      totalPaidAmount,
      totalReservedAmount,
      lossType: policyLoss?.lossType,
    } as IPastPolicyLossInfo;
  });

  return policyLossPayload;
};
