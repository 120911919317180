import { AgentPortalUserType } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { useConfigurationStore } from '../../../components/configuration/store';
import { deleteReportSchedule, getAllReportSchedules } from '../../../externalServices/V2/reports';
import { errorV2 } from '../../../ui-core/Notification';
import { useProfileV2Store } from '../profile/store';
import { REPORT_CENTER_MESSAGES, REPORT_CENTER_STORE_META_DATA } from './constants';
import { IReportCenterStore } from './interface';

export const useReportCenterStore = create<IReportCenterStore>()(
  devtools((set, get) => ({
    reportSchedules: [],
    fetchReportSchedulesLoading: false,
    deleteScheduledReportLoading: false,
    fetchReportSchedulesError: undefined,

    updateReportCenterStoreByKey: async (storeKey: keyof IReportCenterStore, value: any) =>
      set(() => ({ [storeKey]: value }), false, REPORT_CENTER_STORE_META_DATA.UPDATE_REPORT_CENTER_STORE_BY_KEY),

    fetchReportSchedules: async (loading = true) => {
      const { selectedUserProfile } = useProfileV2Store.getState();
      const { userType } = useConfigurationStore.getState();
      set(
        { fetchReportSchedulesLoading: loading },
        false,
        REPORT_CENTER_STORE_META_DATA.FETCH_REPORT_SCHEDULES_LOADING,
      );
      try {
        const response = await getAllReportSchedules({
          agencyNetworkId: selectedUserProfile?.agencyNetworkId ?? '',
          agencyId: userType === AgentPortalUserType.AGENCY ? selectedUserProfile?.agencyId ?? '' : undefined,
        });

        // Filter out Report Schedules that are created by network user when in network view.
        const filteredReportSchedules =
          response?.reportSchedules?.filter((schedule) => {
            if (userType === AgentPortalUserType.AGENCY_NETWORK) {
              return !schedule.agencyId;
            }
            return true;
          }) ?? [];

        set({ reportSchedules: filteredReportSchedules }, false, REPORT_CENTER_STORE_META_DATA.SET_REPORT_SCHEDULES);
        return response?.reportSchedules;
      } catch (error: any) {
        set({ reportSchedules: [], fetchReportSchedulesError: error });
        errorV2(error?.[0]?.errorMessage || REPORT_CENTER_MESSAGES.ERROR_FETCH_REPORT_SCHEDULES);
      } finally {
        set(
          { fetchReportSchedulesLoading: false },
          false,
          REPORT_CENTER_STORE_META_DATA.FETCH_REPORT_SCHEDULES_LOADING,
        );
      }
    },

    deleteScheduledReporting: async (scheduleId: string) => {
      await deleteReportSchedule({ scheduleId });
      get().fetchReportSchedules();
    },

    clearReportCenterStore: () => {
      set({
        reportSchedules: [],
        fetchReportSchedulesLoading: false,
        deleteScheduledReportLoading: false,
        fetchReportSchedulesError: undefined,
      });
    },
  })),
);
