import { useEffect } from 'react';

import { QUOTES_STORE_KEYS } from '../../constants/quotesConstants';
import { errorV2, success } from '../../ui-core/Notification';
import { useForm } from '../../ui-core/V2/cfForm/cfForm';
import CFFormItem from '../../ui-core/V2/cfFormItem/cfFormItem';
import { useCFModalStore } from '../../ui-core/V2/cfModal/store';
import CFTextArea from '../../ui-core/V2/cfTextArea/cfTextArea';
import { pushEvent } from '../../utils/googleAnalytics';
import Disclaimer from '../discaimer/disclaimer';
import { GA_ACTION, GA_LABEL } from '../quotes/constants';
import { useQuotesStore } from '../quotes/store';
import { getRequestToBindDetails } from './componentUtils';
import {
  REQUEST_TO_BIND_FORM_CONFIG,
  REQUEST_TO_BIND_MODAL,
  REQUEST_TO_BIND_MODAL_STORE_KEYS,
  REQUEST_TO_BIND_QUOTE_MESSAGES,
} from './constants';
import { useRequestToBindQuoteModalStore } from './store';
import {
  RequestToBindFormStyled,
  RequestToBindMessageInfoCircleOutlinedStyled,
  RequestToBindModalStyled,
} from './styles';
import { getGACategory } from './utils';

const RequestToBindModal = () => {
  const { fetchQuotes, updateQuotesByKey, selectedQuote } = useQuotesStore();
  const { isRequestToBindLoading, requestToBindQuote, updateRequestToBindModalByKey, clearRequestToBind } =
    useRequestToBindQuoteModalStore();
  const { clearCfModal } = useCFModalStore();
  const [form] = useForm();
  const { carrierId = '' } = selectedQuote || {};

  useEffect(() => {
    updateRequestToBindModalByKey(REQUEST_TO_BIND_MODAL_STORE_KEYS.REQUEST_TO_BIND_FORM, form);
    return () => {
      updateQuotesByKey(QUOTES_STORE_KEYS.SELECTED_QUOTE, undefined);
      clearRequestToBind();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRequestToBind = async () => {
    pushEvent({
      category: getGACategory(carrierId),
      action: GA_ACTION.REQUEST_TO_BIND_BTN_CLICK,
      label: GA_LABEL.REQUEST_TO_BIND,
    });
    try {
      await requestToBindQuote();
      await fetchQuotes(false);
      success(REQUEST_TO_BIND_QUOTE_MESSAGES.SUCCESS);
      clearCfModal();
    } catch (error: any) {
      errorV2(error?.[0]?.errorMessage || REQUEST_TO_BIND_QUOTE_MESSAGES.ERROR);
    } finally {
      updateRequestToBindModalByKey(REQUEST_TO_BIND_MODAL_STORE_KEYS.IS_REQUEST_TO_BIND_LOADING, false);
    }
  };

  const handleCloseModal = () => {
    pushEvent({
      category: getGACategory(carrierId),
      action: GA_ACTION.CLOSE_MODAL_BTN_CLICK,
      label: GA_LABEL.CLOSE_REQUEST_TO_BIND_MODAL,
    });
  };

  return (
    <RequestToBindModalStyled
      title={REQUEST_TO_BIND_MODAL.TITLE}
      okText='Yes'
      cancelText='No'
      okButtonProps={{
        id: 'btn_request_to_bind_ok',
        loading: isRequestToBindLoading,
        disabled: isRequestToBindLoading,
      }}
      cancelButtonProps={{ id: 'btn_request_to_bind_cancel', disabled: isRequestToBindLoading }}
      onOk={onRequestToBind}
      onCancel={handleCloseModal}
      disableCloseIcon={isRequestToBindLoading}
      maskClosable={!isRequestToBindLoading}
    >
      We will submit this request to your underwriter for review.
      {getRequestToBindDetails()}
      <RequestToBindFormStyled id='form_request_to_bind' form={form} labelAlign='left' labelCol={{ span: 8 }}>
        <CFFormItem {...REQUEST_TO_BIND_FORM_CONFIG.noteField}>
          <CFTextArea id='inp_note' rows={4} disabled={isRequestToBindLoading} showCount maxLength={300} />
        </CFFormItem>
      </RequestToBindFormStyled>
      <Disclaimer icon={<RequestToBindMessageInfoCircleOutlinedStyled />}>
        <b>
          Please wait 1-2 business days for a response. Do not cancel any existing policies until your underwriter has
          confirmed that the policy is bound.
        </b>
      </Disclaimer>
    </RequestToBindModalStyled>
  );
};

export default RequestToBindModal;
