import { EmailJSResponseStatus, send } from '@emailjs/browser';

import { REACT_APP_EMAIL_JS_PUBLIC_KEY, REACT_APP_EMAIL_JS_SERVICE_ID } from '../helpers/EnvironmentVariables';

export interface IEmailJSResponse extends EmailJSResponseStatus {
  isSuccess: boolean;
}

/**
 * Send a template to the specific EmailJS service
 * @param {string} serviceId - the EmailJS service ID
 * @param {string} templateId - the EmailJS template ID
 * @param {object} templatePrams - the template params, what will be set to the EmailJS template
 * @param {string} publicKey - the EmailJS public key
 * @returns {Promise<IEmailJSResponse>}
 */
export const sendEmail = async ({
  serviceId = REACT_APP_EMAIL_JS_SERVICE_ID,
  templateId,
  templateParams,
  publicKey = REACT_APP_EMAIL_JS_PUBLIC_KEY,
}: {
  serviceId?: string;
  templateId: string;
  templateParams: Record<string, unknown>;
  publicKey?: string;
}): Promise<IEmailJSResponse> => {
  try {
    const response = await send(serviceId, templateId, templateParams, { publicKey });

    /** Success is only considered where the response status is 200  */
    if (response?.status === 200) {
      return {
        ...response,
        isSuccess: true,
      };
    }

    /** All cases where the reponse status is NOT 200 are considered as mail sent failed cases */
    return {
      ...response,
      isSuccess: false,
    };
  } catch (error: any) {
    /** Catch error sent by emailjs in case of api error */
    return {
      ...error,
      isSuccess: false,
    };
  }
};
