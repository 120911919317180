import { AUTH_TOKENS } from '../globalConstants';
import { saveToLocalStorage } from './saveToLocalStorage';

export const setAuthTokens = async (payload: object): Promise<void> => {
  if (payload) {
    AUTH_TOKENS.forEach((item: string) => {
      const value = payload[item as keyof typeof payload];
      if (value) {
        saveToLocalStorage(item, value);
      }
    });
  }
};
