import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled, LineOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { IStatusWrapper, QuestionStatus } from './interface';
import { DivStyled, StatusChildrenWrapperStyled } from './style';

const StatusWrapper = ({ status, iconSize = '18px', children }: IStatusWrapper) => {
  const theme = useTheme();

  const commonIconStyle = useMemo(() => ({ fontSize: iconSize, marginRight: '10px', height: '22px' }), [iconSize]);
  return (
    <DivStyled className='question-wrapper'>
      {status === QuestionStatus.MANDATORY && (
        <ExclamationCircleFilled style={{ ...commonIconStyle, color: theme.colors.common.warningColor }} />
      )}
      {status === QuestionStatus.ANSWERED && (
        <CheckCircleFilled style={{ ...commonIconStyle, color: theme.colors.common.successColor }} />
      )}
      {status === QuestionStatus.ERROR && (
        <CloseCircleFilled style={{ ...commonIconStyle, color: theme.colors.common.errorColor }} />
      )}
      {status === QuestionStatus.OPTIONAL && (
        <LineOutlined style={{ ...commonIconStyle, color: theme.colors.grayscale.blackOpacityLight }} />
      )}
      <StatusChildrenWrapperStyled>{children}</StatusChildrenWrapperStyled>
    </DivStyled>
  );
};

export default StatusWrapper;
