import { useCallback, useState } from 'react';

import { deNormalizePhoneNumber, normalizePhoneNumber } from '../../../helpers/Utils';
import CFInput from '../cfInput/cfInput';
import { IPhoneInput } from './interface';

const CFPhoneInput = ({ data, onChange, initialValue, id, style, value, disabled = false, ...rest }: IPhoneInput) => {
  const [phone, setPhone] = useState(initialValue);
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (deNormalizePhoneNumber(event.target.value)?.length <= 12) {
        const val = event.target.value;
        setPhone(val);
        onChange && onChange(val, data);
      }
    },
    [data, onChange],
  );
  return (
    <CFInput
      placeholder='Phone Number'
      id={id}
      disabled={disabled}
      {...rest}
      style={style}
      value={normalizePhoneNumber((phone || value || '').toString())}
      onChange={handleChange}
    />
  );
};

export default CFPhoneInput;
