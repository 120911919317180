export const message = {
  rankingsUpdateSuccess: 'Carrier rankings updated successfully!',
};

export const pageHeader = {
  title: 'Carrier Rankings',
  enableCarrierRankingSubtitle:
    'Use the toggle buttons to select which carriers your agency quotes with (we advise keeping all carriers ON). Drag carriers up and down to adjust the order in which quotes are displayed.',
  disableCarrierRankingSubtitle:
    'These are the carriers and products which you have access to as a member. New Products and Carriers will appear as they are launched. Please reach out to your RM if you would like to request specific new carriers.',
};

export const SWITCH_TOOLTIP = {
  ENABLE_CARRIER: 'Enable Carrier',
  DISABLE_CARRIER: 'Disable Carrier',
};

export const GA_CATEGORY = {
  CARRIER_RANKING_TAB: 'carrierRanking_tabs',
};

export const GA_ACTION = {
  POLICY_TYPE_TAB_CHANGE: 'policyTypeTab_change',
};

export const GA_LABEL = {
  POLICY_TYPE_TAB_CHANGE: 'Toggle policy type tabs',
};
