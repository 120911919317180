import { IGetUWQuestionsInput, IQuestionInfo } from '@coverforce-platform/cf-common-api-model';
import { AxiosResponse } from 'axios';

import axiosApplicationService from '../axios/applicationService/instance';
import { createQueryUrl } from '../utils/createQueryUrl';
import { APPLICATION_SERVICE_ENDPOINTS } from './helpers/ApiRoutes';

export const getUWQuestionV2 = async (
  params: IGetUWQuestionsInput,
  queryParams?: { locationId?: string; category?: string },
): Promise<IQuestionInfo[]> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.GET_UW_QUESTIONS(params),
    params: queryParams,
  });
  try {
    const response: AxiosResponse = await axiosApplicationService.get(apiUrl);
    return response?.data?.questions;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};
