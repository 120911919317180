import {
  REACT_APP_API_VERSION,
  REACT_APP_APPLICATION_LIFECYCLE_SERVICE_BASE_URL,
} from '../../helpers/EnvironmentVariables';

export const APPLICATION_LIFECYCLE_SERVICE = 'APPLICATION_LIFECYCLE_SERVICE';

export const APPLICATION_LIFECYCLE_SERVICE_PARAMS = {
  baseURL: `${REACT_APP_APPLICATION_LIFECYCLE_SERVICE_BASE_URL}/${REACT_APP_API_VERSION}`,
};
