import { Modal } from 'antd';
import styled from 'styled-components';

import { ICFModal } from './interface';

export const StyledCFModal = styled(Modal)<ICFModal>`
  .ant-modal-content {
    overflow: auto;
    max-height: 80vh;
    padding: 0px;

    button.ant-modal-close:hover {
      background-color: unset;
    }
    button .ant-modal-close-x button:hover {
      background: ${(p) => p?.theme?.colorScheme?.primary};
      color: ${(p) => p?.theme?.colors?.grayscale?.white};
      border-color: ${(p) => p?.theme?.colors?.grayscale?.white};
    }
  }

  .ant-modal-close {
    pointer-events: ${(p) => (p.disableCloseIcon ? 'none' : 'unset')};
    // display: flex;
    // margin-left: auto;
    // margin-right: 11px;
    // margin-top: 11px;
    // top: 0px;
  }

  // .ant-modal-close-x {
  //   height: auto;
  //   width: auto;
  //   line-height: unset;
  // }

  .ant-modal-header {
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 0px;
  }

  .ant-modal-title {
    max-width: 96%;
  }

  .ant-modal-body {
    padding: 14px 24px 20px 24px;
  }

  .ant-modal-footer {
    border-top: 1px solid #f0f0f0;
    padding: 10px 16px;
    margin-top: 0px;
  }

  // .ant-modal-footer .ant-btn.ant-btn-default:hover,
  // .ant-modal-footer .ant-btn.ant-btn-default:focus {
  //   color: ${(p) => p?.theme?.colorScheme?.primary};
  //   border-color: ${(p) => p?.theme?.colorScheme?.primary};
  // }
  // .ant-modal-footer .ant-btn.ant-btn-primary:hover,
  // .ant-modal-footer .ant-btn.ant-btn-primary:focus {
  //   background: ${(p) => p.theme?.colorScheme?.primaryHover};
  //   color: ${(p) => p.theme.colors.common.pageBgColor};
  // }

  .ant-modal-content .ant-modal-footer .ant-btn-primary:not(:disabled) {
    background: ${(p) => p?.theme?.colorScheme?.primary};
    color: ${(p) => p?.theme?.colors.grayscale.white};
    border-color: ${(p) => p?.theme?.colorScheme?.primary};

    :hover,
    :focus {
      background: ${(p) =>
        p?.theme?.colorScheme?.isPrimaryHoverLight
          ? p?.theme?.colorScheme?.primaryHoverLight
          : p.theme?.colorScheme?.primaryHover};
      color: ${(p) =>
        p?.theme?.colorScheme?.isPrimaryHoverLight
          ? p?.theme?.colorScheme?.primaryHover
          : p?.theme?.colors.grayscale.white};
      border-color: ${(p) => p?.theme?.colorScheme?.primaryHover};
    }
  }
`;
