import { ILiabilityLimitsStore } from './interface';

export const LIABILITY_LIMITS_STORE = {
  NAME: 'Liability Limits Store',
  ANONYMOUS_ACTION: 'liabilityLimits',
  UPDATE_LIABILITY_LIMITS_STORE_BY_KEY: 'UPDATE_LIABILITY_LIMITS_STORE_BY_KEY',
  CLEAR_LIABILITY_LIMITS: 'CLEAR_LIABILITY_LIMITS',
};

// TO-DO:- Need to check how to write the type so as to not duplicate "as keyof ILiabilityLimitsStore" everytime
export const LIABILITY_LIMITS_STORE_KEYS = {
  REGENERATE_QUOTE_APPLICATION_PAYLOAD: 'regenerateQuoteApplicationPayload' as keyof ILiabilityLimitsStore,
};

export const LIABILITY_LIMITS_ERROR = 'An error occurred, please try again later';

export const DISPLAY_LIMIT_FOR_LIABILITY = 5;
