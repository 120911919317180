import { IConfigurationStore } from './interface';

export const ACTION_TYPE = {
  SET_PROFILE_USER_TYPE: 'configuration/setUserType',
  SET_USER_THEME: 'configuration/setUserTheme',
  POLICY_TYPES_LOADING_START: 'POLICY_TYPES_LOADING_START',
  FETCHED_POLICY_TYPES: 'FETCHED_POLICY_TYPES',
  POLICY_TYPES_ERROR: 'POLICY_TYPES_ERROR',
  POLICY_TYPES_LOADING_STOP: 'POLICY_TYPES_LOADING_STOP',
  ALL_CARRIERS_LOADING_START: 'ALL_CARRIERS_LOADING_START',
  FETCHED_ALL_CARRIERS: 'FETCHED_ALL_CARRIERS',
  ALL_CARRIERS_ERROR: 'ALL_CARRIERS_ERROR',
  ALL_CARRIERS_LOADING_STOP: 'ALL_CARRIERS_LOADING_STOP',
  FETCHED_AGENCY_NETWORK_METADATA: 'FETCHED_AGENCY_NETWORK_METADATA',
  FETCHING_AGENCY_NETWORK_METADATA_LOADING_START: 'FETCHING_AGENCY_NETWORK_METADATA_LOADING_START',
  FETCHING_AGENCY_NETWORK_METADATA_LOADING_STOP: 'FETCHING_AGENCY_NETWORK_METADATA_LOADING_STOP',
  AGENCY_NETWORK_METADATA_ERROR: 'AGENCY_NETWORK_METADATA_ERROR',
  WEBAPP_THEME_API: 'WEBAPP_THEME_API',
  WEBAPP_THEME_API_LOADING_START: 'WEBAPP_THEME_API_LOADING_START',
  WEBAPP_THEME_API_LOADING_STOP: 'WEBAPP_THEME_API_LOADING_STOP',
  WEBAPP_THEME_API_ERROR: 'WEBAPP_THEME_API_ERROR',
  UPDATE_CONFIGURATION_STORE_BY_KEY: 'UPDATE_CONFIGURATION_STORE_BY_KEY',
  CLEAR_CONFIGURATION: 'CLEAR_CONFIGURATION',
};

export const CONFIGURATION_STORE_KEYS: { [key: string]: keyof IConfigurationStore } = {
  FEED_CLIENT: 'feedClient',
  KNOCK_CLIENT: 'knockClient',
  IS_CONFIGURATION_LOADING: 'isConfigurationLoading',
};

export const NOTIFICATION_PERMISSIONS = {
  GRANTED: 'granted',
  DENIED: 'denied',
};

export const WEB_PUSH_NOTIFICATIONS = {
  FIREBASE_SW_PATH: '/firebase-messaging-sw.js',
  PERMISSION_REQUEST_DELAY_MS: 15000,
};

export const STORAGE = 'storage';
