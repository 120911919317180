import dayjs from 'dayjs';

import { GLOBAL_LOCALE } from '../globalConstants';
import { optionType } from '../types';

export const numberFormatter = (locale?: string, fractionDigits?: number) =>
  new Intl.NumberFormat(locale ?? GLOBAL_LOCALE.US, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

export const formatEffectiveDate = (date: string): string => {
  const formattedDate = dayjs(date).format('MM-DD-YYYY');
  return formattedDate;
};

export const getInOptionTypeFormat = (data: any[] = [], label: string, value: string): optionType[] => {
  return data?.map((el: any) => {
    return {
      label: el[label],
      value: el[value],
    };
  });
};
