import { GLOBAL_LOCALE } from '../../../globalConstants';
import { normalizeMoneyInput } from '../../../helpers/Utils';
import { numberFormatter } from '../../../utils/formatter';

/**
 * it will return decimal number and if last character is dot then it return as it is
 */
export const extractDecimalNumbers = (inputString: string, numberOfDecimals: number = 2): string => {
  inputString = inputString === '.' ? '0.' : inputString;

  // Regular expression to match decimal numbers
  const pattern: RegExp = /\b\d+(\.\d*)?\.?\b/g;

  // Find all matches of decimal numbers in the input string
  const decimalNumbers: string[] | null = inputString.match(pattern);

  // Concatenate the decimal numbers into one string
  const concatenatedNumber: string = decimalNumbers ? decimalNumbers.join('') : '';

  const decimalIndex = concatenatedNumber.indexOf('.');

  if (decimalIndex !== -1 && concatenatedNumber.length - decimalIndex > numberOfDecimals + 1) {
    return concatenatedNumber.slice(0, decimalIndex + numberOfDecimals + 1);
  }
  return concatenatedNumber + (concatenatedNumber && inputString.indexOf('.') === inputString.length - 1 ? '.' : '');
};

export const usInputFormatter = (value: any, numberOfDecimals: number = 2): string => {
  if (!value || value === '$') {
    return '';
  }

  const splitValues = value?.split('.');

  if (value?.endsWith('.')) {
    return `$${numberFormatter(GLOBAL_LOCALE.US).format(value).toString()}.`;
  }

  if (value?.includes('.')) {
    return `$${numberFormatter(GLOBAL_LOCALE.US).format(splitValues?.[0]).toString()}.${splitValues?.[1]?.slice(
      0,
      numberOfDecimals,
    )}`;
  }

  return `$${numberFormatter(GLOBAL_LOCALE.US).format(value).toString()}`;
};

export const getMoneyValue = ({
  decimalAllowed,
  numberOfDecimals = 2,
  money = '',
}: {
  decimalAllowed: boolean;
  numberOfDecimals?: number;
  money?: string;
}) => {
  return decimalAllowed
    ? usInputFormatter(money || '', numberOfDecimals)
    : normalizeMoneyInput((money || '').toString());
};
