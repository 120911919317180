import { APPLICATION_TABS } from '../../constants/applicationV2Constants';
import { DATE_FORMAT, PAGE_NAME } from '../../globalConstants';
import { useApplicationStore } from '../../pages/v2/application/store';
import { getCurrentPageName } from '../../utils/getCurrentPageName';
import { getLiabilityLimitChangeApplicationPayload } from '../liabilityLimits/utils';
import { GA_CATEGORY as QUOTES_GA_CATEGORY } from '../quotes/constants';
import { EFFECTIVE_DATE_MODAL_CALLBACK_ACTION, EFFECTIVE_DATE_MODAL_QUESTIONS_KEYS } from './constants';
import { useEffectiveDateModalStore } from './store';

export const getGACategory = () => {
  const currentPage = getCurrentPageName();
  const { currentStep } = useApplicationStore.getState();

  switch (currentPage) {
    case PAGE_NAME.APPLICATION:
      switch (currentStep) {
        case APPLICATION_TABS.QUOTES:
          return QUOTES_GA_CATEGORY.QUOTES_TAB_CHANGE_EFFECTIVE_DATE_MODAL;

        default:
          return currentPage;
      }

    default:
      return currentPage;
  }
};

export const getEffectiveDateModalPayloadUtil = (): any => {
  const { effectiveDateModalCallbackData } = useEffectiveDateModalStore.getState();
  const { callbackAction } = effectiveDateModalCallbackData || {};

  switch (callbackAction) {
    case EFFECTIVE_DATE_MODAL_CALLBACK_ACTION.LIABILITY_LIMIT_CHANGE: {
      return getEffectiveDateLiabilityChangeCombinedPayload();
    }
    default: {
      return getEffectiveDateDefaultPayload();
    }
  }
};

const getEffectiveDateLiabilityChangeCombinedPayload = () => {
  const { effectiveDataModalForm, effectiveDateModalCallbackData } = useEffectiveDateModalStore.getState();
  const { callbackPayload } = effectiveDateModalCallbackData || {};
  const { carrierId, selectedLiabilityLimitInfo } = callbackPayload || {};
  const liabilityLimitApplicationPayload = getLiabilityLimitChangeApplicationPayload({
    carrierId,
    selectedLiabilityLimit: selectedLiabilityLimitInfo,
  });
  return {
    ...liabilityLimitApplicationPayload,
    applicationDetails: {
      ...liabilityLimitApplicationPayload?.applicationDetails,
      insuranceEffectiveDate: effectiveDataModalForm
        ?.getFieldValue(EFFECTIVE_DATE_MODAL_QUESTIONS_KEYS.insuranceEffectiveDate)
        ?.format(DATE_FORMAT.YYYYMMDD),
      insuranceExpiryDate: effectiveDataModalForm
        ?.getFieldValue(EFFECTIVE_DATE_MODAL_QUESTIONS_KEYS.insuranceExpiryDate)
        ?.format(DATE_FORMAT.YYYYMMDD),
    },
  };
};

const getEffectiveDateDefaultPayload = () => {
  const { effectiveDataModalForm } = useEffectiveDateModalStore.getState();
  const { applicationData } = useApplicationStore.getState();

  return {
    ...applicationData,
    applicationDetails: {
      ...applicationData?.applicationDetails,
      insuranceEffectiveDate: effectiveDataModalForm
        ?.getFieldValue(EFFECTIVE_DATE_MODAL_QUESTIONS_KEYS.insuranceEffectiveDate)
        ?.format(DATE_FORMAT.YYYYMMDD),
      insuranceExpiryDate: effectiveDataModalForm
        ?.getFieldValue(EFFECTIVE_DATE_MODAL_QUESTIONS_KEYS.insuranceExpiryDate)
        ?.format(DATE_FORMAT.YYYYMMDD),
    },
  };
};
