import { Divider } from 'antd';
import { useEffect } from 'react';

import { QUOTES_STORE_KEYS } from '../../constants/quotesConstants';
import { errorV2, success } from '../../ui-core/Notification';
import CFForm, { useForm } from '../../ui-core/V2/cfForm/cfForm';
import CFFormItem from '../../ui-core/V2/cfFormItem/cfFormItem';
import CFInput from '../../ui-core/V2/cfInput/cfInput';
import CFLoader from '../../ui-core/V2/cfLoader/cfLoader';
import CFModal from '../../ui-core/V2/cfModal/cfModal';
import { useCFModalStore } from '../../ui-core/V2/cfModal/store';
import MoneyInput from '../../ui-core/V2/cfMoneyInput/cfMoneyInput';
import CFSelect from '../../ui-core/V2/cfSelect/cfSelect';
import { pushEvent } from '../../utils/googleAnalytics';
import Disclaimer from '../discaimer/disclaimer';
import { useQuotesStore } from '../quotes/store';
import { getMarkAsBoundBasicDetailsSection } from './componentUtils';
import {
  GA_ACTION,
  GA_LABEL,
  MARK_AS_BOUND_FORM_CONFIG,
  MARK_AS_BOUND_FORM_ITEM,
  MARK_AS_BOUND_MESSAGE,
  MARK_AS_BOUND_MODAL_CONFIG,
} from './constants';
import { useMarkAsBoundModalStore } from './store';
import { MarkAsBoundDisclaimerWrapperStyled, MarkAsBoundMessageInfoCircleOutlinedStyled } from './styles';
import { getGACategory } from './utils';

const MarkAsBoundModal = () => {
  const [form] = useForm();
  const { clearCfModal } = useCFModalStore();
  const {
    presetCarrier,
    markAsBoundForm,
    isMarkAsBoundModalLoading,
    isApplicationBounding,
    markAsBoundSelectedCarriersInfo,
    postMarkAsBoundCallback,
    markApplicationAsBound,
    setMarkAsBoundForm,
    setIsApplicationBound,
    fetchMarkAsBoundModalData,
    clearMarkAsBoundModalStore,
  } = useMarkAsBoundModalStore();
  const { updateQuotesByKey, selectedQuote } = useQuotesStore();

  const { carrierId = '' } = selectedQuote || {};

  useEffect(() => {
    setMarkAsBoundForm(form);
    (async () => {
      await fetchMarkAsBoundModalData();
    })();

    return () => {
      updateQuotesByKey(QUOTES_STORE_KEYS.SELECTED_QUOTE, undefined);
      clearMarkAsBoundModalStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isMarkAsBoundModalLoading && markAsBoundForm && presetCarrier) {
      markAsBoundForm?.setFieldValue(MARK_AS_BOUND_FORM_ITEM.CARRIER, presetCarrier);
    }
  }, [isMarkAsBoundModalLoading, markAsBoundForm, presetCarrier]);

  const handleMarkAsBoundClick = async () => {
    pushEvent({
      category: getGACategory(),
      action: GA_ACTION.MARK_AS_BOUND_BTN_CLICK,
      label: GA_LABEL.MARK_AS_BOUND_MODAL_SUCCESS,
    });
    await markAsBoundForm?.validateFields();
    setIsApplicationBound(true);
    try {
      await markApplicationAsBound();
      // Execute callback function if any
      if (postMarkAsBoundCallback && typeof postMarkAsBoundCallback === 'function') {
        await postMarkAsBoundCallback();
      }

      await clearCfModal();
      await clearMarkAsBoundModalStore();
      success(MARK_AS_BOUND_MESSAGE.BOUND_SUCCESS);
    } catch (error: any) {
      errorV2(error?.[0]?.errorMessage || MARK_AS_BOUND_MESSAGE.BOUND_FAILED);
    } finally {
      setIsApplicationBound(false);
    }
  };

  const handleCancel = () => {
    pushEvent({
      category: getGACategory(carrierId),
      action: GA_ACTION.CLOSE_MARK_AS_BOUND_MODAL,
      label: GA_LABEL.MARK_AS_BOUND_MODAL_CLOSE,
    });
  };

  return (
    <CFModal
      title={MARK_AS_BOUND_MODAL_CONFIG.TITLE}
      okText={MARK_AS_BOUND_MODAL_CONFIG.OK_TEXT}
      onOk={handleMarkAsBoundClick}
      onCancel={handleCancel}
      okButtonProps={{
        id: 'btn_ok_mark_as_bound',
        loading: isApplicationBounding,
        disabled: isMarkAsBoundModalLoading,
      }}
      cancelButtonProps={{
        id: 'btn_cancel_mark_as_bound',
        disabled: isApplicationBounding || isMarkAsBoundModalLoading,
      }}
      disableCloseIcon={isApplicationBounding || isMarkAsBoundModalLoading}
      maskClosable={!(isApplicationBounding || isMarkAsBoundModalLoading)}
    >
      {isMarkAsBoundModalLoading && <CFLoader />}
      {!isMarkAsBoundModalLoading && (
        <>
          <MarkAsBoundDisclaimerWrapperStyled>
            <Disclaimer icon={<MarkAsBoundMessageInfoCircleOutlinedStyled />} color='red'>
              <b>
                PLEASE NOTE: This action does not constitute a binding action with a carrier. It is for your record
                keeping purposes only.
              </b>
            </Disclaimer>
          </MarkAsBoundDisclaimerWrapperStyled>
          Use Mark As Bound to update the Quote & Bind System to indicate that you have manually bound this application
          offline.
          {getMarkAsBoundBasicDetailsSection()}
          <Divider />
          <CFForm id='form_mark_as_bound' form={form} labelAlign='left' labelCol={{ span: 8 }}>
            <CFFormItem {...MARK_AS_BOUND_FORM_CONFIG.carrierField}>
              <CFSelect
                id='sel_carrier'
                placeholder='Select a Carrier'
                getPopupContainer={() => document.body}
                disabled={isApplicationBounding}
                defaultValue={presetCarrier}
                options={(markAsBoundSelectedCarriersInfo || [])?.map((item) => ({
                  label: item?.carrierShortName,
                  value: item?.carrierId,
                }))}
              />
            </CFFormItem>
            <CFFormItem {...MARK_AS_BOUND_FORM_CONFIG.policyIdField}>
              <CFInput
                id='inp_policy_id'
                placeholder='Optional'
                disabled={isApplicationBounding}
                autoComplete='off'
              ></CFInput>
            </CFFormItem>
            <CFFormItem {...MARK_AS_BOUND_FORM_CONFIG.totalPremiumField}>
              <MoneyInput
                id='inp_total_premium'
                placeholder='$0'
                disabled={isApplicationBounding}
                autoComplete='off'
                decimalAllowed
              ></MoneyInput>
            </CFFormItem>
          </CFForm>
        </>
      )}
    </CFModal>
  );
};

export default MarkAsBoundModal;
