// Refer:- https://gist.github.com/adamjmcgrath/0ed6a04047aad16506ca24d85f1b2a5c
// This is needed to be able to refresh tokens from outside react components like axios interceptors
export const deferredGetAuth0TokenSilently = (() => {
  const props: any = {};
  props.promise = new Promise((resolve) => (props.resolve = resolve));
  return props;
})();

export const getAuth0TokenSilently = async (params: any) => {
  const getToken = await deferredGetAuth0TokenSilently.promise;
  return getToken(params);
};
