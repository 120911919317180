import { INDUSTRY_CODES } from '../../../constants/appetite';
import { MIME_TYPES } from '../../../globalConstants';
import { INewQuoteStore } from './interface';

export const ACTION_TYPE = {
  NAME: 'New Quote Store',
  ANONYMOUS_ACTION: 'NEW_QUOTE_STORE',
  SET_NEW_QUOTE_CARRIERS: 'newQuote/carriersStore',
  SET_NEW_QUOTE_CARRIERS_ERROR: 'newQuote/carriersStore/error',
  SET_NEW_QUOTE_POLICY_TYPE: 'newQuote/policyTypeStore',
  SET_NEW_QUOTE_POLICY_TYPE_ERROR: 'newQuote/policyTypeStore/error',
  SET_NEW_QUOTE_SUPPORTED_CARRIERS: 'newQuote/supportedCarriersStore',
  SET_NEW_QUOTE_SUPPORTED_CARRIERS_ERROR: 'newQuote/supportedCarriersStore/error',
  SET_NEW_QUOTE_ACCOUNTS: 'newQuote/allAccountsStore',
  SET_NEW_QUOTE_ACCOUNTS_ERROR: 'newQuote/allAccountsStore/error',
  SET_NEW_QUOTE_NAICS_CODE: 'newQuote/naicsCodes',
  SET_NEW_QUOTE_NAICS_CODE_ERROR: 'newQuote/naicsCodes/error',
  SET_NEW_QUOTE_SIC_CODE: 'newQuote/sicCodes',
  SET_NEW_QUOTE_SIC_CODE_ERROR: 'newQuote/sicCodes/error',
  SET_NEW_QUOTE_APPETITE: 'newQuote/checkAppetite',
  SET_NEW_QUOTE_APPETITE_ERROR: 'newQuote/checkAppetite/error',
  SET_NEW_QUOTE_BROKER_CONFIGS: 'newQuote/brokerConfigs',
  SET_NEW_QUOTE_BROKER_CONFIGS_ERROR: 'newQuote/brokerConfigs/error',
  SET_PRESET_ACCOUNT_ID: 'newQuote/presetAccountId',
  SET_SUPPORTED_CARRIERS_LOADING: 'newQuote/supportedCarriersStore/loading',
  SET_INDUSTRY_CODE_TYPE: 'newQuote/supportedCarriersStore/setIndustryCodeType',
  UPDATE_NEW_QUOTE_STORE_BY_KEY: 'newQuote/updateNewQuoteStoreByKey',
  CLEAR_NEW_QUOTE_STORE: 'newQuote/clearNewQuoteStore',
};

export const APPETITE_TYPE = {
  IN_APPETITE: 'IN_APPETITE',
  NOT_IN_APPETITE: 'NOT_IN_APPETITE',
  MAYBE_IN_APPETITE: 'MAYBE_IN_APPETITE',
};

export const DEFAULT_MAX_ALLOWED_ACORD_FILE_SIZE_KB = 15360; // 15MB, server only accept file till 15MB.

export const ACORD_MESSAGES = {
  FORM_UPLOADED_APPLICATION_CREATING:
    'We are extracting the ACORD data in the background. You can return to this page to check when the application is ready.',
  DEFAULT_ACORD_UPLOAD_MESSAGE: 'Upload ACORD Forms',
};

export const FORM_CONFIG = (industryCodeType: string) => {
  const industryCodeField =
    industryCodeType === INDUSTRY_CODES.NAICS.TYPE
      ? {
          id: 'form_item_sel_naicsCodes',
          name: 'naicsCode',
          label: 'NAICS Code',
          rules: [{ required: true, message: 'Please select NAICS code.' }],
          placeholder: 'Start typing to search NAICS code',
        }
      : {
          id: 'form_item_sel_sicCodes',
          name: 'sicCode',
          label: 'SIC Code',
          rules: [{ required: true, message: 'Please select SIC code.' }],
          placeholder: 'Start typing to search SIC code',
        };
  return {
    accountsField: {
      id: 'form_item_sel_account',
      name: 'account',
      label: 'For Account',
      rules: [{ required: true, message: 'Please select an Account.' }],
      placeholder: 'Choose the Account',
    },
    policyTypeField: {
      id: 'form_item_sel_policy_type',
      name: 'policyType',
      label: 'Policy Type',
      rules: [{ required: true, message: 'Please select a Policy Type.' }],
      placeholder: 'Choose the policy type',
    },
    carriersField: {
      id: 'form_item_sel_carriers',
      name: 'carriers',
      label: 'Carriers',
      rules: [{ required: true, message: 'Please select a Carrier.' }],
      placeholder: 'Choose the carriers',
    },
    industryCodeField,
    stateField: {
      id: 'form_item_sel_state',
      name: 'state',
      label: 'State',
      rules: [{ required: true, message: 'Please select a State.' }],
      placeholder: 'Start typing to search for State',
    },
    acordField: {
      id: 'form_item_rad_is_accord',
      name: 'isAcord',
      label: 'Prepopulate with ACORD form',
      tooltip:
        'Prepopulate application data by uploading existing ACORD forms. The ACORD form data will be extracted and populated in the application',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },
  };
};

export const UPLOAD_ACORD_SUPPORTED_EXT = [MIME_TYPES.JPEG, MIME_TYPES.PDF, MIME_TYPES.PNG];

export const NEW_QUOTE_STORE_KEYS = {
  SUPPORTED_CARRIERS_STORE: 'supportedCarriersStore' as keyof INewQuoteStore,
};

// export const ACORD_UPLOAD_PROGRESS_MESSAGES = [
//   'Uploading ACORD form',
//   'Extracting data from ACORD form',
//   'Creating new application',
// ];

// export const ACORD_UPLOAD_RETRY_COUNT = 3;

// export const ACORD_PROGRESS_INTERVAL = 10000; // 10 secs
export const GA_ACTION = {
  CLOSE_NEW_QUOTE_MODAL: 'closeNewQuoteModalBtn_click',
  START_NEW_APPLICATION: 'startNewApplicationBtn_click',
  SWITCH_CODE_TO_SIC: 'switchToSicBtn_click',
  SWITCH_CODE_TO_NAICS: 'switchToNaicsBtn_click',
};

export const GA_LABEL = {
  CLOSE_NEW_QUOTE_MODAL: 'Close new quote modal',
  START_NEW_APPLICATION: 'Start new application button clicked',
  SWITCH_CODE_TO_SIC: 'Switch to SIC toggle button clicked',
  SWITCH_CODE_TO_NAICS: 'Switch to NAICS toggle button clicked',
};
