export const sortBy = ({ arr = [], sortKey, asc = true }: { arr?: any[]; sortKey: any; asc?: boolean }) => {
  // if (asc) return arr.sort((x, y) => (x[key] < y[key] ? -1 : x[key] > y[key] ? 1 : 0));
  // else return arr.sort((x, y) => (x[key] > y[key] ? -1 : x[key] < y[key] ? 1 : 0));

  if (asc) {
    return arr.sort((x, y) => {
      const [xValue, yValue] = parseKey(x[sortKey], y[sortKey]);
      if (xValue < yValue) {
        return -1;
      } else if (xValue > yValue) {
        return 1;
      } else {
        return 0;
      }
    });
  } else {
    return arr.sort((x, y) => {
      const [xValue, yValue] = parseKey(x[sortKey], y[sortKey]);
      if (xValue > yValue) {
        return -1;
      } else if (xValue < yValue) {
        return 1;
      } else {
        return 0;
      }
    });
  }
};

const parseKey = (x: any, y: any) => {
  let xValue = x;
  let yValue = y;
  if (typeof xValue !== 'number' || typeof yValue !== 'number') {
    if (xValue && !isNaN(xValue)) {
      xValue = parseInt(xValue);
    }
    if (yValue && !isNaN(yValue)) {
      yValue = parseInt(yValue);
    }
  }
  return [xValue, yValue];
};
