import { useCallback } from 'react';

import { ICFSwitch } from './interface';
import { StyledCFSwitch } from './styles';

const CFSwitch = (props: ICFSwitch) => {
  const { id, onChangeData, onChange, onClickData, onClick, stopPropagation = true, ...rest } = props;

  const handleChange = useCallback(
    (checked, event) => {
      onChange && onChange(checked, event, onChangeData);
    },
    [onChange, onChangeData],
  );

  const handleClick = useCallback(
    (checked, event) => {
      if (stopPropagation) {
        event.stopPropagation();
      }
      onClick && onClick(checked, event, onClickData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClickData, onClick],
  );

  return <StyledCFSwitch id={id} onChange={handleChange} onClick={handleClick} {...rest} />;
};

export default CFSwitch;
