import createInstance from '../common/createInstance';
import { defaultRequestInterceptor, defaultResponseInterceptor } from '../common/interceptor';
import methods from '../common/methods';
import { APPLICATION_LIFECYCLE_SERVICE, APPLICATION_LIFECYCLE_SERVICE_PARAMS } from './constants';

const axiosApplicationLifeCycleService = createInstance(APPLICATION_LIFECYCLE_SERVICE_PARAMS);

defaultRequestInterceptor({
  axiosInstance: axiosApplicationLifeCycleService,
  serviceName: APPLICATION_LIFECYCLE_SERVICE,
});
defaultResponseInterceptor({
  axiosInstance: axiosApplicationLifeCycleService,
  serviceName: APPLICATION_LIFECYCLE_SERVICE,
});

export default methods(axiosApplicationLifeCycleService);
