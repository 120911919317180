import { useMarkAsBoundModalStore } from './store';
import { CFColStyled, MarkAsBoundHeaderWrapper } from './styles';

export const getMarkAsBoundBasicDetailsSection = () => {
  const { markAsBoundApplicationData } = useMarkAsBoundModalStore.getState();
  return (
    <MarkAsBoundHeaderWrapper id='row_application_info'>
      <CFColStyled id='col_business' style={{ marginRight: '20px' }}>
        <div>
          <b>Business Name</b>
        </div>
        <div>{markAsBoundApplicationData?.basicBusinessDetails?.businessName ?? '--'}</div>
      </CFColStyled>
      <CFColStyled id='col_policy'>
        <div>
          <b>Policy Type</b>
        </div>
        <div>{markAsBoundApplicationData?.policyDetails?.[0]?.policyType ?? '--'}</div>
      </CFColStyled>
    </MarkAsBoundHeaderWrapper>
  );
};
