import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getPolicyDetails, getPolicyDocument } from '../../externalServices/V2/applicationV2';
import { getDownloadableObject } from '../../utils/getDownloadableObject';
import { useApplicationDocumentCenterStore } from '../modals/applicationDocumentCenter/store';
import { useQuotesStore } from '../quotes/store';
import { CONFIRMATION_STORE_ACTIONS, FILE_NAME } from './constants';
import { IConfirmationStore } from './interface';

export const useConfirmationStore = create<IConfirmationStore>()(
  devtools(
    (set) => ({
      policyDetailsLoading: false,
      policyDetails: undefined,
      policyDetailsError: undefined,
      policyDocumentLoading: false,
      policyDocument: undefined,
      policyDocumentError: undefined,

      getPolicyDocument: async (loading = true) => {
        const { selectedQuote } = useQuotesStore.getState();
        const { fetchDocuments } = useApplicationDocumentCenterStore.getState();
        try {
          set({ policyDocumentLoading: loading }, false, CONFIRMATION_STORE_ACTIONS.POLICY_DOCUMENTS_LOADING_START);
          const response = await getPolicyDocument({
            applicationId: selectedQuote?.applicationId!,
            cfPolicyId: selectedQuote?.cfPolicyId!,
            cfQuoteId: selectedQuote?.cfQuoteId!,
          });
          if (response?.policyDocument) {
            const policyDocument = getDownloadableObject(response.policyDocument, FILE_NAME, response?.fileFormat);
            set(
              { policyDocument, policyDocumentError: undefined },
              false,
              CONFIRMATION_STORE_ACTIONS.POLICY_DOCUMENTS_FETCHED,
            );
          }
          fetchDocuments();
        } catch (err) {
          set(
            { policyDocument: undefined, policyDocumentError: err },
            false,
            CONFIRMATION_STORE_ACTIONS.POLICY_DOCUMENTS_ERROR,
          );
        } finally {
          set({ policyDocumentLoading: false }, false, CONFIRMATION_STORE_ACTIONS.POLICY_DOCUMENTS_LOADING_STOP);
        }
      },

      getPolicyDetails: async (loading = true) => {
        const { selectedQuote } = useQuotesStore.getState();
        try {
          set({ policyDetailsLoading: loading }, false, CONFIRMATION_STORE_ACTIONS.POLICY_DETAILS_LOADING_START);
          const policyDetails = await getPolicyDetails({
            applicationId: selectedQuote?.applicationId!,
            cfQuoteId: selectedQuote?.cfQuoteId!,
            cfPolicyId: selectedQuote?.cfPolicyId!,
          });
          set(
            { policyDetails, policyDetailsError: undefined },
            false,
            CONFIRMATION_STORE_ACTIONS.POLICY_DETAILS_FETCHED,
          );
        } catch (err) {
          set(
            { policyDetails: undefined, policyDetailsError: err },
            false,
            CONFIRMATION_STORE_ACTIONS.POLICY_DETAILS_ERROR,
          );
        } finally {
          set({ policyDetailsLoading: false }, false, CONFIRMATION_STORE_ACTIONS.POLICY_DETAILS_LOADING_STOP);
        }
      },

      clearConfirmation: () => {
        set(
          {
            policyDetailsLoading: false,
            policyDetails: undefined,
            policyDetailsError: undefined,
            policyDocumentLoading: false,
            policyDocument: undefined,
            policyDocumentError: undefined,
          },
          false,
          CONFIRMATION_STORE_ACTIONS.CLEAR_CONFIRMATION,
        );
      },
    }),

    { name: CONFIRMATION_STORE_ACTIONS.NAME, anonymousActionType: CONFIRMATION_STORE_ACTIONS.ANONYMOUS_ACTION },
  ),
);
