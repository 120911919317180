import { IBrokerCodeAgentInfo } from '@coverforce-platform/cf-common-api-model';
import { IBrokerConfiguration } from '@coverforce-platform/cf-common-api-model/lib/models/common/brokerConfiguration.models';
import { AgentPortalUserType } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getAgentDefaultBrokerCodes, getBrokerCodesByAgencyId } from '../../externalServices/V2/brokerConfig';
import { useApplicationStore } from '../../pages/v2/application/store';
import { useProfileV2Store } from '../../pages/v2/profile/store';
import { cloneDeep } from '../../utils/lodash';
import { useConfigurationStore } from '../configuration/store';
import { BROKER_CODES_STORE } from './constants';
import { IBrokerCodesStore, ICarrierBrokerConfig } from './interface';
import { getAutofillBrokerConfigurationFromApplication } from './utils';

export const useBrokerCodesStore = create<IBrokerCodesStore>()(
  devtools(
    (set, get) => ({
      allCarrierBrokerConfigs: undefined,
      enabledCarrierBrokerConfigs: undefined,
      defaultBrokerCodes: undefined,
      isCarrierBrokerConfigsLoading: false,
      carrierBrokerConfigsError: undefined,
      brokerConfigurationData: [],

      fetchCarrierBrokerConfigs: async (isCarrierBrokerConfigsLoading = true) => {
        try {
          set({ isCarrierBrokerConfigsLoading }, false, BROKER_CODES_STORE.CARRIER_BROKER_CONFIGS_LOADING);
          const { accountData } = useApplicationStore.getState();
          const { userData } = useProfileV2Store.getState();
          const { userType } = useConfigurationStore.getState();

          const allCarrierBrokerConfigs: ICarrierBrokerConfig = {};
          const enabledCarrierBrokerConfigs: ICarrierBrokerConfig = {};
          let defaultBrokerCodes: IBrokerConfiguration[] = [];

          const brokerCodesResponse = await getBrokerCodesByAgencyId({
            agencyId: accountData?.agencyId || '',
          });

          if (userType === AgentPortalUserType.AGENCY) {
            const defaultBrokerCodesResponse = await getAgentDefaultBrokerCodes({
              agentId: userData?.agentId || '',
            });
            defaultBrokerCodes = defaultBrokerCodesResponse?.defaultBrokerCodes;
          }

          brokerCodesResponse?.brokerCodes?.forEach((item) => {
            if (allCarrierBrokerConfigs[item.carrierId]) {
              allCarrierBrokerConfigs[item.carrierId]?.push(item as IBrokerCodeAgentInfo);
            } else {
              allCarrierBrokerConfigs[item.carrierId] = [item as IBrokerCodeAgentInfo];
            }
            if (item.isEnabled) {
              if (enabledCarrierBrokerConfigs[item.carrierId]) {
                enabledCarrierBrokerConfigs[item.carrierId]?.push(item as IBrokerCodeAgentInfo);
              } else {
                enabledCarrierBrokerConfigs[item.carrierId] = [item as IBrokerCodeAgentInfo];
              }
            }
          });

          set(
            {
              allCarrierBrokerConfigs,
              enabledCarrierBrokerConfigs,
              defaultBrokerCodes,
              carrierBrokerConfigsError: undefined,
            },
            false,
            BROKER_CODES_STORE.CARRIER_BROKER_CONFIGS_LOADING,
          );
        } catch (err) {
          set(
            {
              allCarrierBrokerConfigs: undefined,
              enabledCarrierBrokerConfigs: undefined,
              defaultBrokerCodes: undefined,
              carrierBrokerConfigsError: err,
            },
            false,
            BROKER_CODES_STORE.CARRIER_BROKER_CONFIGS_LOADING,
          );
        } finally {
          set({ isCarrierBrokerConfigsLoading: false }, false, BROKER_CODES_STORE.CARRIER_BROKER_CONFIGS_LOADING);
        }
      },

      autofillBrokerConfiguration: async () => {
        const brokerConfigurationData = getAutofillBrokerConfigurationFromApplication();
        set({ brokerConfigurationData }, false, BROKER_CODES_STORE.AUTOFILL_BROKER_CONFIGURATION);
      },

      updateBrokerConfiguration: async (brokerConfiguration: IBrokerConfiguration) => {
        const { brokerConfigurationData } = get();
        const currentIndex = brokerConfigurationData.findIndex(
          (item) => item.carrierId === brokerConfiguration.carrierId,
        );
        if (currentIndex !== -1) {
          brokerConfigurationData[currentIndex] = brokerConfiguration;
        } else {
          brokerConfigurationData.push(brokerConfiguration);
        }
        set({ brokerConfigurationData }, false, BROKER_CODES_STORE.UPDATE_BROKER_CONFIGURATION);
      },

      validateBrokerConfigurationData: async () => {
        const { brokerConfigurationData, carrierBrokerConfigsError } = get();
        const { selectedCarriersInfo } = useApplicationStore.getState();

        const brokerConfigurationCarrierIds = brokerConfigurationData.map((item) => item.carrierId);
        return (
          selectedCarriersInfo.every((item) => brokerConfigurationCarrierIds.includes(item.carrierId)) &&
          !carrierBrokerConfigsError
        );
      },

      getBrokerConfigurationApiPayload: () => {
        const { applicationData } = useApplicationStore.getState();
        const { brokerConfigurationData } = get();

        const brokerConfigurationApplicationPayload = {
          ...cloneDeep(applicationData),
          brokerConfiguration: cloneDeep(brokerConfigurationData),
        };

        return brokerConfigurationApplicationPayload;
      },

      updateBrokerCodesByKey: async (storeKey: keyof IBrokerCodesStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, BROKER_CODES_STORE.UPDATE_BROKER_CODES_STORE_BY_KEY),

      clearBrokerCodes: () =>
        set(
          {
            enabledCarrierBrokerConfigs: undefined,
            isCarrierBrokerConfigsLoading: false,
            carrierBrokerConfigsError: undefined,
            defaultBrokerCodes: undefined,
            brokerConfigurationData: [],
          },
          false,
          BROKER_CODES_STORE.CLEAR_BROKER_CODES,
        ),
    }),

    { name: BROKER_CODES_STORE.NAME, anonymousActionType: BROKER_CODES_STORE.ANONYMOUS_ACTION },
  ),
);
