import { useCallback, useMemo } from 'react';

import { ICFMenu } from './interface';
import { CFMenuItemStyled, CFMenuStyled } from './styles';
import { CFMenuItemDividerRawStyled, CFMenuItemRawStyled, CFMenuRawStyled } from './stylesV2';

const CFMenu = (props: ICFMenu) => {
  const {
    id,
    backgroundColor,
    data,
    style,
    onClick,
    getPopupContainer: customGetPopupContainer,
    stopPropagation = true,
    ...rest
  } = props;

  const handleOnClick = useCallback(
    (event) => {
      if (stopPropagation) {
        event.domEvent.stopPropagation();
      }
      onClick && onClick(event, data);
    },
    [data, onClick, stopPropagation],
  );

  const getPopupContainer = useMemo(
    () =>
      customGetPopupContainer
        ? customGetPopupContainer
        : (triggerNode: HTMLElement) => (triggerNode?.parentNode as HTMLElement) || document.body,
    [customGetPopupContainer],
  );

  return (
    <CFMenuStyled
      id={id}
      backgroundColor={backgroundColor}
      onClick={handleOnClick}
      getPopupContainer={getPopupContainer}
      style={style}
      {...rest}
    ></CFMenuStyled>
  );
};

const CFMenuRaw = (props: ICFMenu) => {
  const {
    id,
    // backgroundColor,
    data,
    style,
    onClick,
    getPopupContainer: customGetPopupContainer,
    stopPropagation = true,
    ...rest
  } = props;

  const handleOnClick = useCallback(
    (event) => {
      if (stopPropagation) {
        event.domEvent.stopPropagation();
      }
      onClick && onClick(event, data);
    },
    [data, onClick, stopPropagation],
  );

  const getPopupContainer = useMemo(
    () =>
      customGetPopupContainer
        ? customGetPopupContainer
        : (triggerNode: HTMLElement) => (triggerNode?.parentNode as HTMLElement) || document.body,
    [customGetPopupContainer],
  );

  return (
    <CFMenuRawStyled
      id={id}
      // backgroundColor={backgroundColor}
      onClick={handleOnClick}
      getPopupContainer={getPopupContainer}
      style={style}
      {...rest}
    ></CFMenuRawStyled>
  );
};

export default CFMenu;

export const CFMenuItem = CFMenuItemStyled;

export const CFSubMenu = CFMenuStyled.SubMenu;

export const CFMenuDivider = CFMenuStyled.Divider;

export { CFMenuRaw };
export const CFMenuItemRaw = CFMenuItemRawStyled;
export const CFMenuItemDividerRaw = CFMenuItemDividerRawStyled;
export const CFSubMenuRaw = CFMenuRawStyled.SubMenu;
