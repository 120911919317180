import { ILMIndustryClassCodeDetails } from '@coverforce-platform/cf-common-api-model/lib/models/applicationHelperService/carrierSpecific/libertyMutual/lmIndustryClassCode.model';
import { Carrier, PolicyType } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getIndustryClassCodeDetails } from '../../externalServices/V2/applicationV2';
import { useApplicationStore } from '../../pages/v2/application/store';
import { optionType } from '../../types';
import { errorV2 } from '../../ui-core/Notification';
import { useLocationDetailsStore } from '../locationDetails/store';
import { BUILDING_STORE_META_DATA } from './constants';
import { IBuildingInformationStore } from './interface';
import {
  getBuildingInformationByForm,
  setBuildingInformationInForm,
  updateBuildingInfoQuestionsWithCSPFields,
} from './utils';

export const useBuildingInformationStore = create<IBuildingInformationStore>()(
  devtools(
    (set) => ({
      buildingInformationQuestions: [],
      cspOptions: [],
      cspOptionsLoading: false,

      updateBuildingInformationByKey: (storeKey: keyof IBuildingInformationStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, BUILDING_STORE_META_DATA.UPDATE_BUILDING_INFORMATION_STORE_BY_KEY),

      autoFillBuidlingInformation: async () => {
        const { currentSubStepIndex: selectedLocationIndex } = useApplicationStore.getState();
        const { locationDetailsForm: form, locations } = useLocationDetailsStore.getState();
        const locationBuildingInformation = locations?.[selectedLocationIndex]?.buildingInformation;
        set({ cspOptionsLoading: true }, false, BUILDING_STORE_META_DATA.CSP_OPTIONS_LOADING);
        await setBuildingInformationInForm(
          locationBuildingInformation,
          locations?.[selectedLocationIndex]?.address?.state,
          form,
        );
        set({ cspOptionsLoading: false }, false, BUILDING_STORE_META_DATA.CSP_OPTIONS_LOADING);
      },

      getBuildingInformationApiPayload: () => {
        const { locationDetailsForm: form } = useLocationDetailsStore.getState();
        return getBuildingInformationByForm(form);
      },

      getIndustryClassCodeDetails: async ({ carrierId, loading = true, state }) => {
        const { policyType, applicationData } = useApplicationStore.getState();
        let cspOptions: optionType[] = [];

        set({ cspOptionsLoading: loading }, false, BUILDING_STORE_META_DATA.CSP_OPTIONS_LOADING);

        try {
          const carrierIndustryCode = applicationData?.basicBusinessDetails?.carrierIndustryCodes?.find(
            (code) => code.carrierId === Carrier.LIBERTYMUTUAL,
          );

          if (carrierIndustryCode) {
            const industryCode =
              carrierIndustryCode?.additionalIndustryCodes && carrierIndustryCode?.additionalIndustryCodes?.length > 0
                ? carrierIndustryCode?.additionalIndustryCodes[0]?.industryCode
                : carrierIndustryCode?.industryCode;
            if (industryCode) {
              const response = await getIndustryClassCodeDetails({
                carrierId,
                classCodeId: industryCode,
                policyType: policyType as PolicyType,
                state,
              });
              cspOptions = (response?.industryClassCodeDetails as ILMIndustryClassCodeDetails)?.dependentCodes?.map(
                (code) => ({
                  label: `${code.codeId} - ${code.codeDescription}`,
                  value: code.internalCodeId,
                }),
              );
            }
          }
          updateBuildingInfoQuestionsWithCSPFields(cspOptions, applicationData);
          set({ cspOptions }, false, BUILDING_STORE_META_DATA.SET_CSP_OPTIONS_SUCCESS);
          set({ cspOptions, cspOptionsLoading: false }, false, BUILDING_STORE_META_DATA.SET_CSP_OPTIONS_SUCCESS);
          return cspOptions;
        } catch (error: any) {
          updateBuildingInfoQuestionsWithCSPFields(cspOptions, applicationData);
          errorV2(error?.[0]?.errorMessage);
          set({ cspOptions: [], cspOptionsLoading: false }, false, BUILDING_STORE_META_DATA.SET_CSP_OPTIONS_ERROR);
          return [];
        }
      },

      clearBuildingInformationStore: () => {
        set(
          {
            buildingInformationQuestions: [],
            cspOptionsLoading: false,
          },
          false,
          BUILDING_STORE_META_DATA.CLEAR_BUILDING_INFORMATION,
        );
      },
    }),
    { anonymousActionType: BUILDING_STORE_META_DATA.ANONYMOUS_ACTION, name: BUILDING_STORE_META_DATA.NAME },
  ),
);
