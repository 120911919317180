import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { useApplicationStore } from '../../pages/v2/application/store';
import { useLocationDetailsStore } from '../locationDetails/store';
import { BUILDING_RISK_STORE_META_DATA } from './constants';
import { IBuildingRiskInformationStore } from './interface';
import { autoFillBuildingRiskInformationUtil, getBuildingRiskInformationApiPayload } from './utils';

export const useBuildingRiskInformationStore = create<IBuildingRiskInformationStore>()(
  devtools(
    (set) => ({
      buildingRiskInformationQuestions: [],

      updateBuildingRiskInformationByKey: (storeKey: keyof IBuildingRiskInformationStore, value: any) => {
        set(() => ({ [storeKey]: value }), false, BUILDING_RISK_STORE_META_DATA.UPDATE_BUILDING_RISK_INFORMATION);
      },

      autoFillBuildingRiskInformation: async () => {
        const { currentSubStepIndex: selectedLocationIndex } = useApplicationStore.getState();
        const { locationDetailsForm: form, locations } = useLocationDetailsStore.getState();
        const locationBuildingInformation = locations?.[selectedLocationIndex]?.buildingInformation;
        autoFillBuildingRiskInformationUtil({ locationBuildingInformation, form });
      },

      getBuildingRiskInformationApiPayload: () => {
        const { locationDetailsForm: form } = useLocationDetailsStore.getState();
        return getBuildingRiskInformationApiPayload(form);
      },

      clearBuildingRiskInformationStore: () => {
        set(
          {
            buildingRiskInformationQuestions: [],
          },
          false,
          BUILDING_RISK_STORE_META_DATA.CEAR_BUILDING_RISK_INFORMATION,
        );
      },
    }),
    {
      anonymousActionType: BUILDING_RISK_STORE_META_DATA.ANONYMOUS_ACTION,
      name: BUILDING_RISK_STORE_META_DATA.NAME,
    },
  ),
);
