import { formatTimestamp } from '@knocklabs/react';
import { Tooltip } from 'antd';
import { SyntheticEvent } from 'react';

import { GLOBAL_LOCALE } from '../../globalConstants';
import { cloneDeep } from '../../utils/lodash';
import { useConfigurationStore } from '../configuration/store';
import { NOTIFICATIONS_STORE_KEYS } from '../notifications/constants';
import { useNotificationsStore } from '../notifications/store';
import { NOTIFICATION_ITEM_BLOCKS_TYPE, NOTIFICATION_ITEM_TIMEOUT } from './constants';
import { INotificationItemProps } from './interface';
import {
  ArchiveNotificationStyled,
  ArchiveNotificationWrapperStyled,
  NotificationItemContentStyled,
  NotificationItemStyled,
  NotificationItemTimestampStyled,
  NotificationItemWrapperStyled,
  NotificationUnreadIndicatorStyled,
  NotificationUnreadWrapperStyled,
} from './styles';

const NotificationItem = (notificationItemProps: INotificationItemProps) => {
  const { feedClient } = useConfigurationStore();
  const { notificationsData, updateNotificationsByKey } = useNotificationsStore();
  const { notificationItem, onNotificationClick } = notificationItemProps || {};
  const { inserted_at: insertedAt } = notificationItem || {};

  const getNotificationItemHtml = () => {
    const bodyBlock = notificationItem?.blocks?.find((item) => item.name === NOTIFICATION_ITEM_BLOCKS_TYPE.BODY);
    return bodyBlock && 'rendered' in bodyBlock ? bodyBlock.rendered : '';
  };

  const navigateToNotificationUrl = () => {
    const urlBlock = notificationItem?.blocks?.find((item) => item.name === NOTIFICATION_ITEM_BLOCKS_TYPE.ACTION_URL);
    if (urlBlock && 'rendered' in urlBlock && urlBlock.rendered) {
      setTimeout(() => window.open(urlBlock.rendered), NOTIFICATION_ITEM_TIMEOUT);
    }
  };

  const onNotificationItemClick = () => {
    feedClient?.markAsRead(notificationItem);
    onNotificationClick && onNotificationClick();
    const clonedNotificationsData = cloneDeep(notificationsData);
    clonedNotificationsData.some((item) => {
      if (item.id === notificationItem.id) {
        item.read_at = new Date().toISOString();
        return true;
      }
      return false;
    });
    updateNotificationsByKey(NOTIFICATIONS_STORE_KEYS.NOTIFICATIONS_DATA, clonedNotificationsData);
    navigateToNotificationUrl();
  };

  const onArchiveNotificationClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    feedClient?.markAsArchived(notificationItem);
    const clonedNotificationsData = cloneDeep(notificationsData).filter((item) => item.id !== notificationItem.id);
    updateNotificationsByKey(NOTIFICATIONS_STORE_KEYS.NOTIFICATIONS_DATA, clonedNotificationsData);
  };

  return (
    <>
      <NotificationItemWrapperStyled onClick={onNotificationItemClick} notificationItem={notificationItem}>
        <NotificationUnreadWrapperStyled>
          <NotificationUnreadIndicatorStyled notificationItem={notificationItem} />
        </NotificationUnreadWrapperStyled>
        <NotificationItemContentStyled>
          <NotificationItemStyled
            dangerouslySetInnerHTML={{
              __html: getNotificationItemHtml(),
            }}
          />
          <NotificationItemTimestampStyled>
            {formatTimestamp(insertedAt, { locale: GLOBAL_LOCALE.US })}
          </NotificationItemTimestampStyled>
        </NotificationItemContentStyled>
        <ArchiveNotificationWrapperStyled>
          <Tooltip placement='bottom' title='Archive this notification'>
            <ArchiveNotificationStyled onClick={onArchiveNotificationClick} />
          </Tooltip>
        </ArchiveNotificationWrapperStyled>
      </NotificationItemWrapperStyled>
    </>
  );
};

export default NotificationItem;
