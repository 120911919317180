/**
 *
 * @param obj any
 * @param keysMap {[objectKey] => replacedKey}
 * @returns
 *
 * Ex.
 * let x = {
 *  firstName: "john",
 *  lastName: "doe"
 * }
 *
 * keysMap = {
 * firstName : "fName",
 * lastName:"lName"
 * }
 *
 * replaceKeys(x ,keysMap) => {
 *  fName: "john",
 *  lName: "doe"
 * }
 */
export const replaceObjectKeys = (
  obj: { [key: string]: any },
  keysMap: { [key: string]: string },
): { [key: string]: any } => {
  const result: { [key: string]: any } = {};

  for (const [key, value] of Object.entries(obj)) {
    const newKey = keysMap[key] || key;
    if (Array.isArray(value)) {
      if (typeof value[0] === 'string') {
        // check if array of strings
        result[newKey] = value;
      } else {
        result[newKey] = value.map((item) => replaceObjectKeys(item, keysMap));
      }
    } else if (typeof value === 'object' && value !== null) {
      result[newKey] = replaceObjectKeys(value, keysMap);
    } else {
      result[newKey] = value;
    }
  }

  return result;
};
