import { IBuildingInfo } from '@coverforce-platform/cf-common-api-model';

import { IFormInstance } from '../../ui-core/V2/cfForm/cfForm';
import { getValidDropdownValue } from '../../utils/getValidDropdownValue';
import { BUILDING_CONSTRUCTION_FIELDS } from './constants';

export const autoFillBuildingRiskInformationUtil = ({
  locationBuildingInformation,
  form,
}: {
  locationBuildingInformation: IBuildingInfo | null | undefined;
  form?: IFormInstance;
}) => {
  locationBuildingInformation &&
    form?.setFieldsValue({
      ...locationBuildingInformation,
      constructionTypeCode: getValidDropdownValue(
        locationBuildingInformation?.constructionTypeCode,
        BUILDING_CONSTRUCTION_FIELDS,
      ),
      totalStories: locationBuildingInformation?.totalStories,
      areaOfConstructionProject: locationBuildingInformation?.areaOfConstructionProject,
      totalConstructionProjectValue: locationBuildingInformation?.totalConstructionProjectValue,
    });
};

export const getBuildingRiskInformationApiPayload = (form?: IFormInstance) => {
  const formData = form?.getFieldsValue();

  return (
    formData && {
      buildingInformation: {
        constructionTypeCode: formData?.constructionTypeCode,
        totalStories:
          formData?.totalStories?.toString()?.trim()?.length > 0 ? Number(formData?.totalStories) : undefined,
        areaOfConstructionProject:
          formData?.areaOfConstructionProject?.toString()?.trim()?.length > 0
            ? Number(formData?.areaOfConstructionProject)
            : undefined,
        totalConstructionProjectValue:
          formData?.totalConstructionProjectValue?.toString()?.trim()?.length > 0
            ? Number(formData?.totalConstructionProjectValue)
            : undefined,
      },
    }
  );
};
