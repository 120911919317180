import { forOwn, fromPairs, isArray, isObject, sortBy, toPairs, values } from './lodash';

export const sortObjectKeys = (obj: any) => {
  if (isArray(obj)) {
    // Sort array of objects by the values of their keys
    return sortBy(obj, (item) => values(item).sort().join(''));
  }
  if (isObject(obj)) {
    const sortedObject: any = isArray(obj) ? [] : {};
    forOwn(obj, (value, key) => {
      sortedObject[key] = sortObjectKeys(value);
    });
    return isArray(obj) ? sortBy(sortedObject) : fromPairs(sortBy(toPairs(sortedObject), ([key]) => key));
  }
  return obj;
};
