import { useEffect } from 'react';

import { QUOTES_STORE_KEYS } from '../../constants/quotesConstants';
import { errorV2, success } from '../../ui-core/Notification';
import { useCFModalStore } from '../../ui-core/V2/cfModal/store';
import { pushEvent } from '../../utils/googleAnalytics';
import { GA_ACTION, GA_LABEL } from '../quotes/constants';
import { useQuotesStore } from '../quotes/store';
import {
  CANCEL_REQUEST_TO_BIND_MODAL,
  CANCEL_REQUEST_TO_BIND_MODAL_STORE_KEYS,
  CANCEL_REQUEST_TO_BIND_QUOTE_MESSAGES,
} from './constants';
import { useCancelRequestToBindQuoteModalStore } from './store';
import { CancelRequestToBindModalStyled } from './styles';
import { getGACategory } from './utils';

const CancelRequestToBindModal = () => {
  const { clearCfModal } = useCFModalStore();
  const { fetchQuotes, updateQuotesByKey, selectedQuote } = useQuotesStore();
  const {
    isCancelRequestToBindLoading,
    cancelRequestToBindQuote,
    updateCancelRequestToBindModalByKey,
    clearCancelRequestToBind,
  } = useCancelRequestToBindQuoteModalStore();
  const { carrierId = '' } = selectedQuote || {};

  useEffect(() => {
    return () => {
      updateQuotesByKey(QUOTES_STORE_KEYS.SELECTED_QUOTE, undefined);
      clearCancelRequestToBind();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancelRequestToBind = async () => {
    pushEvent({
      category: getGACategory(carrierId),
      action: GA_ACTION.CANCEL_BIND_REQUEST_BTN_CLICK,
      label: GA_LABEL.CANCEL_BIND_REQUEST,
    });
    try {
      await cancelRequestToBindQuote();
      await fetchQuotes(false);
      success(CANCEL_REQUEST_TO_BIND_QUOTE_MESSAGES.SUCCESS);
      clearCfModal();
    } catch (error: any) {
      errorV2(error?.[0]?.errorMessage || CANCEL_REQUEST_TO_BIND_QUOTE_MESSAGES.ERROR);
    } finally {
      updateCancelRequestToBindModalByKey(
        CANCEL_REQUEST_TO_BIND_MODAL_STORE_KEYS.IS_CANCEL_REQUEST_TO_BIND_LOADING,
        false,
      );
    }
  };

  const handleCloseModal = () => {
    pushEvent({
      category: getGACategory(carrierId),
      action: GA_ACTION.CLOSE_MODAL_BTN_CLICK,
      label: GA_LABEL.CLOSE_CANCEL_TO_REQUEST_BIND_MODAL,
    });
  };

  return (
    <CancelRequestToBindModalStyled
      title={CANCEL_REQUEST_TO_BIND_MODAL.TITLE}
      okText='Confirm'
      cancelText='Cancel'
      okButtonProps={{
        id: 'btn_cancel_request_to_bind_ok',
        loading: isCancelRequestToBindLoading,
        disabled: isCancelRequestToBindLoading,
      }}
      cancelButtonProps={{ id: 'btn_cancel_request_to_bind_cancel', disabled: isCancelRequestToBindLoading }}
      onOk={onCancelRequestToBind}
      onCancel={handleCloseModal}
      disableCloseIcon={isCancelRequestToBindLoading}
      maskClosable={!isCancelRequestToBindLoading}
    >
      Are you sure you want to cancel this bind request?
    </CancelRequestToBindModalStyled>
  );
};

export default CancelRequestToBindModal;
