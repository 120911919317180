import { QUERY_PARAMS, SESSION_STORAGE_KEYS } from '../globalConstants';
import { getFromSessionStorage } from './getFromSessionStorage';

interface ILogoutActions {
  redirectToHome?: boolean;
}

export const redirectToLogout = async (logoutParams?: ILogoutActions) => {
  const { redirectToHome = false } = logoutParams || {};
  const clientApp = getFromSessionStorage(SESSION_STORAGE_KEYS.CLIENT_APP) || 'cf';
  const returnToUrl = `${redirectToHome ? '/' : location.pathname}${location.search}`?.trim();

  const logoutUrl = `/logout?${QUERY_PARAMS.CLIENT_APP}=${clientApp}&${QUERY_PARAMS.RETURN_TO}=${returnToUrl}&${QUERY_PARAMS.IS_LOGOUT}=${redirectToHome}`;

  window.location.href = logoutUrl;
};
