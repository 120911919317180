export interface NavItemProps {
  id: string;
  index: number;
  title: string;
  Icon: any;
  PostFixIcon?: any;
  to?: string;
  className?: string;
  dropdownMenu?: (props: any) => JSX.Element;
  visible?: boolean;
}

export const DropdownMenus = {
  SETTINGS: 'Settings',
  HELP: 'Help',
  USER: 'User',
  NETWORK_SETTINGS: 'Network Settings',
  ACCOUNTS: 'Accounts',
  TOOLS: 'Tools',
};
