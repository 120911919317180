import { Tag } from 'antd';
import styled from 'styled-components';

import CFCol from '../../../ui-core/V2/cfCol/cfCol';
import CFTable from '../../../ui-core/V2/cfTable/cfTable';

export const BrokerIdTableColumnStyled = styled(CFCol)`
  padding: 8px 0px 8px 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const TagStyled = styled(Tag)<{ marginTop?: boolean }>`
  font-size: 10px;
  margin-top: ${(p) => p.marginTop && '10px'};
`;

export const CFTableStyled = styled(CFTable)`
  max-width: 860px;
`;
