import { pushEvent } from '../../utils/googleAnalytics';
import { GA_ACTION, GA_CATEGORY, GA_LABEL } from '../quotes/constants';
import { TIMEOUT_REASONS_MODAL } from './constants';
import { TimeoutReasonsModalStyled } from './styles';

const TimeoutReasonsModal = () => {
  const handleCancel = () => {
    pushEvent({
      category: GA_CATEGORY.QUOTES_TAB_TIME_OUT_MODAL,
      action: GA_ACTION.CLOSE_MODAL_BTN_CLICK,
      label: GA_LABEL.CLOSE_TIMEOUT_MODAL,
    });
  };
  return (
    <TimeoutReasonsModalStyled onCancel={handleCancel} title={TIMEOUT_REASONS_MODAL.TITLE} footer={null}>
      The quote generation process timed out. This is usually due to the carrier taking longer than usual to generate a
      quote.
    </TimeoutReasonsModalStyled>
  );
};

export default TimeoutReasonsModal;
