import {
  ICarrierSpecificModifier,
  ICarrierSpecificModifierDetail,
  IModifiers,
  IModifierValueValidationRules,
} from '@coverforce-platform/cf-common-api-model';
import { CFModifierID, USState } from '@coverforce-platform/cf-common-types';

import { useApplicationStore } from '../../../pages/v2/application/store';
import { cloneDeep } from '../../../utils/lodash';
import { ANTD_QUESTION_TYPE } from '../../applicationQuestion/constants';
import { IApplicationQuestion } from '../../applicationQuestion/interface';
import { useQuotesStore } from '../../quotes/store';
import { MODIFIERS_STORE_KEYS } from './constants';
import { useModifiersStore } from './store';

export const autoFillModifiersUtil = () => {
  const { modifiersForm, modifiersFormConfig, modifiersConfig, updateModifiersStoreByKey } =
    useModifiersStore.getState();
  const { selectedQuote } = useQuotesStore.getState();
  const { applicationData } = useApplicationStore.getState();

  const applicationModifiers = applicationData?.modifiers ?? [];
  if (applicationModifiers?.length > 0) {
    applicationModifiers?.forEach((modifier: IModifiers) => {
      modifier?.carrierSpecificModifiers?.forEach((carrierSpecificModifier) => {
        if (selectedQuote?.carrierId === carrierSpecificModifier?.carrier) {
          modifiersForm?.setFieldValue(
            `${modifier?.state}#${carrierSpecificModifier.modifierId}`,
            carrierSpecificModifier.modifierValue,
          );
        }
      });
    });
  }

  // Handle disabling incompatibleModifiers in case of autofill.
  const modifiersFormConfigClone = cloneDeep(modifiersFormConfig);
  modifiersFormConfigClone?.forEach((fieldConfig) => {
    if (fieldConfig) {
      const { state: fieldState, modifierId: fieldModifierId } = getStateAndModifierIdFromDataIndex(
        fieldConfig?.dataIndex,
      );

      const selectedModifierIncompatibleConfig =
        modifiersConfig?.find((item) => item?.state === fieldState && item?.modifierId === fieldModifierId)
          ?.incompatibleModifiers ?? [];

      //Case where we disable the input for incompatible modifier id present in the autofilled modifier entry.
      selectedModifierIncompatibleConfig.forEach((modifierId: string) => {
        const toDisableModifierFieldIndex =
          modifiersFormConfigClone?.findIndex((item) => {
            return item?.dataIndex === `${fieldState}#${modifierId}`;
          }) ?? -1;

        // This condition tells, disable the form field if it belongs to someone's incompatible modifier and empty and
        // the field for which we are checking is not disabled earlier.
        if (
          !modifiersForm?.getFieldValue(`${fieldState}#${modifierId}`) &&
          !modifiersFormConfigClone?.find((item) => item?.dataIndex === `${fieldState}#${fieldModifierId}`)?.disabled
        ) {
          if (toDisableModifierFieldIndex !== -1 && modifiersFormConfigClone) {
            modifiersFormConfigClone[toDisableModifierFieldIndex] = {
              ...modifiersFormConfigClone[toDisableModifierFieldIndex],
              disabled: true,
            };
          }
        }
      });
    }
  });
  updateModifiersStoreByKey(MODIFIERS_STORE_KEYS.MODIFIERS_FORM_CONFIG, modifiersFormConfigClone);
};

export const getModifiersUpdateApplicationPayloadUtil = () => {
  const { modifiersForm } = useModifiersStore.getState();
  const { policyType, applicationData } = useApplicationStore.getState();
  const { selectedQuote } = useQuotesStore.getState();

  const applicationDataClone = cloneDeep(applicationData);
  const modifiersFormFields = modifiersForm?.getFieldsValue();
  const applicationModifiersData = applicationDataClone?.modifiers ?? [];

  Object.keys(modifiersFormFields)?.map((item) => {
    const state = getStateAndModifierIdFromDataIndex(item)?.state;
    const carrierSpecificModifiersPayload: ICarrierSpecificModifier[] = [];

    const stateModifierIndex = applicationModifiersData?.findIndex((entry) => entry?.state === state) ?? -1;

    const carrierModifierEntry = {
      carrier: selectedQuote?.carrierId!,
      policyType: policyType!,
      modifierId: getStateAndModifierIdFromDataIndex(item)?.modifierId,
      modifierValue: modifiersForm?.getFieldValue(item) ?? '',
    };

    // No existing state modifier present in the application data case.
    if (stateModifierIndex === -1) {
      carrierSpecificModifiersPayload.push(carrierModifierEntry);

      applicationModifiersData?.push({
        state: state as USState,
        carrierSpecificModifiers: carrierSpecificModifiersPayload,
      });
    } else {
      // State Modifier entry present in the application data case.
      // To filter out the non existing items in the form from the payload of the already existing application data modifiers.
      applicationModifiersData[stateModifierIndex].carrierSpecificModifiers = applicationModifiersData[
        stateModifierIndex
      ]?.carrierSpecificModifiers?.filter((entry) =>
        Object.keys(modifiersFormFields)?.includes(`${state}#${entry.modifierId}`),
      );

      const existingModifiers = applicationModifiersData[stateModifierIndex]?.carrierSpecificModifiers ?? [];
      const existingModifierEntryIndex = existingModifiers?.findIndex(
        (entry) =>
          entry?.carrier === carrierModifierEntry.carrier &&
          entry?.policyType === carrierModifierEntry.policyType &&
          entry?.modifierId === carrierModifierEntry.modifierId,
      );

      if (existingModifierEntryIndex === -1) {
        applicationModifiersData[stateModifierIndex].carrierSpecificModifiers?.push(carrierModifierEntry);
      } else {
        applicationModifiersData[stateModifierIndex].carrierSpecificModifiers![existingModifierEntryIndex] =
          carrierModifierEntry;
      }
    }
  });

  applicationModifiersData?.forEach((modifierData) => {
    if (modifierData?.carrierSpecificModifiers) {
      modifierData.carrierSpecificModifiers =
        modifierData?.carrierSpecificModifiers?.filter(
          (carrierSpecificModifier) => carrierSpecificModifier.modifierValue !== '',
        ) ?? [];
    }
  });

  return {
    ...applicationDataClone,
    applicationId: applicationDataClone?.applicationId!,
    modifiers: [...applicationModifiersData],
  };
};

export const getModifierQuestionType = (modifierValidationRules: IModifierValueValidationRules) => {
  if (
    modifierValidationRules?.allowedRangeOfValues &&
    Object.keys(modifierValidationRules?.allowedRangeOfValues)?.length > 0
  ) {
    return ANTD_QUESTION_TYPE.NUMBER;
  }

  if (modifierValidationRules?.allowedValues && modifierValidationRules?.allowedValues?.length > 0) {
    return ANTD_QUESTION_TYPE.DROPDOWN;
  }

  return ANTD_QUESTION_TYPE.STRING;
};

export const checkExpModsIncompatibility = (carrierModifierConfig: ICarrierSpecificModifierDetail) => {
  const carrierSpecificModifierState = carrierModifierConfig?.state;
  const isExpModsNotRequired = carrierModifierConfig?.incompatibleModifiers?.includes(CFModifierID.EXPERIENCE_MODIFIER);

  if (isExpModsNotRequired) {
    const { applicationData } = useApplicationStore.getState();
    const selectedModifier = applicationData?.modifiers?.find(
      (modifier) => modifier.state === carrierSpecificModifierState,
    );

    if (selectedModifier?.experienceModifiers) {
      return Object.keys(selectedModifier.experienceModifiers).length > 0;
    }
    return false;
  }
  return false;
};

export const generateFormConfigFromModifiersData = (carrierModifiersConfig?: ICarrierSpecificModifierDetail[]) => {
  let generatedModifiersFormConfig = [];

  generatedModifiersFormConfig =
    carrierModifiersConfig?.map((carrierModifierConfig) => {
      // Note: This check is because the exp mods are taken before quote, hence while generating the form config we can skip the entries which have incompatible modifiers as EXPERIENCE_MODIFIER.
      const questionConfig = {} as IApplicationQuestion;

      questionConfig.type = getModifierQuestionType(carrierModifierConfig?.modifierValueValidationRules);
      questionConfig.name = carrierModifierConfig?.modifierDescription;
      questionConfig.dataIndex = `${carrierModifierConfig.state}#${carrierModifierConfig?.modifierId}`;
      questionConfig.disabled = checkExpModsIncompatibility(carrierModifierConfig);
      questionConfig.tooltip = checkExpModsIncompatibility(carrierModifierConfig)
        ? `Please remove ExpMods data for state ${carrierModifierConfig?.state} in order to update this field`
        : '';
      switch (questionConfig.type) {
        case ANTD_QUESTION_TYPE.NUMBER:
          {
            const regexValidation = new RegExp(
              carrierModifierConfig?.modifierValueValidationRules?.regexValidation ?? '',
            );

            questionConfig.placeholder = `Enter between ${carrierModifierConfig?.modifierValueValidationRules?.allowedRangeOfValues?.minValue} to ${carrierModifierConfig?.modifierValueValidationRules?.allowedRangeOfValues?.maxValue}`;
            questionConfig.allowDecimals = true;
            questionConfig.rules = [
              {
                message: carrierModifierConfig?.modifierValueValidationRules?.validationMessage,
                validator: (_: any, value: any) => {
                  // As all the fields are optional by default.
                  if (!value?.trim()) {
                    return Promise.resolve();
                  }

                  const valueNumber = parseFloat(value);

                  if (
                    isNaN(valueNumber) ||
                    valueNumber <
                      carrierModifierConfig?.modifierValueValidationRules?.allowedRangeOfValues?.minValue! ||
                    valueNumber > carrierModifierConfig?.modifierValueValidationRules?.allowedRangeOfValues?.maxValue!
                  ) {
                    return Promise.reject(
                      new Error(carrierModifierConfig?.modifierValueValidationRules?.validationMessage),
                    );
                  }

                  if (!regexValidation.test(value)) {
                    return Promise.reject(
                      new Error(carrierModifierConfig?.modifierValueValidationRules?.validationMessage),
                    );
                  }

                  return Promise.resolve();
                },
              },
            ];
          }
          break;

        case ANTD_QUESTION_TYPE.DROPDOWN:
          questionConfig.placeholder = 'Select from the options';
          questionConfig.options = carrierModifierConfig?.modifierValueValidationRules?.allowedValues?.map((item) => {
            return {
              label: item.toString(),
              value: item.toString(),
            };
          });
          break;

        case ANTD_QUESTION_TYPE.STRING:
          questionConfig.placeholder = 'Enter here';
          break;
      }

      return questionConfig;
    }) ?? [];

  return generatedModifiersFormConfig;
};

export const getModifiersUniqueStates = (carrierModifiersConfig: ICarrierSpecificModifierDetail[]) => {
  const uniqueStates = new Set();
  carrierModifiersConfig?.forEach((carrierModifierConfig) => uniqueStates.add(carrierModifierConfig.state.toString()));
  return Array.from(uniqueStates) as string[];
};

export const generateModifiersStateStepsConfig = (carrierModifiersConfig: ICarrierSpecificModifierDetail[]) => {
  const modifiersStateStepsConfig: Array<{ key: string; title: string }> = [];

  getModifiersUniqueStates(carrierModifiersConfig)?.forEach((state) =>
    modifiersStateStepsConfig.push({ key: state, title: state }),
  );

  return modifiersStateStepsConfig;
};

export const getGroupedModifierFormData = (carrierModifiersConfig: ICarrierSpecificModifierDetail[]) => {
  const uniqueStates = getModifiersUniqueStates(carrierModifiersConfig) ?? [];
  const { modifiersFormConfig } = useModifiersStore.getState();
  const groupedConfig = {} as any;

  uniqueStates.forEach((state) => {
    const filteredModifiersFormConfig =
      modifiersFormConfig?.filter((item) => getStateAndModifierIdFromDataIndex(item?.dataIndex)?.state === state) ?? [];
    groupedConfig[state] = filteredModifiersFormConfig;
  });

  return groupedConfig;
};

export const getStateAndModifierIdFromDataIndex = (dataIndex?: string) => {
  return {
    state: dataIndex?.split('#')?.[0] ?? '',
    modifierId: dataIndex?.split('#')?.[1] ?? '',
  };
};
