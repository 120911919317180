import { useApplicationStore } from '../../pages/v2/application/store';
import { uniq } from '../../utils/lodash';
import { replaceSubstring } from '../../utils/replaceSubString';
import { useQuotesStore } from '../quotes/store';
import { OwnerOfficerMessageType, OWNERS_OFFICERS_MESSAGE_REPLACE_PATTERNS } from './constants';
import officerRulesObject from './officersOwnersRules.json';

export const getOwnersOfficerRules = () => {
  const { applicationData } = useApplicationStore.getState();

  const states = uniq(applicationData?.locationDetails?.map((location) => location?.address?.state) || []);

  const messages = getOwnersOfficerMessages({
    states,
    legalEntity: applicationData?.companyStructure?.legalEntityType || '',
    messageType: OwnerOfficerMessageType.QUOTE,
  });

  return Object.keys(messages)
    ?.map((item) => {
      return getDisplayMessage(item, messages[item]?.states);
    })
    .join('<br/><div class="ant-divider ant-divider-horizontal" style="margin: 8px 0px;"></div>');
};

const getDisplayMessage = (message: string, states: string[]) => {
  const { selectedCarriersInfo } = useApplicationStore.getState();
  const { selectedQuote } = useQuotesStore.getState();
  const selectedCarrier = selectedCarriersInfo.find((item) => item.carrierId === selectedQuote?.carrierId);

  message = selectedQuote?.carrierBridgingLink
    ? message
    : replaceSubstring(message, OWNERS_OFFICERS_MESSAGE_REPLACE_PATTERNS.BRIDGE, '');

  message = message.replace(OWNERS_OFFICERS_MESSAGE_REPLACE_PATTERNS.STATE, states.join(', '));
  return message.replace(
    OWNERS_OFFICERS_MESSAGE_REPLACE_PATTERNS.CARRIER_PORTAL,
    `<a href="${selectedQuote?.carrierBridgingLink}" target='_blank'>${selectedCarrier?.carrierShortName} Agent Portal</a>`,
  );
};

const getQuoteIncludeExcludeMessage = ({
  type,
  messageType,
}: {
  type: 'include' | 'exclude';
  messageType: OwnerOfficerMessageType;
}) => {
  if (type === 'include') {
    return messageType === OwnerOfficerMessageType.QUOTE
      ? 'In {state} - Owners/Officers coverage is included in the quote. Please ensure officer payroll has been quoted. To exclude Owners/Officers coverage you can bridge over to the {carrierName} Agent Portal'
      : 'I acknowledge the Owners/Officers coverage is included in {state} for the quote that I am about to Bind.';
  }
  return messageType === OwnerOfficerMessageType.QUOTE
    ? 'In {state} - Owners/Officers coverage is excluded in the quote. To include Owners/Officers coverage you can bridge over to the {carrierName} Agent Portal'
    : 'I acknowledge the Owners/Officers coverage is excluded in {state} for the quote that I am about to Bind.';
};

export const getOwnersOfficerMessages = ({
  states,
  legalEntity,
  messageType,
}: {
  states: string[];
  legalEntity: string;
  messageType: OwnerOfficerMessageType;
}): {
  [k: string]: {
    states: string[];
  };
} => {
  const messages: {
    [k: string]: {
      states: string[];
    };
  } = {};
  if (!Array.isArray(states) || states.length <= 0 || !legalEntity) {
    return messages;
  }
  const officerRules = JSON.parse(JSON.stringify(officerRulesObject));
  const includeMessage = getQuoteIncludeExcludeMessage({ type: 'include', messageType });
  const excludeMessage = getQuoteIncludeExcludeMessage({ type: 'exclude', messageType });

  states.forEach((state) => {
    const rule = officerRules[legalEntity]?.[state];
    if (
      (messageType === OwnerOfficerMessageType.QUOTE && rule?.quoteMessage) ||
      (messageType === OwnerOfficerMessageType.BIND && rule?.bindMessage)
    ) {
      const message = messageType === OwnerOfficerMessageType.QUOTE ? rule?.quoteMessage : rule?.bindMessage;
      if (messages[message]?.states) {
        messages[message].states.push(state);
      } else {
        messages[message] = { states: [state] };
      }
    } else if (rule?.included) {
      if (messages[includeMessage]?.states) {
        messages[includeMessage].states.push(state);
      } else {
        messages[includeMessage] = { states: [state] };
      }
    } else if (officerRules[legalEntity]?.[state]?.excluded) {
      if (messages[excludeMessage]?.states) {
        messages[excludeMessage].states.push(state);
      } else {
        messages[excludeMessage] = { states: [state] };
      }
    }
  });

  return messages;
};
