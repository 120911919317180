import { Dropdown } from 'antd';
import { useMemo } from 'react';

import { ICFDropdownProps } from './interface';
import { CFDropdownStyled } from './style';

const CFDropDown = (props: ICFDropdownProps) => {
  const { id, getPopupContainer: customGetPopupContainer, overlay, children } = props;

  //wrapper area for dropdown
  const getPopupContainer = useMemo(() => {
    return customGetPopupContainer ? customGetPopupContainer : () => document.getElementById(props.id) || document.body;
  }, [customGetPopupContainer, props.id]);

  return (
    <CFDropdownStyled onClick={(e) => e.stopPropagation()} id={id}>
      <Dropdown {...props} getPopupContainer={getPopupContainer} overlay={overlay}>
        {children ?? <></>}
      </Dropdown>
    </CFDropdownStyled>
  );
};

export default CFDropDown;
