import { Carrier, PolicyType } from '@coverforce-platform/cf-common-types';

import { isOwnerOfficersEnabled } from '../../pages/v2/application/utils';

export const INDUSTRY_SELECTION_ACTIONS = {
  SET_INDUSTRY_SELECTION_FORM: 'SET_INDUSTRY_SELECTION_FORM',
  SET_INDUSTRY_SEARCH_TEXT: 'SET_INDUSTRY_SEARCH_TEXT',
  CLEAR_INDUSTRY_SELECTION: 'CLEAR_INDUSTRY_SELECTION',
  ANONYMOUS_ACTION: 'INDUSTRY_SELECTION_ACTION',
  FETCHING_NAICS_CODES: 'FETCHING_NAICS_CODES',
  FETCHED_NAICS_CODES: 'FETCHED_NAICS_CODES',
  NAICS_CODES_ERROR: 'NAICS_CODES_ERROR',
  SET_INDUSTRY_CLASS_CODES: 'SET_INDUSTRY_CLASS_CODES',
  FETCHING_INDUSTRY_OPTIONS: 'FETCHING_INDUSTRY_OPTIONS',
  FETCHED_INDUSTRY_OPTIONS: 'FETCHED_INDUSTRY_OPTIONS',
  INDUSTRY_OPTIONS_ERROR: 'INDUSTRY_OPTIONS_ERROR',
  SET_CARRIER_INDUSTRY_SELECTIONS: 'SET_CARRIER_INDUSTRY_SELECTIONS',
  STORE_NAME: 'Industry Selection',
  SET_ACTIONABLE_CLASS_CODE: 'SET_ACTIONABLE_CLASS_CODE',
  SET_SECONDARY_CARRIER_INDUSTRY: 'SET_SECONDARY_CARRIER_INDUSTRY',
};

export const ERROR_MSGS = {
  SELECT_INDUSTRY_CODES: 'Please select all industry codes',
};

// TODO: move this to growthbook
export const ENABLED_INDUSTRY_CARRIERS_BY_POLICY = (policyType: PolicyType): Carrier[] => {
  switch (policyType) {
    case PolicyType.WC: {
      return isOwnerOfficersEnabled() ? [Carrier.CHUBB, Carrier.CNA, Carrier.TRAVELERS] : [Carrier.CHUBB, Carrier.CNA];
    }
    case PolicyType.BOP: {
      return [
        Carrier.CHUBB,
        Carrier.LIBERTYMUTUAL,
        Carrier.AMTRUST,
        Carrier.CNA,
        Carrier.GAIG,
        Carrier.NATIONWIDE,
        Carrier.MERCHANTS,
        Carrier.COTERIE,
      ];
    }
    case PolicyType.CGL: {
      return [
        Carrier.CHUBB,
        Carrier.LIBERTYMUTUAL,
        Carrier.AMTRUST,
        Carrier.NATIONWIDE,
        Carrier.CNA,
        Carrier.MERCHANTS,
        Carrier.COTERIE,
      ];
    }
    case PolicyType.CYBER: {
      return [Carrier.CHUBB, Carrier.COALITION, Carrier.COALITION_SURPLUS];
    }
    default: {
      return [];
    }
  }
};

export const SECONDARY_CLASS_CODE = {
  ENABLED_POLICIES: [PolicyType.BOP, PolicyType.CGL],
  ENABLED_CARRIERS: [Carrier.LIBERTYMUTUAL],
};

export const BLANK_CELLS = [undefined, ''];

export const INDUSTRY_SELECTION_QUESTIONS = [
  {
    type: 'dropdown',
    possibleAnswers: [],
    name: 'NAICS code',
    dataIndex: 'naicsCode',
    rules: [{ required: true, message: `Please select NAICS code.` }],
  },
];

export const SECONDARY_CLASS_CODE_NOT_ALLOWED_ERROR =
  'This class code cannot be selected as an additional class code. Please select another class code';

export const SECONDARY_CODE_IDENTIFIER = '#Secondary';
export const SECONDARY_CODE_KEY = 'secondary#code';

export const GA_CATEGORY = {
  INDUSTRY_SELECTION_TAB_HEADER: 'industrySelectionTab_header',
  INDUSTRY_SELECTION_TAB_TABLE: 'industrySelectionTab_table',
  INDUSTRY_SELECTION_TAB_FIND_NAICS_BY_SIC_MODAL: 'industrySelectionTab_findNaicsBySicModal',
  INDUSTRY_SELECTION_TAB_SELECT_INDUSTRY_MODAL: 'industrySelectionTab_selectIndustryCodeModal',
  INDUSTRY_SELECTION_TAB_DELETE_CARRIER_MODAL: 'industrySelectionTab_deleteCarrierModal',
};

export const GA_ACTION = {
  FIND_BY_SIC_BTN_CLICK: 'findBySicBtn_click',
  FIND_NAICS_BY_SIC_CLOSE_BTN_CLICK: 'findNaicsBySicCloseBtn_click',
  SELECT_NAICS_BTN_CLICK: 'selectNaicsBtn_click',
  SELECT_INDUSTRY_BTN_CLICK: 'selectIndustryBtn_click',
  DELETE_INDUSTRY_BTN_CLICK: 'deleteIndustryBtn_click',
  SELECT_INDUSTRY_CODE_CLOSE_BTN_CLICK: 'selectIndustryCodeCloseBtn_click',
  CLOSE_DELETE_MODAL: 'closeDeleteModalBtn_click',
};

export const GA_LABEL = {
  FIND_BY_SIC_BTN_CLICK: 'Find by sic button click.',
  CLOSE_MODAL: 'Close industry code modal',
  SELECT_NAICS_BTN_CLICK: 'Select naics code',
  SELECT_INDUSTRY_BTN_CLICK: 'Select industry button click',
  DELETE_INDUSTRY_BTN_CLICK: 'Delete industry button click',
};
