import { REQUEST_COMMON_HEADERS } from '../common/constants';
import { IAxiosRequestConfig, IRequestHeaders } from '../common/interface';

export const getRequestHeaders = (config: IAxiosRequestConfig): IRequestHeaders => {
  const { headers: configHeaders = {} } = config;
  // TO-DO:- Revert to strongly typed approach and remove "any"
  // const commonHeaders: IRequestCommonHeaders = REQUEST_COMMON_HEADERS();
  const commonHeaders: any = REQUEST_COMMON_HEADERS();
  return { ...commonHeaders, ...configHeaders };
};
