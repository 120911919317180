import { AgentPortalUserType } from '@coverforce-platform/cf-common-types';
import ReactGA from 'react-ga4';

import { useConfigurationStore } from '../components/configuration/store';
import { IGAEventInput } from '../globalInterface';
import { REACT_APP_GA_KEY } from '../helpers/EnvironmentVariables';

export const initialiseGoogleAnalytics = () => {
  if (REACT_APP_GA_KEY) {
    ReactGA.initialize(REACT_APP_GA_KEY);
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
  }
};
export const pushEvent = (props: IGAEventInput) => {
  const { userType } = useConfigurationStore.getState();
  userType === AgentPortalUserType.AGENCY && ReactGA.event(props);
};

export const setGA = (fieldsObject: any) => {
  ReactGA.set(fieldsObject);
};
