import { Collapse } from 'antd';
import styled from 'styled-components';

export const CollapseWrapperStyled = styled.div`
  .ant-collapse .ant-collapse-header {
    align-items: center;
  }

  // span.ant-collapse-header-text {
  //   flex: 1 !important;
  // }

  // .ant-collapse-expand-icon {
  //   cursor: pointer !important;
  // }
`;

export const CollapseStyled = styled(Collapse)``;
