import {
  IAddLocationInput,
  IAddLocationOutput,
  IDeleteLocationInput,
  IGetJobCodesByStateInput,
  IGetJobCodesByStateOutput,
  IGetNcciInternalCodeByCarrierInput,
  INcciCarrierDetails,
  IUpdateLocationInput,
  IUpdateLocationOutput,
} from '@coverforce-platform/cf-common-api-model';
import { Carrier } from '@coverforce-platform/cf-common-types';
import { AxiosResponse } from 'axios';

import axiosApplicationHelperService from '../../axios/applicationHelperService/instance';
import axiosApplicationService from '../../axios/applicationService/instance';
import { IAntdOptions } from '../../globalInterface';
import { createQueryUrl } from '../../utils/createQueryUrl';
import { sortBy } from '../../utils/lodash';
import { APPLICATION_SERVICE_ENDPOINTS, HELPER_SERVICE_ENDPOINTS } from '../helpers/ApiRoutes';

export const getJobCodesByState = async (param: IGetJobCodesByStateInput): Promise<IGetJobCodesByStateOutput> => {
  const apiUrl = createQueryUrl({
    url: HELPER_SERVICE_ENDPOINTS.GET_JOB_CODES_BY_STATE,
  });

  try {
    const response: AxiosResponse = await axiosApplicationHelperService.post(apiUrl, param);
    response.data.jobCodesByState = sortBy(response?.data?.jobCodesByState || [], 'jobCode');
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const getInternalClassCodeByCarrier = async (
  params: IGetNcciInternalCodeByCarrierInput,
): Promise<IAntdOptions[]> => {
  const apiUrl = createQueryUrl({
    url: HELPER_SERVICE_ENDPOINTS.GET_NCCI_CODE_BY_CARRIERS,
  });

  try {
    const response: AxiosResponse = await axiosApplicationHelperService.post(apiUrl, params);
    const internalClassCodes = response?.data?.carrierDetails.map((carrierDetail: INcciCarrierDetails) => ({
      // Carrier specific service data changes
      label:
        carrierDetail?.carrier !== Carrier.GAIG
          ? `${carrierDetail?.descriptionId} - ${carrierDetail?.description}`
          : carrierDetail?.description,
      value:
        carrierDetail?.carrier !== Carrier?.GAIG
          ? `${carrierDetail?.descriptionId} - ${carrierDetail?.description}`
          : carrierDetail?.descriptionId,
    }));
    return internalClassCodes;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const addLocation = async (params: IAddLocationInput): Promise<IAddLocationOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.ADD_LOCATION,
  });
  try {
    const response: AxiosResponse = await axiosApplicationService.post(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const updateLocation = async (params: IUpdateLocationInput): Promise<IUpdateLocationOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.UPDATE_LOCATION,
  });
  try {
    const response: AxiosResponse = await axiosApplicationService.put(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const deleteLocation = async (params: IDeleteLocationInput): Promise<void> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.DELETE_LOCATION(params),
  });
  try {
    await axiosApplicationService.delete(apiUrl);
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};
