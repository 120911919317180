import { Carrier, ModificationStatus } from '@coverforce-platform/cf-common-types';

import { IExperienceModifiersStore } from './interface';

export const EXP_MODS_ACTIONS = {
  SET_EXP_MODS_FORM: 'SET_EXP_MODS_FORM',
  SET_EXP_MODS_DATA: 'SET_EXP_MODS_DATA',
  CLEAR_EXP_MODS: 'CLEAR_EXP_MODS',
  ANONYMOUS_ACTION: 'EXP_MODS_ACTION',
  STORE_NAME: 'Experience Modifiers V2',
  UPDATE_EXP_MODS_STORE_BY_KEY: 'UPDATE_EXP_MODS_STORE_BY_KEY',
};

export const MODIFICATION_STATUSES = [
  { label: 'Preliminary', value: ModificationStatus.PRELIMINARY },
  { label: 'Tentative', value: ModificationStatus.TENTATIVE },
  { label: 'Actual/Final', value: ModificationStatus.ACTUAL },
  { label: 'Contingent', value: ModificationStatus.CONTINGENT },
  { label: 'Prior', value: ModificationStatus.PRIOR },
];

export const NOT_SUPPORTED_EXP_MOD_CARRIERS = [Carrier.LIBERTYMUTUAL];

export const RISK_ID_TOOLTIPS = {
  DEFAULT: 'The risk Id is assigned by the NCCI or Independent State Bureau.',
  WARNING: 'Certain carriers require this to add ExpMods.',
};

export const MODIFIER_RULES = {
  rating: {
    min: 0.001,
    max: 9.999,
  },
};

export const EXP_MOD_KEYS = {
  STATE: 'state',
  RATING: 'rating',
  STATUS: 'status',
  RISK_ID: 'riskId',
};

export const MODIFIER_BUTTON_TEXTS = {
  ADD: 'Add Experience Modifiers',
  DELETE: 'Delete Experience Modifiers',
};

export const EXP_MODS_STORE_KEYS: { [key: string]: keyof IExperienceModifiersStore } = {
  SHOW_RISK_ID_TOOLTIP: 'showExpModRiskIdTooltip',
  SHOW_INCOMPATIBLE_MODS_TOOLTIP: 'showExpModIncompatibleTooltip',
  IS_MODIFIERS_ADDED: 'isModifiersAdded',
  EXP_MODS_LOADING: 'experienceModifiersLoading',
  RESET_INCOMPATIBLE_MODIFIERS: 'resetIncompatibleModifiers',
};

export const GA_CATEGORY = {
  EXPERIENCE_MODIFIER_TAB_FOOTER: 'experienceModifierTab_footer',
};

export const GA_ACTION = {
  ADD_EXPERIENCE_MODIFIER_BTN_CLICK: 'addExperienceModifierBtn_click',
  DELETE_EXPERIENCE_MODIFIER_BTN_CLICK: 'deleteExperienceModifierBtn_click',
};

export const GA_LABEL = {
  ADD_EXPERIENCE_MODIFIER: 'Add experience modifier',
  DELETE_EXPERIENCE_MODIFIER: 'Delete experience modifier',
};
