import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import { BUTTON_TYPE } from '../../../globalConstants';
import ErrorRetry from '../../errorRetry/errorRetry';
import {
  DeleteDocumentButtonStyled,
  DownloadDocumentButtonStyled,
  EmptyMessageStyled,
  ExclaimationMarkStyled,
  IconWrapper,
  ModalContentWrapper,
  ViewActionButtonWrapperStyled,
} from './styles';

export const getDeleteApplicationFileModalContent = () => {
  return (
    <ModalContentWrapper>
      <IconWrapper>
        <ExclaimationMarkStyled />
      </IconWrapper>
      Are you sure you want to delete this file? This action is irreversible.
    </ModalContentWrapper>
  );
};

export const getEmptyAttachmentsMessage = () => {
  return <EmptyMessageStyled>No documents are present for this application.</EmptyMessageStyled>;
};

export const getErrorTextMessage = (error: any, onErrorRetry: () => void) => {
  return <ErrorRetry errorText={error?.[0]?.errorMessage} onRetry={onErrorRetry} />;
};

export const getViewApplicationActionButtons = (
  handleDownloadDocumentClick: (event: React.ChangeEvent<HTMLInputElement>, data: any) => void,
  handleDeleteDocumentClick: (event: React.ChangeEvent<HTMLInputElement>, data: any) => void,
) => {
  const getActionButton = (value: any, record: any) => {
    return (
      <ViewActionButtonWrapperStyled>
        <Tooltip placement='top' title='Download File'>
          <DownloadDocumentButtonStyled
            id='btn_delete_claim'
            data={record}
            onClick={handleDownloadDocumentClick}
            buttonType={BUTTON_TYPE.SECONDARY}
            icon={<DownloadOutlined />}
          ></DownloadDocumentButtonStyled>
        </Tooltip>
        <Tooltip placement='top' title='Delete File '>
          <DeleteDocumentButtonStyled
            id='btn_delete_claim'
            data={record}
            onClick={handleDeleteDocumentClick}
            buttonType={BUTTON_TYPE.DELETE}
            icon={<DeleteOutlined />}
          ></DeleteDocumentButtonStyled>
        </Tooltip>
      </ViewActionButtonWrapperStyled>
    );
  };
  return getActionButton;
};
