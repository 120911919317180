import { INotificationsStore } from './interface';

export const NOTIFICATIONS_STORE_METADATA = {
  NAME: 'Notifications Store',
  ANONYMOUS_ACTION: 'notifications',
  NOTIFICATIONS_LOADING: 'NOTIFICATIONS_LOADING',
  NOTIFICATIONS_SUCCESS: 'NOTIFICATIONS_SUCCESS',
  NOTIFICATIONS_ERROR: 'NOTIFICATIONS_ERROR',
  CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
  UPDATE_NOTIFICATIONS_STORE_BY_KEY: 'UPDATE_NOTIFICATIONS_STORE_BY_KEY',
};

export const NOTIFICATIONS_STORE_KEYS: { [key: string]: keyof INotificationsStore } = {
  NOTIFICATIONS_DATA: 'notificationsData',
};
