import { IModifiers } from '@coverforce-platform/cf-common-api-model';
import { CFModifierID } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { useApplicationStore } from '../../pages/v2/application/store';
import { IFormInstance } from '../../ui-core/V2/cfForm/cfForm';
import { useModifiersStore } from '../modals/modifiers/store';
import { EXP_MODS_ACTIONS, EXP_MODS_STORE_KEYS } from './constants';
import { IExperienceModifiersStore, IModifiersData } from './interface';

export const useExperienceModifiersStore = create<IExperienceModifiersStore>()(
  devtools(
    (set, get) => ({
      experienceModifiersForm: undefined,
      experienceModifiersData: [],
      isModifiersAdded: undefined,
      showExpModRiskIdTooltip: false,
      showExpModIncompatibleTooltip: false,
      experienceModifiersLoading: false,
      resetIncompatibleModifiers: false,

      setExperienceModifiersForm: (experienceModifiersForm: IFormInstance) => {
        set({ experienceModifiersForm }, false, EXP_MODS_ACTIONS.SET_EXP_MODS_FORM);
      },

      setExperienceModifiersData: (experienceModifiersData: IModifiersData[]) => {
        set({ experienceModifiersData }, false, EXP_MODS_ACTIONS.SET_EXP_MODS_DATA);
      },

      updateExpModsByKey: (storeKey, value) => {
        set({ [storeKey]: value }, false, EXP_MODS_ACTIONS.UPDATE_EXP_MODS_STORE_BY_KEY);
      },

      autoFillExperienceModifiersData: () => {
        const { applicationData } = useApplicationStore.getState();
        const { setExperienceModifiersData, experienceModifiersForm } = get();

        const states = [...new Set((applicationData?.locationDetails || [])?.map((el) => el.address?.state))];

        let formData: any;
        states?.forEach((state) => {
          const data = applicationData?.modifiers?.find((el) => el.state === state);
          formData = {
            ...formData,
            [`rating.${state}`]: data?.experienceModifiers?.modificationValue,
            [`status.${state}`]: data?.experienceModifiers?.modificationStatus,
            [`riskId.${state}`]: data?.riskId,
          };
        });
        experienceModifiersForm?.setFieldsValue(formData);

        const modifiers = states?.map((state) => {
          const data = applicationData?.modifiers?.find((el) => el.state === state);
          return {
            state,
            rating: data?.experienceModifiers?.modificationValue,
            status: data?.experienceModifiers?.modificationStatus,
            riskId: data?.riskId,
          };
        });

        setExperienceModifiersData(modifiers);
      },

      validateExpModsData: async () => {
        const {
          isModifiersAdded,
          experienceModifiersData,
          experienceModifiersForm,
          showExpModRiskIdTooltip,
          showExpModIncompatibleTooltip,
          updateExpModsByKey,
        } = get();

        await experienceModifiersForm?.validateFields();

        let hasErrors = false;

        // Validate and show error similar to Risk Id where user is prompt and given a warning saying the respective ExpMod update might result in
        // x,y,z modifier reset.
        const { applicationData } = useApplicationStore.getState();
        const modifiersConfigData = useModifiersStore.getState().modifiersConfig;
        const incompatibleModifiersConfig =
          modifiersConfigData?.filter((modifierConfig) =>
            modifierConfig?.incompatibleModifiers?.includes(CFModifierID.EXPERIENCE_MODIFIER),
          ) ?? [];

        if (!showExpModIncompatibleTooltip && incompatibleModifiersConfig?.length > 0) {
          incompatibleModifiersConfig?.forEach((incompatibleModifierConfig) => {
            const incompatibleModifierExpModConfigState = incompatibleModifierConfig?.state;

            const applicationDataStateCarrierSpecificIncompatibleModifier = applicationData?.modifiers
              ?.find((entry) => entry?.state === incompatibleModifierExpModConfigState)
              ?.carrierSpecificModifiers?.find(
                (carrierSpecificModifierEntry) =>
                  carrierSpecificModifierEntry?.modifierId === incompatibleModifierConfig?.modifierId,
              );

            const incompatibleModifiersExpModData = experienceModifiersData?.find(
              (entry) => entry?.state === incompatibleModifierExpModConfigState,
            );
            if (
              incompatibleModifiersExpModData?.rating &&
              applicationDataStateCarrierSpecificIncompatibleModifier?.modifierValue
            ) {
              updateExpModsByKey(EXP_MODS_STORE_KEYS.SHOW_INCOMPATIBLE_MODS_TOOLTIP, true);
              updateExpModsByKey(EXP_MODS_STORE_KEYS.RESET_INCOMPATIBLE_MODIFIERS, true);
              hasErrors = true;
              experienceModifiersForm?.setFields([
                {
                  name: [`rating.${incompatibleModifierExpModConfigState}`],
                  errors: [
                    `Modifier [${incompatibleModifierConfig?.modifierDescription}] will get removed from the application post this state experience modifier update, click Next to continue.`,
                  ],
                },
              ]);
            }
          });
        } else {
          updateExpModsByKey(EXP_MODS_STORE_KEYS.SHOW_INCOMPATIBLE_MODS_TOOLTIP, false);
        }

        const hasEmptyRiskIds = isModifiersAdded
          ? experienceModifiersData.some((item) => {
              return (item.riskId === undefined || item.riskId === '') && item.status && item.rating;
            })
          : false;

        if (!showExpModRiskIdTooltip && hasEmptyRiskIds) {
          updateExpModsByKey(EXP_MODS_STORE_KEYS.SHOW_RISK_ID_TOOLTIP, true);
          experienceModifiersData.forEach((item) => {
            if ((item.riskId === undefined || item.riskId === '') && item.status && item.rating) {
              hasErrors = true;
              experienceModifiersForm?.setFields([
                {
                  name: [`riskId.${item.state}`],
                  errors: ['Optional, click Next to continue.'],
                },
              ]);
            }
          });
        } else {
          updateExpModsByKey(EXP_MODS_STORE_KEYS.SHOW_RISK_ID_TOOLTIP, false);
        }

        if (hasErrors) {
          return false;
        }

        return true;
      },

      getExpModsPayload: () => {
        const { isModifiersAdded, experienceModifiersData, resetIncompatibleModifiers } = get();
        const { applicationData } = useApplicationStore.getState();

        const modifiers: IModifiers[] = [];

        experienceModifiersData?.forEach((item) => {
          const existingModifierData = applicationData?.modifiers?.find((element) => element.state === item.state);
          if (item.status && item.rating) {
            modifiers.push({
              ...existingModifierData,
              state: item.state,
              experienceModifiers: {
                modificationStatus: item.status,
                modificationValue: Number(item.rating),
              },
              riskId: item.riskId,
            });
          } else {
            modifiers.push({
              ...existingModifierData,
              experienceModifiers: undefined,
              riskId: undefined,
              state: item.state,
            });
          }
        });

        if (!isModifiersAdded) {
          modifiers?.forEach((modifier) => {
            modifier.riskId = undefined;
            modifier.experienceModifiers = undefined;
          });
        }

        // Reset Modifiers from application update payload for which the incompatible modifiers includes EXP MODS.
        if (resetIncompatibleModifiers) {
          const modifiersConfigData = useModifiersStore.getState().modifiersConfig;
          const expModIncompatibleModifierIds =
            modifiersConfigData
              ?.filter((modifierConfig) =>
                modifierConfig?.incompatibleModifiers?.includes(CFModifierID.EXPERIENCE_MODIFIER),
              )
              .map((entry) => entry?.modifierId) ?? [];

          modifiers?.forEach((modifierData) => {
            if (modifierData?.carrierSpecificModifiers) {
              modifierData.carrierSpecificModifiers =
                modifierData?.carrierSpecificModifiers?.filter(
                  (carrierSpecificModifier) =>
                    !expModIncompatibleModifierIds?.includes(carrierSpecificModifier.modifierId),
                ) ?? [];
            }
          });
        }

        return {
          ...applicationData,
          modifiers,
        };
      },

      clearExperienceModifiers: () => {
        const { applicationData } = useApplicationStore.getState();
        const { experienceModifiersForm } = get();

        const states = [...new Set(applicationData?.locationDetails?.map((el) => el.address?.state))];

        let formData: any;
        states?.forEach((state) => {
          formData = {
            ...formData,
            [`rating.${state}`]: undefined,
            [`status.${state}`]: undefined,
            [`riskId.${state}`]: undefined,
          };
        });
        experienceModifiersForm?.setFieldsValue(formData);

        set(
          {
            experienceModifiersForm: undefined,
            experienceModifiersData: [],
            isModifiersAdded: undefined,
            showExpModRiskIdTooltip: false,
            showExpModIncompatibleTooltip: false,
            experienceModifiersLoading: false,
            resetIncompatibleModifiers: false,
          },
          false,
          EXP_MODS_ACTIONS.CLEAR_EXP_MODS,
        );
      },
    }),
    { anonymousActionType: EXP_MODS_ACTIONS.ANONYMOUS_ACTION, name: EXP_MODS_ACTIONS.STORE_NAME },
  ),
);
