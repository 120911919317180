import { ICFTableProps } from './interface';
import { CFTableStyled } from './styles';

const { Column } = CFTableStyled;

export { Column as CFTableColumn };

const CFTable = (props: ICFTableProps) => {
  const { children, ...restTableProps } = props;

  return <CFTableStyled {...restTableProps}>{children}</CFTableStyled>;
};

export default CFTable;
