import { memo } from 'react';

import { ISVGProps } from './interface';

const DownArrowSvg = (props: ISVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      opacity={props?.isFocus ? 1 : 0.75}
      {...props}
    >
      <path d='m7 10 5 5 5-5H7Z' fillOpacity={0.75} fill={props?.fillColor} />
    </svg>
  );
};

const DownArrowSvgMemo = memo(DownArrowSvg);
export default DownArrowSvgMemo;
