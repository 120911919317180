import { useCallback, useEffect, useState } from 'react';

import { deNormalizeMoneyInput } from '../../../helpers/Utils';
import CFInput from '../cfInput/cfInput';
import { IMoneyInput } from './interface';
import { extractDecimalNumbers, getMoneyValue } from './utils';

const MoneyInput = ({
  data,
  onChange,
  initialValue,
  id,
  style,
  value,
  disabled = false,
  numberOfDecimals = 2,
  decimalAllowed = false,
  ...rest
}: IMoneyInput) => {
  const [money, setMoney] = useState(initialValue);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const val = decimalAllowed
        ? extractDecimalNumbers(event.target.value, numberOfDecimals)
        : deNormalizeMoneyInput(event.target.value);

      setMoney(val);
      onChange && onChange(val, data);
    },
    [data, decimalAllowed, numberOfDecimals, onChange],
  );

  useEffect(() => {
    setMoney(value?.toString());
  }, [value]);

  return (
    <CFInput
      placeholder='$'
      id={id}
      disabled={disabled}
      {...rest}
      style={style}
      value={getMoneyValue({ numberOfDecimals, money, decimalAllowed })}
      onChange={handleChange}
    />
  );
};

export default MoneyInput;
