import { NavItemProps } from './interface';
import {
  BorderBottomStyled,
  IconWrapperStyled,
  NavItemContainerAnchorStyled,
  NavItemContainerDivStyled,
  NavItemContainerStyled,
  NavItemWrapperStyled,
  TitleStyled,
} from './styles';

export const getNavBarItem = ({
  navitemProps,
  theme,
  isHovered,
  handleMouseLeave,
  handleMouseEnter,
}: {
  navitemProps: NavItemProps;
  theme: any;
  isHovered: boolean;
  handleMouseLeave: () => void;
  handleMouseEnter: () => void;
}) => {
  const { id, Icon, title, isActive = false, dropdownMenu, className, style, PostFixIcon, isUser, to } = navitemProps;
  const isFocus = isActive || isHovered;

  return (
    <NavItemContainerStyled id={id}>
      {dropdownMenu === undefined && (
        <NavItemContainerAnchorStyled
          id={`link_${id}`}
          className={className}
          isActive={isActive}
          themeName={theme?.themeName}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={style}
          href={to}
        >
          <>
            <NavItemWrapperStyled isUser={isUser}>
              {Icon && (
                <IconWrapperStyled>
                  <Icon isFocus={isFocus} fillColor={theme?.component?.navBar?.textColor} />
                </IconWrapperStyled>
              )}
              {title && (
                <TitleStyled isFocus={isFocus} title={title}>
                  {title}
                </TitleStyled>
              )}
            </NavItemWrapperStyled>
            {isActive && <BorderBottomStyled />}
          </>
        </NavItemContainerAnchorStyled>
      )}

      {dropdownMenu && (
        <NavItemContainerDivStyled
          id={`div_${id}`}
          className={className}
          isActive={isActive}
          themeName={theme?.themeName}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={style}
        >
          <NavItemWrapperStyled isUser={isUser}>
            <IconWrapperStyled>
              <Icon isFocus={isFocus} fillColor={theme?.component?.navBar?.textColor} />
            </IconWrapperStyled>

            <TitleStyled isFocus={isFocus} title={title}>
              {title}
            </TitleStyled>

            {PostFixIcon && (
              <IconWrapperStyled>
                <PostFixIcon isFocus={isFocus} fillColor={theme?.component?.navBar?.textColor} />
              </IconWrapperStyled>
            )}
          </NavItemWrapperStyled>
          {isActive && <BorderBottomStyled />}
        </NavItemContainerDivStyled>
      )}
    </NavItemContainerStyled>
  );
};
