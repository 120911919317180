import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { useConfigurationStore } from '../configuration/store';
import { NOTIFICATIONS_STORE_METADATA } from './constants';
import { INotificationsStore } from './interface';

export const useNotificationsStore = create<INotificationsStore>()(
  devtools(
    (set) => ({
      isNotificationsLoading: false,
      notificationsData: [],
      notificationsError: undefined,

      fetchNotifications: async (notificationParams = { isNotificationsLoading: true, feedOptions: undefined }) => {
        const { isNotificationsLoading, feedOptions } = notificationParams;
        set({ isNotificationsLoading }, false, NOTIFICATIONS_STORE_METADATA.NOTIFICATIONS_LOADING);
        try {
          const { feedClient } = useConfigurationStore.getState();
          const notifications = await feedClient?.fetch(feedOptions);
          set(
            { notificationsData: notifications?.data?.entries || [], notificationsError: undefined },
            false,
            NOTIFICATIONS_STORE_METADATA.NOTIFICATIONS_SUCCESS,
          );
          return notifications?.data?.entries || [];
        } catch (err) {
          set(
            { notificationsData: [], notificationsError: err },
            false,
            NOTIFICATIONS_STORE_METADATA.NOTIFICATIONS_ERROR,
          );
          return [];
        } finally {
          set({ isNotificationsLoading: false }, false, NOTIFICATIONS_STORE_METADATA.NOTIFICATIONS_LOADING);
        }
      },

      updateNotificationsByKey: async (storeKey: keyof INotificationsStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, NOTIFICATIONS_STORE_METADATA.UPDATE_NOTIFICATIONS_STORE_BY_KEY),

      clearNotifications: async () =>
        set(
          {
            isNotificationsLoading: false,
            notificationsData: [],
            notificationsError: undefined,
          },
          false,
          NOTIFICATIONS_STORE_METADATA.CLEAR_NOTIFICATIONS,
        ),
    }),

    { name: NOTIFICATIONS_STORE_METADATA.NAME, anonymousActionType: NOTIFICATIONS_STORE_METADATA.ANONYMOUS_ACTION },
  ),
);
