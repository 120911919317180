import {
  EmptyNotificationsHeadingStyled,
  EmptyNotificationsMessageStyled,
  EmptyNotificationsWrapperStyled,
} from './styles';

export const EmptyNotifications = () => {
  return (
    <EmptyNotificationsWrapperStyled>
      <EmptyNotificationsHeadingStyled>No notifications yet</EmptyNotificationsHeadingStyled>
      <EmptyNotificationsMessageStyled>
        We'll let you know when we've got something new for you.
      </EmptyNotificationsMessageStyled>
    </EmptyNotificationsWrapperStyled>
  );
};
