export const normalizeCardExpiryDate = (input: string) => {
  return input
    .replace(
      /[^0-9]/g,
      '', // To allow only numbers
    )
    .replace(
      /^([2-9])$/g,
      '0$1', // To handle 3 > 03
    )
    .replace(
      /^(1{1})([3-9]{1})$/g,
      '0$1/$2', // 13 > 01/3
    )
    .replace(
      /^0{1,}/g,
      '0', // To handle 00 > 0
    )
    .replace(
      /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
      '$1/$2', // To handle 113 > 11/3
    );
};

// input example 01/24 (MM/YY)
export const denormalizeCardExpiryDate = (input: string) => {
  const currentValue = input?.slice(0, 5)?.split('/');
  const month = currentValue[0];
  const year = `20${currentValue[1]}`;
  return { month, year };
};

// Basic validation: as we already sanitize the input via normalizeCardExpiryDate
export const isValidCardExpiryDate = (input: string) => {
  // valid example 01/24 (MM/YY)
  if (!input || input?.length !== 5) {
    return false;
  }
  return true;
};
