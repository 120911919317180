import {
  IAddLocationInput,
  IAddress,
  IApplicationDetails,
  IApplicationInfo,
  IPolicyDetail,
  IUpdateLocationInput,
} from '@coverforce-platform/cf-common-api-model';
import { IEmployeeInformation } from '@coverforce-platform/cf-common-api-model/lib/models/applicationsService/common/employeeInformationBase.models';
import {
  ApplicationFieldPathEnum,
  ApplicationFieldRequirementLevel,
  PolicyType,
} from '@coverforce-platform/cf-common-types';
import dayjs, { extend } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { DATE_FORMAT } from '../../globalConstants';
import {
  deNormalizeFeinNumber,
  deNormalizePhoneNumber,
  getIsApplicationNonEditable,
  getPolicyTypeSpecificLiabilityLimits,
  normalizeFeinNumber,
  normalizePhoneNumber,
} from '../../helpers/Utils';
import { useApplicationStore } from '../../pages/v2/application/store';
import {
  getRequirementLevelOfField,
  getVisibleFormElementKeys,
  modifyVisibilityOfFields,
} from '../../pages/v2/application/utils';
import { errorV2 } from '../../ui-core/Notification';
import { IFormInstance } from '../../ui-core/V2/cfForm/cfForm';
import { isMailingAddressSameAsPrimaryAddress } from '../../utils/application/application';
import { getValidDate } from '../../utils/getValidDate';
import { getValidDropdownValue } from '../../utils/getValidDropdownValue';
import { scrollAndFocusFormItemError } from '../../utils/scrollAndFocusFormItemError';
import { IApplicationQuestion } from '../applicationQuestion/interface';
import { useLocationDetailsStore } from '../locationDetails/store';
import { useLocationInputStore } from '../question/locationInputV2/store';
import { validateAddress } from '../question/locationInputV2/utils';
import { AUTOFILL_GQ_KEYS, GQ_FORM_KEY, INVALID_MSG, LEGAL_ENTITY_TYPE } from './constants';
import { GENERAL_QUESTIONS_ELEMENTS } from './generalQuestionsElements';
import { useGeneralQuestionsStore } from './store';

extend(isSameOrAfter);

export const getGeneralQuestionsConfig = () => {
  return modifyVisibilityOfFields(GENERAL_QUESTIONS_ELEMENTS);
};

export const setFormFieldsGeneralQuestion = (applicationData: IApplicationInfo, form?: IFormInstance) => {
  const { generalQuestionsElements } = useGeneralQuestionsStore.getState();
  const legalEntityDropDown = generalQuestionsElements?.find(
    (element: IApplicationQuestion) => element?.dataIndex === LEGAL_ENTITY_TYPE,
  );

  const legalEntityOptions = legalEntityDropDown?.options;

  form?.setFieldsValue({
    businessName: applicationData?.basicBusinessDetails?.businessName,
    dba: applicationData?.basicBusinessDetails?.doingBusinessAs,
    primaryAddress: applicationData?.locationDetails?.find((location) => location.isPrimary)?.address,
    mailingAddress: isMailingAddressSameAsPrimaryAddress(applicationData)
      ? undefined
      : applicationData?.basicBusinessDetails?.mailingAddress,
    mailingAddressSame: isMailingAddressSameAsPrimaryAddress(applicationData) ? 'yes' : 'no',
    businessYearOfFounding: getValidDate(applicationData?.basicBusinessDetails?.yearOfFounding),
    businessWebsite: applicationData?.basicBusinessDetails?.businessWebsite,
    firstName: applicationData?.basicBusinessDetails?.contacts?.length
      ? applicationData?.basicBusinessDetails.contacts[0].firstName
      : undefined,
    lastName: applicationData?.basicBusinessDetails?.contacts?.length
      ? applicationData?.basicBusinessDetails.contacts[0].lastName
      : undefined,
    phone: applicationData?.basicBusinessDetails?.contacts?.length
      ? normalizePhoneNumber(applicationData?.basicBusinessDetails.contacts[0].phone)
      : undefined,
    email: applicationData?.basicBusinessDetails?.contacts?.length
      ? applicationData?.basicBusinessDetails.contacts[0].email
      : undefined,
    insuranceEffectiveDate: getValidDate(applicationData?.applicationDetails?.insuranceEffectiveDate),
    insuranceExpiryDate: getValidDate(applicationData?.applicationDetails?.insuranceExpiryDate),
    fein: normalizeFeinNumber(applicationData?.basicBusinessDetails?.fein),
    yearsOfExperience: applicationData?.basicBusinessDetails?.yearsOfManagementExperience?.toString(),
    annualRevenue: applicationData?.basicBusinessDetails?.annualRevenue,
    legalEntityType: getValidDropdownValue(applicationData?.companyStructure?.legalEntityType, legalEntityOptions),
    isNonProfit:
      applicationData?.basicBusinessDetails?.isNonProfit === true
        ? 'true'
        : applicationData?.basicBusinessDetails?.isNonProfit === false
        ? 'false'
        : undefined,
    natureOfOperations: applicationData?.basicBusinessDetails?.natureOfOperations,
    numberOfFullTimeEmployees: applicationData?.basicBusinessDetails?.employeeInformation?.numberOfFullTimeEmployees,
    numberOfPartTimeEmployees: applicationData?.basicBusinessDetails?.employeeInformation?.numberOfPartTimeEmployees,
    totalEmployeePayroll: applicationData?.basicBusinessDetails?.employeeInformation?.totalEmployeePayroll,
    natureOfConstructionProject: applicationData?.basicBusinessDetails?.natureOfConstructionProject,
  });
};

export const isGeneralQuestionsAnswersValid = async (applicationData?: IApplicationInfo, form?: IFormInstance) => {
  const generalQuestionsAnswer = {
    ...form?.getFieldsValue(),
  };
  const { setShowLocationError } = useLocationInputStore.getState();

  let isGQAnswersValid = true;

  try {
    if (!validateAddress(generalQuestionsAnswer?.primaryAddress)) {
      isGQAnswersValid = false;
      setShowLocationError(true, GQ_FORM_KEY.PRIMARY_ADDRESS);
    }

    if (
      generalQuestionsAnswer.mailingAddressSame === 'no' &&
      !validateAddress(generalQuestionsAnswer?.mailingAddress)
    ) {
      isGQAnswersValid = false;
      setShowLocationError(true, GQ_FORM_KEY.MAILING_ADDRESS);
    }

    if (
      generalQuestionsAnswer.insuranceEffectiveDate &&
      !dayjs(generalQuestionsAnswer.insuranceEffectiveDate).isSameOrAfter(dayjs().format(DATE_FORMAT.YYYYMMDD))
    ) {
      isGQAnswersValid = false;
      errorV2(INVALID_MSG.EFFECTIVE);
    }

    if (
      generalQuestionsAnswer.insuranceExpiryDate &&
      !dayjs(generalQuestionsAnswer.insuranceExpiryDate).isSameOrAfter(dayjs().format(DATE_FORMAT.YYYYMMDD))
    ) {
      isGQAnswersValid = false;
      errorV2(INVALID_MSG.EXPIRY);
    }
    if (getIsApplicationNonEditable(applicationData?.applicationStatus)) {
      isGQAnswersValid = false;
      errorV2(INVALID_MSG.STATUS);
    }

    await form?.validateFields();
  } catch (err) {
    isGQAnswersValid = false;

    const errorFields = await form?.getFieldsError();
    const formErr = errorFields?.find((error: any) => error.errors?.length > 0);
    if (formErr) {
      scrollAndFocusFormItemError(formErr);
    }
  }
  return isGQAnswersValid;
};

export const isGeneralQuestionsAnswersValidV2 = async (applicationData?: IApplicationInfo, form?: IFormInstance) => {
  const { generalQuestionsElements } = useGeneralQuestionsStore.getState();
  const generalQuestionsAnswer = {
    ...form?.getFieldsValue(),
  };
  const { setShowLocationError } = useLocationInputStore.getState();
  const visibleGQFormKeys = getVisibleFormElementKeys(generalQuestionsElements!);

  let isGQAnswersValid = true;
  try {
    if (!validateAddress(generalQuestionsAnswer?.primaryAddress)) {
      isGQAnswersValid = false;
      setShowLocationError(true, GQ_FORM_KEY.PRIMARY_ADDRESS);
    }

    if (
      generalQuestionsAnswer?.mailingAddressSame === 'no' &&
      !validateAddress(generalQuestionsAnswer?.mailingAddress)
    ) {
      isGQAnswersValid = false;
      setShowLocationError(true, GQ_FORM_KEY.MAILING_ADDRESS);
    }

    if (
      generalQuestionsAnswer?.insuranceEffectiveDate &&
      !dayjs(generalQuestionsAnswer?.insuranceEffectiveDate).isSameOrAfter(dayjs().format(DATE_FORMAT.YYYYMMDD))
    ) {
      isGQAnswersValid = false;
      errorV2(INVALID_MSG.EFFECTIVE);
    }

    if (
      getRequirementLevelOfField(ApplicationFieldPathEnum.INSURANCE_EXPIRY_DATE) !==
        ApplicationFieldRequirementLevel.NOT_ACCEPTED &&
      generalQuestionsAnswer?.insuranceExpiryDate &&
      !dayjs(generalQuestionsAnswer?.insuranceExpiryDate)?.isSameOrAfter(dayjs().format(DATE_FORMAT.YYYYMMDD))
    ) {
      isGQAnswersValid = false;
      errorV2(INVALID_MSG.EXPIRY);
    }

    if (getIsApplicationNonEditable(applicationData?.applicationStatus)) {
      isGQAnswersValid = false;
      errorV2(INVALID_MSG.STATUS);
    }
    await form?.validateFields(visibleGQFormKeys);
  } catch (err) {
    isGQAnswersValid = false;

    const errorFields = await form?.getFieldsError();
    const formErr = errorFields?.find((error: any) => error.errors?.length > 0);
    if (formErr) {
      scrollAndFocusFormItemError(formErr);
    }
  }

  return isGQAnswersValid;
};

export const getGQPayload = (form?: IFormInstance) => {
  const { applicationData, policyType } = useApplicationStore.getState();

  const generalQuestionsAnswer = {
    ...form?.getFieldsValue(),
  };

  const payload = {
    ...applicationData,
  } as IApplicationInfo;

  switch (policyType) {
    case PolicyType.WC:
      return getCommonGQPayloadV2(generalQuestionsAnswer, payload);

    case PolicyType.BOP:
    case PolicyType.CGL:
      return {
        ...getAllPoliciesGQPayload(generalQuestionsAnswer, getCommonGQPayloadV2(generalQuestionsAnswer, payload)),
      };

    case PolicyType.CYBER:
      return {
        ...getAllPoliciesGQPayload(generalQuestionsAnswer, getCommonGQPayloadV2(generalQuestionsAnswer, payload)),
      };
    case PolicyType.BR:
      return {
        ...getAllPoliciesGQPayload(generalQuestionsAnswer, getCommonGQPayloadV2(generalQuestionsAnswer, payload)),
      };

    default:
      return {};
  }
};

const getCommonGQPayloadV2 = (generalQuestionsAnswer: any, payload: IApplicationInfo) => {
  payload.policyDetails = payload?.policyDetails?.map((policyDetail) => ({
    policyType: policyDetail?.policyType,
    selectedLiabilityLimits: policyDetail?.selectedLiabilityLimits?.map((liabilityLimit) => {
      const policyTypeSpecificLimits = getPolicyTypeSpecificLiabilityLimits({
        liabilityLimit: liabilityLimit?.limits,
        policyType: payload?.policyDetails?.[0]?.policyType,
      });
      return {
        carrierId: liabilityLimit?.carrierId,
        limits: {
          ...policyTypeSpecificLimits,
        },
      };
    }),
  })) as IPolicyDetail[];

  payload.applicationDetails = {
    ...payload?.applicationDetails,
    insuranceEffectiveDate: generalQuestionsAnswer?.insuranceEffectiveDate?.format(DATE_FORMAT.YYYYMMDD),
    insuranceExpiryDate: generalQuestionsAnswer?.insuranceExpiryDate?.format(DATE_FORMAT.YYYYMMDD),
  } as IApplicationDetails;

  const mailingAddress: IAddress =
    generalQuestionsAnswer?.mailingAddressSame === 'yes'
      ? generalQuestionsAnswer?.primaryAddress
      : generalQuestionsAnswer?.mailingAddress?.addressLine1
      ? generalQuestionsAnswer?.mailingAddress
      : generalQuestionsAnswer?.primaryAddress;

  if (payload.companyStructure) {
    payload.companyStructure = {
      ...payload?.companyStructure,
      legalEntityType: generalQuestionsAnswer?.legalEntityType,
      ownershipDetails: {
        owners:
          generalQuestionsAnswer?.legalEntityType !== payload?.companyStructure?.legalEntityType
            ? []
            : payload?.companyStructure?.ownershipDetails?.owners,
      },
    };
  }

  payload.basicBusinessDetails = {
    ...payload?.basicBusinessDetails,
    yearsOfManagementExperience:
      generalQuestionsAnswer?.yearsOfExperience?.toString()?.trim()?.length > 0
        ? Number(generalQuestionsAnswer?.yearsOfExperience)
        : undefined,
    doingBusinessAs:
      generalQuestionsAnswer?.dba?.toString()?.trim()?.length > 0 ? generalQuestionsAnswer?.dba : undefined,
    annualRevenue:
      generalQuestionsAnswer?.annualRevenue?.toString()?.trim()?.length > 0
        ? Number(generalQuestionsAnswer?.annualRevenue)
        : undefined,
    fein:
      generalQuestionsAnswer?.fein?.toString()?.trim()?.length > 0
        ? deNormalizeFeinNumber(generalQuestionsAnswer?.fein?.toString())
        : undefined,
    businessName: generalQuestionsAnswer?.businessName, // Always required field
    businessWebsite:
      generalQuestionsAnswer?.businessWebsite?.toString()?.trim()?.length > 0
        ? generalQuestionsAnswer?.businessWebsite
        : undefined,
    yearOfFounding: generalQuestionsAnswer?.businessYearOfFounding?.format('YYYY'),
    mailingAddress,
    contacts: [
      {
        firstName:
          generalQuestionsAnswer?.firstName?.toString()?.trim()?.length > 0
            ? generalQuestionsAnswer?.firstName
            : undefined,
        lastName:
          generalQuestionsAnswer?.lastName?.toString()?.trim()?.length > 0
            ? generalQuestionsAnswer?.lastName
            : undefined,
        email:
          generalQuestionsAnswer?.email?.toString()?.trim()?.length > 0 ? generalQuestionsAnswer?.email : undefined,
        isPrimary: true,
        phone:
          generalQuestionsAnswer?.phone?.toString()?.trim()?.length > 0
            ? deNormalizePhoneNumber(generalQuestionsAnswer?.phone)
            : undefined,
      },
    ],
    isNonProfit:
      generalQuestionsAnswer?.isNonProfit !== undefined ? generalQuestionsAnswer?.isNonProfit === 'true' : undefined,
    natureOfOperations:
      generalQuestionsAnswer?.natureOfOperations?.toString()?.trim()?.length > 0
        ? generalQuestionsAnswer?.natureOfOperations
        : undefined,
    natureOfConstructionProject: generalQuestionsAnswer?.natureOfConstructionProject,
  };

  return payload;
};

const getAllPoliciesGQPayload = (generalQuestionsAnswer: any, payload: IApplicationInfo) => {
  let employeeInformation: IEmployeeInformation = {
    numberOfFullTimeEmployees: 0,
    numberOfPartTimeEmployees: 0,
    totalEmployeePayroll: 0,
  };

  employeeInformation = {
    numberOfFullTimeEmployees:
      generalQuestionsAnswer?.numberOfFullTimeEmployees?.toString()?.trim()?.length > 0
        ? Number(generalQuestionsAnswer?.numberOfFullTimeEmployees)
        : undefined,
    numberOfPartTimeEmployees:
      generalQuestionsAnswer?.numberOfPartTimeEmployees?.toString()?.trim()?.length > 0
        ? Number(generalQuestionsAnswer?.numberOfPartTimeEmployees)
        : undefined,
    totalEmployeePayroll:
      generalQuestionsAnswer?.totalEmployeePayroll?.toString()?.trim()?.length > 0
        ? Number(generalQuestionsAnswer?.totalEmployeePayroll)
        : undefined,
  };

  payload.basicBusinessDetails = {
    ...payload?.basicBusinessDetails,
    employeeInformation,
  };

  return payload;
};

export const getGqLocationPayload = (): IUpdateLocationInput | IAddLocationInput => {
  const { applicationData } = useApplicationStore.getState();
  const { generalQuestionsForm } = useGeneralQuestionsStore.getState();
  const { locations } = useLocationDetailsStore.getState();
  const primaryLocationData = locations?.find((location) => location.isPrimary);
  const generalQuestionsAnswer = {
    ...generalQuestionsForm?.getFieldsValue(),
  };
  let gqLocationPayload: IUpdateLocationInput | IAddLocationInput;
  if (primaryLocationData) {
    gqLocationPayload = {
      applicationId: applicationData?.applicationId!,
      locationDetails: {
        locationId: primaryLocationData?.locationId,
        address: generalQuestionsAnswer?.primaryAddress,
        employeeInformation: primaryLocationData?.employeeInformation,
        buildingInformation: primaryLocationData?.buildingInformation,
        locationUWInformation: primaryLocationData?.locationUWInformation,
        isPrimary: true,
      },
    };
  } else {
    gqLocationPayload = {
      applicationId: applicationData?.applicationId!,
      locationDetails: {
        address: generalQuestionsAnswer?.primaryAddress || applicationData?.locationDetails?.[0]?.address,
        locationId: applicationData?.locationDetails?.[0]?.locationId,
        isPrimary: true,
      },
    };
  }
  return gqLocationPayload;
};

export const autoFillCustomerInfoByAccount = (form?: IFormInstance) => {
  const { applicationData, accountData } = useApplicationStore.getState();

  if (applicationData && accountData) {
    AUTOFILL_GQ_KEYS.forEach((key) => {
      switch (key) {
        case 'businessName': {
          if (!applicationData.basicBusinessDetails?.businessName) {
            form?.setFieldValue('businessName', accountData.accountName);
          }
          break;
        }
        case 'primaryAddress': {
          const primaryAddress = applicationData.locationDetails?.find((location) => location.isPrimary);
          if (!primaryAddress) {
            form?.setFieldValue('primaryAddress', accountData.primaryAddress);
          }
          break;
        }
        case 'firstName': {
          if (!applicationData.basicBusinessDetails?.contacts?.[0]?.firstName) {
            form?.setFieldValue('firstName', accountData.primaryContactDetails?.firstName);
          }
          break;
        }
        case 'lastName': {
          if (!applicationData.basicBusinessDetails?.contacts?.[0]?.lastName) {
            form?.setFieldValue('lastName', accountData.primaryContactDetails?.lastName);
          }
          break;
        }
        case 'phone': {
          if (!applicationData.basicBusinessDetails?.contacts?.[0]?.phone) {
            form?.setFieldValue(
              'phone',
              accountData.primaryContactDetails?.phone
                ? normalizePhoneNumber(accountData.primaryContactDetails?.phone)
                : undefined,
            );
          }
          break;
        }

        case 'email': {
          if (!applicationData.basicBusinessDetails?.contacts?.[0]?.email) {
            form?.setFieldValue('email', accountData.primaryContactDetails?.email);
          }
          break;
        }

        default:
          break;
      }
    });
  }
};

export const handleGQFormChange = (value: any, data: any) => {
  const { generalQuestionsForm } = useGeneralQuestionsStore.getState();

  if (data?.dataIndex === 'insuranceEffectiveDate') {
    if (
      dayjs(value)?.isValid() &&
      getRequirementLevelOfField(ApplicationFieldPathEnum.INSURANCE_EXPIRY_DATE) !==
        ApplicationFieldRequirementLevel.NOT_ACCEPTED
    ) {
      generalQuestionsForm?.setFields([
        {
          name: ['insuranceExpiryDate'],
          value: dayjs(value)?.add(1, 'y'),
          errors: [],
        },
      ]);
    } else {
      generalQuestionsForm?.setFields([
        {
          name: ['insuranceExpiryDate'],
          value: undefined,
          errors: [],
        },
      ]);
    }
  }
};
