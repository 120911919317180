import { IAdditionalIndustryCode, ICarrierIndustryCode, ILocationInfo } from '@coverforce-platform/cf-common-api-model';
import { ILMIndustryClassCodeDetails } from '@coverforce-platform/cf-common-api-model/lib/models/applicationHelperService/carrierSpecific/libertyMutual/lmIndustryClassCode.model';
import { Carrier, IndustryCodeCategory, PolicyType } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  getAllIndustryClassCodes,
  getAllNaicsCodes,
  getIndustryClassCodeDetails as requestGetIndustryClassCodeDetails,
} from '../../externalServices/V2/applicationV2';
import { useApplicationStore } from '../../pages/v2/application/store';
import { getLocationFromApplication, getSelectedCarriersFromApplication } from '../../pages/v2/application/utils';
import { optionType } from '../../types';
import { errorV2 } from '../../ui-core/Notification';
import { IFormInstance } from '../../ui-core/V2/cfForm/cfForm';
import { parseIndustryCodesToLabelValue } from '../../utils/parseIndustryCodesToLabelValue';
import { throwCustomApiError } from '../../utils/throwCustomApiError';
import { IApplicationQuestion } from '../applicationQuestion/interface';
import {
  ENABLED_INDUSTRY_CARRIERS_BY_POLICY,
  ERROR_MSGS,
  INDUSTRY_SELECTION_ACTIONS,
  INDUSTRY_SELECTION_QUESTIONS,
  SECONDARY_CLASS_CODE_NOT_ALLOWED_ERROR,
  SECONDARY_CODE_IDENTIFIER,
  SECONDARY_CODE_KEY,
} from './constants';
import { CarrierIndustrySelection, IIndustryCode, IIndustrySelectionStore } from './interface';
import {
  autoFillIndustrySelection,
  getCarrierId,
  getUpdatedCarrierIndustrySelections,
  isValidCarrierIndustrySelectionsDetails,
  parseToNaicsSicMapping,
} from './utils';

export const useIndustrySelectionStore = create<IIndustrySelectionStore>()(
  devtools(
    (set, get) => ({
      industrySelectionForm: undefined,
      industrySelectionQuestions: INDUSTRY_SELECTION_QUESTIONS,
      naicsCodesLoading: false,
      naicsCodes: [],
      naicsCodesError: undefined,
      naicsSicMapping: [],
      industryOptionsLoading: false,
      industryOptions: { [IndustryCodeCategory.PRIMARY]: {}, [IndustryCodeCategory.SECONDARY]: {} },
      industryOptionsError: undefined,
      carrierIndustrySelections: [],
      industrySearchText: '',
      actionableClassCode: undefined,

      setActionableClassCode: (actionableClassCode) => {
        set({ actionableClassCode }, false, INDUSTRY_SELECTION_ACTIONS.SET_ACTIONABLE_CLASS_CODE);
      },
      setIndustrySelectionForm: (formObj: IFormInstance) => {
        set({ industrySelectionForm: formObj }, false, INDUSTRY_SELECTION_ACTIONS.SET_INDUSTRY_SELECTION_FORM);
      },

      fetchAllNaicsCodes: async (loading = true) => {
        const industrySelectionQuestion: IApplicationQuestion[] = [...INDUSTRY_SELECTION_QUESTIONS];
        set({ naicsCodesLoading: loading }, false, INDUSTRY_SELECTION_ACTIONS.FETCHING_NAICS_CODES);

        try {
          const naicsInfoResponse = await getAllNaicsCodes();
          const naicsCodes: optionType[] = parseIndustryCodesToLabelValue({
            naicsCodeInfos: naicsInfoResponse?.naicsCodeInfos || [],
            isValueNaicsExtendedId: true,
          });

          const naicsCodeDropDownIndex = industrySelectionQuestion.findIndex(
            (el: any) => el.dataIndex === INDUSTRY_SELECTION_QUESTIONS[0].dataIndex,
          );
          industrySelectionQuestion[naicsCodeDropDownIndex] = {
            ...industrySelectionQuestion[naicsCodeDropDownIndex],
            options: naicsCodes,
          };

          set(
            {
              naicsCodes,
              naicsSicMapping: parseToNaicsSicMapping(naicsInfoResponse?.naicsCodeInfos || []),
              naicsCodesLoading: false,
              naicsCodesError: undefined,
              industrySelectionQuestions: industrySelectionQuestion,
            },
            false,
            INDUSTRY_SELECTION_ACTIONS.FETCHED_NAICS_CODES,
          );
        } catch (error) {
          set(
            {
              naicsCodes: [],
              naicsSicMapping: [],
              naicsCodesLoading: false,
              naicsCodesError: error,
            },
            false,
            INDUSTRY_SELECTION_ACTIONS.NAICS_CODES_ERROR,
          );
        }
      },

      fetchAllIndustryOptions: async (industrySelectionCarriers, loading = true) => {
        const industryOptions: { [k: string]: { [key: string]: IIndustryCode[] } } = {
          [IndustryCodeCategory.PRIMARY]: {},
          [IndustryCodeCategory.SECONDARY]: {},
        };
        const { carrierIndustrySelections } = get();
        const { applicationData, policyType = '' } = useApplicationStore.getState();

        try {
          set({ industryOptionsLoading: loading }, false, INDUSTRY_SELECTION_ACTIONS.FETCHING_INDUSTRY_OPTIONS);
          const state = (getLocationFromApplication(applicationData) as ILocationInfo)?.address?.state;

          await Promise.all(
            industrySelectionCarriers?.map(async (carrier) => {
              const params = {
                carrierId: carrier,
                policyTypeId: policyType as PolicyType,
                state,
                industryCodeCategory: IndustryCodeCategory.PRIMARY,
              };
              const industryCodes = await getAllIndustryClassCodes(params);
              industryOptions[IndustryCodeCategory.PRIMARY][carrier] = industryCodes;
            }),
          );

          await Promise.all(
            industrySelectionCarriers?.map(async (carrier) => {
              const params = {
                carrierId: carrier,
                policyTypeId: policyType as PolicyType,
                state,
                industryCodeCategory: IndustryCodeCategory.SECONDARY,
              };
              const industryCodes = await getAllIndustryClassCodes(params);
              industryOptions[IndustryCodeCategory.SECONDARY][carrier] = industryCodes;
            }),
          );

          const supportedFilteredCarriers = getSelectedCarriersFromApplication(applicationData)?.filter((carrier) =>
            ENABLED_INDUSTRY_CARRIERS_BY_POLICY(policyType as PolicyType).includes(carrier),
          );
          const updatedCarrierIndustrySelections = getUpdatedCarrierIndustrySelections({
            carrierIndustrySelections,
            industryOptions,
            supportedFilteredCarriers,
            type: IndustryCodeCategory.PRIMARY,
          });

          set(
            {
              industryOptions,
              industryOptionsLoading: false,
              industryOptionsError: undefined,
              carrierIndustrySelections: updatedCarrierIndustrySelections,
            },
            false,
            INDUSTRY_SELECTION_ACTIONS.FETCHED_INDUSTRY_OPTIONS,
          );
        } catch (error) {
          set(
            {
              industryOptions: {},
              industryOptionsLoading: false,
              industryOptionsError: error,
            },
            false,
            INDUSTRY_SELECTION_ACTIONS.INDUSTRY_OPTIONS_ERROR,
          );
        }
      },

      setIndustrySearchText: (industrySearchText: string) => {
        set({ industrySearchText }, false, INDUSTRY_SELECTION_ACTIONS.SET_INDUSTRY_SEARCH_TEXT);
      },

      validateIndustrySelectionForm: async () => {
        const { industrySelectionForm } = useIndustrySelectionStore.getState();
        let isAnswersValid = true;
        try {
          // As we using a table, this code checks that if every row has code selected or not, if any row has missing code, it throws an error
          if (isValidCarrierIndustrySelectionsDetails()) {
            isAnswersValid = false;
            errorV2(ERROR_MSGS.SELECT_INDUSTRY_CODES);
          }
          await industrySelectionForm?.validateFields();
        } catch (err) {
          isAnswersValid = false;
        }
        return isAnswersValid;
      },

      getIndustryClassCodeDetails: async ({ carrier, classCodeId, carrierIndustrySelections }) => {
        const { policyType } = useApplicationStore.getState();

        const response = await requestGetIndustryClassCodeDetails({
          carrierId: getCarrierId(carrier) as Carrier,
          classCodeId,
          policyType: policyType as PolicyType,
        });

        const industryClassCodeDetails = response?.industryClassCodeDetails as ILMIndustryClassCodeDetails;

        // if required secondary class code
        if (industryClassCodeDetails?.requiresSecondaryClassCode) {
          if (carrier.includes(SECONDARY_CODE_IDENTIFIER)) {
            return throwCustomApiError({ message: SECONDARY_CLASS_CODE_NOT_ALLOWED_ERROR });
          }

          return carrierIndustrySelections.map((carrierIndustry) =>
            carrierIndustry.carrier === carrier
              ? {
                  ...carrierIndustry,
                  key: SECONDARY_CODE_KEY,
                  children: [{ carrier: `${carrier}${SECONDARY_CODE_IDENTIFIER}` }],
                }
              : carrierIndustry,
          );
        }

        // if class code not required and carrier is secondary then not to do any thing
        if (carrier.includes(SECONDARY_CODE_IDENTIFIER)) {
          return carrierIndustrySelections;
        }

        // if class code carrier is primary and secondary class code not required then key and children will be default(initial)
        return carrierIndustrySelections.map((carrierIndustry) => ({
          ...carrierIndustry,
          key: undefined,
          children: [],
        }));
      },

      getIndustrySelectionPayload: () => {
        const { industrySelectionForm, carrierIndustrySelections } = get();
        const { applicationData, policyType, selectedCarriersInfo } = useApplicationStore.getState();

        const selectedCarriers = selectedCarriersInfo?.map((carrier) => carrier.carrierId);

        const fieldValue = {
          ...industrySelectionForm?.getFieldsValue(),
        };

        const carrierIndustryCodes: ICarrierIndustryCode[] = [];
        carrierIndustrySelections?.forEach((carrierIndustrySelection) => {
          if (selectedCarriers.includes(carrierIndustrySelection.carrier as Carrier)) {
            let additionalIndustryCodes: { additionalIndustryCodes: IAdditionalIndustryCode[] } = {
              additionalIndustryCodes: [],
            };

            const computedAdditionalIndustryCode = carrierIndustrySelection?.children?.map(
              (item: CarrierIndustrySelection) => ({
                industryCode: item?.code || '',
                industryCodeDescription: item?.description || '',
              }),
            );

            if (computedAdditionalIndustryCode) {
              additionalIndustryCodes = {
                additionalIndustryCodes: computedAdditionalIndustryCode,
              };
            }
            carrierIndustryCodes.push({
              carrierId: carrierIndustrySelection.carrier as Carrier,
              industryCode: carrierIndustrySelection.code || '',
              industryCodeDescription: carrierIndustrySelection.description || '',
              policyType: policyType!,
              ...additionalIndustryCodes,
            });
          }
        });

        const naicsExtendedId = fieldValue?.naicsCode?.split('-')?.[1]?.trim();
        const industryCodesNaics = { naicsExtendedId };

        // We are sending the entire payload from here and not merging it in the application/utils as
        // incase of delete carrier, if merged with the original payload,
        // carrierIndustryCodes property will also get merged and won't be updated
        return {
          ...applicationData,
          applicationId: applicationData?.applicationId!,
          basicBusinessDetails: {
            ...applicationData?.basicBusinessDetails,
            ...industryCodesNaics,
            carrierIndustryCodes,
          },
          applicationDetails: {
            ...applicationData?.applicationDetails,
            carriersSelected: selectedCarriers,
          },
        };
      },

      autoFillIndustrySelectionForm: () => {
        autoFillIndustrySelection();
      },

      updateCarrierIndustrySelections: (carrierIndustrySelections: CarrierIndustrySelection[]) => {
        set({ carrierIndustrySelections }, false, INDUSTRY_SELECTION_ACTIONS.SET_CARRIER_INDUSTRY_SELECTIONS);
      },

      clearIndustrySelection: () => {
        const { industrySelectionForm } = get();
        industrySelectionForm?.resetFields();
        set(
          {
            industrySelectionForm: undefined,
            industrySelectionQuestions: INDUSTRY_SELECTION_QUESTIONS,
            naicsCodesLoading: false,
            naicsCodes: [],
            naicsCodesError: undefined,
            naicsSicMapping: [],
            industryOptionsLoading: false,
            industryOptions: { [IndustryCodeCategory.PRIMARY]: {}, [IndustryCodeCategory.SECONDARY]: {} },
            industryOptionsError: undefined,
            carrierIndustrySelections: [],
            industrySearchText: '',
            actionableClassCode: undefined,
          },
          false,
          INDUSTRY_SELECTION_ACTIONS.CLEAR_INDUSTRY_SELECTION,
        );
      },
    }),

    { anonymousActionType: INDUSTRY_SELECTION_ACTIONS.ANONYMOUS_ACTION, name: INDUSTRY_SELECTION_ACTIONS.STORE_NAME },
  ),
);
