import { GlobalOutlined, MailOutlined, PhoneOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons';

import { INPUT_TYPES } from '../../../globalConstants';
import { getEmailRegex, getPhoneNumberRegex, normalizePhoneNumber } from '../../../helpers/Utils';
import { IFormConfig } from '../../../ui-core/V2/cfFormDrawer/interface';

export const PAGE_INFO = {
  title: 'Profile',
  subTitle: 'This is where you can update your profile',
};

export const messages = {
  invalidPassword:
    'Password should be of minimum length 8 containing a symbol, upper and lower case letters and a number.',
  oldNewPasswordSame: 'The old and new password cannot be same.',
  passwordsDoNotMatch: 'The two passwords that you entered do not match.',
  passwordChangeSuccess: 'Your Password updated successfully!',
  profileUpdateSuccess: 'Profile updated successfully!',
  someErrorOccured: 'Some error occurred in fetching agent profile.',
};

export const PROFILE_FORM_CONFIG_ELEMENTS: IFormConfig[] = [
  {
    dataIndex: 'firstName',
    label: 'First Name',
    placeholder: 'First name',
    prefix: <UserOutlined />,
    disabled: true,
    rules: [
      {
        required: true,
        validator: (_, value) => {
          if (value?.trim()) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please enter first name.'));
        },
      },
    ],
  },
  {
    dataIndex: 'lastName',
    label: 'Last Name',
    placeholder: 'Last name',
    prefix: <UserOutlined />,
    disabled: true,
    rules: [
      {
        required: true,
        validator: (_, value) => {
          if (value?.trim()) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please enter last name.'));
        },
      },
    ],
  },
  {
    dataIndex: 'email',
    label: 'Email',
    placeholder: 'email@email.com',
    type: INPUT_TYPES.EMAIL,
    prefix: <MailOutlined />,
    disabled: true,
    rules: [
      {
        required: true,
        pattern: getEmailRegex(),
        message: 'Please enter valid email address.',
      },
    ],
  },
  {
    dataIndex: 'phone',
    label: 'Phone',
    placeholder: '(234) 567-8901',
    type: INPUT_TYPES.TEL,
    maxLength: 14,
    prefix: <PhoneOutlined />,
    rules: [
      {
        required: true,
        validator: (_, value) => {
          value = normalizePhoneNumber(value?.trim());
          if (value && value.toString().length >= 14 && getPhoneNumberRegex(value.toString())) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please enter valid 10 digit phone number.'));
        },
      },
    ],
  },
  {
    label: 'Agency',
    dataIndex: 'agencyName',
    prefix: <UsergroupAddOutlined />,
    disabled: true,
  },
  {
    label: 'Agency Network',
    dataIndex: 'agencyNetworkName',
    prefix: <GlobalOutlined />,
    disabled: true,
  },
];

export const PROFILE_ELEMENTS = [
  {
    key: 'firstName',
    dataIndex: 'firstName',
    label: 'First Name',
  },
  {
    key: 'lastName',
    dataIndex: 'lastName',
    label: 'Last Name',
  },
  {
    key: 'email',
    dataIndex: 'email',
    label: 'Email',
  },
  {
    key: 'phone',
    dataIndex: 'phone',
    label: 'Phone',
  },
  {
    key: 'agencyName',
    dataIndex: 'agencyName',
    label: 'Agency',
  },
  {
    key: 'agencyNetworkName',
    dataIndex: 'agencyNetworkName',
    label: 'Agency Network',
  },
  {
    key: 'password',
    dataIndex: 'password',
    label: 'Password',
  },
];

export const AGENCY_USER_FILTERED_PROFILE_DATA_INDEX = ['agencyName'];

export const SSO_THEME_FILTERED_PROFILE_DATA_INDEX = ['password'];

export const PROFILE_STORE_META_DATA = {
  FETCH_USER_DATA: 'fetchUserData',
  FETCH_USER_DATA_ERROR: 'fetchUserDataError',
  FETCH_USER_DATA_LOADING: 'fetchUserDatLoading',
  FETCH_USER_PROFILES: 'fetchUserProfiles',
  FETCH_SELECTED_USER_PROFILE: 'fetchSelectedUserProfile',
  FETCH_SELECTED_USER_PROFILE_ERROR: 'fetchSelectedUserProfileError',
  UPDATE_PROFILE_KEY: 'updateProfileByKey',
};
