export default {
  ERROR: 'Some error occurred!',
  SOMETHING_WRONG: 'Something went wrong!',
  MSG_SENT_SUCCESS: 'Message sent successfully!',
  FILE_UPLOAD_SUCCESS: (fileName: string) => `${fileName} file uploaded successfully!`,
  FILE_UPLOAD_FAILED: (fileName: string) => `${fileName} file upload failed!`,
  ACCOUNT_CREATE_SUCCESS: 'Account added successfully!',
  ACCOUNT_UPDATE_SUCCESS: 'Account updated successfully!',
  ACCOUNT_DELETE_SUCCESS: 'Account deleted successfully!',
};
