import styled from 'styled-components';

import CFModal from '../../ui-core/V2/cfModal/cfModal';

export const EffectiveDateModalStyled = styled(CFModal)``;

export const EffectiveDateTextStyled = styled.div`
  margin: 0px 0px 20px;
  font-weight: ${(p) => p.theme.typography.fontWeight.medium};
`;
