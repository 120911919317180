import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { GLOBAL_LOCALE } from '../../../globalConstants';
import CFInput from '../cfInput/cfInput';
import { INumberInput } from './interface';
import { getNumber, usInputFormatter, usInputParser } from './utils';

const NumberInput = ({
  format = GLOBAL_LOCALE.US,
  onChange,
  id,
  initialValue,
  data,
  disabled = false,
  allowDecimals = false,
  ...rest
}: INumberInput) => {
  const [inputValue, setInputValue] = useState<string>(initialValue?.toString() ?? '');

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const callbackValue = getNumber({ allowDecimals, inputValue: event.target.value });
      switch (format) {
        case GLOBAL_LOCALE.US:
          setInputValue(callbackValue);
          onChange && onChange(usInputParser(callbackValue), data);
          break;

        default:
          setInputValue(callbackValue);
          onChange && onChange(callbackValue, data);
          break;
      }
    },
    [allowDecimals, format, onChange, data],
  );

  const valueFormatter = useMemo(() => {
    if (format && format === GLOBAL_LOCALE.US) {
      const value = inputValue || rest.value?.toString();
      return `${usInputFormatter(value)}${inputValue.endsWith('.') ? '.' : ''}`;
    } else {
      return inputValue || rest.value?.toString();
    }
  }, [format, rest.value, inputValue]);

  return <CFInput disabled={disabled} {...rest} id={id} onChange={handleChange} value={valueFormatter} />;
};

export default NumberInput;
