import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { LIABILITY_LIMITS_STORE } from './constants';
import { ILiabilityLimitsStore } from './interface';

export const useLiabilityLimitsStore = create<ILiabilityLimitsStore>()(
  devtools(
    (set, get) => ({
      regenerateQuoteApplicationPayload: undefined,

      getLiabilityLimitsApiPayload: () => {
        const { regenerateQuoteApplicationPayload } = get();
        return regenerateQuoteApplicationPayload;
      },

      updateLiabilityLimitsByKey: async (storeKey: keyof ILiabilityLimitsStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, LIABILITY_LIMITS_STORE.UPDATE_LIABILITY_LIMITS_STORE_BY_KEY),

      clearLiabilityLimits: () =>
        set({ regenerateQuoteApplicationPayload: undefined }, false, LIABILITY_LIMITS_STORE.CLEAR_LIABILITY_LIMITS),
    }),

    { name: LIABILITY_LIMITS_STORE.NAME, anonymousActionType: LIABILITY_LIMITS_STORE.ANONYMOUS_ACTION },
  ),
);
