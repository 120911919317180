import { IReferQuoteModalStore } from './interface';

export const REFER_QUOTE_MODAL = {
  TITLE: 'Notes for Underwriter',
  PLACEHOLDER: 'Enter note to underwriter',
};

export const REFER_QUOTE_MODAL_STORE = {
  NAME: 'Refer Quote Modal Store',
  ANONYMOUS_ACTION: 'REFER_QUOTE_MODAL',
  REFER_QUOTE_LOADING: 'REFER_QUOTE_LOADING',
  UPDATE_REFER_QUOTE_MODAL_STORE_BY_KEY: 'UPDATE_REFER_QUOTE_MODAL_STORE_BY_KEY',
  CLEAR_REFER_QUOTE: 'CLEAR_REFER_QUOTE',
};

// TO-DO:- Need to check how to write the type so as to not duplicate "as keyof IReferQuoteModalStore" everytime
export const REFER_QUOTE_MODAL_STORE_KEYS = {
  IS_REFER_QUOTE_LOADING: 'isReferQuoteLoading' as keyof IReferQuoteModalStore,
};

export const REFER_QUOTE_ERROR = 'Error while referring quote';
