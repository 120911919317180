import { IGetAllPolicyTypesOutput, IGetCarrierRankingInput } from '@coverforce-platform/cf-common-api-model';
import { AxiosResponse } from 'axios';

import axiosAccountService from '../../axios/accountService/instance';
import axiosApplicationHelperService from '../../axios/applicationHelperService/instance';
import { CARRIER_SERVICE_ENDPOINTS, HELPER_SERVICE_ENDPOINTS } from '../../externalServices/helpers/ApiRoutes';
import { createQueryUrl } from '../../utils/createQueryUrl';

const getAllPolicyTypes = async (): Promise<IGetAllPolicyTypesOutput> => {
  const apiUrl = createQueryUrl({
    url: HELPER_SERVICE_ENDPOINTS.GET_ALL_POLICY_TYPES,
  });

  try {
    const response: AxiosResponse = await axiosApplicationHelperService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getCarrierRankingByPolicyTypeId = async (params: IGetCarrierRankingInput) => {
  const apiUrl = createQueryUrl({
    url: CARRIER_SERVICE_ENDPOINTS.GET_CARRIER_RANKING_BY_POLICY_TYPE(params),
  });

  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export { getAllPolicyTypes, getCarrierRankingByPolicyTypeId };
