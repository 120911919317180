import { Form, FormInstance } from 'antd';

import { ICFForm } from './interface';
import CFFormStyled from './style';

const CFForm = (props: ICFForm) => {
  const { id, style } = props;

  return <CFFormStyled {...props} id={id} style={style}></CFFormStyled>;
};
export default CFForm;

export const useForm = Form.useForm;
export const useWatch = Form.useWatch;
export const CFFormList = Form.List;
export type IFormInstance = FormInstance;
