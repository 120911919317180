import { IErrorResponseData } from '@coverforce-platform/cf-common-api-model';

import { CUSTOM_FRONTEND_ERROR } from '../globalConstants';

export const throwCustomApiError = ({ message }: { message: string }) => {
  return Promise.reject([
    {
      errorMessage: message,
      errorCode: CUSTOM_FRONTEND_ERROR.ERROR_CODE,
      errorDetail: CUSTOM_FRONTEND_ERROR.ERROR_DETAILS,
    },
  ] as IErrorResponseData[]);
};
