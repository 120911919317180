import styled from 'styled-components';

export const AnchorStyled = styled.a.attrs((props: { disabled?: boolean }) => props)`
  color: ${(p) => p?.theme?.colorScheme?.primary};
  text-decoration-line: none;
  &:hover {
    color: ${(p) => p.theme?.colorScheme?.primaryHover};
  }

  ${(p) =>
    p.disabled
      ? `
  pointer-events: none;
  cursor: default;
  `
      : ''}
`;
