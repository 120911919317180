import { useEffect } from 'react';

import { QUOTES_STORE_KEYS } from '../../constants/quotesConstants';
import { useQuotesStore } from '../quotes/store';
import { NO_NOTES_TEXT, REQUEST_TO_BIND_NOTES_MODAL } from './constants';
import {
  RequestToBindNotesHeadingStyled,
  RequestToBindNotesModalStyled,
  RequestToBindNotesTextStyled,
  RequestToBindNotesWrapperStyled,
} from './styles';

const RequestToBindNotesModal = () => {
  const { selectedQuote, updateQuotesByKey } = useQuotesStore();
  const { requestToBindNote = '' } = selectedQuote || {};

  useEffect(() => {
    return () => {
      updateQuotesByKey(QUOTES_STORE_KEYS.SELECTED_QUOTE, undefined);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFormattedNotes = (): string[] => (requestToBindNote || NO_NOTES_TEXT).split('\n');

  return (
    <RequestToBindNotesModalStyled
      title={REQUEST_TO_BIND_NOTES_MODAL.TITLE}
      cancelText='Close'
      okButtonProps={{ style: { display: 'none' } }}
    >
      <RequestToBindNotesWrapperStyled>
        <RequestToBindNotesHeadingStyled>Notes from producer:</RequestToBindNotesHeadingStyled>
        {getFormattedNotes().map((item, index) => (
          <RequestToBindNotesTextStyled key={index}>
            {item}
            <br />
          </RequestToBindNotesTextStyled>
        ))}
      </RequestToBindNotesWrapperStyled>
    </RequestToBindNotesModalStyled>
  );
};

export default RequestToBindNotesModal;
