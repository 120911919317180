import { useEffect } from 'react';
import { useTheme } from 'styled-components';

import { QUOTES_STORE_KEYS } from '../../constants/quotesConstants';
import { pushEvent } from '../../utils/googleAnalytics';
import { getQuoteBridgeAnchorLink } from '../../utils/quotes/quotes';
import { GA_ACTION, GA_CATEGORY, GA_LABEL } from '../quotes/constants';
import { useQuotesStore } from '../quotes/store';
import { FAILED_REASONS_MODAL } from './constants';
import { FailedReasonsModalStyled } from './styles';

const FailedReasonsModal = () => {
  const theme: any = useTheme();
  const { updateQuotesByKey } = useQuotesStore();

  useEffect(() => {
    return () => {
      updateQuotesByKey(QUOTES_STORE_KEYS.SELECTED_QUOTE, undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    pushEvent({
      category: GA_CATEGORY.QUOTES_TAB_FAILED_REASON_MODAL,
      action: GA_ACTION.CLOSE_MODAL_BTN_CLICK,
      label: GA_LABEL.CLOSE_FAILED_REASON_MODAL,
    });
  };
  return (
    <FailedReasonsModalStyled onCancel={handleCancel} title={FAILED_REASONS_MODAL.TITLE} footer={null}>
      The carrier has declined this application because your request could not be processed digitally.{' '}
      {getQuoteBridgeAnchorLink(theme)}
      <br />
      <br />
      For further information, please reach out to your carrier representative.
    </FailedReasonsModalStyled>
  );
};

export default FailedReasonsModal;
