import { IBrokerCodeRestrictionsInfo } from '@coverforce-platform/cf-common-api-model';
import { AgentPortalUserType } from '@coverforce-platform/cf-common-types';
import { Tooltip } from 'antd';
import { ReactElement } from 'react';

import Anchor from '../../../ui-core/V2/anchor/anchor';
import CFSwitch from '../../../ui-core/V2/cfSwitch/cfSwitch';
import { useConfigurationStore } from '../../configuration/store';
import { BROKER_CODE_RESTRICTIONS_KEYS } from './constants';
import { IBrokerCodeInfo } from './interface';

export const getAgencyLevelBrokerActionKey = (key?: string) => {
  switch (key) {
    case BROKER_CODE_RESTRICTIONS_KEYS.DOWNLOAD_QUOTE_PROSPOSAL:
      return 'isQuoteProposalDisabledForAgency';
    case BROKER_CODE_RESTRICTIONS_KEYS.BRIDGE_TO_CARRIER:
      return 'isBridgingDisabledForAgency';
    case BROKER_CODE_RESTRICTIONS_KEYS.REFER_TO_UNDERWRITER:
      return 'isReferToUWDisabledForAgency';
    case BROKER_CODE_RESTRICTIONS_KEYS.BIND_NOW:
      return 'isBindNowDisabledForAgency';
    case BROKER_CODE_RESTRICTIONS_KEYS.REQUEST_TO_BIND:
      return 'isRequestToBindDisabledForAgency';
    case BROKER_CODE_RESTRICTIONS_KEYS.ADD_MODIFIERS:
      return 'isAddModifiersDisabledForAgency';
    default:
      return;
  }
};

export const getTableCellData = ({
  key,
  dataIndex,
  disabled,
  tootlipTitle,
  onSwitchClick,
  data,
}: {
  key: keyof IBrokerCodeRestrictionsInfo;
  dataIndex: string;
  disabled?: boolean;
  tootlipTitle?: ReactElement;
  onSwitchClick: any;
  data: IBrokerCodeInfo;
}) => {
  if (disabled) {
    return (
      <Tooltip title={tootlipTitle}>
        <span>
          <CFSwitch
            id={`switch_${dataIndex}_${data?.level}`}
            checked={!data?.brokerCodeRestrictions?.[key]}
            disabled={disabled}
            onClick={onSwitchClick}
            onClickData={{ level: data?.level, key: dataIndex }}
          />
        </span>
      </Tooltip>
    );
  }
  return (
    <CFSwitch
      id={`switch_${dataIndex}_${data?.level}`}
      checked={!data?.brokerCodeRestrictions?.[key]}
      disabled={disabled}
      onClick={onSwitchClick}
      onClickData={{ level: data?.level, key: dataIndex }}
    />
  );
};

export const getTableCellTooltipTitle = () => {
  const { userType } = useConfigurationStore.getState();
  if (userType === AgentPortalUserType.AGENCY_NETWORK) {
    return (
      <>
        You can modify this configuration in the{' '}
        <Anchor id='link_network_settings' href='/network-settings/brokerconfig'>
          Network Settings
        </Anchor>
      </>
    );
  }
  return <>You can modify this configuration in the Network Settings</>;
};
