import { IScheduleReportStore } from './interface';

export const SCHEDULE_REPORT_MESSAGES = {
  SCHEDULE_REPORT_SUCCESS: 'Report Schedule created successfully.',
  SCHEDULE_REPORT_ERROR: 'Error while creating Report Schedule.',
  UPDATE_SCHEDULE_REPORT_SUCCESS: 'Report Schedule updated successfully.',
  UPDATE_SCHEDULE_REPORT_ERROR: 'Error while updating Report Schedule.',
};

export const SCHEDULE_REPORT_STORE_META_DATA = {
  UPDATE_SCHEDULE_REPORT_STORE_BY_KEY: 'UPDATE_SCHEDULE_REPORT_STORE_BY_KEY',
  CLEAR_SCHEDULE_REPORT_STORE: 'CLEAR_SCHEDULE_REPORT_STORE',
};

export const SCHEDULE_REPORT_STORE_KEYS: { [key: string]: keyof IScheduleReportStore } = {
  SCHEDULE_REPORT_FORM: 'scheduleReportForm',
  SCHEDULE_REPORT_ELEMENTS: 'scheduleReportElements',
  SCHEDULE_REPORT_LOADING: 'scheduleReportLoading',
  REPORT_SCHEDULE: 'reportSchedule',
  IS_EDIT_REPORT_SCHEDULE: 'isEditReportSchedule',
  DISABLE_SCHEDULE_REPORT_BUTTON: 'disableScheduleReportButton',
};

export const SCHEDULE_REPORT_QUESTION_KEYS = {
  REPORT_TYPE: 'reportType',
  TO_EMAIL_LIST: 'toEmailList',
  TIME_FRAME_DATE_RANGE: 'timeFrameDateRange',
  SCHEDULE_NAME: 'scheduleName',
  FREQUENCY_OF_DELIVERY: 'frequencyOfDelivery',
  REPORT_DURATION_FOR_DELIVERY: 'reportDurationForDelivery',
  PREFERRED_TIME_OF_DELIVERY: 'preferredTimeOfDelivery',
};
