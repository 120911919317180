import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { ICFDrawerStore } from './interface';

export const useCFDrawerStore = create<ICFDrawerStore>()(
  devtools(
    (set) => ({
      isVisible: false,
      drawerType: undefined,

      setCFDrawerData: ({ isVisible, drawerType }) => set({ isVisible, drawerType }, false, 'setCFDrawerData'),

      clearCFDrawer: () => set({ isVisible: false, drawerType: undefined }, false, 'clearCFDrawer'),
    }),

    { anonymousActionType: 'cfDrawer' },
  ),
);
