import createInstance from '../common/createInstance';
import { defaultRequestInterceptor, defaultResponseInterceptor } from '../common/interceptor';
import methods from '../common/methods';
import { ACCOUNT_SERVICE, ACCOUNT_SERVICE_PARAMS } from './constants';

const axiosAccountService = createInstance(ACCOUNT_SERVICE_PARAMS);

defaultRequestInterceptor({ axiosInstance: axiosAccountService, serviceName: ACCOUNT_SERVICE });
defaultResponseInterceptor({ axiosInstance: axiosAccountService, serviceName: ACCOUNT_SERVICE });

export default methods(axiosAccountService);
