import { forwardRef, useCallback } from 'react';

import { ICFInput, ICFPassword } from './interface';
import { StyledCFInput, StyledCFPassword } from './styles';

const CFInput = forwardRef((props: ICFInput, ref?: any) => {
  const { data, onChange } = props;

  const handleChange = useCallback(
    (event) => {
      onChange && onChange(event, data);
    },
    [data, onChange],
  );

  return <StyledCFInput ref={ref} {...props} onChange={handleChange} />;
});

CFInput.displayName = 'CFInput';
export default CFInput;

export const CFPassword = (props: ICFPassword) => {
  const { data, onChange } = props;

  const handleChange = useCallback(
    (event) => {
      onChange && onChange(event, data);
    },
    [data, onChange],
  );

  return <StyledCFPassword {...props} onChange={handleChange} />;
};
