import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { generateReport } from '../../externalServices/V2/reports';
import { GENERATE_REPORT_STORE_META_DATA } from './constants';
import { IGenerateReportStore } from './interface';
import { getGenerateReportApiPayloadUtil } from './utils';

export const useGenerateReportStore = create<IGenerateReportStore>()(
  devtools(
    (set, get) => ({
      generateReportElements: undefined,
      generateReportForm: undefined,
      generateReportLoading: false,

      updateGenerateReportStoreByKey: async (storeKey: keyof IGenerateReportStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, GENERATE_REPORT_STORE_META_DATA.UPDATE_GENERATE_REPORT_STORE_BY_KEY),

      validateGenerateReportForm: async () => {
        const { generateReportForm } = get();
        try {
          await generateReportForm?.validateFields();
          return true;
        } catch (error) {
          return false;
        }
      },

      getGenerateReportApiPayload: () => {
        return getGenerateReportApiPayloadUtil();
      },

      generateReport: async () => {
        const { getGenerateReportApiPayload } = get();
        const response = await generateReport(getGenerateReportApiPayload());
        return response;
      },

      clearGenerateReportStore: () => {
        set({
          generateReportElements: undefined,
          generateReportForm: undefined,
          generateReportLoading: false,
        });
      },
    }),
    {
      // NOTE: Please don't include these from constant in future. There is an existing issue in zustand where it tries to initialise store(name, actiontype) even before constants.
      name: 'Generate Report Store',
      anonymousActionType: 'GENERATE_REPORT_ANONYMOUS_ACTION',
    },
  ),
);
