import { Carrier } from '@coverforce-platform/cf-common-types';

import { CarrierClassCodeConfig } from './interface';

// Whenever updating this constant,
// please look CARRIERS_CLASS_CODE_CONFIG below to add configuration details about the carrier
export const ENABLED_CARRIERS_FOR_CLASS_CODE = [
  Carrier.LIBERTYMUTUAL,
  Carrier.CHUBB,
  Carrier.EMPLOYERS,
  Carrier.CNA,
  Carrier.GAIG,
  Carrier.TRAVELERS,
  Carrier.GUARD,
  Carrier.ACCIDENTFUND,
  Carrier.COMPWEST,
];

// Carrier specific config details
export const CARRIERS_CLASS_CODE_CONFIG: CarrierClassCodeConfig = {
  [Carrier.LIBERTYMUTUAL]: {
    carrier: Carrier.LIBERTYMUTUAL,
    formItem: {
      label: 'Liberty Mutual Class Code',
      rules: [
        {
          required: true,
          message: 'Please select LM class code description.',
        },
      ],
      selectItem: {
        id: 'sel_lm_class_code',
        placeholder: 'Start typing to search..',
      },
    },
  },
  [Carrier.TRAVELERS]: {
    carrier: Carrier.TRAVELERS,
    formItem: {
      label: 'Travelers Class Code',
      rules: [
        {
          required: true,
          message: 'Please select Travelers class code description.',
        },
      ],
      selectItem: {
        id: 'sel_travelers_class_code',
        placeholder: 'Start typing to search..',
      },
    },
  },
  [Carrier.CHUBB]: {
    carrier: Carrier.CHUBB,
    formItem: {
      label: 'Chubb Class Code',
      rules: [
        {
          required: true,
          message: 'Please select Chubb class code description.',
        },
      ],
      selectItem: {
        id: 'sel_chubb_class_code',
        placeholder: 'Start typing to search..',
      },
    },
  },
  [Carrier.EMPLOYERS]: {
    carrier: Carrier.EMPLOYERS,
    formItem: {
      label: 'Employers Class Code',
      rules: [
        {
          required: true,
          message: 'Please select Employers class code description.',
        },
      ],
      selectItem: {
        id: 'sel_employers_class_code',
        placeholder: 'Start typing to search..',
      },
    },
  },
  [Carrier.CNA]: {
    carrier: Carrier.CNA,
    formItem: {
      label: 'CNA Class Code',
      rules: [
        {
          required: true,
          message: 'Please select CNA class code description.',
        },
      ],
      selectItem: {
        id: 'sel_cna_class_code',
        placeholder: 'Start typing to search..',
      },
    },
  },
  [Carrier.GAIG]: {
    carrier: Carrier.GAIG,
    formItem: {
      label: 'GAIG Class Code',
      rules: [
        {
          required: true,
          message: 'Please select GAIG class code description.',
        },
      ],
      selectItem: {
        id: 'sel_gaig_class_code',
        placeholder: 'Start typing to search..',
      },
    },
  },
  [Carrier.GUARD]: {
    carrier: Carrier.GUARD,
    formItem: {
      label: 'Guard Class Code',
      rules: [
        {
          required: true,
          message: 'Please select Guard class code description.',
        },
      ],
      selectItem: {
        id: 'sel_guard_class_code',
        placeholder: 'Start typing to search..',
      },
    },
  },
  [Carrier.ACCIDENTFUND]: {
    carrier: Carrier.ACCIDENTFUND,
    formItem: {
      label: 'Accident Fund Class Code',
      rules: [
        {
          required: true,
          message: 'Please select Accident Fund class code description.',
        },
      ],
      selectItem: {
        id: 'sel_accidentFund_class_code',
        placeholder: 'Start typing to search..',
      },
    },
  },
  [Carrier.COMPWEST]: {
    carrier: Carrier.COMPWEST,
    formItem: {
      label: 'Compwest Class Code',
      rules: [
        {
          required: true,
          message: 'Please select Compwest class code description.',
        },
      ],
      selectItem: {
        id: 'sel_compwest_class_code',
        placeholder: 'Start typing to search..',
      },
    },
  },
};

export const ZERO_VALUES = ['$', '$0', '$00', '', '0', 0];

export const FIELD_KEYS = {
  employeeInformation: 'employeeInformation',
  ncciStateClassCode: 'ncciStateClassCode',
  jobCodeExtendedId: 'jobCodeExtendedId',
  numFullTimeEmployees: 'numFullTimeEmployees',
  numPartTimeEmployees: 'numPartTimeEmployees',
  fullTimeEmployeesPayroll: 'fullTimeEmployeesPayroll',
  partTimeEmployeesPayroll: 'partTimeEmployeesPayroll',
  carrierJobDescriptionDetails: 'carrierJobDescriptionDetails',
};

export const EMPLOYEE_INFO_STORE_META_DATA = {
  STORE_NAME: 'Employee Information Store',
  ANONYMOUS_ACTIONS_NAME: 'EMPLOYEE_INFORMATION_ACTION',
  CLEAR_EMPLOYEE_INFORMATION: 'CLEAR_EMPLOYEE_INFORMATION',
  SET_CARRIER_SINTERNAL_CLASS_CODES: 'SET_CARRIER_SINTERNAL_CLASS_CODES',
  SET_CLASS_CODES: 'SET_CLASS_CODES',
  SET_SELECTED_EMPLOYEE_INDEX: 'SET_SELECTED_EMPLOYEE_INDEX',
  SET_CARRIERS_VALID_FOR_INTERNAL_CLASS_CODES: 'SET_CARRIERS_VALID_FOR_INTERNAL_CLASS_CODES',
  SET_ACCORDION_ACTIVE_KEYS: 'SET_ACCORDION_ACTIVE_KEYS',
};

export const EMPLOYEE_INFO_ERROR_MESSAGES = {
  FAILED_TO_FETCH_STATE_CLASS_CODES: 'Failed to get state class codes!',
  FAILED_TO_FETCH_STATE_CARRIER_CLASS_CODES: 'Failed to get state specific carrier codes',
  FAILED_GET_EMPLOYEE_TYPE_DETAILS: 'Failed to get employee type details',
};
