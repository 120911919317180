import { ILiabilityLimitsInfo } from '@coverforce-platform/cf-common-api-model';
import { Carrier } from '@coverforce-platform/cf-common-types';

import { useApplicationStore } from '../../pages/v2/application/store';
import { cloneDeep } from '../../utils/lodash';

export const getLiabilityLimitChangeApplicationPayload = ({
  selectedLiabilityLimit,
  carrierId,
}: {
  selectedLiabilityLimit: ILiabilityLimitsInfo;
  carrierId: Carrier;
}) => {
  const { applicationData } = useApplicationStore.getState();
  const applicationPayload = { ...applicationData };
  const existingLiabilityLimits = applicationData?.policyDetails?.[0]?.selectedLiabilityLimits || [];
  const existingCarrierIndex = existingLiabilityLimits.findIndex((item) => item.carrierId === carrierId);
  const newLiabilityLimits = cloneDeep(existingLiabilityLimits);

  if (existingCarrierIndex !== -1) {
    newLiabilityLimits[existingCarrierIndex].limits = selectedLiabilityLimit;
  } else {
    newLiabilityLimits.push({ carrierId, limits: selectedLiabilityLimit });
  }
  applicationPayload.policyDetails = applicationPayload?.policyDetails?.map((item) => ({
    policyType: item?.policyType,
    selectedLiabilityLimits: newLiabilityLimits,
  }));

  return applicationPayload;
};
