import createInstance from '../common/createInstance';
import { defaultRequestInterceptor, defaultResponseInterceptor } from '../common/interceptor';
import methods from '../common/methods';
import { APPLICATION_SERVICE, APPLICATION_SERVICE_PARAMS } from './constants';

const axiosApplicationService = createInstance(APPLICATION_SERVICE_PARAMS);

defaultRequestInterceptor({ axiosInstance: axiosApplicationService, serviceName: APPLICATION_SERVICE });
defaultResponseInterceptor({ axiosInstance: axiosApplicationService, serviceName: APPLICATION_SERVICE });

export default methods(axiosApplicationService);
