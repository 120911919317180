import { CompassOutlined, MailOutlined, PhoneOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import { IAccountInfo } from '@coverforce-platform/cf-common-api-model';
import { TableColumnProps } from 'antd';

import { MenuConfig } from '../../../components/menuActionButton/interface';
import { MENU_ITEM_KEY } from '../../../globalConstants';
import { getEmailRegex, getPhoneNumberRegex, normalizePhoneNumber } from '../../../helpers/Utils';
import { IFormConfig } from '../../../ui-core/V2/cfFormDrawer/interface';
import { SingleLineTextStyled, WrappedTextStyled } from './style';
import { formattedLocation, formattedName, getPhoneFromAccount } from './utils';

export const accountsTableColumns: Array<TableColumnProps<IAccountInfo>> = [
  {
    title: 'Account',
    dataIndex: 'accountName',
    key: 'accountName',
    render: (_, account) => (
      <span id={`account_name_${account.accountId}`} key={`account-name-${account.accountId}`}>
        {account.accountName}
      </span>
    ),
    sorter: (a, b) => {
      return a.accountName > b.accountName ? 1 : -1;
    },
  },
  {
    title: 'Primary Contact',
    dataIndex: 'primaryContactDetails',
    key: 'primaryContactDetails',
    render: (_, account) => (
      <span id={`contact_${account.accountId}`} key={`contact-${account.accountId}`}>
        {formattedName(account)}
      </span>
    ),
    sorter: (a, b) => {
      return formattedName(a) > formattedName(b) ? 1 : -1;
    },
  },
  {
    title: 'Email',
    dataIndex: 'primaryContactDetails.email',
    key: 'primaryContactDetails.email',
    render: (_, account) => (
      <WrappedTextStyled id={`contact_email_${account.accountId}`} key={`contact-email-${account.accountId}`}>
        {account?.primaryContactDetails?.email || '--'}
      </WrappedTextStyled>
    ),
    sorter: (a, b) => {
      return (a?.primaryContactDetails?.email || '--') > (b?.primaryContactDetails?.email || '--') ? 1 : -1;
    },
  },
  {
    title: 'Phone',
    dataIndex: 'primaryContactDetails.phone',
    key: 'primaryContactDetails.phone',
    render: (_, account) => (
      <SingleLineTextStyled
        itemType='tel'
        id={`contact_phone_${account.accountId}`}
        key={`contact-phone-${account.accountId}`}
      >
        {getPhoneFromAccount(account)}
      </SingleLineTextStyled>
    ),
    sorter: (a, b) => {
      return getPhoneFromAccount(a) > getPhoneFromAccount(b) ? 1 : -1;
    },
  },
  {
    title: 'Location',
    dataIndex: 'primaryAddress',
    key: 'primaryAddress',
    render: (_, account) => (
      <span id={`location_${account.accountId}`} key={`location-${account.accountId}`}>
        {formattedLocation(account)}
      </span>
    ),
    sorter: (a, b) => {
      return formattedLocation(a) > formattedLocation(b) ? 1 : -1;
    },
  },
];

export const accountFormConfig: IFormConfig[] = [
  {
    dataIndex: 'accountName',
    label: 'Account Name',
    placeholder: 'Company Name',
    prefix: <ShopOutlined />,
    rules: [
      {
        required: true,
        validator: (_, value) => {
          if (value?.trim()) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please enter account name.'));
        },
      },
    ],
  },
  {
    dataIndex: 'primaryContactDetails.firstName',
    label: 'Contact First Name',
    placeholder: 'First Name',
    prefix: <UserOutlined />,
  },
  {
    dataIndex: 'primaryContactDetails.lastName',
    label: 'Contact Last Name',
    placeholder: 'Last Name',
    prefix: <UserOutlined />,
  },
  {
    dataIndex: 'primaryContactDetails.email',
    label: 'Email',
    placeholder: 'email@email.com',
    type: 'email',
    prefix: <MailOutlined />,
    rules: [
      {
        pattern: getEmailRegex(),
        message: 'Please enter valid email address.',
      },
    ],
  },
  {
    dataIndex: 'primaryContactDetails.phone',
    label: 'Phone',
    placeholder: '(234) 567-8910',
    maxLength: 14,
    type: 'tel',
    prefix: <PhoneOutlined />,
    rules: [
      {
        validator: (_, value) => {
          value = normalizePhoneNumber(value?.trim());
          if (!value) {
            return Promise.resolve();
          }
          if (value && value.toString().length >= 14 && getPhoneNumberRegex(value.toString())) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please enter valid 10 digit phone number.'));
        },
      },
    ],
  },
  {
    dataIndex: 'primaryAddress',
    label: 'Address',
    placeholder: 'Address Line 1',
    prefix: <CompassOutlined />,
    type: 'location',
  },
];

export const menuConfig: MenuConfig[] = [
  {
    label: 'Edit Account',
    key: MENU_ITEM_KEY.EDIT,
  },
  {
    label: 'Delete Account',
    key: MENU_ITEM_KEY.DELETE,
    extraData: { danger: 'danger' },
  },
];
export const GA_CATEGORY = {
  ACCOUNTS_TABLE: 'accounts_table',
  ACCOUNTS_HEADER: 'accounts_header',
  ACCOUNTS_DRAWER: 'accounts_drawer',
  ACCOUNTS_DELETE_CONFIRM_BOX: 'accounts_deleteConfirmBox',
};

export const GA_ACTION = {
  VIEW_ACCOUNT_DETAILS_BY_ROW_CLICK: 'viewAccountDetailsByRow_click',
  EDIT_ACCOUNT_BTN_CLICK: 'editAccountBtn_click',
  DELETE_ACCOUNT_BTN_CLICK: 'deleteAccountBtn_click',
  ADD_NEW_ACCOUNT_BTN_CLICK: 'addNewAccountBtn_click',
};

export const GA_LABEL = {
  VIEW_ACCOUNT_DETAILS: 'View Account Details',
  EDIT_ACCOUNT_MENU_ITEM: 'Edit account menu item',
  EDIT_ACCOUNT_DRAWER: 'Edit account in drawer',
  ADD_ACCOUNT_DRAWER: 'Add account in drawer',
  DELETE_ACCOUNT_MENU_ITEM: 'Delete account menu item',
  DELETE_ACCOUNT: 'Delete account in confirm box',
  NEW_ACCOUNT: 'New Account button in accounts header',
};
