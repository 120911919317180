import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { EDIT_BIND_ACTIONS_METADATA } from './constants';
import { IEditBindActionsStore } from './interface';

export const useEditBindActionsStore = create<IEditBindActionsStore>()(
  devtools(
    (set) => ({
      presetBrokerConfig: undefined,
      brokerConfig: [],
      agencyRowDisabled: false,
      updateBrokerConfig: undefined,
      selectedCarrierDetails: undefined,

      setBindActionsStoreByKey: (key: keyof IEditBindActionsStore, value: any) => {
        set({ [key]: value }, false, EDIT_BIND_ACTIONS_METADATA.SET_BIND_ACTIONS_STORE_BY_KEY);
      },

      clearEditBindActionsStore: () => {
        set(
          {
            presetBrokerConfig: undefined,
            agencyRowDisabled: false,
            brokerConfig: [],
            updateBrokerConfig: undefined,
            selectedCarrierDetails: undefined,
          },
          false,
          EDIT_BIND_ACTIONS_METADATA.CLEAR_BIND_ACTIONS_STORE,
        );
      },
    }),
    { anonymousActionType: EDIT_BIND_ACTIONS_METADATA.ANONYMOUS_ACTION, name: EDIT_BIND_ACTIONS_METADATA.STORE_NAME },
  ),
);
