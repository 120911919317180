import { IGetLoginAndRegPageConfigInput } from '@coverforce-platform/cf-common-api-model';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getLoginRegistrationConfig } from '../../../externalServices/V2/themeService';
import { IRegistrationStore } from './interface';

export const useRegistrationStore = create<IRegistrationStore>()(
  devtools(
    (set) => ({
      regConfig: undefined,
      setRegConfig: (regConfig: any) => {
        set({ regConfig }, false, 'SET_REG_CONFIG');
      },
      getLoginRegistrationConfigByClientAppName: async (params: IGetLoginAndRegPageConfigInput) => {
        try {
          const response = await getLoginRegistrationConfig(params);
          return response;
        } catch (error) {
          return undefined;
        }
      },
    }),
    { name: 'Registration Store', anonymousActionType: 'REG_ACTION' },
  ),
);
