import { useEffect, useState } from 'react';

import { QUOTES_STORE_KEYS } from '../../constants/quotesConstants';
import { useApplicationStore } from '../../pages/v2/application/store';
import { errorV2 } from '../../ui-core/Notification';
import { useCFModalStore } from '../../ui-core/V2/cfModal/store';
import { pushEvent } from '../../utils/googleAnalytics';
import { GA_ACTION, GA_LABEL } from '../quotes/constants';
import { useQuotesStore } from '../quotes/store';
import { REFER_QUOTE_ERROR, REFER_QUOTE_MODAL, REFER_QUOTE_MODAL_STORE_KEYS } from './constants';
import { useReferQuoteModalStore } from './store';
import { ReferQuoteModalStyled, ReferQuoteNoteStyled } from './styles';
import { getGACategory } from './utils';

const ReferQuoteModal = () => {
  const { clearCfModal } = useCFModalStore();
  const { selectedQuote, fetchQuotes, updateQuotesByKey } = useQuotesStore();
  const { applicationData, fetchApplication } = useApplicationStore();
  const { isReferQuoteLoading, referQuote, updateReferQuoteModalByKey, clearReferQuoteModal } =
    useReferQuoteModalStore();

  const { applicationId = '' } = applicationData || {};
  const { cfQuoteId = '', carrierId = '' } = selectedQuote || {};

  const [noteToUnderwriter, setNoteToUnderwriter] = useState('');

  useEffect(() => {
    return () => {
      clearReferQuoteModal();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNoteToUnderwriter(event.target.value);
  };

  const onReferQuote = async () => {
    pushEvent({
      category: getGACategory(carrierId),
      action: GA_ACTION.REFER_QUOTE_BTN_CLICK,
      label: GA_LABEL.REFER_QUOTE,
    });
    try {
      const referQuoteResponse = await referQuote({ applicationId, cfQuoteId, noteToUnderwriter });
      if (referQuoteResponse.errors.length === 0) {
        await fetchQuotes(false);
        await fetchApplication(applicationId, false);
        await clearCfModal();
      } else {
        referQuoteResponse.errors.map((errorMessage) => errorV2(errorMessage, 10));
      }
    } catch (error: any) {
      errorV2(error?.[0]?.errorMessage || REFER_QUOTE_ERROR);
    } finally {
      updateReferQuoteModalByKey(REFER_QUOTE_MODAL_STORE_KEYS.IS_REFER_QUOTE_LOADING, false);
    }
  };

  const onReferQuoteCancel = () => {
    updateQuotesByKey(QUOTES_STORE_KEYS.SELECTED_QUOTE, undefined);
    pushEvent({
      category: getGACategory(carrierId),
      action: GA_ACTION.CLOSE_MODAL_BTN_CLICK,
      label: GA_LABEL.CLOSE_REFER_QUOTE_MODAL,
    });
  };

  return (
    <ReferQuoteModalStyled
      title={REFER_QUOTE_MODAL.TITLE}
      okText='Submit'
      onOk={onReferQuote}
      onCancel={onReferQuoteCancel}
      cancelButtonProps={{ disabled: isReferQuoteLoading }}
      okButtonProps={{ loading: isReferQuoteLoading, disabled: isReferQuoteLoading }}
      disableCloseIcon={isReferQuoteLoading}
      maskClosable={!isReferQuoteLoading}
    >
      <ReferQuoteNoteStyled
        id='inp_refer_quote'
        size='large'
        showCount
        maxLength={250}
        value={noteToUnderwriter}
        onChange={onChangeInput}
        placeholder={REFER_QUOTE_MODAL.PLACEHOLDER}
        disabled={isReferQuoteLoading}
      />
    </ReferQuoteModalStyled>
  );
};
export default ReferQuoteModal;
