import { useEffect } from 'react';
import { useTheme } from 'styled-components';

import { QUOTES_STORE_KEYS } from '../../constants/quotesConstants';
import { pushEvent } from '../../utils/googleAnalytics';
import { getQuoteBridgeAnchorLink } from '../../utils/quotes/quotes';
import { GA_ACTION, GA_CATEGORY, GA_LABEL } from '../quotes/constants';
import { useQuotesStore } from '../quotes/store';
import { DECLINED_REASONS_MODAL } from './constants';
import { DeclinedReasonItemStyled, DeclinedReasonsModalStyled, DeclinedReasonsStyled } from './styles';

const DeclinedReasonsModal = () => {
  const theme: any = useTheme();
  const { selectedQuote, updateQuotesByKey } = useQuotesStore();
  const { failures = [] } = selectedQuote || {};

  useEffect(() => {
    return () => {
      updateQuotesByKey(QUOTES_STORE_KEYS.SELECTED_QUOTE, undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    pushEvent({
      category: GA_CATEGORY.QUOTES_TAB_DECLINED_REASON_MODAL,
      action: GA_ACTION.CLOSE_MODAL_BTN_CLICK,
      label: GA_LABEL.CLOSE_DECLINED_REASON_MODAL,
    });
  };

  return (
    <DeclinedReasonsModalStyled onCancel={handleCancel} title={DECLINED_REASONS_MODAL.TITLE} footer={null}>
      <DeclinedReasonsStyled>
        {failures.map((item, index) => (
          <DeclinedReasonItemStyled key={`${item.code}-${index}`}>{item.reason}</DeclinedReasonItemStyled>
        ))}
      </DeclinedReasonsStyled>
      {getQuoteBridgeAnchorLink(theme)}
    </DeclinedReasonsModalStyled>
  );
};

export default DeclinedReasonsModal;
