import { AcordFormType, PolicyType } from '@coverforce-platform/cf-common-types';

export const getPolicyTypeForAcord = (acord: AcordFormType) => {
  switch (acord) {
    case AcordFormType.ACORD_125:
      return PolicyType.CAUTO; /** Commercial Insurance */
    case AcordFormType.ACORD_126:
      return PolicyType.CGL; /** CGL */
    case AcordFormType.ACORD_130:
      return PolicyType.WC; /** WC */
    case AcordFormType.ACORD_160:
      return PolicyType.BOP; /** BOP */
  }
};
