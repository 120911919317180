import { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';

import { getNavBarItem } from './componentUtils';
import { NavItemProps } from './interface';
import { CFDropDownStyled, NavItemContainer } from './styles';

const NavItem = (navitemProps: NavItemProps) => {
  const { title, dropdownMenu, style } = navitemProps;

  const theme: any = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const navItem = getNavBarItem({ navitemProps, theme, isHovered, handleMouseLeave, handleMouseEnter });

  return (
    <NavItemContainer style={style}>
      {dropdownMenu === undefined && navItem}
      {dropdownMenu && (
        <CFDropDownStyled id={`dropdown_${title}`} overlay={dropdownMenu}>
          {navItem}
        </CFDropDownStyled>
      )}
    </NavItemContainer>
  );
};

export default NavItem;
