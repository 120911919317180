import { RedoOutlined } from '@ant-design/icons';
import { useCallback } from 'react';

import { BUTTON_TYPE } from '../../globalConstants';
import { IErrorRetry } from './interface';
import { ErrorTextStyled, RetryButtonStyled } from './styles';

const ErrorRetry = ({
  errorText,
  retryButtonText = 'Retry',
  children,
  showRetryButton = true,
  onRetry,
}: IErrorRetry) => {
  const handleRetry = useCallback(() => {
    onRetry && onRetry();
  }, [onRetry]);

  return (
    <>
      <ErrorTextStyled>{errorText}</ErrorTextStyled>
      {children}
      {showRetryButton && (
        <RetryButtonStyled
          id='btn_error_retry'
          buttonType={BUTTON_TYPE.SECONDARY}
          icon={<RedoOutlined />}
          onClick={handleRetry}
        >
          {retryButtonText}
        </RetryButtonStyled>
      )}
    </>
  );
};

export default ErrorRetry;
