import { USState } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getOwnerOfficerTitle } from '../../externalServices/V2/ownersOfficers';
import { useApplicationStore } from '../../pages/v2/application/store';
import { formatToAntdOptions } from '../../utils/formatToAntdOptions';
import { OWNER_OFFICER_DETAILS_STORE_META_DATA, OWNERS_OFFICERS_TITLE_RESPONSE_KEY } from './constants';
import { IOwnerDetailsStore } from './interface';
import { createOwnerOfficerDetailsApiPayload, validateOwnerOfficerDetails } from './utils';

export const useOwnerOfficerDetailsStore = create<IOwnerDetailsStore>()(
  devtools(
    (set, get) => ({
      ownerOfficerDetailsList: [],
      ownerOfficerDetailsError: undefined,
      ownerOfficerDetailsLoading: false,
      ownerOfficerDetailsTabLoading: false,
      ownersOfficersTitleOptions: {},

      updateOwnerOfficerDetailsStoreByKey: async (storeKey: keyof IOwnerDetailsStore, value: any) => {
        set(
          () => ({ [storeKey]: value }),
          false,
          OWNER_OFFICER_DETAILS_STORE_META_DATA.UPDATE_OWNER_OFFICER_STORE_BY_KEY,
        );
      },

      addOwnerOfficerDetails: (ownerOfficerDetails) => {
        const { ownerOfficerDetailsList } = get();
        ownerOfficerDetails.jobCodeExtendedId = ownerOfficerDetails?.ncciStateClassCode?.split('-')?.[1]?.trim();
        ownerOfficerDetails.ncciStateClassCode = ownerOfficerDetails?.ncciStateClassCode?.split('-')?.[0]?.trim();

        set(
          {
            ownerOfficerDetailsList: [...ownerOfficerDetailsList, ownerOfficerDetails],
          },
          false,
          OWNER_OFFICER_DETAILS_STORE_META_DATA.ADD_OWNER_OFFICER_DETAILS,
        );
      },

      updateOwnerOfficerDetails: (ownerOfficerDetails, index) => {
        const { ownerOfficerDetailsList, ownerOfficerDetailsError } = get();
        const currentDetails = [...ownerOfficerDetailsList];
        const errors = ownerOfficerDetailsError ? [...ownerOfficerDetailsError] : [];

        ownerOfficerDetails.jobCodeExtendedId = ownerOfficerDetails?.ncciStateClassCode?.split('-')?.[1]?.trim();
        ownerOfficerDetails.ncciStateClassCode = ownerOfficerDetails?.ncciStateClassCode?.split('-')?.[0]?.trim();

        currentDetails?.splice(index, 1, ownerOfficerDetails);
        errors?.splice(index, 1, { isError: false, errorMessage: '' });

        set(
          { ownerOfficerDetailsList: currentDetails, ownerOfficerDetailsError: errors },
          false,
          OWNER_OFFICER_DETAILS_STORE_META_DATA.UPDATE_OWNER_OFFICER_DETAILS,
        );
      },

      deleteOwnerOfficerDetails: (index: number) => {
        const { ownerOfficerDetailsList, ownerOfficerDetailsError } = get();
        const currentDetails = [...ownerOfficerDetailsList];
        const errors = ownerOfficerDetailsError ? [...ownerOfficerDetailsError] : [];

        currentDetails?.splice(index, 1);
        errors?.splice(index, 1);

        set(
          { ownerOfficerDetailsList: currentDetails, ownerOfficerDetailsError: errors },
          false,
          OWNER_OFFICER_DETAILS_STORE_META_DATA.DELETE_OWNER_OFFICER_DETAIL,
        );
      },

      autoFillOwnerOfficerDetails: () => {
        const { applicationData } = useApplicationStore.getState();
        const { companyStructure } = applicationData || {};
        const { ownershipDetails } = companyStructure || {};
        const { owners } = ownershipDetails || {};

        const ownerOfficerDetailsList = owners?.map((owner) => ({ ...owner })) || [];

        set({ ownerOfficerDetailsList }, false, OWNER_OFFICER_DETAILS_STORE_META_DATA.AUTO_FILL_OWNER_OFFICER_DETAILS);
      },

      fetchOwnerOfficerTitleOptions: async (state: USState, ownerOfficerModalLoading = true) => {
        const { ownersOfficersTitleOptions } = get();
        const { applicationData } = useApplicationStore.getState();
        set(
          {
            ownersOfficersTitleOptions: {
              ...ownersOfficersTitleOptions,
              [state]: { ...ownersOfficersTitleOptions?.[state], loading: ownerOfficerModalLoading },
            },
          },
          false,
          OWNER_OFFICER_DETAILS_STORE_META_DATA.SET_OWNERS_OFFICERS_TITLE_OPTIONS,
        );

        try {
          if (ownersOfficersTitleOptions?.[state]?.data?.length > 0) {
            set(
              {
                ownersOfficersTitleOptions: {
                  ...ownersOfficersTitleOptions,
                  [state]: { ...ownersOfficersTitleOptions?.[state], loading: false },
                },
              },
              false,
              OWNER_OFFICER_DETAILS_STORE_META_DATA.SET_OWNERS_OFFICERS_TITLE_OPTIONS,
            );

            return ownersOfficersTitleOptions?.[state]?.data;
          }

          const ownersOfficersTitleRes = await getOwnerOfficerTitle({
            cfLegalEntityId: applicationData?.companyStructure?.legalEntityType!,
            state,
          });
          const ownersOfficersTitleOptionsRes = formatToAntdOptions({
            list: ownersOfficersTitleRes?.cfOwnerOfficerTitles || [],
            listLabelKey: OWNERS_OFFICERS_TITLE_RESPONSE_KEY.TITLE_LABEL,
            listValueKey: OWNERS_OFFICERS_TITLE_RESPONSE_KEY.TITLE_VALUE,
          });

          set(
            {
              ownersOfficersTitleOptions: {
                ...ownersOfficersTitleOptions,
                [state]: {
                  ...ownersOfficersTitleOptions?.[state],
                  loading: false,
                  data: ownersOfficersTitleOptionsRes,
                },
              },
            },
            false,
            OWNER_OFFICER_DETAILS_STORE_META_DATA.SET_OWNERS_OFFICERS_TITLE_OPTIONS,
          );

          return ownersOfficersTitleOptionsRes;
        } catch (error: any) {
          set(
            {
              ownersOfficersTitleOptions: {
                ...ownersOfficersTitleOptions,
                [state]: { ...ownersOfficersTitleOptions?.[state], loading: false, data: [] },
              },
            },
            false,
            OWNER_OFFICER_DETAILS_STORE_META_DATA.SET_OWNERS_OFFICERS_TITLE_OPTIONS,
          );

          return [];
        }
      },

      getOwnerOfficerDetailsApiPayload: () => {
        const { ownerOfficerDetailsList } = get();
        const { applicationData } = useApplicationStore.getState();

        const ownerOfficerDetailsPayload = createOwnerOfficerDetailsApiPayload(ownerOfficerDetailsList);
        return {
          ...applicationData,
          companyStructure: {
            ...applicationData?.companyStructure,
            ownershipDetails: {
              owners: ownerOfficerDetailsPayload,
            },
          },
        };
      },

      validateOwnerOfficerDetails: () => {
        const { ownerOfficerDetailsList } = get();
        const validatedOwnerOfficerDetails = validateOwnerOfficerDetails(ownerOfficerDetailsList || []);
        if (!validatedOwnerOfficerDetails.isValid) {
          set(
            { ownerOfficerDetailsError: validatedOwnerOfficerDetails.errors },
            false,
            OWNER_OFFICER_DETAILS_STORE_META_DATA.SET_ERROR_OWNER_OFFICER_DETAILS,
          );
        } else {
          set(
            { ownerOfficerDetailsError: undefined },
            false,
            OWNER_OFFICER_DETAILS_STORE_META_DATA.SET_ERROR_OWNER_OFFICER_DETAILS,
          );
        }
        return validatedOwnerOfficerDetails.isValid;
      },

      clearOwnerOfficerDetails: () => {
        set(
          {
            ownerOfficerDetailsList: [],
            ownerOfficerDetailsError: undefined,
            ownerOfficerDetailsLoading: false,
            ownerOfficerDetailsTabLoading: false,
            ownersOfficersTitleOptions: {},
          },
          false,
          OWNER_OFFICER_DETAILS_STORE_META_DATA.CLEAR_OWNER_OFFICER_STORE,
        );
      },
    }),
    {
      anonymousActionType: OWNER_OFFICER_DETAILS_STORE_META_DATA.ANONYMOUS_ACTION_NAME,
      name: OWNER_OFFICER_DETAILS_STORE_META_DATA.STORE_NAME,
    },
  ),
);
