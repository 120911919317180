import { ILocationInfo } from '@coverforce-platform/cf-common-api-model';
import { cloneDeep } from 'lodash';

export const movePrimaryAddressToTop = ({ locationDetails }: { locationDetails: ILocationInfo[] }) => {
  let locations = cloneDeep(locationDetails);

  const primaryLocationIndex =
    locationDetails !== undefined ? locationDetails?.findIndex((location) => location?.isPrimary) : -1;
  const primaryLocation = locationDetails?.find((location) => location?.isPrimary);

  if (primaryLocationIndex !== -1) {
    locations?.splice(primaryLocationIndex, 1);
  }
  if (primaryLocation) {
    locations = locations?.length ? [primaryLocation, ...locations] : [primaryLocation];
  }

  return locations;
};
