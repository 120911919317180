import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { referQuote as referQuoteRequest } from '../../externalServices/V2/quotes';
import { REFER_QUOTE_MODAL_STORE } from './constants';
import { IReferQuoteModalStore } from './interface';

export const useReferQuoteModalStore = create<IReferQuoteModalStore>()(
  devtools(
    (set) => ({
      isReferQuoteLoading: false,

      referQuote: async ({ applicationId, cfQuoteId, noteToUnderwriter = '', isReferQuoteLoading = true }) => {
        set({ isReferQuoteLoading }, false, REFER_QUOTE_MODAL_STORE.REFER_QUOTE_LOADING);

        const referQuoteResponse = await referQuoteRequest({ applicationId, cfQuoteId, noteToUnderwriter });
        return referQuoteResponse;
      },

      updateReferQuoteModalByKey: async (storeKey: keyof IReferQuoteModalStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, REFER_QUOTE_MODAL_STORE.UPDATE_REFER_QUOTE_MODAL_STORE_BY_KEY),

      clearReferQuoteModal: () => set({ isReferQuoteLoading: false }, false, REFER_QUOTE_MODAL_STORE.CLEAR_REFER_QUOTE),
    }),

    { name: REFER_QUOTE_MODAL_STORE.NAME, anonymousActionType: REFER_QUOTE_MODAL_STORE.ANONYMOUS_ACTION },
  ),
);
