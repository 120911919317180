export const getFromSessionStorage = (storageKey: string): any => {
  if (typeof window !== 'undefined' && window) {
    const stringifiedData = sessionStorage.getItem(storageKey);
    try {
      return (stringifiedData && JSON.parse(stringifiedData)) || null;
    } catch (err) {
      return stringifiedData;
    }
  }
  return null;
};
