import { IQuoteInfo } from '@coverforce-platform/cf-common-api-model';
import {
  AgentPortalUserType,
  Carrier,
  PolicyType,
  QuoteStatus,
  QuoteTag,
  QuoteType,
} from '@coverforce-platform/cf-common-types';

import { useBrokerCodesStore } from '../../components/brokerCodes/store';
import { useConfigurationStore } from '../../components/configuration/store';
import { useQuotesStore } from '../../components/quotes/store';
import { QuoteCardLogoStyled, QuoteCardLogoWrapperStyled } from '../../components/quotes/styles';
import { QUOTES_STORE_KEYS } from '../../constants/quotesConstants';
import { useApplicationStore } from '../../pages/v2/application/store';
import { ITheme } from '../../types';
import Anchor from '../../ui-core/V2/anchor/anchor';
import { ANCHOR_TARGET } from '../../ui-core/V2/anchor/constants';

export const getLogoByCarrierId = (carrierId: Carrier) => {
  const { selectedCarriersInfo } = useApplicationStore.getState();
  const selectedCarrier = selectedCarriersInfo?.find((carrierInfo) => carrierInfo?.carrierId === carrierId);
  return (
    <QuoteCardLogoWrapperStyled>
      <QuoteCardLogoStyled src={selectedCarrier?.carrierLogoUrl} />
    </QuoteCardLogoWrapperStyled>
  );
};

export const setSelectedQuoteInQuoteStore = (selectedQuote: IQuoteInfo) => {
  const { updateQuotesByKey } = useQuotesStore.getState();
  updateQuotesByKey(QUOTES_STORE_KEYS.SELECTED_QUOTE, selectedQuote);
};

export const isBindRequestedQuote = (quote?: IQuoteInfo): boolean => {
  const { quoteTags = [] } = quote || {};
  return quoteTags.includes(QuoteTag.BIND_REQUESTED);
};

export const getAllowedQuoteActions = ({ quote, theme }: { quote?: IQuoteInfo; theme: any }) => {
  const { userType } = useConfigurationStore.getState();
  const { allCarrierBrokerConfigs } = useBrokerCodesStore.getState();
  const { applicationData } = useApplicationStore.getState();
  const { brokerConfiguration } = applicationData || {};
  const { carrierId, carrierQuoteId, carrierBridgingLink, quoteType, quoteStatus } = quote || {};
  const { statusCode: quoteStatusCode } = quoteStatus || {};

  const quoteBrokerConfigurationId = brokerConfiguration?.find(
    (item) => item.carrierId === carrierId,
  )?.brokerConfigurationId;
  const quoteBrokerConfiguration = (allCarrierBrokerConfigs?.[carrierId!] || []).find(
    (item) => item.brokerConfigurationId === quoteBrokerConfigurationId,
  );
  const { brokerCodeRestrictions } = quoteBrokerConfiguration || {};
  const {
    isBindNowDisabledForAgency = false,
    isRequestToBindDisabledForAgency = false,
    isReferToUWDisabledForAgency = false,
    isQuoteProposalDisabledForAgency = false,
    isBridgingDisabledForAgency = false,
    isAddModifiersDisabledForAgency = false,
  } = brokerCodeRestrictions || {};

  const isQuoteTypeAllowed =
    [QuoteType.REFER, QuoteType.BIND_ONLINE, QuoteType.BRIDGE].includes(quoteType as QuoteType) &&
    !theme?.config?.disableQuoteType;

  let isDownloadQuoteProposalAllowed =
    carrierQuoteId && !theme?.config?.disabledQuoteDownloadCarriers?.[userType]?.includes(carrierId);

  let isReferToUWQuoteAllowed =
    quoteType === QuoteType.REFER &&
    quoteStatusCode !== QuoteStatus.REFERRED &&
    !theme?.config?.disabledReferCarriers?.[userType]?.includes(carrierId);

  let isBindNowQuoteAllowed =
    quoteType === QuoteType.BIND_ONLINE &&
    quoteStatusCode !== QuoteStatus.BOUND &&
    !theme?.config?.disabledBindCarriers?.[userType]?.includes(carrierId);

  let isRequestToBindQuoteAllowed =
    userType === AgentPortalUserType.AGENCY
      ? [QuoteType.REFER, QuoteType.BIND_ONLINE, QuoteType.BRIDGE].includes(quoteType!) &&
        !isBindRequestedQuote(quote) &&
        !theme?.config?.disabledRequestToBindCarriers?.[userType]?.includes(carrierId)
      : false;

  let isCancelRequestToBindQuoteAllowed =
    [QuoteType.REFER, QuoteType.BIND_ONLINE, QuoteType.BRIDGE].includes(quoteType!) &&
    isBindRequestedQuote(quote) &&
    !theme?.config?.disabledRequestToBindCarriers?.[userType]?.includes(carrierId);

  let isBridgeQuoteAllowed =
    carrierBridgingLink && !theme?.config?.disabledBridgingCarriers?.[userType]?.includes(carrierId);

  const isMarkAsBoundQuoteAllowed = !theme?.config?.disabledMarkAsBoundCarriers?.[userType]?.includes(carrierId);

  let isAddModifiersAllowed = !theme?.config?.disabledAddModifiersCarriers?.[userType]?.includes(carrierId);

  if (userType === AgentPortalUserType.AGENCY) {
    isDownloadQuoteProposalAllowed = isDownloadQuoteProposalAllowed && !isQuoteProposalDisabledForAgency;
    isReferToUWQuoteAllowed = isReferToUWQuoteAllowed && !isReferToUWDisabledForAgency;
    isBindNowQuoteAllowed = isBindNowQuoteAllowed && !isBindNowDisabledForAgency;
    isRequestToBindQuoteAllowed = isRequestToBindQuoteAllowed && !isRequestToBindDisabledForAgency;
    isCancelRequestToBindQuoteAllowed = isCancelRequestToBindQuoteAllowed && !isRequestToBindDisabledForAgency;
    isBridgeQuoteAllowed = isBridgeQuoteAllowed && !isBridgingDisabledForAgency;
    isAddModifiersAllowed = isAddModifiersAllowed && !isAddModifiersDisabledForAgency;
  }

  return {
    isQuoteTypeAllowed,
    isDownloadQuoteProposalAllowed,
    isReferToUWQuoteAllowed,
    isBindNowQuoteAllowed,
    isRequestToBindQuoteAllowed,
    isCancelRequestToBindQuoteAllowed,
    isBridgeQuoteAllowed,
    isMarkAsBoundQuoteAllowed,
    isAddModifiersAllowed,
  };
};

export const getQuoteBridgeAnchorLink = (theme: ITheme) => {
  const { selectedCarriersInfo } = useApplicationStore.getState();
  const { selectedQuote } = useQuotesStore.getState();
  const { isBridgeQuoteAllowed } = getAllowedQuoteActions({ quote: selectedQuote, theme });
  const { carrierBridgingLink } = selectedQuote || {};
  const selectedCarrier = selectedCarriersInfo.find((item) => item.carrierId === selectedQuote?.carrierId);
  const { carrierShortName } = selectedCarrier || {};

  return (
    <>
      {isBridgeQuoteAllowed && (
        <>
          You can view and continue the quote on the{' '}
          <Anchor id={`link_quote_bridge`} href={carrierBridgingLink} target={ANCHOR_TARGET.BLANK}>
            {carrierShortName} Agent Portal.
          </Anchor>
        </>
      )}
    </>
  );
};

export const isLiabilityLimitsApplicable = () => {
  const { policyType } = useApplicationStore.getState();
  return policyType !== PolicyType.BR;
};

export const isTotalProjectValueApplicable = () => {
  const { policyType } = useApplicationStore.getState();
  return policyType === PolicyType.BR;
};
