import { IEmptyProps } from './interface';
import { EmptyStyled } from './styles';

const Empty = (props: IEmptyProps) => {
  const { children } = props;

  return <EmptyStyled {...props}>{children}</EmptyStyled>;
};

export default Empty;
