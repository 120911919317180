import { AgentPortalUserType } from '@coverforce-platform/cf-common-types';

import { ITheme } from '../../types';
import { getAllCarrierIds } from '../../utils/getAllCarrierIds';

const BaseTheme: ITheme = {
  themeName: 'cf_theme',

  colorScheme: {
    primary: '#624de3',
    primaryHover: '#8c78f0',
    isPrimaryHoverLight: false,
    primaryHoverLight: '#8c78f0',
  },

  component: {
    navBar: {
      backgroundColor: '#3639a4',
      textColor: '#ffffff',
    },
  },

  colors: {
    primary: {
      bgMuted: '#EAF0F6',
      border: '#DDE2EA',
      borderLight: '#E8ECEE',
      requiredText: '#ff4d4f',
      blackOpacity: 'rgba(0, 0, 0, 0.15)',
      darkGray: '#657695',
      whiteBg: 'rgb(245, 244, 244)',
      footerTextColor: 'rgb(51,51,51,0.85)',
    },
    common: {
      pageBgColor: '#f8faff',
      menuItemSelectedBgColor: '#eff4ff',
      inputBoxShadow: 'rgba(21, 79, 140, 0.2)',
      warningColor: '#faad14',
      successColor: '#22bb33',
      errorColor: '#bb2124',
    },
    grayscale: {
      white: '#ffffff',
      lightGrayText: '#617798',
      lightGrayBg: '#FAFAFA',
      darkGray: '#999999',
      black: '#000000',
      grayBorder: '#d9d9d9',
      blackOpacity: 'rgba(0, 0, 0, 0.25)',
      grayBg: '#f5f5f5',
      blackOpacityLight: 'rgba(0, 0, 0, 0.45)', // account info sidebar meta title color
    },
    navBar: {
      dropDownItemSelectedBgColor: '#e8fbff',
      dropDownItemSelectedTextColor: '#0c4a4f',
    },

    application: {
      redBg: '#d32c28',
      darkRedBg: '#ad2620',
    },
  },

  typography: {
    fontWeight: {
      light: 300,
      regular: 400,
      medium: 500,
      mediumBold: 600,
      bold: 700,
    },
    fontSize: {
      extraMini: '10px',
      mini: '12px',
      small: '13px',
      medium: '14px',
      extendedMedium: '16px',
      mediumLarge: '18px',
      extendedMediumLarge: '20px',
      almostLarge: '24px',
      large: '26px',
      extendedLarge: '30px',
      xl: '32px',
    },
    fontFamily: {
      primary: `'Inter', sans-serif`,
    },
  },

  custom: {
    boxShadow: {
      navBar: '0px 0px 10px rgba(0, 0, 0, 0.75)',
      black: '1px 1px 5px 0px rgba(0, 0, 0, 0.3)',
      login: '0px 8px 8px 8px rgba(0, 0, 0, 0.06)',
      profile: '0px 0px 4px rgba(0, 0, 0, 0.06)',
      table: '6px 6px 54px rgba(0, 0, 0, 0.05)',
      dashBoard: '0px 0px 10px rgba(0, 0, 0, 0.25)',
      actionButton: '0 1px 4px rgba(0, 0, 0, 0.45)',
    },
    blankCell: {
      main: '#483900',
      bg: '#ffe68b',
    },
    applicationStatus: {
      acordInProgress: 'purple',
      reassign: 'purple',
      bindRequested: 'purple',
      created: 'orange',
      inProgress: 'orange',
      quoting: 'blue',
      quoted: 'blue',
      referred: 'green',
      bound: 'green',
      declined: 'red',
      failed: 'red',
      deleted: 'red',
    },
    fileContainer: {
      main: '#61d5e1',
      bg: '#f0fcff',
      hoverBorder: '#9ed1da',
      buttonBorder: '#91e5ee',
    },
    tags: {
      network: 'orange',
      agency: 'blue',
      type: 'geekblue',
      admin: 'purple',
      defaultBrokerCode: 'red',
      green: 'green',
      volcano: 'volcano',
    },
    appetite: {
      inAppetite: 'green',
      maybeAppetite: 'blue',
      notAppetite: 'red',
    },
    carrier: {
      main: 'rgba(63, 63, 68, 0.05)',
      rankBg: '#959595',
      dragButtonBg: '#f6f8fb',
      buttonBg: 'transparent',
      shadow: 'rgba(255, 255, 255, 0.7)',
      boxShadow1: 'rgba(34, 33, 81, 0.15)',
      boxShadow2: 'rgba(34, 33, 81, 0.02)',
      boxShadow3: 'rgba(34, 33, 81, 0.01)',
      boxShadow4: 'rgba(34, 33, 81, 0.25)', // carrier item container overlay box shadow
    },
    application: {
      blackOpacity: 'rgba(0, 0, 0, 0.85)',
    },
  },

  config: {
    showDashboardWhatsNew: true,
    showDashboardApiStatus: true,
    disableCarrierRankings: true,
    isSSOTheme: false,
    disableQuoteType: true,
    disabledReassignApplication: {
      [AgentPortalUserType.AGENCY]: true,
      [AgentPortalUserType.AGENCY_NETWORK]: false,
    },
    disabledBridgingCarriers: {
      [AgentPortalUserType.AGENCY]: [],
      [AgentPortalUserType.AGENCY_NETWORK]: [],
    },
    disabledBindCarriers: {
      [AgentPortalUserType.AGENCY]: [],
      [AgentPortalUserType.AGENCY_NETWORK]: [],
    },
    disabledReferCarriers: {
      [AgentPortalUserType.AGENCY]: [],
      [AgentPortalUserType.AGENCY_NETWORK]: [],
    },
    disabledMarkAsBoundCarriers: {
      [AgentPortalUserType.AGENCY]: [],
      [AgentPortalUserType.AGENCY_NETWORK]: [],
    },
    disabledQuoteDownloadCarriers: {
      [AgentPortalUserType.AGENCY]: [],
      [AgentPortalUserType.AGENCY_NETWORK]: [],
    },
    disabledRequestToBindCarriers: {
      [AgentPortalUserType.AGENCY]: getAllCarrierIds(),
      [AgentPortalUserType.AGENCY_NETWORK]: getAllCarrierIds(),
    },
  },

  brandLogo: '/logo/cf-logo.svg',
};

export { BaseTheme };
