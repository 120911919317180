import { TimePicker } from 'antd';
import styled from 'styled-components';

export const CFTimePickerStyled = styled(TimePicker)`
  :hover {
    border-color: ${(p) => p.theme?.colorScheme?.primaryHover};
  }
`;

export const CFTimePickerWrapperStyled = styled.div`
  .ant-picker-now-btn {
    color: ${(p) => p?.theme?.colorScheme?.primary};

    :hover {
      color: ${(p) => p?.theme?.colorScheme?.primaryHover};
    }
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background-color: ${(p) => p?.theme?.colorScheme?.primary};
    color: ${(p) => p?.theme?.colors?.grayscale?.white};

    :hover {
      background-color: ${(p) => p?.theme?.colorScheme?.primary};
      color: ${(p) => p?.theme?.colors?.grayscale?.white};
    }
  }
  .ant-picker-focused {
    border-color: ${(p) => p.theme?.colorScheme?.primaryHover} !important;
  }
`;
