import { APPLICATION_TABS } from '../../../constants/applicationV2Constants';
import { useApplicationStore } from '../../../pages/v2/application/store';
import { GA_CATEGORY as APPLICATION_STEP_GA_CATEGORY } from '../../applicationSteps/constants';
import { GA_CATEGORY as CONFIRMATION_GA_CATEGORY } from '../../confirmation/constants';
import { useApplicationDocumentCenterStore } from './store';

export const getGACategory = () => {
  const { currentStep } = useApplicationStore.getState();
  const { defaultFilter } = useApplicationDocumentCenterStore.getState();

  if (currentStep === APPLICATION_TABS.CONFIRMATION && defaultFilter?.fileType?.length) {
    return CONFIRMATION_GA_CATEGORY.CONFIRMATION_TAB_POLICY_DOCUMENT;
  }

  return APPLICATION_STEP_GA_CATEGORY.APPLICATION_SIDEBAR_DOCUMENT_CENTER_MODAL;
};
