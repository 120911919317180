import { Empty, Tag } from 'antd';
import styled from 'styled-components';

import CFButton from '../../../ui-core/V2/cfButton/cfButton';
import CFInput from '../../../ui-core/V2/cfInput/cfInput';
import CFTable from '../../../ui-core/V2/cfTable/cfTable';
import { CFTableHeaderStyled } from '../../../ui-core/V2/cfTable/styles';

export const EmptyMessageStyled = styled.div`
  margin-bottom: 20px;
`;

export const TableEmptyStyled = styled(Empty)`
  margin: 80px auto;
`;

export const ApplicationsTableStyled = styled(CFTable)`
  .ant-table .ant-table-thead > tr > th {
    padding: 8px 12px;
  }
  .ant-table .ant-table-tbody > tr:not(.ant-table-measure-row) > td {
    padding: 8px 12px !important;
  }
  .ant-tag {
    margin-right: 0 !important;
  }
`;

export const DateCellStyled = styled.div`
  white-space: nowrap;
`;

export const CFTableHeaderCustomStyled = styled(CFTableHeaderStyled)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const CFInputStyled = styled(CFInput)`
  flex: 1;
`;

export const TagStyled = styled(Tag)`
  margin: 2px 0px;
  width: max-content;
`;

export const TagContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CFButtonStyled = styled(CFButton)`
  margin-left: 10px;
`;
