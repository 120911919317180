import { IBrokerCodeAgentInfo as BrokerIdConfig } from '@coverforce-platform/cf-common-api-model';
import { Carrier, IdHelper, IDType } from '@coverforce-platform/cf-common-types';
import { TableColumnProps } from 'antd';
import { DefaultTheme } from 'styled-components';

import { getBrokerCodeFullName } from '../../../utils/brokerConfig/broker';
import { TagStyled } from './styles';

export const getCarrierSpecificBrokerColumn = (selectedCarrier: string) => {
  const columns = [];
  const emailField = {
    title: 'Email',
    dataIndex: 'contactDetails.Email',
    key: 'contactDetails.Email',
    render: (_: any, brokerConfig: BrokerIdConfig) => (
      <span
        id={`contact_email_${brokerConfig?.brokerConfigurationId}`}
        key={`contact-email-${brokerConfig?.brokerConfigurationId}`}
      >
        {brokerConfig?.contactDetails?.email}
      </span>
    ),
    sorter: (a: BrokerIdConfig, b: BrokerIdConfig) => {
      return a?.contactDetails?.email && b?.contactDetails?.email
        ? a?.contactDetails?.email > b?.contactDetails?.email
          ? 1
          : -1
        : 1;
    },
  };

  const primaryContact = {
    title: 'Primary Contact',
    dataIndex: 'contactDetails',
    key: 'contactDetails',
    width: '25%',
    render: (_: any, brokerConfig: BrokerIdConfig) => (
      <span
        id={`contact_${brokerConfig?.brokerConfigurationId}`}
        key={`contact-${brokerConfig?.brokerConfigurationId}`}
      >
        {getBrokerCodeFullName(brokerConfig)}
      </span>
    ),
    sorter: (a: BrokerIdConfig, b: BrokerIdConfig) => {
      return getBrokerCodeFullName(a) > getBrokerCodeFullName(b) ? 1 : -1;
    },
  };
  switch (selectedCarrier) {
    case Carrier.AMTRUST:
    case Carrier.EMPLOYERS:
      columns.push(emailField);
      break;
    case Carrier.LIBERTYMUTUAL:
      columns.push(primaryContact);
      columns.push(emailField);
      break;
    case Carrier.CNA:
      columns.push(primaryContact);
      break;
    default:
      break;
  }
  return columns;
};

export const getBrokerConfigColumns = ({
  theme,
  selectedCarrier,
}: {
  theme: DefaultTheme;
  selectedCarrier: string;
}): Array<TableColumnProps<BrokerIdConfig>> => {
  return [
    {
      title: 'Label',
      dataIndex: 'configurationName',
      key: 'configurationName',
      render: (_, brokerConfig) => (
        <span
          id={`config_name_${brokerConfig?.brokerConfigurationId}`}
          key={`config-name-${brokerConfig?.brokerConfigurationId}`}
        >
          {brokerConfig?.configurationName}
        </span>
      ),
      sorter: (a, b) => {
        return a?.configurationName > b?.configurationName ? 1 : -1;
      },
    },
    ...getCarrierSpecificBrokerColumn(selectedCarrier),
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      render: (_, brokerConfig) => {
        const brokerIdHelperInstance = new IdHelper();
        const agencyType = brokerIdHelperInstance.determineIdType(brokerConfig?.agencyOrNetworkId);
        return (
          <>
            <TagStyled
              color={agencyType === IDType.AGENCY_NETWORK ? theme.custom.tags.network : theme.custom.tags.agency}
              id={`role_${brokerConfig?.brokerConfigurationId}`}
              key={`role-${brokerConfig?.brokerConfigurationId}`}
            >
              {agencyType === IDType.AGENCY_NETWORK ? 'NETWORK' : 'AGENCY'}
            </TagStyled>
            {brokerConfig?.isDefaultCode && (
              <TagStyled marginTop color={theme.custom.tags.defaultBrokerCode}>
                DEFAULT
              </TagStyled>
            )}
          </>
        );
      },
      sorter: (a, b) => {
        const brokerIdHelperInstance = new IdHelper();
        return brokerIdHelperInstance.determineIdType(a?.agencyOrNetworkId) >
          brokerIdHelperInstance.determineIdType(b?.agencyOrNetworkId)
          ? 1
          : -1;
      },
    },
  ];
};

export const getCarrierSpecificCategory = (categoryName: string, carrier: string) => `${categoryName}For${carrier}`;
