import styled from 'styled-components';

import CFInput from '../../../ui-core/V2/cfInput/cfInput';

export const SearchBarStyled = styled(CFInput)``;

export const NoAccountsMessageStyled = styled.div`
  margin-bottom: 20px;
`;

export const SingleLineTextStyled = styled.span`
  white-space: nowrap;
`;

export const WrappedTextStyled = styled.span`
  word-break: break-all;
`;
