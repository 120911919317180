import { PullRequestOutlined, SolutionOutlined, TeamOutlined } from '@ant-design/icons';

import { useConfigurationStore } from '../../configuration/store';

export const getSettingsMenu = () => {
  const { userTheme } = useConfigurationStore.getState();

  return [
    { icon: SolutionOutlined, to: '/settings/brokerconfig', label: 'Broker Configuration', visible: true },
    {
      icon: PullRequestOutlined,
      to: '/settings/carrierconfig',
      label: 'Carrier Rankings',
      visible: !userTheme?.config?.disabledCarrierRankingsMenu,
    },
    { icon: TeamOutlined, to: '/settings/team', label: 'Team Management', visible: true },
  ];
};
