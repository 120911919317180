import { useCallback, useMemo } from 'react';

import { ICFTimePicker } from './interface';
import { CFTimePickerStyled, CFTimePickerWrapperStyled } from './styles';

const CFTimePicker = (props: ICFTimePicker) => {
  const { data, onChange, disabled, getPopupContainer: customGetPopupContainer, containerId } = props;

  const handleChange = useCallback(
    (value) => {
      onChange && onChange(value, data);
    },
    [data, onChange],
  );

  const getPopupContainer: (node: HTMLElement) => HTMLElement = useMemo(() => {
    if (customGetPopupContainer) {
      return customGetPopupContainer;
    } else if (containerId) {
      return () => document.getElementById(containerId) || document.body;
    } else {
      return (triggerNode: HTMLElement) => (triggerNode?.parentNode as HTMLElement) || document.body;
    }
  }, [containerId, customGetPopupContainer]);

  return (
    <CFTimePickerWrapperStyled>
      <CFTimePickerStyled
        disabled={disabled}
        {...props}
        onChange={handleChange}
        getPopupContainer={getPopupContainer}
      />
    </CFTimePickerWrapperStyled>
  );
};

export default CFTimePicker;
