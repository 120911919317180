import { Tag } from 'antd';
import { useCallback } from 'react';

import { ICFTag } from './interface';

const CFTag = (props: ICFTag) => {
  const { id, data, onClose, ...rest } = props;

  const handleClose = useCallback(
    (event) => {
      onClose && onClose(event, data);
    },
    [onClose, data],
  );

  return <Tag {...rest} id={id} onClose={handleClose} />;
};

export default CFTag;
