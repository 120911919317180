import { PAGE_ROUTES } from '../../globalConstants';
import Anchor from '../../ui-core/V2/anchor/anchor';
import Empty from '../../ui-core/V2/empty/empty';
import {
  UnauthorisedDescriptionStyled,
  UnauthorisedDescriptionWrapperStyled,
  UnauthorisedWrapperStyled,
} from './styles';

const UnAuthorisedElement = () => {
  const getUnAuthorisedDescription = () => (
    <UnauthorisedDescriptionStyled>
      Sorry! You are unauthorized to visit this Page. Try going to the
      {<Anchor id='link_unauthorised' text=' Home Page' href={PAGE_ROUTES.ROOT} />}
    </UnauthorisedDescriptionStyled>
  );

  return (
    <>
      <UnauthorisedWrapperStyled>
        <UnauthorisedDescriptionWrapperStyled>
          <Empty id='unauthorised_route' description={getUnAuthorisedDescription()} imageStyle={{ height: 200 }} />
        </UnauthorisedDescriptionWrapperStyled>
      </UnauthorisedWrapperStyled>
    </>
  );
};

export default UnAuthorisedElement;
