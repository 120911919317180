import { ICarrierQuoteIdentifier, ICFQuoteIdentifier } from '@coverforce-platform/cf-common-api-model';

import { APPLICATION_TABS } from '../../constants/applicationV2Constants';
import { PAGE_NAME } from '../../globalConstants';
import { GA_CATEGORY as ACCOUNT_GA_CATEGORY } from '../../pages/v2/account/constants';
import { useApplicationStore } from '../../pages/v2/application/store';
import { GA_CATEGORY as APPLICATION_GA_CATEGORY } from '../../pages/v2/applications/constants';
import { getCurrentPageName } from '../../utils/getCurrentPageName';
import { GA_CATEGORY as QUOTES_GA_CATEGORY } from '../quotes/constants';
import { getGaCategoryName } from '../quotes/utils';
import { useMarkAsBoundModalStore } from './store';

export const getQuoteIdentifierPayload = (): ICFQuoteIdentifier | ICarrierQuoteIdentifier => {
  const { markAsBoundForm, markAsBoundQuotesData } = useMarkAsBoundModalStore.getState();
  const modalFormAnswers = markAsBoundForm?.getFieldsValue();
  let quoteIdentifierPayload = {} as ICFQuoteIdentifier | ICarrierQuoteIdentifier;

  if (markAsBoundQuotesData) {
    const selectedQuoteInfo = markAsBoundQuotesData?.find((quote) => quote?.carrierId === modalFormAnswers?.carrier);

    if (selectedQuoteInfo && selectedQuoteInfo?.cfQuoteId) {
      quoteIdentifierPayload = {
        cfQuoteId: selectedQuoteInfo?.cfQuoteId,
      };
    } else {
      quoteIdentifierPayload = {
        carrier: modalFormAnswers?.carrier,
      };
    }
  } else {
    quoteIdentifierPayload = {
      carrier: modalFormAnswers?.carrier,
    };
  }
  return quoteIdentifierPayload;
};

export const getGACategory = (carrierId?: string) => {
  const currentPage = getCurrentPageName();
  const { currentStep } = useApplicationStore.getState();

  switch (currentPage) {
    case PAGE_NAME.ACCOUNT_DETAIL:
      return ACCOUNT_GA_CATEGORY.MARK_AS_BOUND_APPLICATION_MODAL;
    case PAGE_NAME.APPLICATIONS:
      return APPLICATION_GA_CATEGORY.MARK_AS_BOUND_APPLICATION_MODAL;
    case PAGE_NAME.APPLICATION:
      switch (currentStep) {
        case APPLICATION_TABS.QUOTES:
          return getGaCategoryName(QUOTES_GA_CATEGORY.QUOTES_TAB_REFER_QUOTE_MODAL, carrierId);

        default:
          return currentPage;
      }

    default:
      return currentPage;
  }
};
