import styled from 'styled-components';

import CFModalV2 from '../../ui-core/V2/cfModal/cfModal';

export const RequestToBindNotesModalStyled = styled(CFModalV2)``;

export const RequestToBindNotesWrapperStyled = styled.div``;

export const RequestToBindNotesHeadingStyled = styled.h4``;

export const RequestToBindNotesTextStyled = styled.span``;
