import * as Sentry from '@sentry/react';

import { FEATURE_FLAG } from '../globalConstants';
import { REACT_APP_ENV } from '../helpers/EnvironmentVariables';
import { useProfileV2Store } from '../pages/v2/profile/store';
import { isGrowthbookFeatureEnabledByKey } from './growthbook';

const SENTRY = {
  SENTRY_DSN: 'https://e33206f0eeab4eec84653aa85f988c91@o1185085.ingest.sentry.io/6468707',
  SENTRY_ENV: REACT_APP_ENV,
  SENTRY_RELEASE: '1.0.0',
  SENTRY_LOG_LEVEL: 'debug',
};

export const initialiseSentry = () => {
  Sentry.init({
    dsn: SENTRY.SENTRY_DSN,
    environment: SENTRY.SENTRY_ENV,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
  });
};

export const logError = (error: any) => {
  const { userData } = useProfileV2Store.getState();
  const isSentryEnabled = isGrowthbookFeatureEnabledByKey(FEATURE_FLAG.SENTRY_INTEGRATION);
  isSentryEnabled && Sentry.captureException(error, { extra: { agentId: userData?.agentId ?? '' } });
};
