import { Menu } from 'antd';
import styled from 'styled-components';

export const CFMenuRawStyled = styled(Menu)
  .attrs((props: {}) => props)
  .withConfig({ shouldForwardProp: (props) => ![''].includes(props) })`
`;

export const CFMenuItemRawStyled = styled(CFMenuRawStyled.Item).attrs((p: any) => p)``;

export const CFMenuItemDividerRawStyled = styled(CFMenuRawStyled.Divider).attrs((p: any) => p)``;
