import Anchor from '../../ui-core/V2/anchor/anchor';
import { useCFModalStore } from '../../ui-core/V2/cfModal/store';
import { isEmpty } from '../../utils/lodash';
import { APPETITE_TYPE } from '../modals/newQuote/constants';
import { useNewQuoteStore } from '../modals/newQuote/store';
import { getCarrierOptions } from './componentUtils';
import { IAppetiteCarrierList } from './interface';
import {
  AppetiteCheckboxTitleStyled,
  AppetiteCheckboxWrapperStyled,
  AppetiteDetailsWrapperStyled,
  AppetiteErrorStyled,
  AppetiteInfoWrapperStyled,
  AppetiteTitleStyled,
  AppetiteWrapperStyled,
  CarrierLabelStyled,
  DividerStyled,
} from './styles';
import { getPreferCarriers } from './utils';

const AppetiteCarrierList = ({
  isAcordUpload,
  handleInAppetiteCheckboxChange,
  handleNotInAppetiteCheckboxChange,
  selectedCarriers,
  policyType,
}: IAppetiteCarrierList) => {
  const { appetiteDetailsStore, createNewApplicationLoading, brokerConfigDataStore } = useNewQuoteStore();
  const { clearCfModal } = useCFModalStore();

  const { inAppetiteCarriers, notInAppetiteCarriers, noBrokerCodeCarriers } = appetiteDetailsStore;

  const { brokerIdConfigs } = brokerConfigDataStore;

  const getSelectedCarrierErrorMessage = (): string => {
    if (
      (brokerIdConfigs && isEmpty(brokerIdConfigs)) ||
      inAppetiteCarriers?.length + notInAppetiteCarriers?.length === 0
    ) {
      return 'No carriers are available for this policy type. Please ensure you have added the broker codes for all carriers.';
    } else {
      return 'Please select a carrier to proceed.';
    }
  };

  return (
    <div>
      <AppetiteWrapperStyled>
        <AppetiteTitleStyled disabled={isAcordUpload && createNewApplicationLoading}>
          Select Carriers to proceed with quote
        </AppetiteTitleStyled>
        <AppetiteInfoWrapperStyled>
          {inAppetiteCarriers && (
            <AppetiteDetailsWrapperStyled>
              <AppetiteCheckboxTitleStyled disabled={isAcordUpload && createNewApplicationLoading}>
                Potential Appetite Guide
              </AppetiteCheckboxTitleStyled>
              {inAppetiteCarriers.length > 0 && (
                <AppetiteCheckboxWrapperStyled value={selectedCarriers} onChange={handleInAppetiteCheckboxChange}>
                  {getCarrierOptions({
                    options: inAppetiteCarriers,
                    disabledCarriers: getPreferCarriers(policyType),
                    selectedPolicy: policyType,
                    appetiteType: APPETITE_TYPE.IN_APPETITE,
                  })}
                </AppetiteCheckboxWrapperStyled>
              )}
            </AppetiteDetailsWrapperStyled>
          )}
          {(notInAppetiteCarriers?.length > 0 || noBrokerCodeCarriers?.length > 0) && <DividerStyled type='vertical' />}
          <AppetiteDetailsWrapperStyled>
            {notInAppetiteCarriers?.length > 0 && (
              <>
                <AppetiteCheckboxTitleStyled disabled={isAcordUpload && createNewApplicationLoading}>
                  Not in Appetite Guide
                </AppetiteCheckboxTitleStyled>

                <AppetiteCheckboxWrapperStyled value={selectedCarriers} onChange={handleNotInAppetiteCheckboxChange}>
                  {getCarrierOptions({
                    options: notInAppetiteCarriers,
                    appetiteType: APPETITE_TYPE.NOT_IN_APPETITE,
                    selectedPolicy: policyType,
                  })}
                </AppetiteCheckboxWrapperStyled>
              </>
            )}

            {notInAppetiteCarriers?.length > 0 && noBrokerCodeCarriers?.length > 0 && <DividerStyled />}

            {noBrokerCodeCarriers?.length > 0 && (
              <>
                <AppetiteCheckboxTitleStyled disabled={isAcordUpload && createNewApplicationLoading}>
                  Broker Codes Not Setup
                </AppetiteCheckboxTitleStyled>
                {noBrokerCodeCarriers.map((item) => (
                  <CarrierLabelStyled key={item?.value}>{item?.label}</CarrierLabelStyled>
                ))}
                <span onClick={clearCfModal}>
                  <Anchor id={`link_broker_config_page`} href={'/settings/brokerconfig'}>
                    Setup Broker Codes here.
                  </Anchor>
                </span>
              </>
            )}
          </AppetiteDetailsWrapperStyled>
        </AppetiteInfoWrapperStyled>
      </AppetiteWrapperStyled>
      {selectedCarriers?.length === 0 && <AppetiteErrorStyled>{getSelectedCarrierErrorMessage()}</AppetiteErrorStyled>}
    </div>
  );
};

export default AppetiteCarrierList;
