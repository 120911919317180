import { MenuConfig } from '../../../components/menuActionButton/interface';
import { MENU_ITEM_KEY } from '../../../globalConstants';

export const getMenuConfig = (isAgency = true): MenuConfig[] => {
  const config: MenuConfig[] = isAgency
    ? [
        {
          label: 'Edit Configuration',
          key: MENU_ITEM_KEY.EDIT,
        },
        {
          label: 'Edit Quote Actions',
          key: MENU_ITEM_KEY.EDIT_BIND_ACTIONS,
        },
        {
          label: 'Delete Configuration',
          key: MENU_ITEM_KEY.DELETE,
          extraData: { danger: 'danger' },
        },
      ]
    : [
        {
          label: 'Edit Quote Actions',
          key: MENU_ITEM_KEY.EDIT_BIND_ACTIONS,
        },
      ];

  return [
    ...config,
    {
      label: 'Set as default',
      key: MENU_ITEM_KEY.DEFAULT,
    },
  ];
};

export const GA_CATEGORY = {
  BROKER_CONFIG_TABLE: 'brokerConfig_table',
  BROKER_CONFIG_HEADER: 'brokerConfig_header',
  BROKER_CONFIG_SIDE_BAR: 'brokerConfig_sideBar',
  BROKER_CONFIG_ADD_DRAWER: 'brokerConfig_addDrawer',
  BROKER_CONFIG_EDIT_DRAWER: 'brokerConfig_editDrawer',
  BROKER_CONFIG_QUOTE_ACTION_MODAL: 'brokerConfig_quoteActionModal',
  BROKER_CONFIG_DELETE_MODAL: 'brokerConfig_deleteModal',
};

export const GA_ACTION = {
  GET_APPOINTMENT_BTN_CLICK: 'getAppointment_click',
  ADD_BROKER_ID_BTN_CLICK: 'addBrokerIdBtn_click',
  EDIT_BROKER_ID_BTN_CLICK: 'editBrokerIdBtn_click',
  DELETE_BROKER_ID_BTN_CLICK: 'deleteBrokerIdBtn_click',
  CLOSE_DRAWER_BTN_CLICK: 'closeDrawerBtn_click',
  CLOSE_CONFIRM_BOX_BTN_CLICK: 'closeConfirmBoxBtn_click',
  WHERE_TO_FIND_BTN_CLICK: 'whereToFindCodeBtn_clicks',
  SWITCH_CARRIER_BTN_CLICK: 'switchTheCarrierTab_change',
  SET_DEFAULT_BROKER_CODE_BTN_CLICK: 'setDefaultBrokerCodeBtn_click',
  EDIT_QUOTE_ACTION_BTN_CLICK: 'editQuoteActionsBtn_click',
};

export const GA_LABEL = {
  GET_APPOINTMENT: 'Get Appointment button click',
  ADD_BROKER_ID: 'Add broker code button click from header',
  EDIT_BROKER_ID: 'Edit broker code button click from table menu',
  DELETE_BROKER_ID_MENU_ITEM: 'Delete broker code button click from table menu',
  CLOSE_DRAWER: 'Close the drawer',
  CLOSE_CONFIRM_BOX: 'Close the confirm box',
  WHERE_TO_FIND: 'Where to find code button click from the drawer',
  SAVE_BROKER_ID: 'Save broker id',
  SWITCH_CARRIER: 'Carrier change threw tabs',
  SET_DEFAULT_BROKER_CODE: 'Set default broker code',
  EDIT_QUOTE_ACTION: 'Edit quote action button click from table menu',
  DELETE_BROKER_ID: 'Delete broker id',
};
