import { Tooltip } from 'antd';
import styled from 'styled-components';

export const TooltipStyled = styled(Tooltip)`
  margin-left: 5px;
`;

export const TextStyled = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.mediumBold};
  margin-top: 5px;
`;
