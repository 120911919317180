import styled from 'styled-components';

export const QuotesStyled = styled.div`
  > div {
    margin-bottom: 40px;
    box-shadow: 0px 5px 8px ${(p) => p.theme.colors.primary.blackOpacity};
    border-radius: 6px;
  }
`;

export const QuoteCardLogoWrapperStyled = styled.div`
  max-width: 125px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const QuoteCardLogoStyled = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
`;
