import { removeUndefinedProperty } from '../helpers/Utils';
import { isEqual } from './lodash';
import { sortObjectKeys } from './sortObjectKeys';

export const checkIfTwoObjectsAreSame = (sourceObj: any, targetObj?: any) => {
  return isEqual(
    removeUndefinedProperty(sortObjectKeys(sourceObj)),
    removeUndefinedProperty(sortObjectKeys(targetObj)),
  );
};
