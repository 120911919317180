import { Col, Row } from 'antd';
import { useEffect } from 'react';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';

import messages from '../../../constants/messages';
import { IAntdOptions } from '../../../globalInterface';
import { digitsOnlyRegex } from '../../../helpers/Utils';
import { IFormConfig } from '../../../ui-core/V2/cfFormDrawer/interface';
import CFFormItem from '../../../ui-core/V2/cfFormItem/cfFormItem';
import { getInOptionTypeFormat } from '../../../utils/formatter';
import { get } from '../../../utils/lodash';
import { logError } from '../../../utils/sentry';
import { addressLine1Error, DEBOUNCE_TIME, locationConfig, LocationSearchCountry, STATUSES } from './constants';
import { ILocationInputProps } from './interface';
import { useLocationInputStore } from './store';
import { AutoCompleteStyled, InputStyled, LocationContainerStyled, LocationWrapperStyled } from './styles';
import {
  getAddressComponent,
  getAddressLine1,
  getCity,
  getValidationProps,
  instanceOfAddressComponents,
} from './utils';

const LocationInput = ({ id, onSave, value: initialValue, onSaveData, disabled = false }: ILocationInputProps) => {
  const {
    addressData: storeAddressData,
    setAddressData,
    setShowLocationError,
    clearLocationInput,
  } = useLocationInputStore();

  const addressData = storeAddressData[id]?.address;
  const showLocationError = storeAddressData[id]?.showLocationError;

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: LocationSearchCountry },
    },
    defaultValue: '',
    debounce: DEBOUNCE_TIME,
  });

  let suggestions: IAntdOptions[] = [];
  if (status === STATUSES.OK) {
    suggestions = getInOptionTypeFormat(data, 'description', 'place_id');
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clearLocationInput(id), []);

  useEffect(() => {
    const updatedAddress = { ...addressData, addressLine1: value };
    setAddressData(updatedAddress, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (initialValue === undefined) {
      return;
    }
    setAddressData(initialValue, id);
    setValue(get(initialValue, 'addressLine1'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const onSearch = (query: string) => {
    // Update the keyword of the input element
    setValue(query);
  };

  const onChange = (addressLine1: any) => {
    const updatedAddress = {
      ...addressData,
      addressLine1,
    };
    setAddressData(updatedAddress, id);
    onSave(updatedAddress, onSaveData);
  };

  const onSelect = async (placeId: any) => {
    const suggestion = data?.find(({ place_id }) => place_id === (placeId as string));

    // Update the keyword of the input element
    setValue(get(suggestion, 'description', messages.SOMETHING_WRONG), false);
    clearSuggestions();

    const parameter = { placeId, fields: ['name', 'address_components'] };

    try {
      const result = await getDetails(parameter);
      const addressComponents = get(result, 'address_components', {});

      if (onSave && instanceOfAddressComponents(addressComponents)) {
        const addressValues = {
          addressLine1: getAddressLine1(addressComponents),
          addressLine2: '',
          city: getCity(addressComponents),
          state: getAddressComponent(addressComponents, 'administrative_area_level_1'),
          zip: getAddressComponent(addressComponents, 'postal_code'),
        };

        setAddressData(addressValues, id);
        onSave(addressValues, onSaveData);
        setValue(get(addressValues, 'addressLine1'));

        // Show error for all address fields active once parent field is filled
        setShowLocationError(true, id);
      }
    } catch (error) {
      logError(`Location input on select: ${JSON.stringify(error)}`);
    }
  };

  const getLocationItem = (itemConfig: IFormConfig) => {
    const { dataIndex, message, prefix, placeholder } = itemConfig;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let fieldValue = event.target.value;
      switch (dataIndex) {
        // To allow only digits for zip code field
        case 'zip':
          fieldValue = fieldValue?.replace(digitsOnlyRegex(), '');
          break;
        case 'state':
          fieldValue = fieldValue?.toUpperCase();
          break;
      }
      const updatedAddress = {
        ...addressData,
        [dataIndex]: fieldValue,
      };
      setAddressData(updatedAddress, id);
      onSave(updatedAddress, onSaveData);
    };

    return (
      <CFFormItem
        id={`form_item_${dataIndex}_${id}`}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 24 }}
        {...getValidationProps(addressData, dataIndex, showLocationError, message)}
      >
        <InputStyled
          id={`inp_${dataIndex}_${id}`}
          value={get(addressData, dataIndex)}
          onChange={handleChange}
          prefix={prefix}
          placeholder={placeholder}
          disabled={disabled}
        />
      </CFFormItem>
    );
  };

  return (
    <LocationWrapperStyled id={id}>
      <LocationContainerStyled id={`form_loc_inp_${id}`}>
        <CFFormItem
          id={`form_item_loc_inp_${id}`}
          className='autocomplete-form-item'
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 24 }}
          {...getValidationProps(addressData, 'addressLine1', showLocationError, addressLine1Error)}
        >
          <AutoCompleteStyled
            id={`inp_addressLine1_${id}`}
            value={value}
            options={suggestions}
            placeholder='Address'
            onChange={onChange}
            onSelect={onSelect}
            onSearch={onSearch}
            disabled={!ready || disabled}
          />
        </CFFormItem>
        {getLocationItem(locationConfig[0])}
        <Row gutter={8}>
          <Col span={10}>{getLocationItem(locationConfig[1])}</Col>
          <Col span={6}>{getLocationItem(locationConfig[2])}</Col>
          <Col span={8}>{getLocationItem(locationConfig[3])}</Col>
        </Row>
      </LocationContainerStyled>
    </LocationWrapperStyled>
  );
};

export default LocationInput;
