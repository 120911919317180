import { getEmailRegex } from '../../../helpers/Utils';
import { TagStyled } from './styles';

export const tagRender = (props: any) => {
  const { label, value, onClose, closable = true } = props;
  const isEmailValid = getEmailRegex().test(value?.trim());

  return (
    // theme agnostic error colors
    <TagStyled
      id='tag-request-to-bind'
      color={!isEmailValid ? 'red' : 'green'}
      closable={closable}
      onClose={onClose}
      data={value}
    >
      {label}
    </TagStyled>
  );
};
