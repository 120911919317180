import { jwtDecode } from 'jwt-decode';

import { JWT_TOKEN } from '../globalConstants';
import { getFromLocalStorage } from './getFromLocalStorage';

export const getAuthId = () => {
  const storageAccessToken = getFromLocalStorage(JWT_TOKEN.ACCESS_TOKEN);
  const accessToken: any = (storageAccessToken && jwtDecode(storageAccessToken)) || {};
  const { sub = '' } = accessToken || {};
  return sub;
};
