import dayjs, { isDayjs } from 'dayjs';

import { CONDITION_PROPS, QUESTION_TYPE } from '../globalConstants';

export const evaluateCondition = (condition: string, value: any, type: string) => {
  if (!condition || [null, undefined].includes(value)) {
    return false;
  }
  let cond = condition;
  cond = cond.replace(CONDITION_PROPS.CURRENT_YEAR, dayjs().year().toString());

  switch (type) {
    case QUESTION_TYPE.YEAR:
      cond = isDayjs(value) ? cond.replace(CONDITION_PROPS.CURRENT_VALUE, value?.year().toString()) : cond;
      break;

    default:
      cond = cond.replace(CONDITION_PROPS.CURRENT_VALUE, value);
      break;
  }

  if (cond.includes('||')) {
    cond = cond.replace('||', `|| ${value}`);
  } else if (cond.includes('&&')) {
    cond = cond.replace('&&', `&& ${value}`);
  }

  try {
    // eslint-disable-next-line no-eval
    const result = eval(cond);
    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return false;
  }
};
