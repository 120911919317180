import {
  IAddAgencyNetworkMetadataInput,
  IAddAgencyNetworkMetadataOutput,
  IGetAgencyNetworkInput,
  IGetAgencyNetworkMetadataInput,
  IGetAgencyNetworkMetadataOutput,
  IGetAgencyNetworkOutput,
  IGetAgencyNetworkUsersInput,
  IGetAgencyNetworkUsersOutput,
  IGetApplicationsForAgencyNetworkInput,
  IGetApplicationsForAgencyNetworkListOutput,
  IUpdateAgencyNetworkInput,
  IUpdateAgencyNetworkOutput,
} from '@coverforce-platform/cf-common-api-model';
import { AxiosResponse } from 'axios';

import axiosAccountService from '../../axios/accountService/instance';
import axiosApplicationService from '../../axios/applicationService/instance';
import { createQueryUrl } from '../../utils/createQueryUrl';
import { ACCOUNT_SERVICE_ENDPOINTS, AGENCY_NETWORK_SERVICE_ENDPOINTS } from '../helpers/ApiRoutes';

export const getAgencyNetworkApplications = async (
  params: IGetApplicationsForAgencyNetworkInput,
): Promise<IGetApplicationsForAgencyNetworkListOutput> => {
  const apiUrl = createQueryUrl({
    url: AGENCY_NETWORK_SERVICE_ENDPOINTS.GET_AGENCY_NETWORK_APPLICATIONS,
  });
  try {
    const response: AxiosResponse = await axiosApplicationService.post(apiUrl, params);
    if (response?.data && response.data?.pagination && !response.data.pagination?.nextPageCursor) {
      response.data = {
        ...response.data,
        pagination: {
          ...response.data.pagination,
          nextPageCursor: params?.pagination?.nextPageCursor,
        },
      };
    }

    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const getAgencyNetworkUsers = async (
  params: IGetAgencyNetworkUsersInput,
): Promise<IGetAgencyNetworkUsersOutput> => {
  const apiUrl = createQueryUrl({
    url: AGENCY_NETWORK_SERVICE_ENDPOINTS.GET_AGENCY_NETWORK_USERS,
    params,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const getAgencyNetworkDetails = async (params: IGetAgencyNetworkInput): Promise<IGetAgencyNetworkOutput> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.GET_AGENCY_NETWORK_DETAILS(params.agencyNetworkId),
  });

  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const updateAgencyNetworkDetails = async (
  payload: IUpdateAgencyNetworkInput,
): Promise<IUpdateAgencyNetworkOutput> => {
  const apiUrl = createQueryUrl({
    url: ACCOUNT_SERVICE_ENDPOINTS.UPDATE_AGENCY_NETWORK,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.put(apiUrl, payload);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const getAgencyNetworkMetadata = async (
  params: IGetAgencyNetworkMetadataInput,
): Promise<IGetAgencyNetworkMetadataOutput> => {
  const apiUrl = createQueryUrl({
    url: AGENCY_NETWORK_SERVICE_ENDPOINTS.GET_AGENCY_NETWORK_METADATA(params),
  });

  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const updateAgencyNetworkMetadata = async (
  payload: IAddAgencyNetworkMetadataInput,
): Promise<IAddAgencyNetworkMetadataOutput> => {
  const apiUrl = createQueryUrl({
    url: AGENCY_NETWORK_SERVICE_ENDPOINTS.UPDATE_AGENCY_NETWORK_METADATA,
  });

  try {
    const response: AxiosResponse = await axiosAccountService.post(apiUrl, payload);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};
