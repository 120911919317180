import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { redirectToLogout } from '../../../utils/redirectToLogout';
import { getOtherProfilesSubMenu, getSelectedUserProfileSubMenu, getUserSubMenu } from './componentUtils';
import { USER_MENU_NAVIGATION_PATHS } from './constants';
import { UserMenuWrapperStyled } from './styles';

const UserMenu = () => {
  const navigate = useNavigate();
  const theme: any = useTheme();

  const onClickNavigate = (to: string) => {
    switch (to) {
      case USER_MENU_NAVIGATION_PATHS.LOGOUT: {
        redirectToLogout({ redirectToHome: true });
        break;
      }
      case USER_MENU_NAVIGATION_PATHS.PROFILE: {
        navigate(to);
        break;
      }
      default: {
        window.open(`${window.location.origin}${to}`);
        break;
      }
    }
  };

  return (
    <UserMenuWrapperStyled>
      {getSelectedUserProfileSubMenu({ onClickNavigate, theme })}
      {getOtherProfilesSubMenu({ onClickNavigate, theme })}
      {getUserSubMenu({ onClickNavigate, theme })}
    </UserMenuWrapperStyled>
  );
};

export default UserMenu;
