import { ICancelRequestToBindStore } from './interface';

export const CANCEL_REQUEST_TO_BIND_MODAL = {
  TITLE: 'Cancel Bind Request',
};

export const CANCEL_REQUEST_TO_BIND_MODAL_STORE = {
  NAME: 'Cancel Request To Bind Modal Store',
  ANONYMOUS_ACTION: 'CANCEL_REQUEST_TO_BIND_MODAL',
  CANCEL_REQUEST_TO_BIND_LOADING: 'CANCEL_REQUEST_TO_BIND_LOADING',
  UPDATE_CANCEL_REQUEST_TO_BIND_MODAL_STORE_BY_KEY: 'UPDATE_CANCEL_REQUEST_TO_BIND_MODAL_STORE_BY_KEY',
  CLEAR_CANCEL_REQUEST_TO_BIND_MODAL: 'CLEAR_CANCEL_REQUEST_TO_BIND_MODAL',
};

export const CANCEL_REQUEST_TO_BIND_MODAL_STORE_KEYS = {
  IS_CANCEL_REQUEST_TO_BIND_LOADING: 'isCancelRequestToBindLoading' as keyof ICancelRequestToBindStore,
};

export const CANCEL_REQUEST_TO_BIND_QUOTE_MESSAGES = {
  SUCCESS: 'The bind request was cancelled successfully!',
  ERROR: 'Error while cancelling the bind request!',
};
