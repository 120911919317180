import { AcordFormType } from '@coverforce-platform/cf-common-types';

export const DOWNLOAD_ACORD_METADATA = {
  CLEAR_DOWNLOAD_ACORD_STORE: 'clearDownloadAcordStore',
  ANONYMOUS_ACTION: 'DOWNLOAD_ACORD_STORE_ACTION',
  STORE_NAME: 'Download Acord Store',
};

export const DOWNLOAD_ACORD_MESSAGES = {
  DOWNLOAD_SUCCESS: 'ACORD Forms downloaded successfully!',
  DOWNLOAD_ERROR: 'Failed to download ACORD Forms!',
};

export const ACORD_FORM_TYPES: { [key: string]: AcordFormType } = {
  ACORD_125: AcordFormType.ACORD_125 /** Commercial Insurance */,
  ACORD_126: AcordFormType.ACORD_126 /** CGL */,
  ACORD_130: AcordFormType.ACORD_130 /** WC */,
  ACORD_140: AcordFormType.ACORD_140 /** Property Section */,
  ACORD_160: AcordFormType.ACORD_160 /** BOP */,
};

export const ACORD_LOADING_TEXTS = [
  'Extracting the application data',
  'Populating the ACORD Forms',
  'Finalizing the ACORD Forms',
];

export const ACORD_LOADER_TEXT_INTERVAL_IN_MS = 10000;
