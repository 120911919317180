export const QUOTES_STORE = {
  NAME: 'Quotes Store',
  ANONYMOUS_ACTION: 'quotes',
  QUOTES_LOADING: 'QUOTES_LOADING',
  QUOTES_SUCCESS: 'QUOTES_SUCCESS',
  QUOTES_ERROR: 'QUOTES_ERROR',
  UPDATE_QUOTES_STORE_BY_KEY: 'UPDATE_QUOTES_STORE_BY_KEY',
  ISU_CLPA_DETAILS_SUCCESS: 'ISU_CLPA_DETAILS_SUCCESS',
  ISU_CLPA_DETAILS_ERROR: 'ISU_CLPA_DETAILS_ERROR',
  LIABILITY_LIMITS_SUCCESS: 'LIABILITY_LIMITS_SUCCESS',
  LIABILITY_LIMITS_ERROR: 'LIABILITY_LIMITS_ERROR',
  SET_LOADING_STATE_FOR_QUOTE_PROPOSAL: 'SET_LOADING_STATE_FOR_QUOTE_PROPOSAL',
  CLEAR_QUOTES: 'CLEAR_QUOTES',
};

export const QUOTE_PROPOSAL = {
  LOADING: 'Downloading Quote Proposal.',
  SUCCESS: 'Downloaded Quote Proposal!',
  ERROR: 'An error occurred while downloading quote proposal, please try again!',
};

export const GA_CATEGORY = {
  QUOTES_TAB_CARD: 'quotesTab_card',
  QUOTES_TAB_CHANGE_EFFECTIVE_DATE_MODAL: 'quotesTab_changeEffectiveDateModal',
  QUOTES_TAB_TIME_OUT_MODAL: 'quotesTab_timeOutModal',
  QUOTES_TAB_FAILED_REASON_MODAL: 'quotesTab_failedReasonModal',
  QUOTES_TAB_DECLINED_REASON_MODAL: 'quotesTab_declinedReasonModal',
  QUOTES_TAB_REFER_QUOTE_MODAL: 'quotesTab_referQuoteModal',
  QUOTES_TAB_REQUEST_TO_BIND_MODAL: 'quotesTab_requestToBindModal',
  QUOTES_TAB_CANCEL_REQUEST_TO_BIND_MODAL: 'quotesTab_cancelRequestToBindModal',
};

export const GA_ACTION = {
  LIABILITY_LIMIT_CHANGE_BTN_CLICK: 'liabilityLimitChangeBtn_click',
  CLOSE_MODAL_BTN_CLICK: 'closeModalBtn_click',
  GET_QUOTE_BTN_CLICK: 'getQuoteBtn_click',
  QUOTE_TIME_OUT_BTN_CLICK: 'quoteTimeOutBtn_click',
  DECLINED_REASONS_BTN_CLICK: 'declinedReasonsBtn_click',
  QUOTE_DETAILS_BTN_CLICK: 'quoteDetailsBtn_click',
  QUOTE_DOWNLOAD_BTN_CLICK: 'quoteDownloadBtn_click',
  REFER_TO_CARRIER_UNDERWRITER_BTN_CLICK: 'referToCarrierUnderwriterBtn_click',
  BIND_NOW_BTN_CLICK: 'bindNowBtn_click',
  REQUEST_TO_BIND_BTN_CLICK: 'requestToBindBtn_click',
  CANCEL_BIND_REQUEST_BTN_CLICK: 'cancelBindRequestBtn_click',
  BRIDGE_TO_CARRIER_PORTAL_BTN_CLICK: 'bridgeToCarrierPortalBtn_click',
  MARK_AS_BOUND_BTN_CLICK: 'bridgeToCarrierPortalBtn_click',
  REFER_QUOTE_BTN_CLICK: 'referQuoteBtn_click',
};

export const GA_LABEL = {
  LIABILITY_LIMIT_CHANGE: 'Liability limit change by selecting dropdown',
  CLOSE_MODAL: 'Close modal',
  CLOSE_TIMEOUT_MODAL: 'Close time out modal',
  CLOSE_REQUEST_TO_BIND_MODAL: 'Close request to bind modal',
  CLOSE_REFER_QUOTE_MODAL: 'Close refer quote modal',
  CLOSE_FAILED_REASON_MODAL: 'Close failed reason modal',
  CLOSE_EFFECTIVE_DATE_MODAL: 'Close effective date modal',
  CLOSE_DECLINED_REASON_MODAL: 'Close declined reason modal',
  CLOSE_CANCEL_TO_REQUEST_BIND_MODAL: 'Close cancel to request bind modal',
  GET_QUOTE: 'Get quote button clicked',
  QUOTE_TIME_OUT: 'Quote time out button clicked',
  DECLINED_REASONS: 'Declined reasons button clicked',
  QUOTE_DETAILS: 'Quote details button clicked',
  QUOTE_DOWNLOAD: 'Quote download button clicked',
  REFER_TO_CARRIER_UNDERWRITER: 'Refer to carrier underwriter button clicked',
  BIND_NOW: 'Bind now button clicked',
  REQUEST_TO_BIND: 'Request to bind button clicked',
  CANCEL_BIND_REQUEST: 'Cancel bind request button clicked',
  BRIDGE_TO_CARRIER_PORTAL: 'Bridge to carrier portal button clicked',
  MARK_AS_BOUND: 'Mark as bound button clicked',
  REFER_QUOTE: 'Refer quote button clicked',
};
// Soon this information will be coming from Theme API
export const ISU_THEME = 'isu_theme';
