export const contactUsFormRules = {
  question: [
    {
      required: true,
      validator: (_: any, value: any) => {
        if (value?.trim()) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Please enter your question.'));
      },
    },
  ],
  tellUsMore: [
    {
      required: true,
      validator: (_: any, value: any) => {
        if (value?.trim()) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Please enter the details of your request.'));
      },
    },
  ],
};

export const contactUsFormTemplate = 'template_pt04k0k';
