import { Form } from 'antd';
import styled from 'styled-components';

const CFFormStyled = styled(Form)`
  .ant-form-item:not(:last-child) {
    padding-bottom: 12px;
    margin-bottom: 12px;
  }
  .ant-form-item .ant-form-item-label > label {
    height: auto;
  }
`;

export default CFFormStyled;
