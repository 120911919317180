import { ICarrierInfo } from '@coverforce-platform/cf-common-api-model';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { APPLICATION_STORE_KEYS, UPDATE_APPLICATION_STEPS } from '../../../constants/applicationV2Constants';
import { useApplicationStore } from '../../../pages/v2/application/store';
import { useIndustrySelectionStore } from '../../industrySelection/store';
import { getUpdatedIndustrySelections } from '../../industrySelection/utils';
import { DELETE_CARRIER_METADATA } from './constants';
import { IDeleteCarrierStore } from './interface';

export const useDeleteCarrierStore = create<IDeleteCarrierStore>()(
  devtools(
    (set, get) => ({
      deletingCarrier: undefined,

      setDeletingCarrier: (deletingCarrier?: ICarrierInfo) => {
        set({ deletingCarrier }, false, DELETE_CARRIER_METADATA.SET_DELETING_CARRIER);
      },

      updateSelectedCarriers: async (updatedCarriers: ICarrierInfo[]) => {
        const { currentStep } = useApplicationStore.getState();
        const { updateSelectedCarriersIndustryStep, updateSelectedCarriersUWStep } = get();

        switch (currentStep) {
          case UPDATE_APPLICATION_STEPS.INDUSTRY_SELECTION:
            await updateSelectedCarriersIndustryStep(updatedCarriers);
            break;
          case UPDATE_APPLICATION_STEPS.UNDERWRITING:
            await updateSelectedCarriersUWStep(updatedCarriers);
            break;
          default:
            break;
        }
      },

      updateSelectedCarriersIndustryStep: async (updatedCarriers: ICarrierInfo[]) => {
        const { updateApplicationByKey, updateApplication } = useApplicationStore.getState();
        const { carrierIndustrySelections, updateCarrierIndustrySelections } = useIndustrySelectionStore.getState();

        const industrySelections = getUpdatedIndustrySelections({ updatedCarriers, carrierIndustrySelections });
        updateCarrierIndustrySelections(industrySelections);

        updateApplicationByKey(APPLICATION_STORE_KEYS.SELECTED_CARRIERS_INFO, updatedCarriers);
        await updateApplication({ updateApplicationStep: UPDATE_APPLICATION_STEPS.UPDATE_SELECTED_CARRIERS });
      },

      updateSelectedCarriersUWStep: async (updatedCarriers: ICarrierInfo[]) => {
        const { currentSubStepIndex, updateApplicationByKey, updateApplication } = useApplicationStore.getState();

        updateApplicationByKey(APPLICATION_STORE_KEYS.SELECTED_CARRIERS_INFO, updatedCarriers);
        await updateApplication({ updateApplicationStep: UPDATE_APPLICATION_STEPS.UPDATE_SELECTED_CARRIERS });

        if (currentSubStepIndex > 0) {
          updateApplicationByKey(APPLICATION_STORE_KEYS.CURRENT_SUBSTEP_INDEX, currentSubStepIndex - 1);
        }
      },

      getUpdatedSelectedCarriersAPIPayload: () => {
        const { applicationData, selectedCarriersInfo } = useApplicationStore.getState();

        const selectedCarriers = selectedCarriersInfo?.map((carrier) => carrier?.carrierId);

        const carrierIndustryCodes = applicationData?.basicBusinessDetails?.carrierIndustryCodes?.filter((item) =>
          selectedCarriers?.includes(item?.carrierId),
        );

        return {
          ...applicationData,
          applicationId: applicationData?.applicationId!,
          basicBusinessDetails: {
            ...applicationData?.basicBusinessDetails,
            carrierIndustryCodes,
          },
          applicationDetails: {
            ...applicationData?.applicationDetails,
            carriersSelected: selectedCarriers,
          },
        };
      },

      clearDeleteCarrier: () => {
        set({ deletingCarrier: undefined }, false, DELETE_CARRIER_METADATA.CLEAR_DELETE_CARRIER_STORE);
      },
    }),

    { anonymousActionType: DELETE_CARRIER_METADATA.ANONYMOUS_ACTION, name: DELETE_CARRIER_METADATA.STORE_NAME },
  ),
);
