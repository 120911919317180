export const LOCATION_DETAILS_STORE_META_DATA = {
  ANONYMOUS_ACTION_NAME: 'LOCATION_DETAILS_ACTIONS',
  STORE_NAME: 'Location Details Store',
  SET_LOCATION_FORM: 'SET_LOCATION_FORM',
  ADD_OR_UPDATE_LOCATION: 'ADD_OR_UPDATE_LOCATION',
  UPDATE_STORE_LOCATION: 'UPDATE_STORE_LOCATION',
  CLEAR_LOCATION_DETAILS: 'CLEAR_LOCATION_DETAILS',
};

export const LOCATION_DETAILS_ERROR_MESSAGE = {
  FAILED_ADD_OR_UPDATE_LOCATION: 'Request regarding locations failed!',
  DELETE_LOCATION: 'Failed to delete location',
};

export const LOCATION_LABELS = {
  ADDRESS: 'Address',
  PRIMARY_ADDRESS: 'Primary Address',
};

export const GA_CATEGORY = {
  LOCATION_TAB_FOOTER: 'locationTab_footer',
  LOCATION_TAB_EMPLOYEE_TYPE: 'locationTab_employeeType',
};

export const GA_ACTION = {
  ADD_EMPLOYEE_TYPE_BTN_CLICK: 'addEmployeeTypeBtn_click',
  DELETE_EMPLOYEE_TYPE_BTN_CLICK: 'deleteEmployeeTypeBtn_click',
};

export const GA_LABEL = {
  ADD_EMPLOYEE_TYPE: 'Add employee type',
  DELETE_EMPLOYEE_TYPE: 'Delete employee type',
};
