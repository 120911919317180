import { IQuotesStore } from '../components/quotes/interface';

// TO-DO:- Need to check how to write the type so as to not duplicate "as keyof IQuotesStore" everytime
export const QUOTES_STORE_KEYS = {
  QUOTES_DATA: 'quotesData' as keyof IQuotesStore,
  SELECTED_QUOTE: 'selectedQuote' as keyof IQuotesStore,
  REGENERATE_QUOTE_CARRIER_ID: 'regenerateQuoteCarrierId' as keyof IQuotesStore,
};

export const FE_QUOTE_FAILURE_CODES = {
  DCL_STATE: 'declined_reason_state',
  DCL_UW: 'declined_reason_underwriter',
  CTO: 'carrier_timeout',
};
