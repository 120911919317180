import { FormInstance } from 'antd';
import { isDayjs } from 'dayjs';

import { Address } from '../components/question/locationInputV2/interface';
import { useLocationInputStore } from '../components/question/locationInputV2/store';
import { validateAddress } from '../components/question/locationInputV2/utils';
import { QuestionStatus } from '../components/statusWrapper/interface';
import { isObject } from './lodash';

export const getQuestionStatus = ({
  fullKey,
  rules,
  form,
  isAddressTypeQuestion,
}: {
  fullKey: string;
  rules: any[];
  form?: FormInstance;
  isAddressTypeQuestion: boolean;
}) => {
  const { addressData } = useLocationInputStore.getState();
  const key = fullKey.split('.');
  const errors = form?.getFieldError(key);
  const value = form?.getFieldValue(key);
  if (
    // This check error length is grater then zero and if its address type then check its valid or not
    (errors &&
      Array.isArray(errors) &&
      errors.length > 0 &&
      (isAddressTypeQuestion ? !validateAddress(value as Address) : true)) ||
    // If for has not any error regarding that question, but we are displaying any error in any field of address then it is error.
    (isAddressTypeQuestion && addressData?.[key.join('_')]?.showLocationError && !validateAddress(value as Address))
  ) {
    return QuestionStatus.ERROR;
  }

  // if address then check value is valid
  if (isAddressTypeQuestion && isObject(value) && validateAddress(value as Address)) {
    // Here object only comes under the address component.
    return QuestionStatus.ANSWERED;
  }
  // if its array then check length > 0, ex: MULTISELECT dropdown
  else if (Array.isArray(value) && value?.length > 0) {
    return QuestionStatus.ANSWERED;
  }
  // if its boolean then its answer, EX : radio button
  else if (typeof value === 'boolean') {
    return QuestionStatus.ANSWERED;
  }
  // it handle string, number and any object related value
  else if (
    // if value present
    (value && (['string', 'number'].includes(typeof value) || isDayjs(value))) ||
    // special case to handle 0
    value === 0
  ) {
    return QuestionStatus.ANSWERED;
  }

  if (!rules || !Array.isArray(rules)) {
    return QuestionStatus.OPTIONAL;
  }

  const requiredRule = rules.find((rule) => rule.required);

  if (requiredRule) {
    return QuestionStatus.MANDATORY;
  }

  return QuestionStatus.OPTIONAL;
};
