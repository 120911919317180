import { PolicyType } from '@coverforce-platform/cf-common-types';

import { IPolicyLiabilityLimits } from '../globalInterface';
import { IApplicationStore } from '../pages/v2/application/interface';

export const APPLICATION_TABS = {
  GENERAL_QUESTIONS: 'generalQuestions',
  INDUSTRY_SELECTION: 'industrySelection',
  LOCATIONS: 'locations',
  OWNEROFFICER: 'ownerOfficer',
  EXPMODS: 'expMods',
  PAST_POLICYLOSS: 'pastPolicyLoss',
  ADDITIONAL_INTEREST: 'additionalInterest',
  UNDERWRITING: 'underwriting',
  BROKER_CODES: 'brokerCodes',
  QUOTES: 'quotes',
  PAYMENT: 'payment',
  CONFIRMATION: 'confirmation',
};

/*
Created a separate constants for different use-cases of updateApplication function, so that sub-steps like updating application from quotes page
on change of liability-limits/bind-now, if insuranceEffectiveDate is in past can be handled without messing up the getUpdateApplicationPayload function
Any NEW step which is part of ApplicationNavigation should be added with same key-value as present in APPLICATION_TABS to avoid any misses.
*/
export const UPDATE_APPLICATION_STEPS = {
  GENERAL_QUESTIONS: 'generalQuestions',
  INDUSTRY_SELECTION: 'industrySelection',
  LOCATIONS: 'locations',
  OWNEROFFICER: 'ownerOfficer',
  EXPMODS: 'expMods',
  PAST_POLICYLOSS: 'pastPolicyLoss',
  ADDITIONAL_INTEREST: 'additionalInterest',
  UNDERWRITING: 'underwriting',
  BROKER_CODES: 'brokerCodes',
  QUOTE_CHANGE_LIABILITY_LIMITS: 'quoteChangeliabilityLimits',
  UPDATE_APPLICATION_EFFECTIVE_DATE_MODAL: 'updateApplicationEffectiveDateModal',
  QUOTES: 'quotes',
  PAYMENT: 'payment',
  CONFIRMATION: 'confirmation',
  UPDATE_SELECTED_CARRIERS: 'updateSelectedCarriers',
  UPDATE_MODIFIERS: 'updateModifiers',
};

export const APPLICATION_STORE_KEYS: { [key: string]: keyof IApplicationStore } = {
  IS_APPLICATION_LOADING: 'isApplicationLoading',
  CURRENT_STEP: 'currentStep',
  CURRENT_SUBSTEP_INDEX: 'currentSubStepIndex',
  SELECTED_CARRIERS_INFO: 'selectedCarriersInfo',
  POLICY_TYPE: 'policyType',
  APPLICATION_DATA: 'applicationData',
  IS_READONLY_APPLICATION: 'isReadOnlyApplication',
};

//TO-DO:- Look at if can use ILiabilityLimitsInfo
export const POLICY_LIABILITY_LIMITS: { [key in PolicyType]?: IPolicyLiabilityLimits } = {
  [PolicyType.WC]: {
    liabilityLimitKeys: ['wcPerAccidentLimit', 'wcPerDiseasePolicyLimit', 'wcPerDiseaseEmployeeLimit'],
    liabilityLimitTexts: ['Per Accident', 'Per Disease Policy', 'Per Disease Employee'],
  },
  [PolicyType.BOP]: {
    liabilityLimitKeys: ['bopPerOccurrenceLimit', 'bopAggregateLimit'],
    liabilityLimitTexts: ['Per Occurrence', 'Per Aggregate'],
  },
  [PolicyType.CGL]: {
    liabilityLimitKeys: ['cglPerOccurrenceLimit', 'cglAggregateLimit'],
    liabilityLimitTexts: ['Per Occurrence', 'Per Aggregate'],
  },
  [PolicyType.CYBER]: {
    liabilityLimitKeys: ['cyberRetentionLimit', 'cyberAggregateLimit'],
    liabilityLimitTexts: ['Retention Limit', 'Aggregate Limit'],
    liabilityLimitTooltips: [
      'The amount of money that the insured is required to pay, per claim, before the insurance company will start paying',
      'The maximum amount the insurance company will pay for all covered claims filed during the policy period',
    ],
  },
};
