import { CheckCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const NotificationHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NotificationTitleStyled = styled.span``;

export const MarkAsReadWrapperStyled = styled.div
  .attrs((props: any) => props)
  .withConfig({
    shouldForwardProp: (props: any) => !['unreadCount'].includes(props),
  })`
  display: flex;
  align-items: center;
  text-decoration: underline;

  cursor: ${(p) => (p.unreadCount > 0 ? 'pointer' : '')};
  color: ${(p) => (p.unreadCount > 0 ? p?.theme?.colorScheme?.primary : p.theme.colors.grayscale.darkGray)};
  :hover {
    color: ${(p) => (p.unreadCount > 0 ? p.theme?.colorScheme?.primaryHover : p.theme.colors.grayscale.darkGray)};
  }
`;

export const MarkAsReadTextStyled = styled.div`
  padding: 4px;
`;

export const MarkAsReadIconStyled = styled(CheckCircleOutlined)``;
