import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { message, notification } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification/interface';
import React from 'react';

export const success = (title: string, timeToExpire?: number) => {
  message.success({
    content: title,
    style: { lineHeight: 2.5, fontWeight: 500 },
    duration: timeToExpire || 3,
  });
};

export const error = (title: string, timeToExpire?: number) => {
  message.error({
    content: title,
    style: { lineHeight: 2.5, fontWeight: 500 },
    duration: timeToExpire || 3,
  });
};

export const errorV2 = (title: string, timeToExpire?: number, onClick?: Function, key?: string) => {
  message.error({
    content: title,
    style: { lineHeight: 2.5, fontWeight: 500, cursor: 'pointer' },
    duration: timeToExpire === 0 ? timeToExpire : timeToExpire || 3,
    onClick,
    key,
  });
};

export const loading = (title: string, timeToExpire?: number, loaderColor?: string) => {
  return message.info({
    content: title,
    style: { lineHeight: 2.5, fontWeight: 500 },
    duration: timeToExpire || 3,
    icon: <LoadingOutlined style={{ color: loaderColor }} />,
  });
};

export const toastInfo = ({
  title,
  iconColor,
  timeToExpire,
  placement,
  width,
}: {
  title: React.ReactNode;
  iconColor?: string;
  timeToExpire?: number;
  placement?: NotificationPlacement;
  width?: string;
}) => {
  return notification.info({
    message: title,
    icon: <InfoCircleOutlined style={{ color: iconColor }} />,
    duration: timeToExpire === 0 ? timeToExpire : timeToExpire || 10,
    placement: placement ?? 'top',
    style: { width: width ?? '520px' },
  });
};
