import { ICFImage } from './interface';
import { StyledCFImage } from './styles';

const CFImage = (props: ICFImage) => {
  const { id, src, height, width, style, loading = 'lazy', decoding = 'async', preview = false, ...rest } = props;

  return (
    <StyledCFImage
      id={id}
      src={src}
      height={height}
      width={width}
      style={style}
      loading={loading}
      decoding={decoding}
      preview={preview}
      {...rest}
    />
  );
};

export default CFImage;
