import { IFileDetails } from '@coverforce-platform/cf-common-api-model';
import { FileType } from '@coverforce-platform/cf-common-types';
import { TableColumnProps } from 'antd';

import { MIME_TYPES } from '../../../globalConstants';
import { formatDateTime } from '../../../helpers/Utils';
import CFParagraph from '../../../ui-core/V2/cfParagraph/cfParagraph';
import { IApplicationDocumentCenter, IFilter } from './interface';
import { DateCellStyled } from './styles';

export const FILE_CATEGORY = [
  { label: 'Policy Document', value: FileType.POLICY },
  { label: 'Other Document', value: FileType.MISCELLANEOUS },
];

export const DOCUMENT_CENTER_MESSAGES = {
  SUCCESS_DELETE_DOCUMENT: 'Document deleted successfully!',
  SUCCESS_DOWNLOAD_DOCUMENT: 'Document downloaded successfully!',
  SUCCESS_UPLOAD_DOCUMENT: 'Document uploaded successfully!',

  ERROR_FETCH_DOCUMENTS: 'Failed to get documents!',
  ERROR_DELETE_DOCUMENT: 'Failed to delete document!',
  ERROR_DOWNLOAD_DOCUMENT: 'Failed to download document!',
  ERROR_UPLOAD_DOCUMENT: 'Failed to upload document!',

  FILE_UPLOAD_SIZE_EXCEED: 'The max file size supported is 10MB.',
};

export const DOCUMENT_CENTER_STORE_META_DATA = {
  ANONYMOUS_ACTION_NAME: 'DOCUMENT_CENTER_ACTION',
  STORE_NAME: 'Document center store',
  UPDATE_DOCUMENT_CENTER_STORE_BY_KEY: 'UPDATE_DOCUMENT_CENTER_STORE_BY_KEY',
  VIEW_DOCUMENTS_LOADING: 'VIEW_DOCUMENTS_LOADING',
  UPLOAD_DOCUMENTS_LOADING: 'UPLOAD_DOCUMENTS_LOADING',
  CLEAR_DOCUMENT_CENTER_STORE: 'CLEAR_DOCUMENT_CENTER_STORE',
};

export const DOCUMENT_CENTER_STORE_KEYS: { [key: string]: keyof IApplicationDocumentCenter } = {
  VIEW_DOCUMENTS_LOADING: 'viewDocumentsLoading',
  UPLOAD_DOCUMENTS_LOADING: 'uploadDocumentLoading',
  FETCH_DOCUMENT_ERROR: 'fetchDocumentsError',
  APPLICATION_DOCUMENTS_LIST: 'applicationDocumentsList',
  DEFAULT_FILE_TYPE: 'defaultFileType',
  DEFAULT_FILTER: 'defaultFilter',
};

export const MAX_ALLOWED_SIZE_FOR_FILE_UPLOAD = 10240; // 10MB

export const VIEW_DOCUMENT_PAGE_SIZE = 3;

export const UPLOAD_ACCEPTED_FILES = Object.values(MIME_TYPES).join(',');

export const DOCUMENT_CENTER_FORM_KEYS = { FILE_UPLOAD: 'fileUpload' };

export const UNSUPPORTED_VIEW_FILE_TYPES = [FileType.ACORD_DOWNLOAD, FileType.ACORD_UPLOAD];

export const UPLOAD_DOCUMENT_FORM_CONFIG = [
  {
    id: 'form-item-file-upload-document',
    key: 'fileUpload',
    name: 'fileUpload',
    label: 'Document upload',
    labelCol: { span: 8 },
    rules: [
      {
        required: true,
        message: 'Please upload a document.',
      },
    ],
  },
  {
    id: 'form-item-file-type',
    key: 'fileType',
    name: 'fileType',
    label: 'File category',
    labelCol: { span: 8 },
    rules: [
      {
        required: true,
        validator: (_: any, value: any) => {
          if (!value?.trim()) {
            return Promise.reject(new Error('Please select a file category.'));
          }
          return Promise.resolve();
        },
      },
    ],
  },
  {
    id: 'form-item-file-type',
    key: 'fileDescription',
    name: 'fileDescription',
    label: 'Document description',
    labelCol: { span: 8 },
    rules: [
      {
        required: true,
        validator: (_: any, value: any) => {
          if (!value?.trim()) {
            return Promise.reject(new Error('Please enter document description.'));
          }
          return Promise.resolve();
        },
      },
    ],
  },
];

export const FORM_FIELD_TYPE = {
  FILE_TYPE: 'fileType',
  FILE_NAME: 'fileName',
  LABEL: 'label',
  CREATED_AT: 'createdAt',
};

export const VIEW_DOCUMENTS_TABLE_CONFIG = (filters?: IFilter | null): Array<TableColumnProps<IFileDetails>> => {
  return [
    {
      title: 'Filename',
      dataIndex: FORM_FIELD_TYPE.FILE_NAME,
      key: FORM_FIELD_TYPE.FILE_NAME,
      width: 150,
      render: (_, fileDetails) => {
        return (
          <CFParagraph
            id={fileDetails?.fileName}
            key={fileDetails?.fileName}
            ellipsis={{
              rows: 2,
              tooltip: <>{fileDetails?.fileName || ''}</>,
            }}
          >
            {fileDetails?.fileName || '--'}
          </CFParagraph>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: FORM_FIELD_TYPE.LABEL,
      key: FORM_FIELD_TYPE.LABEL,
      width: 250,
      render: (_, fileDetails) => {
        return (
          <CFParagraph
            id={`${fileDetails?.label}_${fileDetails?.fileName}`}
            key={`${fileDetails?.label}_${fileDetails?.fileName}`}
            ellipsis={{
              rows: 2,
              tooltip: <>{fileDetails?.label || ''}</>,
            }}
          >
            {fileDetails?.label || '--'}
          </CFParagraph>
        );
      },
    },
    {
      title: 'Category',
      dataIndex: FORM_FIELD_TYPE.FILE_TYPE,
      key: FORM_FIELD_TYPE.FILE_TYPE,
      width: 250,
      render: (_, fileDetails) => {
        return (
          <CFParagraph id={fileDetails?.fileType} key={fileDetails?.fileType}>
            {FILE_CATEGORY.find(({ value }) => value === fileDetails?.fileType)?.label || '--'}
          </CFParagraph>
        );
      },
      onFilter: (value: string, record) => {
        const filteredValue = value?.split(',');
        return filteredValue?.includes(record?.fileType);
      },
      filters: FILE_CATEGORY?.map((category) => ({
        text: category.label,
        value: category.value,
      })),
      filterSearch: true,
      filterMultiple: true,
      filteredValue: filters?.fileType ? [...filters.fileType] : undefined,
    },
    {
      title: 'Uploaded Date',
      dataIndex: FORM_FIELD_TYPE.CREATED_AT,
      key: FORM_FIELD_TYPE.CREATED_AT,
      width: 180,
      render: (_, fileDetails) => {
        return (
          <DateCellStyled>
            {fileDetails?.createdAt ? formatDateTime(fileDetails.createdAt, 'MM-DD-YYYY') : '--'}
          </DateCellStyled>
        );
      },
    },
  ] as Array<TableColumnProps<IFileDetails>>;
};

export const GA_ACTION = {
  CLOSE_MODAL_BTN_CLICK: 'closeModalBtn_click',
  UPLOAD_DOCUMENT_BTN_CLICK: 'uploadDocumentBtn_click',
  BACK_BTN_CLICK: 'backBtn_click',
  DELETE_DOCUMENT_BTN_CLICK: 'deleteDocumentBtn_click',
  DOWNLOAD_BTN_CLICK: 'downloadBtn_click',
};

export const GA_LABEL = {
  CLOSE_MODAL: 'Close modal button clicked.',
  UPLOAD_DOCUMENT: 'Upload document button clicked.',
  BACK_BTN_CLICK: 'Back button clicked from confirmation modal.',
  DELETE_DOCUMENT_FROM_TABLE: 'Delete document button clicked from table.',
  DELETE_DOCUMENT_FROM_CONFIRM_BOX: 'Delete document button clicked from table.',
  DOWNLOAD: 'Download document button clicked.',
};
