import { PolicyType } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getAllLegalEntities } from '../../externalServices/V2/applicationV2';
import { IAntdOptions } from '../../globalInterface';
import { useApplicationStore } from '../../pages/v2/application/store';
import { getPolicyFromApplication } from '../../pages/v2/application/utils';
import { IFormInstance } from '../../ui-core/V2/cfForm/cfForm';
import { isApplicationGeneratedFromAcord } from '../../utils/application/application';
import { GENERAL_QUESTIONS_ACTIONS, LEGAL_ENTITY_TYPE } from './constants';
import { IGeneralQuestionsStore } from './interface';
import {
  autoFillCustomerInfoByAccount,
  getGeneralQuestionsConfig,
  getGqLocationPayload,
  getGQPayload,
  isGeneralQuestionsAnswersValidV2,
  setFormFieldsGeneralQuestion,
} from './utils';

export const useGeneralQuestionsStore = create<IGeneralQuestionsStore>()(
  devtools(
    (set, get) => ({
      generalQuestionsElements: undefined,
      generalQuestionsForm: undefined,
      isGeneralQuestionsLoading: false,

      getGeneralQuestionsElements: () => {
        const generalQuestionsElements = getGeneralQuestionsConfig();
        set({ generalQuestionsElements });
        return generalQuestionsElements;
      },

      setGeneralQuestionsForm: (formObj: IFormInstance) => {
        set({ generalQuestionsForm: formObj }, false, GENERAL_QUESTIONS_ACTIONS.SET_GQ_FORM);
      },

      validateGeneralQuestionsForm: async () => {
        const { generalQuestionsForm } = get();
        const { applicationData } = useApplicationStore.getState();

        const isGQFormValid = await isGeneralQuestionsAnswersValidV2(applicationData, generalQuestionsForm);

        return isGQFormValid;
      },

      fetchLegalEntities: async () => {
        const legalEntities: IAntdOptions[] = await getAllLegalEntities();
        const { getGeneralQuestionsElements } = get();
        const generalQuestionsElements = getGeneralQuestionsElements();
        const legalEntityDropDownIndex = generalQuestionsElements.findIndex(
          (element: any) => element?.dataIndex === LEGAL_ENTITY_TYPE,
        );

        generalQuestionsElements[legalEntityDropDownIndex] = {
          ...generalQuestionsElements[legalEntityDropDownIndex],
          options: legalEntities,
        };
        set({ generalQuestionsElements }, false, GENERAL_QUESTIONS_ACTIONS.FETCH_LEGAL_ENTITIES);
      },

      getGeneralQuestionsApiPayload: () => {
        const { generalQuestionsForm } = get();
        return getGQPayload(generalQuestionsForm);
      },

      getGeneralQuestionsLocationApiPayload: () => {
        return getGqLocationPayload();
      },

      autoFillGeneralQuestionsForm: () => {
        const { generalQuestionsForm } = get();
        const { applicationData } = useApplicationStore.getState();
        const policyType = getPolicyFromApplication(applicationData);

        if (
          isApplicationGeneratedFromAcord(applicationData?.applicationTags) ||
          (applicationData?.basicBusinessDetails?.contacts &&
            applicationData?.basicBusinessDetails?.contacts?.length > 0)
        ) {
          setFormFieldsGeneralQuestion(applicationData!, generalQuestionsForm);
        } else {
          // if we create new application, set default value to yes for mailingAddressSameAsPrimaryAddress
          generalQuestionsForm?.resetFields();
          generalQuestionsForm?.setFieldsValue({ mailingAddressSame: policyType === PolicyType.BR ? 'no' : 'yes' });
        }
      },

      autoFillCustomerInfo: async () => {
        const { generalQuestionsForm } = get();
        autoFillCustomerInfoByAccount(generalQuestionsForm);
      },

      updateGeneralQuestionByKey: async (storeKey: keyof IGeneralQuestionsStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, GENERAL_QUESTIONS_ACTIONS.UPDATE_GQ_BY_KEY),

      clearGeneralQuestions: () => {
        const { generalQuestionsForm } = get();
        generalQuestionsForm?.resetFields();
        set(
          {
            generalQuestionsElements: undefined,
            generalQuestionsForm: undefined,
            isGeneralQuestionsLoading: false,
          },
          false,
          GENERAL_QUESTIONS_ACTIONS.CLEAR_GQ,
        );
      },
    }),
    { anonymousActionType: GENERAL_QUESTIONS_ACTIONS.ANONYMOUS_ACTION, name: GENERAL_QUESTIONS_ACTIONS.STORE_NAME },
  ),
);
