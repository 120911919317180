import { CloseOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { AgentPortalUserType } from '@coverforce-platform/cf-common-types';
import { useCallback, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CSSProperties, useTheme } from 'styled-components';

import { BUTTON_TYPE, FEATURE_FLAG, PAGE_ROUTES, POPUP_TYPE } from '../../globalConstants';
import { useProfileV2Store } from '../../pages/v2/profile/store';
import CFButton from '../../ui-core/V2/cfButton/cfButton';
import { useCFModalStore } from '../../ui-core/V2/cfModal/store';
import { isGrowthbookFeatureEnabledByKey } from '../../utils/growthbook';
import { useConfigurationStore } from '../configuration/store';
import NavItem from '../navItemV2/navItem';
import { USER_MENU_NAVIGATION_PATHS } from '../navMenus/userMenu/constants';
import { getNavItems, NAV_ITEMS, NETWORK_SETTINGS_URL_PREFIX, SETTINGS_URL_PREFIX } from './constants';
import { DropdownMenus, NavItemProps } from './interface';
import { CFButtonStyled, LogoStyled, LogoWrapperStyled, MenuDrawerStyled, NavItemStyled } from './styles';

const NavBar = () => {
  const navigate = useNavigate();
  const theme: any = useTheme();
  const location = useLocation();

  const { userData } = useProfileV2Store();
  const { userType } = useConfigurationStore();
  const { setCfModalData } = useCFModalStore();

  const [isMobileView, setMobileView] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const navItems = getNavItems(userType);

  useLayoutEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 1020) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = useCallback(() => {
    setShowMobileMenu(!showMobileMenu);
  }, [setShowMobileMenu, showMobileMenu]);

  const handleToggleMenuClick = useCallback(() => {
    toggleMenu();
  }, [toggleMenu]);

  const handleHideMobileMenu = () => {
    setShowMobileMenu(false);
  };

  const handleSetShowNewAppModal = () => {
    setCfModalData({ isVisible: true, popupType: POPUP_TYPE.NEW_QUOTE });
  };

  const onClickContactUs = () => {
    setCfModalData({ isVisible: true, popupType: POPUP_TYPE.CONTACT_US });
  };

  const onClickNavigate = (to: string) => {
    navigate(to);
  };
  const getDropdownMenu = (title: string, DropdownMenu: any) => {
    switch (title) {
      case DropdownMenus.SETTINGS:
        return <DropdownMenu />;
      case DropdownMenus.HELP:
        return <DropdownMenu onClickContactUs={onClickContactUs} />;
      case DropdownMenus.USER:
        return <DropdownMenu />;
      case DropdownMenus.NETWORK_SETTINGS:
        return <DropdownMenu />;
      case DropdownMenus.ACCOUNTS:
        return <DropdownMenu />;
      case DropdownMenus.TOOLS:
        return <DropdownMenu />;
      default:
        return;
    }
  };

  const currentNavItem = navItems.findIndex((item) => {
    if (item.to === location.pathname) {
      return true;
    }
    if (item.to === undefined) {
      switch (item.title) {
        case 'Settings':
          return location.pathname.startsWith(SETTINGS_URL_PREFIX);
        case 'Network Settings':
          return location.pathname.startsWith(NETWORK_SETTINGS_URL_PREFIX);
        case 'User':
          return location.pathname.startsWith(USER_MENU_NAVIGATION_PATHS.PROFILE);
        case 'Accounts':
          return location.pathname.startsWith(PAGE_ROUTES.ACCOUNTS) || location.pathname === PAGE_ROUTES.APPLICATIONS;
        case 'Tools':
          return (
            location.pathname.startsWith(PAGE_ROUTES.REPORT_CENTER) ||
            location.pathname.startsWith(PAGE_ROUTES.APPETITE)
          );
      }
    }
  });

  const renderNavItems = ({ itemsToRender, style }: { itemsToRender: NavItemProps[]; style?: CSSProperties }) =>
    itemsToRender.map((item) => {
      const { id, visible = true } = item;
      const dropdown = item.dropdownMenu ? getDropdownMenu(item.title, item.dropdownMenu) : undefined;
      const isItemVisible =
        id === NAV_ITEMS.NOTIFICATIONS.ID
          ? isGrowthbookFeatureEnabledByKey(FEATURE_FLAG.KNOCK_IN_APP_MESSAGE)
          : visible;

      return (
        <>
          {isItemVisible && (
            <NavItem
              {...item}
              key={item.title}
              id={`nav_item_${item.id}`}
              title={item.title === 'User' ? userData?.firstName ?? '' : item.title}
              isActive={currentNavItem === item.index}
              dropdownMenu={dropdown}
              style={style}
              isUser={item.title === 'User'}
            />
          )}
        </>
      );
    });

  const WebMenu = (
    <>
      <NavItemStyled style={{ width: '70%', margin: '0px 20px' }}>
        <LogoWrapperStyled>
          <LogoStyled
            src={theme.brandLogo}
            onClick={() => onClickNavigate(PAGE_ROUTES.ROOT)}
            themeName={theme.themeName}
          />
        </LogoWrapperStyled>
        {renderNavItems({ itemsToRender: navItems.slice(0, 4), style: { marginRight: '10px' } })}
      </NavItemStyled>
      <NavItemStyled style={{ width: '30%', justifyContent: 'end', margin: '0px 20px 0px 0px' }}>
        <CFButtonStyled
          icon={<PlusOutlined />}
          shape='round'
          buttonType={BUTTON_TYPE.PRIMARY}
          id='nav_btn_new_quote'
          onClick={handleSetShowNewAppModal}
        >
          New Quote
        </CFButtonStyled>
        {renderNavItems({
          itemsToRender: navItems.slice(-3),
        })}
      </NavItemStyled>
    </>
  );

  const MobileMenu = (
    <>
      <NavItemStyled className='mobileNav'>
        <CFButton className='menuBtn' buttonType={BUTTON_TYPE.PRIMARY} onClick={handleToggleMenuClick}>
          {!showMobileMenu && <MenuOutlined />}
          {showMobileMenu && <CloseOutlined />}
        </CFButton>
        <LogoWrapperStyled>
          <LogoStyled
            src={theme.brandLogo}
            onClick={() => onClickNavigate(PAGE_ROUTES.ROOT)}
            themeName={theme.themeName}
          />
        </LogoWrapperStyled>
      </NavItemStyled>
      {showMobileMenu && (
        <MenuDrawerStyled
          visible={showMobileMenu}
          className='mobileMenuDrawer'
          onClose={handleHideMobileMenu}
          closable
          destroyOnClose
          maskClosable={false}
          closeIcon={<CFButton buttonType={BUTTON_TYPE.ACTION} shape='circle' icon={<CloseOutlined />} />}
        >
          {renderNavItems({ itemsToRender: navItems.filter((item) => item.id !== NAV_ITEMS.NOTIFICATIONS.ID) })}
        </MenuDrawerStyled>
      )}
    </>
  );

  const NetworkWebMenu = (
    <>
      <NavItemStyled style={{ width: '85%', margin: '0px 10px' }}>
        <LogoWrapperStyled>
          <LogoStyled
            src={theme.brandLogo}
            onClick={() => onClickNavigate(PAGE_ROUTES.ROOT)}
            themeName={theme.themeName}
          />
        </LogoWrapperStyled>
        {renderNavItems({
          itemsToRender: navItems.slice(0, 5),
          style: { marginRight: '5px' },
        })}
      </NavItemStyled>
      <NavItemStyled style={{ width: '15%', justifyContent: 'end', margin: '0px 20px 0px 0px' }}>
        {renderNavItems({
          itemsToRender: navItems.slice(-2),
        })}
      </NavItemStyled>
    </>
  );

  const NetworkMobileMenu = (
    <>
      <NavItemStyled>
        <CFButton buttonType={BUTTON_TYPE.PRIMARY} onClick={handleToggleMenuClick}>
          {!showMobileMenu && <MenuOutlined />}
          {showMobileMenu && <CloseOutlined />}
        </CFButton>
        <LogoWrapperStyled>
          <LogoStyled
            src={theme.brandLogo}
            onClick={() => onClickNavigate(PAGE_ROUTES.ROOT)}
            themeName={theme.themeName}
          />
        </LogoWrapperStyled>
      </NavItemStyled>
      {showMobileMenu && (
        <MenuDrawerStyled
          visible={showMobileMenu}
          onClose={handleHideMobileMenu}
          closable
          destroyOnClose
          maskClosable={false}
          closeIcon={<CFButton buttonType={BUTTON_TYPE.ACTION} shape='circle' icon={<CloseOutlined />} />}
        >
          {renderNavItems({
            itemsToRender: navItems.filter((item) => item.id !== NAV_ITEMS.NOTIFICATIONS.ID),
            style: { width: 'fit-content' },
          })}
        </MenuDrawerStyled>
      )}
    </>
  );

  /** Decided view for nav bar based on userType */
  let navBarView;
  switch (userType) {
    case AgentPortalUserType.AGENCY_NETWORK:
      navBarView = (
        <>
          {!isMobileView && NetworkWebMenu}
          {isMobileView && NetworkMobileMenu}
        </>
      );
      break;
    default:
      navBarView = (
        <>
          {!isMobileView && WebMenu}
          {isMobileView && MobileMenu}
        </>
      );
      break;
  }

  return <>{navBarView}</>;
};

export default NavBar;
