import { Address } from '../../../components/question/locationInput/interface';
import { INPUT_TYPES } from '../../../globalConstants';
import { removeFalseProperty } from '../../../helpers/Utils';
import { cloneDeep, isEmpty, omit, omitBy } from '../../../utils/lodash';
import { IFormConfig } from './interface';

const isAddressFilled = (address?: Address) => {
  if (address === undefined) {
    return false;
  }
  return Object.values(address).some((item) => !isEmpty(item?.trim()));
};

const validateAddress = (address: Address, formConfig: IFormConfig[], validateLocation: boolean) => {
  // check if there is any location input in the form config
  if (!formConfig.some((item) => item.type === INPUT_TYPES.LOCATION)) {
    return true;
  }
  if (!validateLocation && !isAddressFilled(address)) {
    return true;
  }
  if (address === undefined) {
    return false;
  }
  // addressLine2 is optional field so removing it before validation
  const requiredAddressFields = omit(address, 'addressLine2');

  return Object.values(requiredAddressFields).every((item) => item.toString()?.trim()?.length);
};

const cleanEmptyFields = (obj: any) => omitBy(removeFalseProperty(cloneDeep(obj)), isEmpty);

export { validateAddress, isAddressFilled, cleanEmptyFields };
