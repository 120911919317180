import { IBrokerCodeAgentInfo } from '@coverforce-platform/cf-common-api-model';
import { IBrokerConfiguration } from '@coverforce-platform/cf-common-api-model/lib/models/common/brokerConfiguration.models';
import { Carrier, IdHelper, IDType } from '@coverforce-platform/cf-common-types';

import { useApplicationStore } from '../../pages/v2/application/store';
import { cloneDeep } from '../../utils/lodash';
import { useBrokerCodesStore } from './store';

export const getAutofillBrokerConfigurationFromApplication = (): IBrokerConfiguration[] => {
  const { applicationData, selectedCarriersInfo } = useApplicationStore.getState();
  let { brokerConfiguration = [] } = cloneDeep(applicationData) || {};
  const { enabledCarrierBrokerConfigs } = useBrokerCodesStore.getState();
  const brokerConfigs: IBrokerConfiguration[] = [];

  selectedCarriersInfo.forEach((carrierInfo) => {
    const selectedCarrierBrokerConfigurations = enabledCarrierBrokerConfigs?.[carrierInfo.carrierId] || [];
    const carrierBrokerConfigurationIds = selectedCarrierBrokerConfigurations.map((item) => item.brokerConfigurationId);

    const existingBrokerConfiguration = brokerConfiguration.find(
      (item) =>
        item.carrierId === carrierInfo.carrierId && carrierBrokerConfigurationIds.includes(item.brokerConfigurationId),
    );

    if (!existingBrokerConfiguration) {
      brokerConfiguration = brokerConfiguration.filter((item) => item.carrierId !== carrierInfo.carrierId);
      const fallbackBrokerConfiguration = getFallbackCarrierBrokerConfiguration(
        selectedCarrierBrokerConfigurations,
        carrierInfo.carrierId,
      );
      brokerConfigs.push(fallbackBrokerConfiguration);
    } else {
      brokerConfigs.push(existingBrokerConfiguration);
    }
  });

  return brokerConfigs;
};

const getFallbackCarrierBrokerConfiguration = (
  carrierBrokerConfigurations: IBrokerCodeAgentInfo[] = [],
  carrierId: Carrier,
): IBrokerConfiguration => {
  const { isReadOnlyApplication } = useApplicationStore.getState();
  if (!isReadOnlyApplication) {
    const { defaultBrokerCodes } = useBrokerCodesStore.getState();
    const defaultBrokerCode = defaultBrokerCodes?.find((item) => item.carrierId === carrierId);
    if (defaultBrokerCode) {
      return {
        carrierId: defaultBrokerCode.carrierId,
        brokerConfigurationId: defaultBrokerCode.brokerConfigurationId || '',
      };
    } else {
      const brokerIdHelperInstance = new IdHelper();
      const agencyLevelBrokerConfiguration = carrierBrokerConfigurations.find(
        (item) => brokerIdHelperInstance.determineIdType(item?.agencyOrNetworkId) === IDType.AGENCY,
      );
      if (agencyLevelBrokerConfiguration) {
        return {
          carrierId: agencyLevelBrokerConfiguration.carrierId,
          brokerConfigurationId: agencyLevelBrokerConfiguration?.brokerConfigurationId || '',
        };
      } else {
        const networkLevelBrokerConfiguration = carrierBrokerConfigurations.find(
          (item) => brokerIdHelperInstance.determineIdType(item?.agencyOrNetworkId) === IDType.AGENCY_NETWORK,
        );
        return { carrierId, brokerConfigurationId: networkLevelBrokerConfiguration?.brokerConfigurationId || '' };
      }
    }
  } else {
    return { carrierId, brokerConfigurationId: '' };
  }
};
