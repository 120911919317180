import { DatePicker } from 'antd';
import styled from 'styled-components';

export const CFDatePickerStyled = styled(DatePicker)`
  // box-shadow: none;
  // border-color: ${(p) => p.theme.colors.grayscale.grayBorder} !important;

  // :hover {
  //   border-color: ${(p) => (!p.disabled ? p.theme?.colorScheme?.primaryHover : 'default')} !important;
  // }

  // :focus {
  //   border-color: ${(p) => p.theme?.colorScheme?.primaryHover} !important;
  //   box-shadow: 0 0 0 2px ${(p) => p.theme.colors.common.inputBoxShadow} !important;
  //   box-shadow: unset;
  // }

  // .ant-picker-focused {
  //   border-color: ${(p) => p.theme?.colorScheme?.primaryHover} !important;
  //   box-shadow: 0 0 0 2px ${(p) => p.theme.colors.common.inputBoxShadow} !important;
  // }

  // & ~ div {
  //   .ant-picker-cell-selected {
  //     .ant-picker-cell-inner {
  //       background: ${(p) => p?.theme?.colorScheme?.primary};
  //     }
  //   }
  //   .ant-picker-cell-today {
  //     .ant-picker-cell-inner {
  //       &::before {
  //         border-color: ${(p) => p?.theme?.colorScheme?.primary};
  //       }
  //     }
  //   }
  //   .ant-picker-header-view {
  //     button:hover {
  //       color: ${(p) => p?.theme?.colorScheme?.primary};
  //     }
  //   }
  //   .ant-picker-footer {
  //     .ant-picker-today-btn {
  //       color: ${(p) => p?.theme?.colorScheme?.primary};
  //     }
  //   }
  // }

  & ~ div {
    .ant-picker-now-btn {
      color: ${(p) => p?.theme?.colorScheme?.primary};

      :hover {
        color: ${(p) => p?.theme?.colorScheme?.primaryHover};
      }
    }
  }
`;
