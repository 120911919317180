import './index.css';
import './App.css';

import { AppState, Auth0Context, Auth0Provider } from '@auth0/auth0-react';
import { KnockFeedProvider, KnockProvider } from '@knocklabs/react';
import { ConfigProvider } from 'antd';
import { lazy, StrictMode, Suspense, useEffect, useState } from 'react';
import { render } from 'react-dom';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { useConfigurationStore } from './components/configuration/store';
import ErrorBoundaryWrapper from './components/errorBoundary/errorBoundary';
import { FEATURE_FLAG, SESSION_STORAGE_KEYS } from './globalConstants';
import { PageLoaderStyled } from './globalStyles';
import {
  KNOCK_API_KEY,
  KNOCK_FEED_ID,
  REACT_APP_AUDIENCE_AUTH0,
  REACT_APP_CLIENT_ID_AUTH0,
  REACT_APP_DOMAIN_AUTH0,
} from './helpers/EnvironmentVariables';
import { useProfileV2Store } from './pages/v2/profile/store';
import CFLoader from './ui-core/V2/cfLoader/cfLoader';
import { deferredGetAuth0TokenSilently } from './utils/auth0';
import { initialiseGoogleAnalytics } from './utils/googleAnalytics';
import { initialiseGrowthBook, isGrowthbookFeatureEnabledByKey } from './utils/growthbook';
import { isDevEnvironment } from './utils/isDevEnvironment';
import { initialiseLogRocket } from './utils/logrocket';
import { saveToSessionStorage } from './utils/saveToSessionStorage';
import { initialiseSentry } from './utils/sentry';

const AppRoutesPromise = import('./appRoutes');
const AppRoutes = lazy(() => AppRoutesPromise);

const ConfigurationPromise = import('./components/configuration/configuration');
const Configuration = lazy(() => ConfigurationPromise);

const MainApp = () => {
  const navigate = useNavigate();
  const { selectedUserProfile } = useProfileV2Store();
  const { userTheme } = useConfigurationStore();
  const { agentProfileId = '' } = selectedUserProfile || {};

  const onRedirectCallback = (appState: AppState | undefined) => {
    const { returnTo, clientApp } = appState || {};
    saveToSessionStorage(SESSION_STORAGE_KEYS.CLIENT_APP, clientApp ?? 'cf');
    if (returnTo) {
      navigate(returnTo, { replace: true });
    }
  };

  return (
    <StrictMode>
      <Auth0Provider
        domain={REACT_APP_DOMAIN_AUTH0}
        clientId={REACT_APP_CLIENT_ID_AUTH0}
        cacheLocation='localstorage'
        useRefreshTokens={true}
        onRedirectCallback={onRedirectCallback}
        authorizationParams={{
          audience: REACT_APP_AUDIENCE_AUTH0,
          redirect_uri: window.location.origin,
          // scope: REACT_APP_SCOPES_AUTH0,
        }}
      >
        <Auth0Context.Consumer>
          {({ getAccessTokenSilently }: any) => {
            deferredGetAuth0TokenSilently.resolve(getAccessTokenSilently);
            return (
              <ThemeProvider theme={userTheme}>
                <ErrorBoundaryWrapper>
                  <Suspense
                    fallback={
                      <PageLoaderStyled>
                        <CFLoader />
                      </PageLoaderStyled>
                    }
                  >
                    <KnockProvider apiKey={KNOCK_API_KEY} userId={agentProfileId}>
                      <KnockFeedProvider feedId={KNOCK_FEED_ID}>
                        <ConfigProvider
                          theme={{
                            token: {
                              colorPrimary: userTheme?.colorScheme?.primary,
                              fontFamily: userTheme?.typography?.fontFamily?.primary,
                              colorTextPlaceholder: userTheme?.colors?.grayscale?.darkGray,
                              colorTextDisabled: userTheme?.custom?.application?.blackOpacity,
                            },
                            components: {
                              Button: {
                                fontWeight: userTheme?.typography?.fontWeight?.medium,
                              },
                            },
                          }}
                        >
                          <Configuration>
                            <AppRoutes />
                          </Configuration>
                        </ConfigProvider>
                      </KnockFeedProvider>
                    </KnockProvider>
                  </Suspense>
                </ErrorBoundaryWrapper>
              </ThemeProvider>
            );
          }}
        </Auth0Context.Consumer>
      </Auth0Provider>
    </StrictMode>
  );
};

const RootApp = () => {
  const [isGrowthBookLoading, setIsGrowthBookLoading] = useState(true);

  useEffect(() => {
    initialiseGrowthBook({ callback: initialiseGrowthbookCallback });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialiseGrowthbookCallback = () => {
    setIsGrowthBookLoading(false);
    const isSentryEnabled = isGrowthbookFeatureEnabledByKey(FEATURE_FLAG.SENTRY_INTEGRATION);
    const isGAEnabled = isGrowthbookFeatureEnabledByKey(FEATURE_FLAG.GOOGLE_ANALYTICS);
    const isLogRocketEnabled = isGrowthbookFeatureEnabledByKey(FEATURE_FLAG.LOG_ROCKET_INTEGRATION);

    if (isSentryEnabled) {
      initialiseSentry();
    }
    if (isGAEnabled) {
      initialiseGoogleAnalytics();
    }
    if (isLogRocketEnabled && !isDevEnvironment()) {
      initialiseLogRocket();
    }
  };

  return (
    <>
      {isGrowthBookLoading && (
        <PageLoaderStyled>
          <CFLoader />
        </PageLoaderStyled>
      )}
      {!isGrowthBookLoading && (
        <BrowserRouter>
          <MainApp />
        </BrowserRouter>
      )}
    </>
  );
};

render(<RootApp />, document.getElementById('root'));
