import { useCallback, useMemo } from 'react';

// import { ICFDatePicker } from './interface';
import { CFDatePickerStyled } from './style';

// TO-DO:- Fix for "props: any"
// const CFDatePicker = (props: ICFDatePicker) => {
const CFDatePicker = (props: any) => {
  const { data, onChange, disabled, getPopupContainer: customGetPopupContainer, containerId, subtype, picker } = props;

  const handleChange = useCallback(
    (value) => {
      onChange && onChange(value, data);
    },
    [data, onChange],
  );

  const getPopupContainer: (node: HTMLElement) => HTMLElement = useMemo(() => {
    if (customGetPopupContainer) {
      return customGetPopupContainer;
    } else if (containerId) {
      return () => document.getElementById(containerId) || document.body;
    } else {
      return (triggerNode: HTMLElement) => (triggerNode?.parentNode as HTMLElement) || document.body;
    }
  }, [containerId, customGetPopupContainer]);

  return (
    <CFDatePickerStyled
      disabled={disabled}
      picker={picker ?? subtype}
      {...props}
      onChange={handleChange}
      getPopupContainer={getPopupContainer}
    />
  );
};

export default CFDatePicker;
