import { Popover } from 'antd';
import styled from 'styled-components';

export const CfPopoverWrapperStyled = styled.div`
  .ant-popover-inner {
    padding: 0px;
  }

  .ant-popover-title {
    min-height: 36px;
    padding: 8px 16px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 0px;
  }

  .ant-popover-inner-content {
    min-height: 350px;
    max-height: 450px;
    overflow: auto;
    width: 350px;
    padding: 0px;
  }
`;

export const CFPopoverStyled = styled(Popover)``;
