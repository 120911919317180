import { PAGE_NAME, PAGE_ROUTES } from '../globalConstants';

export const getCurrentPageName = () => {
  const pathName = window?.location?.pathname;

  if (!pathName) {
    return PAGE_NAME.UNKNOWN;
  }

  const matchResultForAccountDetailsPage = pathName.match(
    /\/accounts\/AC-[a-f0-9]+-[a-f0-9]+-[a-f0-9]+-[a-f0-9]+-[a-f0-9]+/,
  );

  const matchResultForApplicationDetailsPage = pathName.match(
    /\/application\/APP-[a-f0-9]+-[a-f0-9]+-[a-f0-9]+-[a-f0-9]+-[a-f0-9]+/,
  );

  if (matchResultForAccountDetailsPage) {
    return PAGE_NAME.ACCOUNT_DETAIL;
  }

  if (matchResultForApplicationDetailsPage) {
    return PAGE_NAME.APPLICATION;
  }

  if (pathName.startsWith(PAGE_ROUTES.APPLICATIONS)) {
    return PAGE_NAME.APPLICATIONS;
  }

  return PAGE_NAME.UNKNOWN;
};
