import {
  IApplicationReassignAgencyInput,
  IApplicationReassignAgencyOutput,
  ICloneApplicationInput,
  ICloneApplicationOutput,
  ICreateApplicationFromAcordInput,
  ICreateApplicationFromAcordOutput,
  ICreateApplicationInput,
  ICreateApplicationOutput,
  IDeleteApplicationInput,
  IGetAllIndustryClassCodesInput,
  IGetAllIndustryClassCodesOutput,
  IGetApplicationFieldsConfigInput,
  IGetApplicationFieldsConfigOutput,
  IGetApplicationInput,
  IGetApplicationListInput,
  IGetApplicationListOutput,
  IGetApplicationOutput,
  IGetIndustryClassCodeDetailsInput,
  IGetIndustryClassCodeDetailsOutput,
  IGetModifiersForApplicationInput,
  IGetModifiersForApplicationOutput,
  IGetNaicsCodesOutput,
  IGetPolicyDocumentInput,
  IGetPolicyDocumentOutput,
  IGetPolicyInput,
  IGetPolicyOutput,
  IGetSicCodeOutput,
  IGetStatementsInput,
  IGetStatementsOutput,
  IGetStatementsQueryParamInput,
  IMarkApplicationBoundInput,
  IUpdateApplicationInput,
  IUpdateApplicationOutput,
} from '@coverforce-platform/cf-common-api-model';
import { Carrier } from '@coverforce-platform/cf-common-types';
import { AxiosResponse } from 'axios';
import { capitalize } from 'lodash';

import axiosApplicationHelperService from '../../axios/applicationHelperService/instance';
import axiosApplicationService from '../../axios/applicationService/instance';
import { IIndustryCode } from '../../components/industrySelection/interface';
import { APPLICATION_SERVICE_ENDPOINTS, HELPER_SERVICE_ENDPOINTS } from '../../externalServices/helpers/ApiRoutes';
import { IAntdOptions } from '../../globalInterface';
import { createQueryUrl } from '../../utils/createQueryUrl';
import { formatToAntdOptions } from '../../utils/formatToAntdOptions';
import { movePrimaryAddressToTop } from '../../utils/movePrimaryAddressToTop';

export const createApplication = async (params: ICreateApplicationInput): Promise<ICreateApplicationOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.CREATE_APPLICATION(),
  });
  try {
    const response: AxiosResponse = await axiosApplicationService.post(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const getAllNaicsCodes = async (): Promise<IGetNaicsCodesOutput> => {
  const apiUrl = createQueryUrl({
    url: HELPER_SERVICE_ENDPOINTS.GET_NAICS_CODES,
  });

  try {
    // ?? why it is a post api when there is no payload?
    const response: AxiosResponse<IGetNaicsCodesOutput, any> = await axiosApplicationHelperService.post(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const getAllSicCodes = async (): Promise<IGetSicCodeOutput> => {
  const apiUrl = createQueryUrl({
    url: HELPER_SERVICE_ENDPOINTS.GET_SIC_CODES,
  });

  try {
    // ?? why it is a post api when there is no payload?
    const response: AxiosResponse<IGetSicCodeOutput, any> = await axiosApplicationHelperService.post(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const getApplicationsByAccountId = async (
  params: IGetApplicationListInput,
): Promise<IGetApplicationListOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.GET_ALL_APPLICATION_BY_ACOUNT_ID,
    params,
  });
  try {
    const response: AxiosResponse = await axiosApplicationService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const deleteApplication = async (params: IDeleteApplicationInput) => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.DELETE_APPLICATION(params.applicationId),
  });

  try {
    const response: AxiosResponse = await axiosApplicationService.delete(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const cloneApplication = async (params: ICloneApplicationInput): Promise<ICloneApplicationOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.CLONE_APPLICATION,
  });
  try {
    const response: AxiosResponse = await axiosApplicationService.post(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const getUWStatements = async (
  params: IGetStatementsInput,
  queryParams: IGetStatementsQueryParamInput,
): Promise<IGetStatementsOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.GET_UW_STATEMENTS(params),
    params: queryParams,
  });
  try {
    const response: AxiosResponse = await axiosApplicationService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const createApplicationFromAcord = async (
  params: ICreateApplicationFromAcordInput,
): Promise<ICreateApplicationFromAcordOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.CREATE_APPLICATION_FROM_ACORD,
  });
  try {
    const response: AxiosResponse = await axiosApplicationService.post(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const getApplication = async (params: IGetApplicationInput): Promise<IGetApplicationOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.GET_APPLICATION(params),
  });
  try {
    const response: AxiosResponse = await axiosApplicationService.get(apiUrl);

    const applicationData = response?.data as IGetApplicationOutput;
    //movePrimaryAddressToTop util introduced and modify the locations ordering as backend doesn't send in locations array in order where primary is at top
    if (applicationData?.locationDetails && applicationData?.locationDetails?.length > 0) {
      const modifiedLocationDetails = movePrimaryAddressToTop({ locationDetails: applicationData?.locationDetails });
      applicationData.locationDetails = modifiedLocationDetails;
      return applicationData;
    }

    return response?.data;
  } catch (error: any) {
    if (error?.response?.status) {
      (error?.response?.data?.errors || []).forEach((item: any) => {
        item.status = error?.response?.status;
      });
    }
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const updateApplication = async (params: IUpdateApplicationInput): Promise<IUpdateApplicationOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.UPDATE_APPLICATION,
  });
  try {
    const response: AxiosResponse = await axiosApplicationService.put(apiUrl, params);

    //movePrimaryAddressToTop util introduced and modify the locations ordering as backend doesn't send in locations array in order where primary is at top
    const applicationData = response?.data as IGetApplicationOutput;
    if (applicationData?.locationDetails && applicationData?.locationDetails?.length > 0) {
      const modifiedLocationDetails = movePrimaryAddressToTop({ locationDetails: applicationData?.locationDetails });
      applicationData.locationDetails = modifiedLocationDetails;
      return applicationData;
    }

    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const reAssignApplication = async (
  params: IApplicationReassignAgencyInput,
): Promise<IApplicationReassignAgencyOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.RE_ASSIGN_APPLICATION,
  });
  try {
    const response: AxiosResponse = await axiosApplicationService.post(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const markApplicationAsBound = async (params: IMarkApplicationBoundInput): Promise<void> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.MARK_AS_BOUND,
  });
  try {
    const response: AxiosResponse = await axiosApplicationService.post(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const getAllLegalEntities = async (): Promise<IAntdOptions[]> => {
  const apiUrl = createQueryUrl({
    url: HELPER_SERVICE_ENDPOINTS.GET_LEGAL_ENTITIES,
  });

  try {
    const response: AxiosResponse = await axiosApplicationHelperService.post(apiUrl);
    const legalEntities = formatToAntdOptions({
      list: response?.data?.legalEntityInfos,
      listLabelKey: 'description',
      listValueKey: 'cfLegalEntityId',
      labelCallback: (label) => capitalize(label),
    });
    return legalEntities;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const getPolicyDocument = async (params: IGetPolicyDocumentInput): Promise<IGetPolicyDocumentOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.GET_POLICY_DOCUMENT,
  });
  try {
    const response: AxiosResponse = await axiosApplicationService.post(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const getPolicyDetails = async (params: IGetPolicyInput): Promise<IGetPolicyOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.GET_POLICY_DETAILS,
  });
  try {
    const response: AxiosResponse = await axiosApplicationService.post(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const getAllIndustryClassCodes = async (params: IGetAllIndustryClassCodesInput): Promise<IIndustryCode[]> => {
  const apiUrl = createQueryUrl({
    url: HELPER_SERVICE_ENDPOINTS.GET_INDUSTRY_CLASS_CODES,
    params,
  });

  try {
    const response: AxiosResponse<IGetAllIndustryClassCodesOutput> = await axiosApplicationHelperService.get(apiUrl);
    const industryCodes: IIndustryCode[] = response?.data?.industryCodesInfo.map((classCode) => ({
      code: classCode.classCodeId,
      description: classCode.classCodeDescription,
      sic: classCode.sicCodes?.length ? classCode.sicCodes?.join(', ') : '--',
      naics: classCode.naicsCodes?.length ? classCode.naicsCodes?.join(', ') : '--',
    }));
    return industryCodes;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const getIndustryClassCodeDetails = async (
  params: IGetIndustryClassCodeDetailsInput,
): Promise<IGetIndustryClassCodeDetailsOutput> => {
  const apiUrl = createQueryUrl({
    url: HELPER_SERVICE_ENDPOINTS.GET_INDUSTRY_CLASS_CODE_DESCRIPTION,
    params,
  });

  try {
    const response: AxiosResponse<IGetIndustryClassCodeDetailsOutput> = await axiosApplicationHelperService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const getApplicationFieldsConfig = async (
  params: IGetApplicationFieldsConfigInput,
): Promise<IGetApplicationFieldsConfigOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.GET_APPLICATION_FIELDS_CONFIG(params),
  });

  try {
    const response: AxiosResponse<IGetApplicationFieldsConfigOutput> = await axiosApplicationService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const getModifiers = async (
  params: IGetModifiersForApplicationInput,
  queryParams?: { carrier: Carrier },
): Promise<IGetModifiersForApplicationOutput> => {
  const apiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.GET_MODIFIERS(params),
    params: queryParams,
  });

  try {
    const response: AxiosResponse<IGetModifiersForApplicationOutput> = await axiosApplicationService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};
