import { IdcardOutlined, LogoutOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons';

import { SESSION_STORAGE_KEYS } from '../../../globalConstants';
import { useProfileV2Store } from '../../../pages/v2/profile/store';
import { ITheme } from '../../../types';
import Anchor from '../../../ui-core/V2/anchor/anchor';
import { CFMenuItem } from '../../../ui-core/V2/cfMenu/cfMenu';
import { getFromSessionStorage } from '../../../utils/getFromSessionStorage';
import { USER_MENU_NAVIGATION_PATHS, USER_MENU_SECTIONS, USER_ROLE_DISPLAY_NAME_MAPPING } from './constants';
import {
  AvailableProfilesAvatarStyled,
  AvailableProfileTitleStyled,
  CFNavUserMenuStyled,
  SelectedProfileAvatarStyled,
  SelectedUserProfileWrapperStyled,
  UserProfileBottomSectionItemRoleStyled,
  UserProfileBottomSectionItemStyled,
  UserProfileBottomSectionStyled,
  UserProfileDetailWrapperStyled,
  UserProfileMenuItemStyled,
  UserProfileTopSectionItemStyled,
  UserProfileTopSectionStyled,
  UserProfileWrapperStyled,
} from './styles';

export const getSelectedUserProfileSubMenu = ({
  onClickNavigate,
  theme,
}: {
  onClickNavigate: Function;
  theme: ITheme;
}) => {
  const { userProfiles } = useProfileV2Store.getState();

  return (
    <CFNavUserMenuStyled
      id='nav_menu_user_profile'
      onClick={(info) => onClickNavigate(info.key)}
      backgroundColor={theme.colors.grayscale.white}
      sectionType={USER_MENU_SECTIONS.SELECTED_USER_PROFILE}
    >
      {userProfiles && userProfiles?.length > 0 && (
        <AvailableProfileTitleStyled>Available Profiles</AvailableProfileTitleStyled>
      )}
      {userProfiles?.map((profile) => {
        const isSelectedProfile = profile?.agentProfileId === getFromSessionStorage(SESSION_STORAGE_KEYS.PROFILE_ID);
        return (
          <>
            {isSelectedProfile && (
              <SelectedUserProfileWrapperStyled key={profile?.agentProfileId}>
                <div>
                  <SelectedProfileAvatarStyled size={28} icon={<UserOutlined />} />
                </div>
                <UserProfileDetailWrapperStyled selected={isSelectedProfile}>
                  <UserProfileTopSectionStyled>
                    <UserProfileTopSectionItemStyled>{profile?.agencyName}</UserProfileTopSectionItemStyled>
                  </UserProfileTopSectionStyled>
                  <UserProfileBottomSectionStyled>
                    <UserProfileBottomSectionItemStyled isNetworkUser={!profile?.agencyName}>
                      {profile?.agencyNetworkName}
                    </UserProfileBottomSectionItemStyled>
                    <UserProfileBottomSectionItemRoleStyled>
                      ({USER_ROLE_DISPLAY_NAME_MAPPING[profile?.role]})
                    </UserProfileBottomSectionItemRoleStyled>
                  </UserProfileBottomSectionStyled>
                </UserProfileDetailWrapperStyled>
              </SelectedUserProfileWrapperStyled>
            )}
          </>
        );
      })}
    </CFNavUserMenuStyled>
  );
};

export const getOtherProfilesSubMenu = ({ onClickNavigate, theme }: { onClickNavigate: Function; theme: ITheme }) => {
  const { userProfiles } = useProfileV2Store.getState();

  return (
    <CFNavUserMenuStyled
      id='nav_menu_user_available_profiles'
      onClick={(info) => onClickNavigate(info.key)}
      backgroundColor={theme.colors.grayscale.white}
      sectionType={USER_MENU_SECTIONS.OTHER_PROFILES}
    >
      {userProfiles?.map((profile) => {
        const isSelectedProfile = profile?.agentProfileId === getFromSessionStorage(SESSION_STORAGE_KEYS.PROFILE_ID);
        return (
          <>
            {!isSelectedProfile && (
              <UserProfileMenuItemStyled selected={false} key={`?profileId=${profile?.agentProfileId}`}>
                <UserProfileWrapperStyled selected={false}>
                  <div>
                    <AvailableProfilesAvatarStyled size={28} icon={<UserSwitchOutlined />} />
                  </div>
                  <UserProfileDetailWrapperStyled selected={isSelectedProfile}>
                    <UserProfileTopSectionStyled>
                      <UserProfileTopSectionItemStyled>{profile?.agencyName}</UserProfileTopSectionItemStyled>
                    </UserProfileTopSectionStyled>
                    <UserProfileBottomSectionStyled>
                      <UserProfileBottomSectionItemStyled isNetworkUser={!profile?.agencyName}>
                        {profile?.agencyNetworkName}
                      </UserProfileBottomSectionItemStyled>
                      <UserProfileBottomSectionItemRoleStyled>
                        ({USER_ROLE_DISPLAY_NAME_MAPPING[profile?.role]})
                      </UserProfileBottomSectionItemRoleStyled>
                    </UserProfileBottomSectionStyled>
                  </UserProfileDetailWrapperStyled>
                </UserProfileWrapperStyled>
              </UserProfileMenuItemStyled>
            )}
          </>
        );
      })}
    </CFNavUserMenuStyled>
  );
};

export const getUserSubMenu = ({ onClickNavigate, theme }: { onClickNavigate: Function; theme: ITheme }) => {
  return (
    <CFNavUserMenuStyled
      id='nav_menu_user'
      onClick={(info) => onClickNavigate(info.key)}
      backgroundColor={theme.colors.grayscale.white}
      sectionType={USER_MENU_SECTIONS.USER}
    >
      <CFMenuItem icon={<IdcardOutlined size={28} />} key={USER_MENU_NAVIGATION_PATHS.PROFILE}>
        <Anchor id='nav_profile' href={USER_MENU_NAVIGATION_PATHS.PROFILE}>
          Profile Settings
        </Anchor>
      </CFMenuItem>
      <CFMenuItem icon={<LogoutOutlined size={28} />} key={USER_MENU_NAVIGATION_PATHS.LOGOUT} danger>
        Logout
      </CFMenuItem>
    </CFNavUserMenuStyled>
  );
};
