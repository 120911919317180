import {
  IAddAgentPortalThemeInput,
  IAddAgentPortalThemeOutput,
  IGetAgentPortalThemeInput,
  IGetAgentPortalThemeOutput,
  IGetLoginAndRegPageConfigInput,
  IGetLoginAndRegPageConfigOutput,
  IUpdateAgentPortalThemeInput,
  IUpdateAgentPortalThemeOutput,
} from '@coverforce-platform/cf-common-api-model';
import { AxiosResponse } from 'axios';

import axiosAccountService from '../../axios/accountService/instance';
import { createQueryUrl } from '../../utils/createQueryUrl';
import { THEME_SERVICE } from '../helpers/ApiRoutes';

const getThemeByThemeName = async (params: IGetAgentPortalThemeInput): Promise<IGetAgentPortalThemeOutput> => {
  const apiUrl = createQueryUrl({ url: THEME_SERVICE.GET_THEME(params) });
  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const updateTheme = async (params: IUpdateAgentPortalThemeInput): Promise<IUpdateAgentPortalThemeOutput> => {
  const apiUrl = createQueryUrl({ url: THEME_SERVICE.UPDATE_THEME });
  try {
    const response: AxiosResponse = await axiosAccountService.put(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const addTheme = async (params: IAddAgentPortalThemeInput): Promise<IAddAgentPortalThemeOutput> => {
  const apiUrl = createQueryUrl({ url: THEME_SERVICE.ADD_THEME });
  try {
    const response: AxiosResponse = await axiosAccountService.post(apiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getLoginRegistrationConfig = async (
  params: IGetLoginAndRegPageConfigInput,
): Promise<IGetLoginAndRegPageConfigOutput> => {
  const apiUrl = createQueryUrl({ url: THEME_SERVICE.GET_LOGIN_REGISTRATION_CONFIG(params) });

  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl, {
      customConfig: { isUnauthenticated: true },
    });
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export { getThemeByThemeName, updateTheme, addTheme, getLoginRegistrationConfig };
