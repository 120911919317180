import styled from 'styled-components';

export const StyledCFLoader = styled.div.attrs((props: { marginTop?: string; marginBottom?: string }) => props)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};

  .ant-spin-dot-item {
    background-color: ${(p) => p.theme?.colorScheme?.primary};
  }
`;
