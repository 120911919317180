import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';

import { UPDATE_APPLICATION_STEPS } from '../../constants/applicationV2Constants';
import { QUOTES_STORE_KEYS } from '../../constants/quotesConstants';
import { useApplicationStore } from '../../pages/v2/application/store';
import { errorV2 } from '../../ui-core/Notification';
import CFDatePicker from '../../ui-core/V2/cfDatePicker/cFDatePicker';
import CFForm, { useForm } from '../../ui-core/V2/cfForm/cfForm';
import CFFormItem from '../../ui-core/V2/cfFormItem/cfFormItem';
import { useCFModalStore } from '../../ui-core/V2/cfModal/store';
import { pushEvent } from '../../utils/googleAnalytics';
import { cloneDeep } from '../../utils/lodash';
import { GA_ACTION, GA_LABEL } from '../quotes/constants';
import { useQuotesStore } from '../quotes/store';
import { EFFECTIVE_DATE_ERROR, EFFECTIVE_DATE_MODAL_QUESTIONS_KEYS, EFFECTIVE_DATE_QUESTIONS } from './constants';
import { useEffectiveDateModalStore } from './store';
import { EffectiveDateModalStyled, EffectiveDateTextStyled } from './styles';
import { getGACategory } from './utils';

const EffectiveDateModal = () => {
  const [form] = useForm();
  const {
    effectiveDataModalForm,
    effectiveDateModalCallbackData,
    setEffectiveDateModalForm,
    autoFillEffectiveDateModalForm,
    validateEffectiveDateModalForm,
    clearEffectiveDateModal,
  } = useEffectiveDateModalStore();
  const { updateApplication } = useApplicationStore();
  const { fetchQuotes, updateQuotesByKey } = useQuotesStore();
  const { clearCfModal } = useCFModalStore();

  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setEffectiveDateModalForm(form);
    autoFillEffectiveDateModalForm();

    return () => {
      clearEffectiveDateModal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeDate = useCallback(
    (date, data) => {
      switch (data.dataIndex) {
        case EFFECTIVE_DATE_MODAL_QUESTIONS_KEYS.insuranceEffectiveDate:
          {
            if (dayjs(date).isValid()) {
              effectiveDataModalForm?.setFieldValue(
                EFFECTIVE_DATE_MODAL_QUESTIONS_KEYS.insuranceExpiryDate,
                dayjs(date)?.add(1, 'y'),
              );
            } else {
              effectiveDataModalForm?.setFieldValue(EFFECTIVE_DATE_MODAL_QUESTIONS_KEYS.insuranceExpiryDate, '');
            }

            // Clearing existing error
            effectiveDataModalForm?.setFields([
              {
                name: EFFECTIVE_DATE_MODAL_QUESTIONS_KEYS.insuranceEffectiveDate,
                errors: [],
              },
              {
                name: EFFECTIVE_DATE_MODAL_QUESTIONS_KEYS.insuranceExpiryDate,
                errors: [],
              },
            ]);
          }
          break;

        default:
          {
            effectiveDataModalForm?.setFieldValue(data.dataIndex || '', date);
          }
          break;
      }
    },
    [effectiveDataModalForm],
  );

  const onEffectiveDatesUpdate = async () => {
    const callbackData = cloneDeep(effectiveDateModalCallbackData);
    const { callbackFunction } = callbackData || {};
    const isEffectiveDateModalFormValid = await validateEffectiveDateModalForm();

    if (isEffectiveDateModalFormValid) {
      setIsProcessing(true);
      pushEvent({
        category: getGACategory(),
        action: GA_ACTION.GET_QUOTE_BTN_CLICK,
        label: GA_LABEL.GET_QUOTE,
      });
      try {
        await updateApplication({
          updateApplicationStep: UPDATE_APPLICATION_STEPS.UPDATE_APPLICATION_EFFECTIVE_DATE_MODAL,
          isApplicationLoading: false,
        });
        clearCfModal();
        await updateQuotesByKey(QUOTES_STORE_KEYS.QUOTES_DATA, []);
        await fetchQuotes();
        callbackFunction && (await callbackFunction());
      } catch (err: any) {
        errorV2(err?.[0]?.errorMessage || EFFECTIVE_DATE_ERROR);
      } finally {
        setIsProcessing(false);
      }
    }
  };
  const handleCancel = () => {
    pushEvent({
      category: getGACategory(),
      action: GA_ACTION.CLOSE_MODAL_BTN_CLICK,
      label: GA_LABEL.CLOSE_EFFECTIVE_DATE_MODAL,
    });
  };
  return (
    <EffectiveDateModalStyled
      title={'Change Policy Period'}
      okText='Get Quote'
      onOk={onEffectiveDatesUpdate}
      okButtonProps={{ id: 'btn_effective_modal_ok', loading: isProcessing, disabled: isProcessing }}
      cancelButtonProps={{ id: 'btn_effective_modal_cancel', disabled: isProcessing }}
      maskClosable={!isProcessing}
      disableCloseIcon={isProcessing}
      onCancel={handleCancel}
    >
      <EffectiveDateTextStyled>
        The policy effective date is in the past. Please select a new policy effective date to continue.
      </EffectiveDateTextStyled>
      <CFForm
        id='form_past_effective_date_modal'
        form={form}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 12 }}
        colon={false}
        labelAlign='left'
        labelWrap={true}
      >
        {EFFECTIVE_DATE_QUESTIONS.map((question) => (
          <CFFormItem
            id={question.name}
            name={question.dataIndex}
            label={question.name}
            rules={question.rules}
            key={question.dataIndex}
          >
            <CFDatePicker
              id={`dtp_${question.dataIndex}`}
              getPopupContainer={() => document.body}
              disabledDate={question.disabledDate}
              format={'MM-DD-YYYY'}
              onChange={handleChangeDate}
              data={question}
            />
          </CFFormItem>
        ))}
      </CFForm>
    </EffectiveDateModalStyled>
  );
};
export default EffectiveDateModal;
