import styled from 'styled-components';

import CFForm from '../../ui-core/V2/cfForm/cfForm';
import ApplicationQuestion from '../applicationQuestion/applicationQuestion';

export const GenerateReportQuestionStyled = styled(ApplicationQuestion)`
  .ant-picker-range {
    width: 100%;
  }
  .ant-checkbox-wrapper {
    padding-bottom: 7px;
  }
`;

export const GenerateReportFormStyled = styled(CFForm)`
  .ant-picker-cell-selected > .ant-picker-cell-inner {
    background-color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-picker-range .ant-picker-active-bar {
    background: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: ${(p) => p?.theme?.colorScheme?.primary};
    opacity: 0.1;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: ${(p) => p?.theme?.colorScheme?.primary};
    opacity: 0.1;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: ${(p) => p?.theme?.colorScheme?.primary};
  }
`;
