export const BROKER_CODES_STORE = {
  NAME: 'Broker Codes Store',
  ANONYMOUS_ACTION: 'brokerCodes',
  CARRIER_BROKER_CONFIGS_LOADING: 'CARRIER_BROKER_CONFIGS_LOADING',
  FETCH_CARRIER_BROKER_CONFIGS: 'FETCH_CARRIER_BROKER_CONFIGS',
  AUTOFILL_BROKER_CONFIGURATION: 'AUTOFILL_BROKER_CONFIGURATION',
  UPDATE_BROKER_CONFIGURATION: 'UPDATE_BROKER_CONFIGURATION',
  UPDATE_BROKER_CODES_STORE_BY_KEY: 'UPDATE_BROKER_CODES_STORE_BY_KEY',
  CLEAR_BROKER_CODES: 'CLEAR_BROKER_CODES',
};

export const BROKER_CODE_LEVEL_TEXT = {
  AGENCY: 'Direct Appointment',
  AGENCY_NETWORK: 'Network Code',
};

export const BROKER_CODES_ERROR = 'There was an error. Please try again in some time';
