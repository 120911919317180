import { useCallback } from 'react';

import { ICFAutoComplete } from './interface';
import { StyledCFAutoComplete } from './styles';

const CFAutoComplete = (props: ICFAutoComplete) => {
  const { id, onChangeData, onChange, onSelectData, onSelect, ...rest } = props;

  const handleChange = useCallback(
    (value) => {
      onChange && onChange(value, onChangeData);
    },
    [onChangeData, onChange],
  );

  const handleSelect = useCallback(
    (value, option) => {
      onSelect && onSelect(value, option, onSelectData);
    },
    [onSelectData, onSelect],
  );

  return <StyledCFAutoComplete id={id} onChange={handleChange} onSelect={handleSelect} {...rest} />;
};

export default CFAutoComplete;
