import { PolicyType } from '@coverforce-platform/cf-common-types';

import { useConfigurationStore } from '../configuration/store';
import { PREFER_CARRIER_PARTNER } from './constants';

export const getPreferCarriers = (selectedPolicy?: PolicyType) => {
  const { userTheme } = useConfigurationStore.getState();
  if (selectedPolicy === PolicyType.WC) {
    return PREFER_CARRIER_PARTNER[userTheme.themeName] || [];
  }
  return [];
};
