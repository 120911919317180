import { Collapse } from 'antd';
import React, { useCallback } from 'react';

import { IAccordion } from './interface';
import { CollapseStyled, CollapseWrapperStyled } from './style';

const { Panel } = Collapse;

const Accordion = ({
  children,
  header,
  data,
  onChange,
  itemKey,
  disabled = false,
  showArrow,
  panelStyle,
  defaultActiveKey,
  ...rest
}: IAccordion) => {
  const handleChange = useCallback(
    (key) => {
      onChange && onChange(key, data);
    },
    [data, onChange],
  );

  return (
    <React.Fragment key={itemKey}>
      <CollapseWrapperStyled>
        <CollapseStyled
          expandIconPosition={'end'}
          {...rest}
          defaultActiveKey={defaultActiveKey}
          onChange={handleChange}
        >
          <Panel
            header={header}
            key={itemKey}
            collapsible={disabled ? 'disabled' : undefined}
            showArrow={showArrow}
            style={panelStyle}
          >
            {children}
          </Panel>
        </CollapseStyled>
      </CollapseWrapperStyled>
    </React.Fragment>
  );
};

export default Accordion;
