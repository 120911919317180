import { useEffect } from 'react';

import { QUOTES_STORE_KEYS } from '../../constants/quotesConstants';
import { useQuotesStore } from '../quotes/store';
import { ISU_BLUE_LIGHT_SPECIAL_MODAL } from './constants';
import { IsuBlueLightSpecialModalStyled } from './styles';

const IsuBlueLightSpecialModal = () => {
  const { selectedQuote, isuCarriersInfo, updateQuotesByKey } = useQuotesStore();
  const { carrierId } = selectedQuote || {};

  useEffect(() => {
    return () => {
      updateQuotesByKey(QUOTES_STORE_KEYS.SELECTED_QUOTE, undefined);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isuBlueLightSpecialContent =
    isuCarriersInfo.find((item) => item.carrierId === carrierId)?.isuBlueLightSpecials || '';

  return (
    <IsuBlueLightSpecialModalStyled title={ISU_BLUE_LIGHT_SPECIAL_MODAL.TITLE} footer={null}>
      {isuBlueLightSpecialContent}
    </IsuBlueLightSpecialModalStyled>
  );
};

export default IsuBlueLightSpecialModal;
