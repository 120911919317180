import { IApplicationInfo, IGetApplicationFieldsConfigInput } from '@coverforce-platform/cf-common-api-model';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { APPLICATION_TABS } from '../../../constants/applicationV2Constants';
import { getAccountByAccountId } from '../../../externalServices/V2/accountsV2';
import {
  getApplication,
  getApplicationFieldsConfig,
  updateApplication,
} from '../../../externalServices/V2/applicationV2';
import { UWQuestionsRanking } from '../../../helpers/Constants';
import { sortBy } from '../../../utils/lodash';
import { useBrokerConfigStore } from '../brokerConfig/store';
import { useProfileV2Store } from '../profile/store';
import { APPLICATION_STORE } from './constants';
import { IApplicationStore } from './interface';
import {
  getPolicyFromApplication,
  getSelectedCarriersFromApplication,
  getSelectedCarriersInfoFromApplication,
  getUpdateApplicationPayload,
  isDifferentApplicationPayload,
} from './utils';

export const useApplicationStore = create<IApplicationStore>()(
  devtools(
    (set, get) => ({
      isReadOnlyApplication: false,
      isApplicationLoading: false,
      applicationData: undefined,
      applicationError: undefined,
      isAccountLoading: false,
      accountData: undefined,
      accountError: undefined,
      policyType: undefined,
      selectedCarriersInfo: [],
      currentStep: APPLICATION_TABS.GENERAL_QUESTIONS,
      currentSubStepIndex: -1,
      applicationFieldsConfig: undefined,

      fetchApplication: async (applicationId: string, isApplicationLoading = true) => {
        set({ isApplicationLoading }, false, APPLICATION_STORE.APPLICATION_LOADING);
        try {
          const applicationData = (await getApplication({ applicationId })) as IApplicationInfo;
          set({ applicationData, applicationError: undefined }, false, APPLICATION_STORE.APPLICATION_SUCCESS);
          return applicationData;
        } catch (error: any) {
          set({ applicationData: undefined, applicationError: error }, false, APPLICATION_STORE.APPLICATION_ERROR);
          return error;
        } finally {
          set({ isApplicationLoading: false }, false, APPLICATION_STORE.APPLICATION_LOADING);
        }
      },

      updateApplication: async ({ updateApplicationStep, isApplicationLoading = true, forceUpdate = false }) => {
        const { applicationData: storeApplicationData } = get();
        set({ isApplicationLoading }, false, APPLICATION_STORE.APPLICATION_LOADING);
        const updateApplicationPayload = getUpdateApplicationPayload(updateApplicationStep);

        // check payload is same or not, if yes then not make any api call.
        // if we want to make forcefully update application call, then pass forceUpdate = true in updateApplication call.
        let applicationData = storeApplicationData;
        if (isDifferentApplicationPayload(updateApplicationPayload) || forceUpdate) {
          applicationData = (await updateApplication(updateApplicationPayload)) as IApplicationInfo;
        }

        set({ applicationData }, false, APPLICATION_STORE.UPDATE_APPLICATION);
        return applicationData as IApplicationInfo;
      },

      fetchAccount: async (accountId: string, isAccountLoading = true) => {
        set({ isAccountLoading }, false, APPLICATION_STORE.ACCOUNT_LOADING);
        try {
          const response = await getAccountByAccountId({
            accountId,
          });
          set({ accountData: response, accountError: null }, false, APPLICATION_STORE.ACCOUNT_SUCCESS);
        } catch (err: any) {
          set({ accountData: undefined, accountError: err }, false, APPLICATION_STORE.ACCOUNT_ERROR);
        } finally {
          set({ isAccountLoading: false }, false, APPLICATION_STORE.ACCOUNT_LOADING);
        }
      },

      fetchSelectedCarriersInfo: async () => {
        const { userData } = useProfileV2Store.getState();
        const { fetchAllCarriers: fetchAllAvailableCarriers } = useBrokerConfigStore.getState();
        const { applicationData } = get();
        const applicationSelectedCarriers = getSelectedCarriersFromApplication(applicationData);
        const policyType = getPolicyFromApplication(applicationData);

        if (applicationSelectedCarriers?.length > 0) {
          const allAvailableCarriers = await fetchAllAvailableCarriers({ agentId: userData?.agentId || '' });
          if (allAvailableCarriers?.length > 0) {
            const applicationSelectedCarriersInfo = getSelectedCarriersInfoFromApplication(
              applicationData,
              allAvailableCarriers,
            );

            // INFO: According to predefined rankings of carriers it will be set, so if travelers priority high then that carrier display first.
            const rankedUWQuestionsCarriersSorted = sortBy(applicationSelectedCarriersInfo, (carrier) => {
              return UWQuestionsRanking[policyType ?? '']?.indexOf(carrier.carrierId);
            });
            set(
              { selectedCarriersInfo: rankedUWQuestionsCarriersSorted },
              false,
              APPLICATION_STORE.APPLICATION_SELECTED_CARRIERS_INFO,
            );
          }
        }
      },

      fetchApplicationFieldsConfig: async ({ applicationId }: IGetApplicationFieldsConfigInput) => {
        try {
          const fieldsConfigResponse = await getApplicationFieldsConfig({ applicationId });
          set(
            { applicationFieldsConfig: fieldsConfigResponse?.fieldsConfig },
            false,
            APPLICATION_STORE.SET_APPLICATION_FIELDS,
          );
          return fieldsConfigResponse?.fieldsConfig;
        } catch (error) {
          return [];
          //NOTE: return empty array, in case api fails we will assume every field has to be shown to the user
        }
      },

      updateApplicationByKey: (storeKey: keyof IApplicationStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, APPLICATION_STORE.UPDATE_APPLICATION_STORE_BY_KEY),

      clearApplication: () =>
        set(
          {
            isApplicationLoading: false,
            applicationData: undefined,
            applicationError: undefined,
            isAccountLoading: false,
            accountData: undefined,
            accountError: undefined,
            policyType: undefined,
            selectedCarriersInfo: [],
            currentSubStepIndex: -1,
            isReadOnlyApplication: false,
            applicationFieldsConfig: undefined,
            currentStep: APPLICATION_TABS.GENERAL_QUESTIONS,
          },
          false,
          APPLICATION_STORE.CLEAR_APPLICATION,
        ),
    }),

    { name: APPLICATION_STORE.NAME, anonymousActionType: APPLICATION_STORE.ANONYMOUS_ACTION_TYPE },
  ),
);
