import { useForm, useWatch } from 'antd/lib/form/Form';
import { useEffect } from 'react';

import { errorV2, success } from '../../ui-core/Notification';
import CFModal from '../../ui-core/V2/cfModal/cfModal';
import { useCFModalStore } from '../../ui-core/V2/cfModal/store';
import { getReportTypeColumnInfo } from '../scheduleReport/componentUtils';
import { ReportTypeColumnInfoStyled } from '../scheduleReport/styles';
import { GENERATE_REPORT_FORM_CONFIG } from './componentUtils';
import { GENERATE_REPORT_MESSAGES, GENERATE_REPORT_QUESTION_KEYS, GENERATE_REPORT_STORE_KEYS } from './constants';
import { IGenerateReportQuestion } from './interface';
import { useGenerateReportStore } from './store';
import { GenerateReportFormStyled, GenerateReportQuestionStyled } from './styles';
import { handleGenerateReportFieldChange } from './utils';

const GenerateReport = () => {
  const [form] = useForm();
  const { clearCfModal } = useCFModalStore();
  const {
    generateReportForm,
    generateReportElements,
    generateReportLoading,
    generateReport,
    updateGenerateReportStoreByKey,
    validateGenerateReportForm,
    clearGenerateReportStore,
  } = useGenerateReportStore();

  useEffect(() => {
    updateGenerateReportStoreByKey(GENERATE_REPORT_STORE_KEYS.GENERATE_REPORT_FORM, form);
    updateGenerateReportStoreByKey(GENERATE_REPORT_STORE_KEYS.GENERATE_REPORT_ELEMENTS, GENERATE_REPORT_FORM_CONFIG);
    return () => {
      clearGenerateReportStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reportType = useWatch([GENERATE_REPORT_QUESTION_KEYS.REPORT_TYPE], generateReportForm);

  const handleOkGenerateReport = async () => {
    const isValid = await validateGenerateReportForm();

    try {
      if (isValid) {
        updateGenerateReportStoreByKey(GENERATE_REPORT_STORE_KEYS.GENERATE_REPORT_LOADING, true);
        const response = await generateReport();
        if (response?.reportContent) {
          window.open(response?.reportContent);
        }
        success(GENERATE_REPORT_MESSAGES.SUCCESS_GENERATE_REPORT, 6);
        clearCfModal();
      }
    } catch (error: any) {
      errorV2(error?.[0]?.errorMessage || GENERATE_REPORT_MESSAGES.ERROR_GENERATE_REPORT);
    } finally {
      updateGenerateReportStoreByKey(GENERATE_REPORT_STORE_KEYS.GENERATE_REPORT_LOADING, false);
    }
  };

  return (
    <CFModal
      title='Generate Report'
      width={700}
      onOk={handleOkGenerateReport}
      disableCloseIcon={generateReportLoading}
      maskClosable={!generateReportLoading}
      cancelButtonProps={{ id: 'btn_generate_report_cancel', disabled: generateReportLoading }}
      okButtonProps={{ id: 'btn_generate_ok', disabled: generateReportLoading, loading: generateReportLoading }}
    >
      {generateReportForm && (
        <GenerateReportFormStyled
          id='form_generate_report'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={generateReportForm}
          disabled={generateReportLoading}
        >
          {generateReportElements &&
            generateReportElements?.map((question: IGenerateReportQuestion) => {
              const handleChangeProp = handleGenerateReportFieldChange(question);

              return (
                <>
                  <GenerateReportQuestionStyled
                    question={question}
                    key={`question-${question.dataIndex}`}
                    allQuestions={GENERATE_REPORT_FORM_CONFIG}
                    form={form}
                    {...handleChangeProp}
                  />
                  {
                    <>
                      {question.dataIndex === GENERATE_REPORT_QUESTION_KEYS.REPORT_TYPE && reportType && (
                        <ReportTypeColumnInfoStyled>{getReportTypeColumnInfo(reportType)}</ReportTypeColumnInfoStyled>
                      )}
                    </>
                  }
                </>
              );
            })}
        </GenerateReportFormStyled>
      )}
    </CFModal>
  );
};

export default GenerateReport;
