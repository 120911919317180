import { ICarrierInfo } from '@coverforce-platform/cf-common-api-model';
import { Carrier, PolicyType } from '@coverforce-platform/cf-common-types';

import { PAGE_NAME, USState } from '../../../globalConstants';
import { GA_CATEGORY as ACCOUNT_GA_CATEGORY } from '../../../pages/v2/account/constants';
import { getCurrentPageName } from '../../../utils/getCurrentPageName';
import { difference } from '../../../utils/lodash';
import { ACORD_MESSAGES } from './constants';
import { IAppetiteField, IApplicationAcordConfig } from './interface';

export const getSupportedCarriers = (carriersData: ICarrierInfo[], onCarriers: Carrier[], policyTypeId: PolicyType) => {
  const supportedCarriersArr: Array<{
    carrierId: Carrier;
    carrierDisplayName: string;
  }> = carriersData
    .filter((carrier) => {
      const availablePolicyTypes = carrier.availablePolicyTypes?.map((policy) => policy.policyTypeId);
      return availablePolicyTypes?.includes(policyTypeId);
    })
    .filter((carrier) => onCarriers?.includes(carrier?.carrierId))
    .map(({ carrierId, carrierDisplayName }) => ({
      carrierId,
      carrierDisplayName,
    }));

  return supportedCarriersArr;
};

export const getStateFromAccountId = (
  allAccountsData: Array<{ accountId: string; accountName: string; accountPrimaryState?: string }>,
  accountId: string,
) => {
  const stateInfo = {
    code: '',
    state: '',
  };
  const selectedAccountPrimaryStateCode = allAccountsData?.filter(
    (accountData: { accountId: string; accountName: string; accountPrimaryState?: string }) => {
      return accountId === accountData?.accountId;
    },
  )[0]?.accountPrimaryState;
  if (selectedAccountPrimaryStateCode) {
    stateInfo.code = selectedAccountPrimaryStateCode;
    stateInfo.state = USState[selectedAccountPrimaryStateCode];
    return stateInfo;
  }
};

const getInAppetiteDefaultValue = (inAppetiteCarriers: IAppetiteField[]) => {
  const selectedCarrierArr = inAppetiteCarriers.map((item) => {
    return item?.value;
  });
  return selectedCarrierArr;
};

export const getDefaultCarrierInAppetite = ({
  policyType,
  inAppetiteCarriers,
}: {
  policyType?: PolicyType;
  inAppetiteCarriers: IAppetiteField[];
}) => {
  const carriers = getInAppetiteDefaultValue(inAppetiteCarriers);
  if (policyType === PolicyType.WC && carriers.includes(Carrier.ACCIDENTFUND) && carriers.includes(Carrier.COMPWEST)) {
    return carriers.filter((carrier) => carrier !== Carrier.ACCIDENTFUND);
  }
  return carriers;
};
export const isPolicyTypeAllowedForAcord = (
  allowedPolicyTypesForAcord: IApplicationAcordConfig[],
  policyType: PolicyType,
): boolean => {
  const acordDetailByPolicyType = allowedPolicyTypesForAcord?.find(
    (policyTypeAcordDetails: IApplicationAcordConfig) => policyTypeAcordDetails.policyType === policyType,
  );
  /** If ".enabled" is not there it will return false */
  return acordDetailByPolicyType ? Boolean(acordDetailByPolicyType.enabled) : false;
};

export const extractPolicySpecificRule = (
  allowedPolicyTypesForAcord: IApplicationAcordConfig[],
  policyType: PolicyType,
) => {
  const acordDetailByPolicyType = allowedPolicyTypesForAcord?.find(
    (policyTypeAcordDetails: IApplicationAcordConfig) => policyTypeAcordDetails.policyType === policyType,
  );
  return acordDetailByPolicyType;
};

export const getAcordUploadMessageForPolicyType = (
  allowedPolicyTypesForAcord: IApplicationAcordConfig[],
  policyType: PolicyType,
): string => {
  const acordDetailByPolicyType = allowedPolicyTypesForAcord?.find(
    (policyTypeAcordDetails: IApplicationAcordConfig) => policyTypeAcordDetails.policyType === policyType,
  );
  /** it will pick default message it, there is no config for selected policy type or no uploadAcordMessage */
  return acordDetailByPolicyType
    ? acordDetailByPolicyType.uploadAcordMessage || ACORD_MESSAGES.DEFAULT_ACORD_UPLOAD_MESSAGE
    : ACORD_MESSAGES.DEFAULT_ACORD_UPLOAD_MESSAGE;
};
export const getGACategory = () => {
  const currentPage = getCurrentPageName();
  switch (currentPage) {
    case PAGE_NAME.ACCOUNT_DETAIL:
      return ACCOUNT_GA_CATEGORY.ACCOUNT_NEW_QUOTE_MODAL;

    default:
      return currentPage;
  }
};

const filterEitherOneSelectCarrier = ({
  addedCarrier,
  options,
  newSelectedCarriers,
  otherGroupCarriers,
}: {
  addedCarrier?: string;
  options: Carrier[];
  newSelectedCarriers: string[];
  otherGroupCarriers: string[];
}) => {
  if (addedCarrier && options.includes(addedCarrier as Carrier)) {
    return {
      currentGroupCarriers: [
        ...newSelectedCarriers.filter((carrier) => !options.includes(carrier as Carrier)),
        addedCarrier,
      ],
      otherGroupCarriers: [...otherGroupCarriers.filter((carrier) => !options.includes(carrier as Carrier))],
    };
  }
  return { currentGroupCarriers: newSelectedCarriers, otherGroupCarriers };
};

export const getCheckBoxValues = ({
  oldSelectedCarriers,
  newSelectedCarriers,
  policyType,
  otherGroupCarriers,
}: {
  oldSelectedCarriers: string[];
  newSelectedCarriers: string[];
  policyType?: PolicyType;
  otherGroupCarriers: string[];
}) => {
  if (policyType === PolicyType.WC) {
    const addedCarriers = difference(newSelectedCarriers, oldSelectedCarriers);
    return filterEitherOneSelectCarrier({
      addedCarrier: addedCarriers?.[0],
      newSelectedCarriers,
      options: [Carrier.ACCIDENTFUND, Carrier.COMPWEST],
      otherGroupCarriers,
    });
  }
  return { otherGroupCarriers, currentGroupCarriers: newSelectedCarriers };
};
