import { useCallback } from 'react';

import { ICFTextArea } from './interface';
import { StyledCFTextArea } from './styles';

const CFTextArea = (props: ICFTextArea) => {
  const { data, onChange, id } = props;

  const handleChange = useCallback(
    (event) => {
      onChange && onChange(event, data);
    },
    [data, onChange],
  );

  return <StyledCFTextArea {...props} id={id} onChange={handleChange} />;
};

export default CFTextArea;
