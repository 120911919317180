import { useCallback } from 'react';

import { PAGE_ROUTES, POPUP_TYPE } from '../../globalConstants';
import CFButton from '../../ui-core/V2/cfButton/cfButton';
import CFImage from '../../ui-core/V2/cfImage/cfImage';
import { useCFModalStore } from '../../ui-core/V2/cfModal/store';
import withLayout from '../withLayout/withLayout';
import { BUTTON_IDS, errorActionButtons } from './constant';
import { ButtonWrapperStyled, ImageWrapperStyled, TextWrapperStyled, WrapperStyled } from './styles';

const ErrorFallback = ({
  resetErrorBoundary,
}: {
  resetErrorBoundary: (event: React.MouseEvent<HTMLElement>) => void;
}) => {
  const { setCfModalData } = useCFModalStore();

  const handleClick = useCallback(
    (event, key) => {
      switch (key) {
        case BUTTON_IDS.SUBMIT_REQUEST:
          setCfModalData({ isVisible: true, popupType: POPUP_TYPE.CONTACT_US });
          return;
        case BUTTON_IDS.TRY_AGAIN:
          resetErrorBoundary(event);
          return;

        case BUTTON_IDS.HOME:
        default:
          window.location.href = PAGE_ROUTES.ROOT;
          return;
      }
    },
    [resetErrorBoundary, setCfModalData],
  );

  return (
    <WrapperStyled>
      <ImageWrapperStyled>
        <CFImage id='img_something_wrong' src={'/images/something_wrong.gif'} />
      </ImageWrapperStyled>
      <div>
        <TextWrapperStyled>
          <p>
            Oops. Something went wrong! Please try again later. If the issue re-occurs you can submit a request using
            the button below
          </p>
        </TextWrapperStyled>
        <ButtonWrapperStyled>
          {errorActionButtons.map((button) => (
            <CFButton
              key={button.id}
              id={button.id}
              buttonType={button.buttonType}
              data={button.id}
              onClick={handleClick}
            >
              {button.title}
            </CFButton>
          ))}
        </ButtonWrapperStyled>
      </div>
    </WrapperStyled>
  );
};

export default withLayout(ErrorFallback, false);
