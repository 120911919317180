import { Menu } from 'antd';
import styled from 'styled-components';

export const CFMenuStyled = styled(Menu)
  .attrs((props: { backgroundColor?: string }) => props)
  .withConfig({ shouldForwardProp: (props) => !['backgroundColor'].includes(props) })`
  border-bottom: 1px solid ${(p) => p.theme.colors.primary.border};
  background-color: ${(p) => p.backgroundColor ?? 'transparent'};
  font-weight: ${(p) => p.theme.typography.fontWeight.medium};
  font-size: ${(p) => p.theme.typography.fontSize.mediumLarge};
  line-height: 55px;
  color: ${(p) => p.theme.colors.grayscale.lightGrayText};

  .ant-menu-item {
    // margin-left: -20px;
    margin-right: 20px;

    :hover {
      // color: ${(p) => p?.theme?.colors?.grayscale?.black} !important;

      // &:not(.ant-menu-item-selected):after {
      //   border-bottom: 3px solid ${(p) => p?.theme?.colors?.grayscale?.black} !important;
      // }
      &:not(.ant-menu-item-selected) {
        border-radius: 0px;
        // border-bottom: 3px solid ${(p) => p?.theme?.colors?.grayscale?.black} !important;
        border-bottom: 3px solid ${(p) => p?.theme?.colorScheme?.primaryHover};

        :hover {
          color: ${(p) => p?.theme?.colorScheme?.primaryHover};
        }
      }
    }
  }

  .ant-menu-item-selected {
    // color: ${(p) => p?.theme?.colors?.grayscale?.black} !important;
    color: ${(p) => p?.theme?.colorScheme?.primary};

    // :hover {
    //   color: ${(p) => p?.theme?.colorScheme?.primary} !important;
    // }
    :hover {
      color: ${(p) => p?.theme?.colorScheme?.primaryHover};
      border-bottom: 3px solid ${(p) => p?.theme?.colorScheme?.primaryHover};
    }

    // ::after {
    //   border-bottom: 3px solid ${(p) => p?.theme?.colorScheme?.primary} !important;
    // }
    border-radius: 0px;
    border-bottom: 3px solid ${(p) => p?.theme?.colorScheme?.primary};
  }

  // .ant-menu-submenu {
  //   color: ${(p) => p?.theme?.colors?.grayscale?.black} !important;

  //   :hover {
  //     color: ${(p) => p?.theme?.colorScheme?.primary} !important;

  //     &:not(.ant-menu-submenu-selected):after {
  //       border-bottom: 3px solid ${(p) => p?.theme?.colorScheme?.primary} !important;
  //     }
  //   }

  //   ::after {
  //     border-bottom: 3px solid ${(p) => p?.theme?.colorScheme?.primary} !important;
  //   }
  // }
`;

export const CFMenuItemStyled = styled(CFMenuStyled.Item).attrs((p: any) => p)`
  &.ant-dropdown-menu-item-danger {
    color: ${(p) => (p.disabled ? p.theme.colors.grayscale.blackOpacity : 'default')};

    :hover {
      color: ${(p) => (p.disabled ? p.theme.colors.grayscale.blackOpacity : 'default')};
      background-color: ${(p) => (p.disabled ? 'unset' : 'default')};
    }
  }
`;
