import { IPolicyTypeInfo } from '@coverforce-platform/cf-common-api-model';
import { Carrier, PolicyType } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getAllPolicyTypes, getCarrierRankingByPolicyTypeId } from '../../../externalServices/V2/carrierRankingV2';
import {
  getAllCarriersInfo,
  getAvailableCarriersByAgencyId,
  updateCarrierRanking,
} from '../../../externalServices/V2/carriersV2';
import { error, success } from '../../../ui-core/Notification';
import { useProfileV2Store } from '../profile/store';
import { message } from './constants';
import { ICarrierConfigStore, IUpdateCarrierRankingParams } from './interface';
import { getAllowedPoliciesForAgency, getAllPoliciesForAgency } from './utils';

export const useCarrierConfigStore = create<ICarrierConfigStore>()(
  devtools(
    (set, get) => ({
      rankingsLoading: false,
      rankingsError: null,
      allCarriersLoading: false,
      allCarriers: {},
      allCarriersError: null,
      availableCarriersForAgencyLoading: true,
      availableCarriersForAgency: [],
      availableCarriersForAgencyError: null,
      allowedPoliciesForAgency: [],
      policyTypesLoading: false,
      policyTypes: {},
      policyTypesError: null,
      allCarriersPolicyTypes: {},
      selectedPolicyType: '',
      rankedCarriers: {},
      disabledCarriers: {},

      getAllCarriers: async () => {
        set({ allCarriersLoading: true }, false, 'getAllCarriersLoadingStart');
        try {
          const response = await getAllCarriersInfo();
          const carriers: { [k: string]: string } = {};
          const agentDisabledCarriers: { [k: string]: IPolicyTypeInfo[] } = {};
          response.carriers.forEach((carrier) => {
            carriers[carrier.carrierId] = carrier.carrierDisplayName;
            agentDisabledCarriers[carrier.carrierId] = carrier.availablePolicyTypes || [];
            return carriers;
          });
          set(
            { allCarriers: carriers, allCarriersPolicyTypes: agentDisabledCarriers, allCarriersError: null },
            false,
            'getAllCarriers',
          );
        } catch (err: any) {
          set({ allCarriers: {}, allCarriersPolicyTypes: {}, allCarriersError: err }, false, 'getAllCarriersError');
        } finally {
          set({ allCarriersLoading: false }, false, 'getAllCarriersLoadingStop');
        }
      },

      getAvailableCarriersByAgencyId: async (agencyId: string, availableCarriersForAgencyLoading = true) => {
        set({ availableCarriersForAgencyLoading }, false, 'getAvailableCarriersForAgencyLoadingStart');
        try {
          const response = await getAvailableCarriersByAgencyId({ agencyId });
          const allPoliciesAgency = getAllPoliciesForAgency(response?.availableCarriers);
          const allowedPoliciesForAgency = getAllowedPoliciesForAgency(allPoliciesAgency);
          set(
            {
              availableCarriersForAgency: response?.availableCarriers,
              allowedPoliciesForAgency,
              availableCarriersForAgencyError: null,
            },
            false,
            'availableCarriersForAgency',
          );
        } catch (err: any) {
          set(
            { availableCarriersForAgency: [], allowedPoliciesForAgency: [], availableCarriersForAgencyError: err },
            false,
            'availableCarriersForAgencyError',
          );
        } finally {
          set({ availableCarriersForAgencyLoading: false }, false, 'getAvailableCarriersForAgencyLoadingEnd');
        }
      },

      getCarrierRankingsList: async (selectedPolicyType: string) => {
        set({ rankingsLoading: true }, false, 'getPolicyTypesLoadingStart');
        const { updateRankedCarriers, updateDisabledCarriers } = get();
        try {
          const carriersRanking = await getCarrierRankingByPolicyTypeId({
            agentId: useProfileV2Store.getState().userData?.agentId || '',
            policyTypeId: PolicyType[selectedPolicyType as keyof typeof PolicyType],
          });
          updateRankedCarriers(selectedPolicyType, carriersRanking.onCarriers);
          updateDisabledCarriers(selectedPolicyType, carriersRanking.offCarriers);
          set({ rankingsError: null }, false, 'getCarrierRankingsList');
        } catch (err: any) {
          set({ rankingsError: err }, false, 'getCarrierRankingsListError');
        } finally {
          set({ rankingsLoading: false }, false, 'getCarrierRankingsListLoadingStop');
        }
      },

      getPolicyTypes: async () => {
        try {
          set({ policyTypesLoading: true }, false, 'getPolicyTypesLoadingStart');
          const rankedCarriers: { [k: string]: string[] } = {};
          const disabledCarriers: { [k: string]: string[] } = {};
          const policyTypes: { [k: string]: string } = {};
          const response = await getAllPolicyTypes();
          response.policyTypes.forEach((policyType) => {
            policyTypes[policyType.policyTypeId] = policyType.policyTypeDisplayName;
          });
          // Initilizing the data with empty array, will be updated when we select policyType
          Object.keys(policyTypes).forEach((policyTypeId) => {
            disabledCarriers[policyTypeId] = [];
            rankedCarriers[policyTypeId] = [];
          });
          set(
            {
              policyTypes,
              disabledCarriers: { ...disabledCarriers },
              rankedCarriers: { ...rankedCarriers },
              policyTypesError: null,
              selectedPolicyType: Object.keys(policyTypes).sort().reverse()[0],
            },
            false,
            'getPolicyTypes',
          );
        } catch (err: any) {
          set(
            { policyTypes: {}, disabledCarriers: {}, rankedCarriers: {}, policyTypesError: err },
            false,
            'getPolicyTypesError',
          );
        } finally {
          set({ policyTypesLoading: false }, false, 'getPolicyTypesLoadingStop');
        }
      },

      setSelectedPolicyType: (selectedPolicyType: string) => {
        set({ selectedPolicyType }, false, 'setSelectedPolicyType');
      },

      updateRankedCarriers: (selectedPolicyType: string, updatedCarriers: string[]) => {
        const { rankedCarriers } = get();
        rankedCarriers[selectedPolicyType] = [...updatedCarriers];
        set({ rankedCarriers: { ...rankedCarriers } }, false, 'updateRankedCarriers');
      },

      updateDisabledCarriers: (selectedPolicyType: string, updatedCarriers: string[]) => {
        const { disabledCarriers } = get();
        disabledCarriers[selectedPolicyType] = [...updatedCarriers];
        set({ disabledCarriers: { ...disabledCarriers } }, false, 'updateDisabledCarriers');
      },

      updateCarrierRanking: async ({
        rankedCarriers,
        disabledCarriers,
        selectedPolicyType,
      }: IUpdateCarrierRankingParams) => {
        try {
          const params = {
            agencyOrNetworkId: useProfileV2Store.getState().selectedUserProfile?.agencyId || '',
            policyTypeId: PolicyType[selectedPolicyType as keyof typeof PolicyType],
            onCarriers: rankedCarriers[selectedPolicyType].map((carrierString) => {
              return Carrier[carrierString as keyof typeof Carrier];
            }),
            offCarriers: disabledCarriers[selectedPolicyType].map((carrierString) => {
              return Carrier[carrierString as keyof typeof Carrier];
            }),
          };
          await updateCarrierRanking({ ...params });
          success(message.rankingsUpdateSuccess);
        } catch (err: any) {
          get().getCarrierRankingsList(get().selectedPolicyType);
          error(err?.[0]?.errorMessage);
        }
      },

      clearCarrierConfig: () =>
        set(
          {
            allCarriers: {},
            selectedPolicyType: '',
            rankedCarriers: {},
            rankingsError: null,
            disabledCarriers: {},
            allCarriersError: null,
            allCarriersLoading: false,
            rankingsLoading: false,
            policyTypesLoading: false,
            policyTypes: {},
            policyTypesError: null,
            allCarriersPolicyTypes: {},
          },
          false,
          'clearCarrierConfig',
        ),
    }),

    { name: 'Carrier Config Store', anonymousActionType: 'carrierConfig' },
  ),
);
