import { useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { logError } from '../../utils/sentry';
import ErrorFallback from '../errorFallback/errorFallback';

const ErrorBoundaryWrapper = ({ children }: { children: React.ReactElement }) => {
  const resetHandler = useCallback(() => {
    window.location.reload();
  }, []);

  const errorHandler = useCallback((error: Error) => {
    logError(error);
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler} onReset={resetHandler}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
