import { IFileDetails } from '@coverforce-platform/cf-common-api-model';
import { FileType } from '@coverforce-platform/cf-common-types';
import { TablePaginationConfig } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { FilterValue } from 'antd/lib/table/interface';
import { useEffect, useMemo, useState } from 'react';

import { useConfirmationStore } from '../../../components/confirmation/store';
import { APPLICATION_TABS } from '../../../constants/applicationV2Constants';
import { BUTTON_TYPE } from '../../../globalConstants';
import { useApplicationStore } from '../../../pages/v2/application/store';
import { errorV2, success } from '../../../ui-core/Notification';
import CFForm from '../../../ui-core/V2/cfForm/cfForm';
import CFSelect from '../../../ui-core/V2/cfSelect/cfSelect';
import { CFTableColumn } from '../../../ui-core/V2/cfTable/cfTable';
import { TableEmptyStyled } from '../../../ui-core/V2/cfTable/styles';
import CFTextArea from '../../../ui-core/V2/cfTextArea/cfTextArea';
import getBase64FromFile from '../../../utils/getBase64FromFile';
import { pushEvent } from '../../../utils/googleAnalytics';
import {
  getDeleteApplicationFileModalContent,
  getEmptyAttachmentsMessage,
  getErrorTextMessage,
  getViewApplicationActionButtons,
} from './componentUtils';
import {
  DOCUMENT_CENTER_FORM_KEYS,
  DOCUMENT_CENTER_MESSAGES,
  DOCUMENT_CENTER_STORE_KEYS,
  FILE_CATEGORY,
  FORM_FIELD_TYPE,
  GA_ACTION,
  GA_LABEL,
  MAX_ALLOWED_SIZE_FOR_FILE_UPLOAD,
  UNSUPPORTED_VIEW_FILE_TYPES,
  UPLOAD_ACCEPTED_FILES,
  UPLOAD_DOCUMENT_FORM_CONFIG,
  VIEW_DOCUMENT_PAGE_SIZE,
  VIEW_DOCUMENTS_TABLE_CONFIG,
} from './constants';
import { IFilter } from './interface';
import { useApplicationDocumentCenterStore } from './store';
import {
  CFFileDropperStyled,
  CFModalStyled,
  ConfirmModalContentStyled,
  DocumentCenterModalContentStyled,
  DocUploadTextStyled,
  InboxOutlinedStyled,
  InboxOutlinedWrapperStyled,
  UploadDocumentButton,
  UploadDocumentButtonWrapper,
  UploadDocumentFormItemStyled,
  ViewDocumentTableStyled,
  ViewDocumentTitleStyled,
  ViewDocumentWrapperStyled,
} from './styles';
import { getGACategory } from './utils';

const ApplicationDocumentCenterModal = () => {
  const [form] = useForm();
  const [isConfirmDocumentDeleteModal, setIsConfirmDocumentDeleteModal] = useState<boolean>(false);
  const [deleteFile, setDeleteFile] = useState<IFileDetails | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState<IFilter | undefined>(undefined);
  const { currentStep } = useApplicationStore();
  const { getPolicyDocument } = useConfirmationStore();

  const {
    applicationDocumentsList,
    viewDocumentsLoading,
    uploadDocumentLoading,
    fetchDocumentsError,
    deleteDocument,
    fetchDocuments,
    uploadDocument,
    downloadDocument,
    updateDocumentCenterByKey,
    clearDocumentCenterStore,
    defaultFileType,
    defaultFilter,
  } = useApplicationDocumentCenterStore();

  useEffect(() => {
    fetchDocuments();

    return () => {
      clearDocumentCenterStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilters(defaultFilter);
  }, [defaultFilter]);

  useEffect(() => {
    form?.setFieldValue(FORM_FIELD_TYPE.FILE_TYPE, defaultFileType);
  }, [defaultFileType, form]);

  const handleBackFromConfirmation = () => {
    setIsConfirmDocumentDeleteModal(false);
    pushEvent({
      category: getGACategory(),
      action: GA_ACTION.BACK_BTN_CLICK,
      label: GA_LABEL.BACK_BTN_CLICK,
    });
  };

  const handleCancelModal = () => {
    pushEvent({
      category: getGACategory(),
      action: GA_ACTION.CLOSE_MODAL_BTN_CLICK,
      label: GA_LABEL.CLOSE_MODAL,
    });
  };

  const handleDeleteApplicationFile = async () => {
    pushEvent({
      category: getGACategory(),
      action: GA_ACTION.DELETE_DOCUMENT_BTN_CLICK,
      label: GA_LABEL.DELETE_DOCUMENT_FROM_CONFIRM_BOX,
    });
    updateDocumentCenterByKey(DOCUMENT_CENTER_STORE_KEYS.VIEW_DOCUMENTS_LOADING, true);
    try {
      await deleteDocument({ fileId: deleteFile?.fileId || '' });
      handleBackFromConfirmation();
      setDeleteFile(undefined);
      if (currentStep === APPLICATION_TABS.CONFIRMATION && deleteFile?.fileType === FileType.POLICY) {
        getPolicyDocument();
      }
      await fetchDocuments();
      success(DOCUMENT_CENTER_MESSAGES.SUCCESS_DELETE_DOCUMENT, 3);
    } catch (error: any) {
      errorV2(error?.[0]?.errorMessage || DOCUMENT_CENTER_MESSAGES.ERROR_DELETE_DOCUMENT, 3);
    }
    updateDocumentCenterByKey(DOCUMENT_CENTER_STORE_KEYS.VIEW_DOCUMENTS_LOADING, false);
  };

  const handleDeleteDocumentClick = async (_: any, fileDetails: IFileDetails) => {
    pushEvent({
      category: getGACategory(),
      action: GA_ACTION.DELETE_DOCUMENT_BTN_CLICK,
      label: GA_LABEL.DELETE_DOCUMENT_FROM_TABLE,
    });
    setDeleteFile(fileDetails);
    setIsConfirmDocumentDeleteModal(true);
  };

  const cfModalConditionalProps = useMemo(() => {
    if (isConfirmDocumentDeleteModal) {
      return {
        width: 520,
        okText: 'Delete',
        cancelText: 'Back',
        cancelButtonProps: {
          id: 'btn_delete_file_cancel',
          disabled: viewDocumentsLoading,
          onClick: handleBackFromConfirmation,
        },
        okButtonProps: {
          id: 'btn_delete_file_ok',
          disabled: viewDocumentsLoading,
          loading: viewDocumentsLoading,
        },
        onOk: handleDeleteApplicationFile,
      };
    } else {
      return {
        width: 800,
        footer: null,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDocument, fetchDocuments, isConfirmDocumentDeleteModal, viewDocumentsLoading]);

  const fileUploadCustomRequest = async (options: any) => {
    setTimeout(() => {
      options.onSuccess('ok');
    }, 0);
  };

  const handlePaginationChange = (pagination: TablePaginationConfig, newFilter: Record<string, FilterValue | null>) => {
    setCurrentPage(pagination.current || 1);
    setFilters(newFilter);
  };

  const handleDownloadDocumentClick = async (_: any, fileDetails: IFileDetails) => {
    pushEvent({
      category: getGACategory(),
      action: GA_ACTION.DOWNLOAD_BTN_CLICK,
      label: GA_LABEL.DOWNLOAD,
    });
    updateDocumentCenterByKey(DOCUMENT_CENTER_STORE_KEYS.VIEW_DOCUMENTS_LOADING, true);
    try {
      const downloadURL = await downloadDocument({ fileId: fileDetails?.fileId });
      if (downloadURL) {
        window.open(downloadURL);
      }
      success(DOCUMENT_CENTER_MESSAGES.SUCCESS_DOWNLOAD_DOCUMENT, 3);
    } catch (error: any) {
      errorV2(error?.[0]?.errorMessage || DOCUMENT_CENTER_MESSAGES.ERROR_DOWNLOAD_DOCUMENT, 3);
    }
    updateDocumentCenterByKey(DOCUMENT_CENTER_STORE_KEYS.VIEW_DOCUMENTS_LOADING, false);
  };

  const handleUploadDocument = async () => {
    pushEvent({
      category: getGACategory(),
      action: GA_ACTION.UPLOAD_DOCUMENT_BTN_CLICK,
      label: GA_LABEL.UPLOAD_DOCUMENT,
    });
    const formAnswers = form?.getFieldsValue();
    // This is intended change because form.validateFields doesn't work when file is deleted from file upload section
    // it somehows preserve the state for the file. The actual update happens in fileList. So by making it undefined,
    // the form validates correctly for the file upload.
    if (formAnswers?.fileUpload?.fileList?.length !== 1) {
      form?.setFieldValue(DOCUMENT_CENTER_FORM_KEYS.FILE_UPLOAD, undefined);
    }
    await form?.validateFields();
    updateDocumentCenterByKey(DOCUMENT_CENTER_STORE_KEYS.UPLOAD_DOCUMENTS_LOADING, true);
    try {
      const fileUploaded = formAnswers?.fileUpload?.file;
      if (fileUploaded?.originFileObj) {
        const fileBase64Data = (await getBase64FromFile(fileUploaded?.originFileObj)) as string;
        await uploadDocument({
          file: fileBase64Data,
          fileType: formAnswers?.fileType,
          fileName: fileUploaded?.name,
          fileDescription: formAnswers?.fileDescription,
        });
        await form?.resetFields();
        await fetchDocuments();
        if (currentStep === APPLICATION_TABS.CONFIRMATION && formAnswers?.fileType === FileType.POLICY) {
          getPolicyDocument();
        }
        setCurrentPage(1);
        success(DOCUMENT_CENTER_MESSAGES.SUCCESS_UPLOAD_DOCUMENT, 3);
      }
    } catch (error: any) {
      errorV2(error?.[0]?.errorMessage || DOCUMENT_CENTER_MESSAGES.ERROR_UPLOAD_DOCUMENT, 3);
    }

    updateDocumentCenterByKey(DOCUMENT_CENTER_STORE_KEYS.UPLOAD_DOCUMENTS_LOADING, false);
  };

  const onFetchDocumentsErrorRetry = async () => {
    await fetchDocuments();
  };

  const applicationDocuments = useMemo(() => {
    return applicationDocumentsList?.filter((file) => !UNSUPPORTED_VIEW_FILE_TYPES.includes(file?.fileType));
  }, [applicationDocumentsList]);

  return (
    <>
      <CFModalStyled
        title='Document Center'
        disableCloseIcon={viewDocumentsLoading || uploadDocumentLoading}
        maskClosable={!(viewDocumentsLoading || uploadDocumentLoading)}
        onCancel={handleCancelModal}
        {...cfModalConditionalProps}
      >
        <DocumentCenterModalContentStyled id='form-modal-content' isVisible={!isConfirmDocumentDeleteModal}>
          <CFForm id='form-upload-document' form={form} disabled={uploadDocumentLoading}>
            <UploadDocumentFormItemStyled {...UPLOAD_DOCUMENT_FORM_CONFIG[0]}>
              <CFFileDropperStyled
                id='file-drop-upload-document'
                maxCount={1}
                maxSizeAllowedKb={MAX_ALLOWED_SIZE_FOR_FILE_UPLOAD}
                customRequest={fileUploadCustomRequest}
                sizeExceedMessage={DOCUMENT_CENTER_MESSAGES.FILE_UPLOAD_SIZE_EXCEED}
                accept={UPLOAD_ACCEPTED_FILES}
              >
                <InboxOutlinedWrapperStyled className='ant-upload-drag-icon'>
                  <InboxOutlinedStyled disabled={uploadDocumentLoading} />
                </InboxOutlinedWrapperStyled>
                <DocUploadTextStyled disabled={uploadDocumentLoading}>
                  Drop a document (pdf, doc, docx, xls, xlsx, ppt, pptx, txt, zip, jpeg, jpg, png) here or click to
                  upload
                </DocUploadTextStyled>
              </CFFileDropperStyled>
            </UploadDocumentFormItemStyled>
            <UploadDocumentFormItemStyled {...UPLOAD_DOCUMENT_FORM_CONFIG[1]}>
              <CFSelect
                id='file-type-upload-document'
                placeholder='Please select the type of file you want to upload'
                options={FILE_CATEGORY}
              ></CFSelect>
            </UploadDocumentFormItemStyled>
            <UploadDocumentFormItemStyled {...UPLOAD_DOCUMENT_FORM_CONFIG[2]}>
              <CFTextArea
                id='file-type-upload-document'
                placeholder='Please enter document description here'
                showCount
                maxLength={100}
                rows={1}
              ></CFTextArea>
            </UploadDocumentFormItemStyled>
            <UploadDocumentButtonWrapper>
              <UploadDocumentButton
                id='btn-upload-document'
                buttonType={BUTTON_TYPE.PRIMARY}
                onClick={handleUploadDocument}
                loading={uploadDocumentLoading}
                disabled={uploadDocumentLoading}
              >
                Upload Document
              </UploadDocumentButton>
            </UploadDocumentButtonWrapper>
          </CFForm>
          <ViewDocumentWrapperStyled>
            <>
              <ViewDocumentTitleStyled>Attachments</ViewDocumentTitleStyled>
              <ViewDocumentTableStyled
                id='tbl-view-documents'
                loading={viewDocumentsLoading || uploadDocumentLoading}
                dataSource={applicationDocuments}
                pagination={{
                  pageSize: VIEW_DOCUMENT_PAGE_SIZE,
                  current: currentPage,
                }}
                onChange={handlePaginationChange}
                locale={{
                  emptyText: fetchDocumentsError ? (
                    getErrorTextMessage(fetchDocumentsError, onFetchDocumentsErrorRetry)
                  ) : (
                    <TableEmptyStyled description={getEmptyAttachmentsMessage()} />
                  ),
                }}
                getPopupContainer={() => document.getElementById('tbl-view-documents') || document.body}
              >
                {VIEW_DOCUMENTS_TABLE_CONFIG(filters).map((col) => {
                  return <CFTableColumn<IFileDetails> {...col} key={col.key} />;
                })}
                <CFTableColumn
                  title='Actions'
                  key='actions'
                  render={getViewApplicationActionButtons(handleDownloadDocumentClick, handleDeleteDocumentClick)}
                  width={50}
                />
              </ViewDocumentTableStyled>
            </>
          </ViewDocumentWrapperStyled>
        </DocumentCenterModalContentStyled>
        <ConfirmModalContentStyled id='confirm-modal-content' isVisible={isConfirmDocumentDeleteModal}>
          {getDeleteApplicationFileModalContent()}
        </ConfirmModalContentStyled>
      </CFModalStyled>
    </>
  );
};

export default ApplicationDocumentCenterModal;
