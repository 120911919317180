import { Input } from 'antd';
import styled from 'styled-components';

const { TextArea } = Input;

export const StyledCFTextArea = styled(TextArea)`
  box-shadow: none;
  border-color: ${(p) => p.theme.colors.grayscale.grayBorder} !important;

  :hover {
    border-color: ${(p) => (!p.disabled ? p.theme?.colorScheme?.primaryHover : 'default')} !important;
  }

  // .ant-input {
  //   :hover,
  //   :focus {
  //     border-color: ${(p) => (!p.disabled ? p.theme?.colorScheme?.primaryHover : 'default')} !important;
  //   }
  // }

  // :focus {
  //   border-color: ${(p) => p.theme?.colorScheme?.primaryHover} !important;
  //   box-shadow: 0 0 0 2px ${(p) => p.theme.colors.common.inputBoxShadow} !important;
  // }
`;
