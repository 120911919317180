import { isDayjs } from 'dayjs';

import { ANTD_QUESTION_TYPE } from '../components/applicationQuestion/constants';
import { IApplicationQuestion } from '../components/applicationQuestion/interface';
import { validateAddress } from '../components/question/locationInputV2/utils';
import { getQuestionInfo } from '../components/underwriting/utils';
import { DATE_FORMAT } from '../globalConstants';
import { isObject, map } from './lodash';

export const convertToAntdSupportedAnswer = (
  answers: { [k: string]: string },
  antdQuestions: IApplicationQuestion[],
): { [k: string]: string } => {
  if (!answers || antdQuestions.length === 0) {
    return {};
  }
  let convertedObject = { ...answers };

  map(answers, (value, key) => {
    if (isDayjs(value)) {
      const question: IApplicationQuestion | undefined = antdQuestions.find(
        (item: IApplicationQuestion) => item.dataIndex === key,
      );

      if (!question) {
        convertedObject = {
          ...convertedObject,
          [key]: value.format(),
        };
      } else {
        switch (question.subtype?.toLowerCase()) {
          case ANTD_QUESTION_TYPE.YEAR:
            convertedObject = {
              ...convertedObject,
              [key]: value.format('YYYY'),
            };
            break;
          case ANTD_QUESTION_TYPE.MONTH:
            convertedObject = {
              ...convertedObject,
              [key]: value.format('MM'),
            };
            break;
          case ANTD_QUESTION_TYPE.DATE:
          default:
            convertedObject = {
              ...convertedObject,
              [key]: value.format(DATE_FORMAT.YYYYMMDD),
            };
        }
      }
    } else if (getQuestionInfo(key, antdQuestions).isAddressType) {
      if (isObject(value) && validateAddress(value)) {
        convertedObject = {
          ...convertedObject,
          [key]: JSON.stringify(value),
        };
      } else {
        delete convertedObject[key];
      }
    }
  });

  return convertedObject;
};
