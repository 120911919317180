import styled from 'styled-components';

import CFModalV2 from '../../ui-core/V2/cfModal/cfModal';
import CFTextArea from '../../ui-core/V2/cfTextArea/cfTextArea';

export const ReferQuoteModalStyled = styled(CFModalV2)``;

export const ReferQuoteNoteStyled = styled(CFTextArea)`
  .ant-input:hover,
  .ant-input:focus {
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
  }
`;
