import { RadioChangeEvent } from 'antd';
import { useCallback, useMemo } from 'react';

import { IRadioChoice } from './interface';
import { RadioChoiceStyled } from './styles';

const RadioChoice = ({
  defaultValue,
  id,
  style,
  optionType,
  buttonStyle,
  onChange,
  data,
  options,
  disabled = false,
  ...rest
}: IRadioChoice) => {
  const selectedOptionType = useMemo(() => optionType || 'button', [optionType]);
  const selectedButtonStyle = useMemo(() => buttonStyle || 'solid', [buttonStyle]);

  const handleChange = useCallback(
    (event: RadioChangeEvent) => {
      onChange && onChange(event, data);
    },
    [data, onChange],
  );
  return (
    <RadioChoiceStyled
      defaultValue={defaultValue}
      options={options}
      id={id}
      style={style}
      disabled={disabled}
      {...rest}
      optionType={selectedOptionType}
      buttonStyle={selectedButtonStyle}
      onChange={handleChange}
    />
  );
};

export default RadioChoice;
