import { APPLICATION_TABS } from '../../../constants/applicationV2Constants';
import { PAGE_NAME } from '../../../globalConstants';
import { useApplicationStore } from '../../../pages/v2/application/store';
import { getCurrentPageName } from '../../../utils/getCurrentPageName';
import { GA_CATEGORY as INDUSTRY_GA_CATEGORY } from '../../industrySelection/constants';
import { GA_CATEGORY as UW_GA_CATEGORY } from '../../underwriting/constants';

export const getGACategory = (carrierId?: string) => {
  const currentPage = getCurrentPageName();
  const { currentStep } = useApplicationStore.getState();

  switch (currentPage) {
    case PAGE_NAME.APPLICATION:
      switch (currentStep) {
        case APPLICATION_TABS.UNDERWRITING:
          return `${UW_GA_CATEGORY.UNDERWRITING_TAB_DELETE_CARRIER_MODAL}_for_${carrierId}`;
        case APPLICATION_TABS.INDUSTRY_SELECTION:
          return `${INDUSTRY_GA_CATEGORY.INDUSTRY_SELECTION_TAB_DELETE_CARRIER_MODAL}_for_${carrierId}`;

        default:
          return currentPage;
      }

    default:
      return currentPage;
  }
};
