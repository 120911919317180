import { IDisclaimer } from './interface';
import { IconWrapperStyled, TagStyled, TextStyled } from './styles';

const Disclaimer = ({ color = 'yellow', icon, children }: IDisclaimer) => {
  return (
    <TagStyled color={color}>
      {icon && <IconWrapperStyled>{icon}</IconWrapperStyled>}
      <TextStyled>{children}</TextStyled>
    </TagStyled>
  );
};

export default Disclaimer;
