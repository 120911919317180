import { IDependencyInfo } from '@coverforce-platform/cf-common-api-model';

import ApplicationQuestion from '../applicationQuestion/applicationQuestion';
import { IApplicationQuestion, IRenderDependentQuestion } from '../applicationQuestion/interface';
import CfQuestionWithStatus from '../cfQuestionWithStatusV2/cfQuestionWithStatus';
import { isChildQuestionRender } from './utils';

const DependentQuestion = ({
  question,
  getFieldValue,
  allQuestions,
  form,
  showStatus,
  prefixDataIndex,
  hidden = false,
  disabled = false,
}: IRenderDependentQuestion) => {
  const getChildQuestion = (dependentQuestion: IDependencyInfo, parentQuestion: IApplicationQuestion) => {
    return dependentQuestion.childQuestionIds?.map((id) => {
      const filteredQuestion = allQuestions?.find((item: IApplicationQuestion) => item.dataIndex === id);

      const hasRendered = filteredQuestion?.childToParentQuestionsMap
        ? filteredQuestion?.childToParentQuestionsMap?.find((el) => el.parentQuestionId === parentQuestion.dataIndex)
            ?.hasRendered
        : true;

      return (
        filteredQuestion &&
        hasRendered &&
        (showStatus ? (
          <CfQuestionWithStatus
            key={filteredQuestion.dataIndex}
            question={{ ...filteredQuestion, dataIndex: `${filteredQuestion.dataIndex}`, isChildRender: true }}
            questions={allQuestions}
            form={form}
            prefixDataIndex={prefixDataIndex}
            disabled={disabled}
          />
        ) : (
          <ApplicationQuestion
            key={`form-updater-dependent-item-${id}`}
            question={{ ...filteredQuestion, dataIndex: filteredQuestion.dataIndex, isChildRender: true }}
            allQuestions={allQuestions}
            form={form}
            hidden={hidden}
            disabled={disabled}
          />
        ))
      );
    });
  };

  // check parent question answered or not, if answered then check condition for dependency question and if its satisfied then return the question
  const Elements: any = question.dependentQuestions?.map((dependentQuestion) => {
    if (isChildQuestionRender({ question, getFieldValue, dependentQuestion, prefixDataIndex, form })) {
      return getChildQuestion(dependentQuestion, question);
    } else {
      return null;
    }
  });

  return Elements || <></>;
};

export default DependentQuestion;
