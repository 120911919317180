import styled from 'styled-components';

import CFAutoComplete from '../../../ui-core/V2/cfAutoComplete/cfAutoComplete';
import CFForm from '../../../ui-core/V2/cfForm/cfForm';
import CFInput from '../../../ui-core/V2/cfInput/cfInput';

export const LocationWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LocationContainerStyled = styled(CFForm)`
  margin-top: 5px;
  padding: 15px 15px 0px;
  border: 1px solid ${(p) => p.theme.colors.primary.border};
  border-radius: 3px;

  .ant-form-item,
  .ant-input-group .ant-form-item,
  &.ant-form .ant-form-item.autocomplete-form-item {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    border: none;
  }

  .ant-form-item-with-help .ant-form-item-explain {
    min-height: 18px;
  }

  .ant-form-item-margin-offset {
    margin-bottom: 0px;
  }
`;

export const InputStyled = styled(CFInput)`
  font-size: ${(p) => p.theme.typography.fontSize.medium};
`;

export const AutoCompleteStyled = styled(CFAutoComplete)`
  font-size: ${(p) => p.theme.typography.fontSize.medium};
`;
