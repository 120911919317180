import { message, Upload } from 'antd';
import { useCallback } from 'react';

import { ICFFileDropperProps } from './interface';
import { StyledCFFileDropper } from './styles';

const CFFileDropper = ({
  children,
  onChange,
  data,
  maxSizeAllowedKb = 5120,
  allowedFileTypes,
  sizeExceedMessage,
  ...rest
}: ICFFileDropperProps) => {
  const handleChange = useCallback(
    (info) => {
      onChange && onChange(info, data);
    },
    [data, onChange],
  );

  const beforeUpload = (file: any) => {
    const isFileTypeAllowed = allowedFileTypes?.length ? allowedFileTypes.includes(file.type) : true;
    if (!isFileTypeAllowed) {
      message.error(`You can only upload ${allowedFileTypes?.join(', ')} file!`);
    }
    const isFileSizeLtAllowed = file.size / 1024 < maxSizeAllowedKb;
    if (!isFileSizeLtAllowed) {
      message.error(sizeExceedMessage || `File must be smaller than ${maxSizeAllowedKb}KB!`);
    }
    return (isFileTypeAllowed && isFileSizeLtAllowed) || Upload.LIST_IGNORE;
  };

  return (
    <StyledCFFileDropper onChange={handleChange} beforeUpload={beforeUpload} {...rest}>
      {children}
    </StyledCFFileDropper>
  );
};

export default CFFileDropper;
