import { useCallback } from 'react';

import { ICFRadio } from './interface';
import { StyledCFRadio, StyledCFRadioButton, StyledCFRadioGroup } from './styles';

const CFRadio = (props: ICFRadio) => {
  const { data, onChange, disabled = false } = props;

  const handleChange = useCallback(
    (event) => {
      onChange && onChange(event, data);
    },
    [data, onChange],
  );

  return <StyledCFRadio disabled={disabled} {...props} onChange={handleChange} />;
};

export default CFRadio;

export const CFRadioButton = StyledCFRadioButton;

export const CFRadioGroup = StyledCFRadioGroup;
