import { IErrorResponseData } from '@coverforce-platform/cf-common-api-model';

import { DEFAULT_API_ERROR_MESSAGE } from '../globalConstants';
import { IApiErrorSchema } from '../types';

// Need to define a standard axios error type schema as exists for response
// Refer:- https://github.com/axios/axios/issues/517#issuecomment-266042361
export const parseApiError = (error: any): IApiErrorSchema => {
  const { config, response, message } = error;
  const defaultError: IApiErrorSchema = { config, response: null, message: null };
  if (response) {
    defaultError.response = response;
  } else {
    const errors: IErrorResponseData[] = [
      { errorCode: '', errorDetail: '', errorMessage: message || DEFAULT_API_ERROR_MESSAGE },
    ];
    defaultError.response = { data: { errors } };
  }
  return defaultError;
};
