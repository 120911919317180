import { FooterTextStyled, FooterWrapperStyled } from './styles';

const Footer = () => {
  return (
    <FooterWrapperStyled>
      <FooterTextStyled>Powered by CoverForce, Inc.</FooterTextStyled>
    </FooterWrapperStyled>
  );
};

export default Footer;
