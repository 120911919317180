import { Tag } from 'antd';
import styled from 'styled-components';

import Anchor from '../../ui-core/V2/anchor/anchor';

export const CarrierCodeHelpLinkStyled = styled(Anchor)`
  margin: 14px 24px 0px 24px;
  font-size: ${(p) => p.theme.typography.fontSize.medium};
  font-weight: ${(p) => p.theme.typography.fontWeight.medium};
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`;

export const DrawerInfo = styled.div`
  margin: 8px 24px 4px 24px;

  .ant-tag.ant-tag-yellow {
    color: #957c01;
  }
`;

export const CarrierInfoTagStyled = styled(Tag)`
  white-space: normal;
  font-size: ${(p) => p.theme.typography.fontSize.medium};
  // This is common for all the theme
  // color: #957c01;
`;
