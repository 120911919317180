import styled from 'styled-components';

import CFModalV2 from '../../ui-core/V2/cfModal/cfModal';

export const PolicyDeclinedReasonsModalStyled = styled(CFModalV2)``;

export const PolicyDeclinedReasonsStyled = styled.ol`
  padding-inline-start: 24px;
  padding-right: 12px;
`;

export const PolicyDeclinedReasonItemStyled = styled.li`
  :not(:last-child) {
    padding-bottom: 12px;
  }
`;
