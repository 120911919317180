import { GLOBAL_LOCALE } from '../../../globalConstants';
import { digitsOnlyRegex } from '../../../helpers/Utils';
import { numberFormatter } from '../../../utils/formatter';
import { getDecimalNumberRegex } from '../../../utils/regex';

export const usInputParser = (value: any): string => {
  return value?.replace(/(,*)/g, '');
};

export const usInputFormatter = (value: any): string => {
  const fractionDigits = value?.toString()?.split('.')?.[1]?.length;
  return value ? numberFormatter(GLOBAL_LOCALE.US, fractionDigits).format(value).toString() : '';
};

export const getNumber = ({ allowDecimals, inputValue }: { allowDecimals?: boolean; inputValue: string }) => {
  const firstDecimal = inputValue?.replaceAll(',', '')?.indexOf('.');

  // If the inputValue is just "." then we are treating it as "0."
  if (allowDecimals && inputValue === '.') {
    return '0.';
  }

  const callbackValue = allowDecimals
    ? inputValue?.replace(getDecimalNumberRegex(), '').replace(/\./g, (_, index) => (index !== firstDecimal ? '' : '.'))
    : inputValue?.replace(digitsOnlyRegex(), '');

  return callbackValue.toString();
};
