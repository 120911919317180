import { ConstructionTypeCode } from '@coverforce-platform/cf-common-types';

import { ANTD_QUESTION_TYPE } from '../applicationQuestion/constants';
import { IBopQuestion } from '../bopBusinessQuestion/interface';
import { IBuildingRiskInformationStore } from './interface';

export const BUILDING_CONSTRUCTION_FIELDS = [
  { label: 'Frame', value: ConstructionTypeCode.FRAME },
  { label: 'Joisted Masonry', value: ConstructionTypeCode.JOISTED_MASONRY },
  { label: 'Non Combustible', value: ConstructionTypeCode.NON_COMBUSTIBLE },
  { label: 'Masonry Non Combustible', value: ConstructionTypeCode.MASONRY_NON_COMBUSTIBLE },
  { label: 'Fire Resistive', value: ConstructionTypeCode.FIRE_RESISTIVE },
  { label: 'Modified Fire Resistive', value: ConstructionTypeCode.MODIFIED_FIRE_RESISTIVE },
];

export const ERROR_MESSAGES = {
  greaterThan0: 'Value should be greater than 0.',
};

export const QUESTION_KEYS = {
  totalStories: 'totalStories',
  constructionTypeCode: 'constructionTypeCode',
  areaOfConstructionProject: 'areaOfConstructionProject',
  totalConstructionProjectValue: 'totalConstructionProjectValue',
};

export const BUILDING_RISK_INFORMATION_QUESTION: IBopQuestion[][] = [
  [
    {
      type: ANTD_QUESTION_TYPE.DROPDOWN,
      name: 'Building Construction Type',
      dataIndex: QUESTION_KEYS.constructionTypeCode,
      placeholder: 'Choose the Building Construction Type',
      options: BUILDING_CONSTRUCTION_FIELDS,
      rules: [{ required: true, message: 'Please select Building Construction Type.' }],
    },
    {
      type: ANTD_QUESTION_TYPE.NUMBER,
      name: 'Total Stories',
      dataIndex: QUESTION_KEYS.totalStories,
      placeholder: 'Total Stories',
      rules: [
        {
          required: true,
          validator: (_: any, value: any) => {
            if (!value && value !== 0) {
              return Promise.reject('Please enter Total Stories.');
            }
            if (Number(value) <= 0) {
              return Promise.reject(ERROR_MESSAGES.greaterThan0);
            }
            return Promise.resolve();
          },
        },
      ],
    },
  ],
  [
    {
      type: ANTD_QUESTION_TYPE.NUMBER,
      name: 'Area of Construction Project',
      dataIndex: QUESTION_KEYS.areaOfConstructionProject,
      placeholder: 'Area of Construction Project',
      rules: [
        {
          required: true,
          validator: (_: any, value: any) => {
            if (!value && value !== 0) {
              return Promise.reject('Please enter Area of Construction Project.');
            }
            if (Number(value) <= 0) {
              return Promise.reject(ERROR_MESSAGES.greaterThan0);
            }
            return Promise.resolve();
          },
        },
      ],
    },
    {
      type: ANTD_QUESTION_TYPE.MONEY,
      name: 'Total Construction Project Value',
      dataIndex: QUESTION_KEYS.totalConstructionProjectValue,
      placeholder: 'Total Construction Project Value',
      rules: [
        {
          required: true,
          validator: (_: any, value: any) => {
            if (!value && value !== 0) {
              return Promise.reject('Please enter Total Construction Project Value.');
            }
            if (Number(value) <= 0) {
              return Promise.reject(ERROR_MESSAGES.greaterThan0);
            }
            return Promise.resolve();
          },
        },
      ],
    },
  ],
];

export const BUILDING_RISK_STORE_META_DATA = {
  NAME: 'Building Risk Information Store',
  ANONYMOUS_ACTION: 'BUILDING_RISK  _INFORMATION_ACTIONS',
  UPDATE_BUILDING_RISK_INFORMATION: 'UPDATE_BUILDING_RISK_INFORMATION',
  CEAR_BUILDING_RISK_INFORMATION: 'CEAR_BUILDING_RISK_INFORMATION',
};

export const BUILDING_RISK_STORE_KEYS: { [key: string]: keyof IBuildingRiskInformationStore } = {
  BUILDING_RISK_INFORMATION_QUESTIONS: 'buildingRiskInformationQuestions',
};
