import { useApplicationStore } from '../../pages/v2/application/store';
import CFCol from '../../ui-core/V2/cfCol/cfCol';
import { useQuotesStore } from '../quotes/store';
import { RequestToBindDetailsWrapper } from './styles';

export const getRequestToBindDetails = () => {
  const { applicationData, selectedCarriersInfo } = useApplicationStore.getState();
  const { selectedQuote } = useQuotesStore.getState();
  const { carrierId } = selectedQuote || {};

  const selectedCarrierInfo = selectedCarriersInfo.find((item) => item.carrierId === carrierId);

  return (
    <RequestToBindDetailsWrapper id='row_application_info'>
      <CFCol id='col_business_name' span={10}>
        <div>
          <b>Business Name</b>
        </div>
        <div>{applicationData?.basicBusinessDetails?.businessName ?? '--'}</div>
      </CFCol>
      <CFCol id='col_business' span={5}>
        <div>
          <b>Carrier</b>
        </div>
        <div>{selectedCarrierInfo?.carrierShortName ?? '--'}</div>
      </CFCol>
      <CFCol id='col_policy' span={7}>
        <div>
          <b>Quote Id</b>
        </div>
        <div>{selectedQuote?.carrierQuoteId ?? '--'}</div>
      </CFCol>
    </RequestToBindDetailsWrapper>
  );
};
