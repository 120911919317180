import { QuestionCircleOutlined } from '@ant-design/icons';
import { IBrokerCodeRestrictionsInfo } from '@coverforce-platform/cf-common-api-model';
import { TableColumnProps } from 'antd';

import { IBrokerCodeInfo, IEditBindActionsStore } from './interface';
import { TooltipStyled } from './styles';
import { getTableCellData, getTableCellTooltipTitle } from './utils';

export const bindActionsTableColumns = ({
  onSwitchClick,
  disabled,
}: {
  onSwitchClick: any;
  disabled?: boolean;
}): Array<TableColumnProps<IBrokerCodeInfo>> => [
  {
    title: '',
    dataIndex: 'level',
    key: 'level',
    width: 120,
    render: (_, data) => {
      return (
        <>
          {data?.level}
          <TooltipStyled title='Enable/Disbale this action for every Agency within your network/wholesale group'>
            <QuestionCircleOutlined />
          </TooltipStyled>
        </>
      );
    },
  },
  {
    title: (
      <>
        Download Quote Proposal
        <TooltipStyled title='Enable this to allow the Agent to download the quote proposal'>
          <QuestionCircleOutlined />
        </TooltipStyled>
      </>
    ),
    dataIndex: BROKER_CODE_RESTRICTIONS_KEYS.DOWNLOAD_QUOTE_PROSPOSAL,
    key: BROKER_CODE_RESTRICTIONS_KEYS.DOWNLOAD_QUOTE_PROSPOSAL,
    width: 120,
    render: (_, data) => {
      const key: keyof IBrokerCodeRestrictionsInfo = 'isQuoteProposalDisabledForAgency';
      return getTableCellData({
        key,
        disabled,
        dataIndex: BROKER_CODE_RESTRICTIONS_KEYS.DOWNLOAD_QUOTE_PROSPOSAL,
        tootlipTitle: getTableCellTooltipTitle(),
        onSwitchClick,
        data,
      });
    },
  },
  {
    title: (
      <>
        Bridge to Carrier
        <TooltipStyled title={`Enable this to allow the Agent to bridge into the Carrier's Agent Portal`}>
          <QuestionCircleOutlined />
        </TooltipStyled>
      </>
    ),
    dataIndex: BROKER_CODE_RESTRICTIONS_KEYS.BRIDGE_TO_CARRIER,
    key: BROKER_CODE_RESTRICTIONS_KEYS.BRIDGE_TO_CARRIER,
    width: 120,
    render: (_, data) => {
      const key: keyof IBrokerCodeRestrictionsInfo = 'isBridgingDisabledForAgency';
      return getTableCellData({
        key,
        disabled,
        dataIndex: BROKER_CODE_RESTRICTIONS_KEYS.BRIDGE_TO_CARRIER,
        tootlipTitle: getTableCellTooltipTitle(),
        onSwitchClick,
        data,
      });
    },
  },
  {
    title: (
      <>
        Refer To Underwriter (Carrier)
        <TooltipStyled title={`Enable this to allow the Agent to Refer a quote to the Carrier's underwriter`}>
          <QuestionCircleOutlined />
        </TooltipStyled>
      </>
    ),
    dataIndex: BROKER_CODE_RESTRICTIONS_KEYS.REFER_TO_UNDERWRITER,
    key: BROKER_CODE_RESTRICTIONS_KEYS.REFER_TO_UNDERWRITER,
    width: 120,
    render: (_, data) => {
      const key: keyof IBrokerCodeRestrictionsInfo = 'isReferToUWDisabledForAgency';
      return getTableCellData({
        key,
        disabled,
        dataIndex: BROKER_CODE_RESTRICTIONS_KEYS.REFER_TO_UNDERWRITER,
        tootlipTitle: getTableCellTooltipTitle(),
        onSwitchClick,
        data,
      });
    },
  },
  {
    title: (
      <>
        Bind NOW
        <TooltipStyled title='Enable this to allow the Agent to instantly Bind a policy'>
          <QuestionCircleOutlined />
        </TooltipStyled>
      </>
    ),
    dataIndex: BROKER_CODE_RESTRICTIONS_KEYS.BIND_NOW,
    key: BROKER_CODE_RESTRICTIONS_KEYS.BIND_NOW,
    width: 120,
    render: (_, data) => {
      const key: keyof IBrokerCodeRestrictionsInfo = 'isBindNowDisabledForAgency';
      return getTableCellData({
        key,
        disabled,
        dataIndex: BROKER_CODE_RESTRICTIONS_KEYS.BIND_NOW,
        tootlipTitle: getTableCellTooltipTitle(),
        onSwitchClick,
        data,
      });
    },
  },
  {
    title: (
      <>
        Request To Bind
        <TooltipStyled title='Enable this to allow the Agent to request the network management to bind a quote'>
          <QuestionCircleOutlined />
        </TooltipStyled>
      </>
    ),
    dataIndex: BROKER_CODE_RESTRICTIONS_KEYS.REQUEST_TO_BIND,
    key: BROKER_CODE_RESTRICTIONS_KEYS.REQUEST_TO_BIND,
    width: 120,
    render: (_, data) => {
      const key: keyof IBrokerCodeRestrictionsInfo = 'isRequestToBindDisabledForAgency';
      return getTableCellData({
        key,
        disabled,
        dataIndex: BROKER_CODE_RESTRICTIONS_KEYS.REQUEST_TO_BIND,
        tootlipTitle: getTableCellTooltipTitle(),
        onSwitchClick,
        data,
      });
    },
  },
  {
    title: (
      <>
        Add Modifiers
        <TooltipStyled title='Enable this to allow the Agent to add modifiers for the application'>
          <QuestionCircleOutlined />
        </TooltipStyled>
      </>
    ),
    dataIndex: BROKER_CODE_RESTRICTIONS_KEYS.ADD_MODIFIERS,
    key: BROKER_CODE_RESTRICTIONS_KEYS.ADD_MODIFIERS,
    width: 120,
    render: (_, data) => {
      const key: keyof IBrokerCodeRestrictionsInfo = 'isAddModifiersDisabledForAgency';
      return getTableCellData({
        key,
        disabled,
        dataIndex: BROKER_CODE_RESTRICTIONS_KEYS.ADD_MODIFIERS,
        tootlipTitle: getTableCellTooltipTitle(),
        onSwitchClick,
        data,
      });
    },
  },
];

export const EDIT_BIND_ACTIONS_STORE_KEYS: { [key: string]: keyof IEditBindActionsStore } = {
  PRESET_BROKER_CONFIG: 'presetBrokerConfig',
  AGENCY_ROW_DISABLED: 'agencyRowDisabled',
  BROKER_CONFIG: 'brokerConfig',
  UPDATE_BROKER_CONFIG: 'updateBrokerConfig',
  SELECTED_CARRIER_DETAILS: 'selectedCarrierDetails',
};

export const EDIT_BIND_ACTIONS_METADATA = {
  SET_BIND_ACTIONS_STORE_BY_KEY: 'setBindActionsStoreByKey',
  CLEAR_BIND_ACTIONS_STORE: 'clearEditBindActionsStore',
  ANONYMOUS_ACTION: 'EDIT_BIND_ACTIONS_STORE_ACTION',
  STORE_NAME: 'Edit Bind Actions Store',
};

export const BIND_ACTIONS_ROW_LEVEL = {
  AGENCY: 'Agency Level',
};

export const BIND_ACTIONS_MESSAGES = {
  UPDATE_SUCCESS: 'Broker config actions updated successfully!',
};

export const BROKER_CODE_RESTRICTIONS_KEYS = {
  BROKER_CODE_RESTRICTIONS: 'brokerCodeRestrictions',
  DOWNLOAD_QUOTE_PROSPOSAL: 'downloadQuoteProposal',
  BRIDGE_TO_CARRIER: 'bridgeToCarrier',
  REFER_TO_UNDERWRITER: 'referToUnderwriter',
  BIND_NOW: 'bindNow',
  REQUEST_TO_BIND: 'requestToBind',
  ADD_MODIFIERS: 'addModifiers',
};
export const GA_ACTION = {
  SAVE_QUOTE_ACTIONS: 'saveQuoteActionBtn_click',
  CLOSE_MODAL: 'closeQuoteActionBtn_click',
};

export const GA_LABEL = {
  SAVE_QUOTE_ACTIONS: 'Save quote actions',
  CLOSE_MODAL: 'Close the quote action modal',
};
