// eslint-disable-next-line no-shadow
export enum QuestionStatus {
  ANSWERED = 'ANSWERED',
  MANDATORY = 'MANDATORY',
  ERROR = 'ERROR',
  OPTIONAL = 'OPTIONAL',
}

export interface IStatusWrapper {
  status?: QuestionStatus;
  iconSize?: string;
  children?: React.ReactElement;
}
