import { isDevEnvironment } from './isDevEnvironment';

export const saveToSessionStorage = (storageKey: string, storageData: any): void => {
  try {
    sessionStorage.setItem(storageKey, JSON.stringify(storageData));
  } catch (err) {
    // eslint-disable-next-line no-console
    isDevEnvironment() && console.log('Unable to save to sessionStorage', err);
  }
};
