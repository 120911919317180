import { useCallback } from 'react';

import { ICFCheckbox } from './interface';
import { StyledCFCheckbox, StyledCFCheckboxGroup } from './styles';

const CFCheckbox = (props: ICFCheckbox) => {
  const { data, onChange } = props;

  const handleChange = useCallback(
    (event) => {
      onChange && onChange(event, data);
    },
    [data, onChange],
  );

  return <StyledCFCheckbox {...props} onChange={handleChange} />;
};

export default CFCheckbox;
export const CFCheckboxGroup = StyledCFCheckboxGroup;
