import { Carrier } from '@coverforce-platform/cf-common-types';

export const PAYMENT_WIDGET_FORM_CONFIG = {
  // Payment Option Deferred
  paymentPlanDeferred: {
    id: 'form_item_sel_payment_type_deferred',
    key: 'payment_plan_deferred',
    name: 'payment_plan_deferred',
  },

  // Payment Option Non-deferred
  paymentPlanNonDeferred: {
    id: 'form_item_sel_payment_type_non_deferred',
    key: 'payment_plan_non_deferred',
    name: 'payment_plan_non_deferred',
  },
  cardNumberNonDeferred: {
    id: 'form_item_inp_card_number_non_deferred',
    key: 'card_number_non_deferred',
    name: 'card_number_non_deferred',
  },
  cardExpiryNonDeferred: {
    id: 'form_item_inp_card_expiry_non_deferred',
    key: 'card_expiry_non_deferred',
    name: 'card_expiry_non_deferred',
  },
  cardCvvNonDeferred: {
    id: 'form_item_inp_card_cvv_non_deferred',
    key: 'card_cvv_non_deferred',
    name: 'card_cvv_non_deferred',
  },
  cardNameNonDeferred: {
    id: 'form_item_inp_card_name_non_deferred',
    key: 'card_name_non_deferred',
    name: 'card_name_non_deferred',
  },
};
export const DISABLED_PAYMENT_PLAN_CARRIERS: string[] = [Carrier.COALITION, Carrier.COALITION_SURPLUS];
