import { AcordDownloadTransactionStatus, AcordFormType } from '@coverforce-platform/cf-common-types';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useState } from 'react';

import { errorV2, success } from '../../../ui-core/Notification';
import CFImage from '../../../ui-core/V2/cfImage/cfImage';
import { useCFModalStore } from '../../../ui-core/V2/cfModal/store';
import { pushEvent } from '../../../utils/googleAnalytics';
import { GA_ACTION, GA_CATEGORY, GA_LABEL } from '../../applicationSteps/constants';
import {
  ACORD_FORM_TYPES,
  ACORD_LOADER_TEXT_INTERVAL_IN_MS,
  ACORD_LOADING_TEXTS,
  DOWNLOAD_ACORD_MESSAGES,
} from './constants';
import { useDownloadAcordStore } from './store';
import {
  AcordCheckboxStyled,
  AcordImageLoaderStyled,
  AcordLabelTextStyled,
  AcordTextLoaderStyled,
  CFCheckboxStyled,
  CFModalStyled,
  CheckboxItemStyled,
  CheckboxLabelWrapperStyled,
  ColStyled,
  FileDoneOutlinedStyled,
  PolicyTypeTextStyled,
  RowStyled,
} from './styles';
import { getPolicyTypeForAcord } from './utils';

const DownloadAcordModal = () => {
  const { requestAcord, downloadAcord } = useDownloadAcordStore();
  const { clearCfModal } = useCFModalStore();

  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedAcordForms, setSelectedAcordForms] = useState<string[]>([]);
  const [acordLoadingText, setAcordLoadingText] = useState('');

  const createAcordLoaderTextSetter = () => {
    let i = 0;
    return () => {
      setAcordLoadingText(ACORD_LOADING_TEXTS[i]);
      if (i < ACORD_LOADING_TEXTS.length - 1) {
        i++;
      }
    };
  };

  const handleDownload = async () => {
    pushEvent({
      category: GA_CATEGORY.APPLICATION_SIDEBAR_DOWNLOAD_ACORD_MODAL,
      action: GA_ACTION.DOWNLOAD_ACCORD_BTN_CLICK,
      label: GA_LABEL.DOWNLOAD_ACCORD,
    });
    const setAcordLoaderText = createAcordLoaderTextSetter();
    setAcordLoaderText();
    const intervalId = setInterval(setAcordLoaderText, ACORD_LOADER_TEXT_INTERVAL_IN_MS);
    setIsDownloading(true);
    try {
      const { acordDownloadTransactionId } = await requestAcord(selectedAcordForms as AcordFormType[]);
      if (acordDownloadTransactionId) {
        const response = await downloadAcord(acordDownloadTransactionId);
        const { acordDownloadTransactionStatus, presignedUrl } = response;
        if (acordDownloadTransactionStatus === AcordDownloadTransactionStatus.SUCCESS) {
          window.open(presignedUrl);
          success(DOWNLOAD_ACORD_MESSAGES.DOWNLOAD_SUCCESS, 5);
          clearCfModal();
        } else {
          errorV2(DOWNLOAD_ACORD_MESSAGES.DOWNLOAD_ERROR, 5);
        }
      }
    } catch (err: any) {
      errorV2(err?.[0]?.errorMessage || DOWNLOAD_ACORD_MESSAGES.DOWNLOAD_ERROR, 5);
    } finally {
      setIsDownloading(false);
      setSelectedAcordForms([]);
      setAcordLoadingText('');
      clearInterval(intervalId);
    }
  };

  const handleAcordFormCheckboxChange = (values: CheckboxValueType[]) => {
    setSelectedAcordForms(values as string[]);
  };

  const handleCloseModal = () => {
    pushEvent({
      category: GA_CATEGORY.APPLICATION_SIDEBAR_DOWNLOAD_ACORD_MODAL,
      action: GA_ACTION.CLOSE_MODAL_BTN_CLICK,
      label: GA_LABEL.CLOSE_MODAL,
    });
  };

  return (
    <CFModalStyled
      title='Download ACORD Forms'
      okText='Download'
      onOk={handleDownload}
      onCancel={handleCloseModal}
      confirmLoading={isDownloading}
      okButtonProps={{
        id: 'btn_download_acord_ok',
        loading: isDownloading,
        disabled: selectedAcordForms?.length === 0,
      }}
      cancelButtonProps={{ id: 'btn_download_acord_cancel', disabled: isDownloading }}
      disableCloseIcon={isDownloading}
      maskClosable={!isDownloading}
    >
      <>
        {isDownloading && (
          <>
            <AcordImageLoaderStyled>
              <CFImage id='img_acord_loader' src='/images/fancy_loader.gif' height={180} />
            </AcordImageLoaderStyled>
            <AcordTextLoaderStyled>{acordLoadingText}</AcordTextLoaderStyled>
          </>
        )}

        {!isDownloading && (
          <>
            Select the ACORD forms you want to generate and download:
            <AcordCheckboxStyled onChange={handleAcordFormCheckboxChange}>
              <RowStyled>
                {Object.keys(ACORD_FORM_TYPES).map((key) => {
                  return (
                    <ColStyled key={key} span={11} isChecked={selectedAcordForms.includes(ACORD_FORM_TYPES[key])}>
                      <CFCheckboxStyled id={`checkbox-item-${key}`} value={ACORD_FORM_TYPES[key]}>
                        <CheckboxItemStyled>
                          <FileDoneOutlinedStyled />
                          <CheckboxLabelWrapperStyled>
                            <AcordLabelTextStyled>{key?.split('_')?.join(' ')}</AcordLabelTextStyled>
                            <PolicyTypeTextStyled>{getPolicyTypeForAcord(ACORD_FORM_TYPES[key])}</PolicyTypeTextStyled>
                          </CheckboxLabelWrapperStyled>
                        </CheckboxItemStyled>
                      </CFCheckboxStyled>
                    </ColStyled>
                  );
                })}
              </RowStyled>
            </AcordCheckboxStyled>
          </>
        )}
      </>
    </CFModalStyled>
  );
};

export default DownloadAcordModal;
