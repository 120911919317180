import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import CFCol from '../../ui-core/V2/cfCol/cfCol';
import CFRow from '../../ui-core/V2/cfRow/cfRow';

export const MarkAsBoundHeaderWrapper = styled(CFRow)`
  margin: 20px 0px;
  justify-content: space-between;
`;

export const CFColStyled = styled(CFCol)`
  flex: 1;
`;

export const MarkAsBoundDisclaimerWrapperStyled = styled.div`
  margin-bottom: 12px;
`;

export const MarkAsBoundMessageInfoCircleOutlinedStyled = styled(InfoCircleOutlined)`
  font-size: ${(p) => p.theme.typography.fontSize.extendedMedium};
`;
